import React from 'react';
import styles from 'src/components/base/menuBar/MenuBar.module.scss';
import MenuItem from 'src/components/base/menuItem/MenuItem';
import BellIcon from 'src/components/logos/BellIcon';
import HomeIcon from 'src/components/logos/HomeIcon';
import { ROLES } from 'src/Constant';
import useHasAuthorization from 'src/store/hooks/useHasAuthorization';

const MenuBarEng: React.FC = () => {
    const isTechnologyAuthorized = useHasAuthorization(ROLES.technology);

    return (
        <div className={styles.menuContainer}>
            <ul className={styles.list}>
                {isTechnologyAuthorized && (
                    <>
                        <MenuItem text="Home" url="/eng">
                            <HomeIcon />
                        </MenuItem>
                        <MenuItem text="Events" url="/eng/events-search">
                            <BellIcon width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="Support" url="/eng/support">
                            <BellIcon width={14} height={14} />
                        </MenuItem>
                    </>
                )}
            </ul>
        </div>
    );
};

export default MenuBarEng;