import { PaymentType, TransactionType, TransferSource } from 'src/util/interfaces';

export const TRANSACTION_LINK = {
    flagged: 'flagged',
    resolved: 'resolved',
    all: 'all',
    chargeback: 'chargeback'
};
export interface TransactionSearchCount {
    flaggedCount: number;
    returnedCount: number;
    failedCount: number;
    allCount: number;
    chargebackCount: number;
    cardWaitingApprovalCount: number;
}

export type SearchTransaction = {
    id: string;
    transactionType: string;
    transactionAmount: string;
    date: string;
    transactionStatus: string;
    transferStatus: string;
    payorDbaName: string;
    payorName: string;
    payeeDbaName: string;
    payeeName: string;
    payorAccountLogo: string;
    payeeAccountLogo: string;
    payeePaymentMethod: string;
    payeePaymentType: string;
    payorPaymentMethod: string;
    payorPaymentType: string;
    source: keyof typeof TransferSource | 'N/A'
}

export type PayorOrPayeeDto = {
    institutionName: string;
    dbaName: string;
    accountNumber: string;
    name: string;
    accountLogo: string;
    paymentMethod: string;
    paymentType: keyof typeof PaymentType;
}

export type SearchTransactionDto = {
    transactionId: string;
    transactionType: keyof typeof TransactionType;
    transactionAmount: string;
    transactionDate: string;
    transactionStatus: string;
    transferStatus: string;
    payor: PayorOrPayeeDto;
    payee: PayorOrPayeeDto;
    source: Nullable<keyof typeof TransferSource>
}