import React from 'react';
import { useNavigate } from 'react-router-dom';
import ExitIcon from '../../../logos/ExitIcon';
import styles from './NavigableCloseButton.module.scss';

interface CloseButtonInterface {
    onClick?: () => void
};

const NavigateCloseButton: React.FC<CloseButtonInterface> = ({ onClick }) => {
    const navigate = useNavigate();
    return (
        <div>
            <button
                type="button"
                className={styles.closebtn}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    } else {
                        navigate(-1);
                    }
                }}>
                <ExitIcon />
            </button>
        </div >

    );
};

export default NavigateCloseButton;