import React from 'react';
import PaymentMediumBlock from 'src/components/business/PaymentMediumBlock/PaymentMediumBlock';
import LbaCardView from 'src/components/business/transactions/lbaCardView/LbaCardView';
import styles from 'src/components/business/transactions/linkedBankAccountView/fundingWithdrawingReportView/FundingWithdrawingReportView.module.scss';
import PayeePayorCardView from 'src/components/business/transactions/payeePayorCardView/PayeePayorCardView';
import { useStore } from 'src/store';
import { PaymentType } from 'src/util/interfaces';

type FundingReportViewProps = {
    payee: any;
}

const FundingReportView: React.FC<FundingReportViewProps> = ({ payee }) => {
    const { TransactionDetailsPageStore } = useStore();
    const payeeLbaDetails = TransactionDetailsPageStore.getPayeeFirstLBADetails();
    const payorLbaDetails = TransactionDetailsPageStore.getPayorFirstLBADetails();
    const payor = TransactionDetailsPageStore.transaction.payer;
    const availableBalance = payor.hopscotchBalance;

    const totalTransactionsCount = payor.averageTransactionSize;
    const totalTransactionsSum = payor.totalTransactionVolume;
    return (
        <div>
            <div className={styles.mainWrapper}>
                <div className={styles.payeeLbaContainer}>
                    <div className={styles.payeeLbaWrapper}>
                        <PayeePayorCardView
                            title='Payee'
                            dbaName={payee?.dbaName}
                            isVerified={payee?.isVerified}
                            score={payee?.score}
                            createdTimestamp={payee?.createdTimestamp}
                            caseId={payee?.caseId}
                            accountLogo={payee?.accountLogo}
                            hopscotchBalance={payee?.hopscotchBalance}
                            bankPartnerBalance={payee?.bankPartnerBalance}
                            averageTransactionSize={payee?.averageTransactionSize}
                            totalTransactionVolume={payee?.totalTransactionVolume}
                            planType={payee?.planType}
                        />
                        <LbaCardView
                            title='Payor'
                            institutionName={payorLbaDetails.institutionName}
                            accountNumber={payorLbaDetails.accountNumber}
                            availableBalance={availableBalance}
                            valueOfTransactions={totalTransactionsCount}
                            numberOfTransactions={totalTransactionsSum}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.lbaContainer}>
                <div className={styles.payeePayorWrapper}>
                    <div className={styles.payeeLBA}>
                        <PaymentMediumBlock
                            institutionName={payeeLbaDetails?.institutionName}
                            isBackup={payeeLbaDetails?.isFunding}
                            isBlocked={payeeLbaDetails?.isBlocked}
                            isOps={payeeLbaDetails?.isOps}
                            accountNumber={payeeLbaDetails?.accountNumber}
                            ownerName={payeeLbaDetails?.ownerName}
                            paymentType={payeeLbaDetails?.paymentType || PaymentType.NotSet}
                        />
                    </div>
                    <div className={styles.payorLBA}>
                        <PaymentMediumBlock
                            institutionName={payorLbaDetails?.institutionName}
                            isBackup={payorLbaDetails?.isFunding}
                            isBlocked={payorLbaDetails?.isBlocked}
                            isOps={payorLbaDetails?.isOps}
                            accountNumber={payorLbaDetails?.accountNumber}
                            ownerName={payorLbaDetails?.ownerName}
                            paymentType={payorLbaDetails?.paymentType || PaymentType.NotSet}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FundingReportView;