import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import BaseAuditHistoryPopup from 'src/components/base/popups/baseAuditHistoryPopup/BaseAuditHistoryPopup';
import { useStore } from 'src/store';
import FactoredInvoiceDetailsContent from './factoredInvoiceDetailsContent/FactoredInvoiceDetailsContent.tsx';

type FactoredInvoiceAuditPopup = {
    popupVisible: boolean;
    hidePopup: any;
};

const FactoredInvoiceAuditPopup: React.FC<FactoredInvoiceAuditPopup> = observer(({ popupVisible, hidePopup }) => {
    const { FactoredInvoiceDetailsPageStore } = useStore();
    const { invoiceId } = useParams();
    const fetch = useFetchLegacy();

    const histories = FactoredInvoiceDetailsPageStore.getAuditInvoicesHistory();

    return (
        <BaseAuditHistoryPopup
            popupVisible={popupVisible}
            hidePopup={hidePopup}
            title="Invoice History"
            updateCallBack={() => invoiceId && FactoredInvoiceDetailsPageStore.fetchInvoicesAuditCSV(fetch, invoiceId)}
        >
            <FactoredInvoiceDetailsContent histories={histories} />
        </BaseAuditHistoryPopup>
    );
});

export default FactoredInvoiceAuditPopup;