import { observer } from 'mobx-react-lite';
import React from 'react';
import FeatureItem from 'src/pages/paidPlanControls/featureItem/FeatureItem';
import { PaidProgramControlsInterface } from 'src/pages/paidPlanControls/paidPlanControlsInterface/PaidPlanControlsInterface';
import { useStore } from 'src/store';
import styles from '../PaidPlanControls.module.scss';

type FeatureListProps = {
    isPlanShown: boolean;
    planFeatures: PaidProgramControlsInterface[];
    planType: string
    updatePlanType?: (value: boolean, featureId: string, planType: string) => void
}

const FeatureList: React.FC<FeatureListProps> = observer(({ isPlanShown, planFeatures, planType, updatePlanType }) => {
    const { PaidPlanControlsStore } = useStore();
    const updatedFeature = PaidPlanControlsStore.getUpdatedFeature();

    const handleChange = (value: boolean, featureId: string,) => {
        updatePlanType?.(value, featureId, planType);

    };
    return (<div className={styles.sectionWrapper}>
        {isPlanShown &&
            planFeatures.map((feature: PaidProgramControlsInterface) => (
                <FeatureItem updatedFeature={updatedFeature} onChange={handleChange} enabled={feature.enabled} key={feature.featureId} featureId={feature.featureId} description={feature.featureDescription || ''} wrapperClassName={styles.featureItemWrapper} />
            ))}
    </div>
    );
})
    ;

export default FeatureList;