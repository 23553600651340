import React from 'react';
import Title from '../../components/base/title/Title';
import MainLayout from '../mainLayout/MainLayout';
import MenuBarContentLayout from '../menubarLayout/MenuBarContentLayout';
import TradeMark from '../trademark/TradeMark';

type Props = {
    children: JSX.Element
}

const EntireLayout: React.FC<Props> = ({ children }) => (
    <MainLayout>
        <Title />
        <MenuBarContentLayout>{children}</MenuBarContentLayout>
        <TradeMark />
    </MainLayout>
);

export default EntireLayout;
