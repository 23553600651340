const getCookie = (name: string): string | null => {
    const nameEQ = `${name}=`;
    const caa = document.cookie.split(';');
    let i = 0;
    for (i; i < caa.length; i++) {
        let item = caa[i];
        while (item.charAt(0) === ' ') { item = item.substring(1, item.length); }
        if (item.indexOf(nameEQ) === 0) { return item.substring(nameEQ.length, item.length); }
    }
    return null;
};

export default getCookie;