import { Column, HeaderFilter } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import LinkButton from 'src/components/base/buttons/linkButton/LinkButton';
import LinkButtonWrapper from 'src/components/base/buttons/linkButton/linkButtonWrapper/LinkButtonWrapper';
import DataGridWrapper from 'src/components/base/dataGridWrapper/DataGridWrapper';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import StatusCell from 'src/components/base/statusCell/StatusCell';
import {
    GREY_TEXT_COLOR, TRANSACTION_DETAILS_URL,
    VERTICAL_ALIGN
} from 'src/Constant';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import { renderAccountLogo, renderAmountCell } from 'src/util';
import currencyColumnFilter from 'src/util/datagrid/filters/currencyColumnFilter';
import renderTextCell from 'src/util/datagrid/renderTextCell';
import rowType from 'src/util/datagrid/rowType';
import RenderPayorPaymentCell from '../../util/datagrid/cellRenders/renderPayorPaymentCell/RenderPayorPaymentCell';
import { SearchPaymentType } from '../../util/interfaces';
import styles from './TransactionsSearchPage.module.scss';

const TransactionsSearchPage: React.FC = observer(() => {
    const { type } = useParams();
    const fetch = useFetchLegacy();
    const { TransactionsSearchPageStore, sharedStore } = useStore();

    useEffect(() => {
        if (type) {
            TransactionsSearchPageStore.fetchTransactions(fetch, type);
            TransactionsSearchPageStore.fetchTransactionCounts(fetch);
        }
    }, [TransactionsSearchPageStore, type]);

    const transactions = TransactionsSearchPageStore.getTransactions();
    const { flaggedCount, returnedCount, allCount, chargebackCount, failedCount, cardWaitingApprovalCount } = TransactionsSearchPageStore.getCounts();

    const onCellPrepared = (e: any) => {
        if (rowType(e)) {
            e.cellElement.style.color = GREY_TEXT_COLOR;
            e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
        }
    };

    const renderStatus = (data: { value: string }): JSX.Element => <StatusCell status={data.value} />;
    const renderPayeeCell = (rowData: { payeeDbaName: string; payeeName: string }) => <><p className={styles.businessName}>{rowData.payeeDbaName}</p> {rowData.payeeName}</>;
    const renderPayorCell = (rowData: { payorDbaName: string; payorName: string }) => <><p className={styles.businessName}>{rowData.payorDbaName}</p> {rowData.payorName}</>;
    const textValue = sharedStore.textValue;

    const handleTextChange = (event: string) => {
        sharedStore.setTextValue(event);
    };

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Payments</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <LinkButtonWrapper>
                            <LinkButton
                                isActive={type === SearchPaymentType.all}
                                to={`/biz/search-transactions/${SearchPaymentType.all}`}
                                count={allCount}
                                label="All"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={type === SearchPaymentType.cardWaitingApproval}
                                to={`/biz/search-transactions/${SearchPaymentType.cardWaitingApproval}`}
                                count={cardWaitingApprovalCount}
                                label="Card approvals"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={type === SearchPaymentType.failed}
                                to={`/biz/search-transactions/${SearchPaymentType.failed}`}
                                count={failedCount}
                                label="Failed"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={type === SearchPaymentType.flagged}
                                to={`/biz/search-transactions/${SearchPaymentType.flagged}`}
                                count={flaggedCount}
                                label="Flagged"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={type === SearchPaymentType.returned}
                                to={`/biz/search-transactions/${SearchPaymentType.returned}`}
                                count={returnedCount}
                                label="Returned"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={type === SearchPaymentType.chargeback}
                                to={`/biz/search-transactions/${SearchPaymentType.chargeback}`}
                                count={chargebackCount}
                                label="Chargeback"
                            />
                        </LinkButtonWrapper>

                        <DataGridWrapper
                            dataSource={transactions}
                            onCellPrepared={onCellPrepared}
                            keyExp='id'
                            navigator={TRANSACTION_DETAILS_URL}
                            wordWrapEnabled={true}
                            headerFilter={true}
                            textValue={textValue}
                            handleTextChange={handleTextChange}
                        >
                            <Column
                                allowSorting={false}
                                dataField="payeeAccountLogo"
                                caption="Payee"
                                cellRender={renderAccountLogo}
                                width="auto"
                                allowHeaderFiltering={false}
                                allowFiltering={true}
                            />
                            <Column
                                dataField="payeeDbaName"
                                allowSorting={true}
                                allowFiltering={true}
                                caption=""
                                width="auto"
                                cellRender={renderTextCell}
                                calculateCellValue={renderPayeeCell}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                allowSorting={false}
                                dataField="payorAccountLogo"
                                caption="Payor"
                                cellRender={renderAccountLogo}
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                caption=""
                                width="auto"
                                cellRender={renderTextCell}
                                calculateCellValue={renderPayorCell}
                                allowHeaderFiltering={false}
                            />
                            <Column width="100%" />
                            <Column
                                dataField="source"
                                caption="Source"
                                dataType="string"
                                width="auto"
                            />
                            <Column
                                dataField="id"
                                caption="ID"
                                dataType="string"
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField="transactionType"
                                caption="Type"
                                dataType="string"
                                width="auto"
                            />
                            <Column
                                dataField="payorPaymentType"
                                caption="Payment Method"
                                cellRender={RenderPayorPaymentCell}
                                dataType="string"
                                width={200}
                            />
                            <Column
                                dataField="transactionStatus"
                                caption="Status"
                                allowSorting={true}
                                allowFiltering={true}
                                width="auto"
                                cellRender={renderStatus}
                            />
                            <Column
                                dataField="transactionAmount"
                                caption="Amount"
                                dataType="number"
                                alignment="left"
                                cellRender={renderAmountCell}
                                width="auto"
                                format="currency"
                            >
                                <HeaderFilter dataSource={currencyColumnFilter('transactionAmount')} />
                            </Column>
                            <Column
                                dataField="transferStatus"
                                caption="Transfer Status"
                                allowSorting={true}
                                allowFiltering={true}
                                width="auto"
                                cellRender={renderStatus}
                                visible={false}
                            />
                            <Column
                                dataField="date"
                                caption="Date"
                                width={100}
                                allowSorting={true}
                                sortOrder='desc'
                                dataType="date"
                                format="MMM dd, yyyy hh:mm a"
                            />
                            <Column
                                dataField="payorName"
                                allowFiltering={true}
                                visible={false}
                            />
                            <Column
                                dataField="payorDbaName"
                                allowFiltering={true}
                                visible={false}
                            />
                            <Column
                                dataField="payeeName"
                                allowFiltering={true}
                                visible={false}
                            />
                            <Column
                                dataField="payeeDbaName"
                                visible={false}
                            />
                        </DataGridWrapper>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default TransactionsSearchPage;