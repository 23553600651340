import { makeAutoObservable } from 'mobx';
import { FetchInterface } from 'src/api/useFetch';
import { SEARCH_ACCOUNTS_COUNT_URL, SEARCH_ACCOUNTS_URL } from 'src/Constant';
import { AccountSearchCount, AccountSearchInterface, AccountSearchInterfaceResponse } from 'src/pages/accountSearchPage/AccountSearchPageStoreInterface';
import { ResponseInterface } from 'src/util/interfaces';
import { selectAccountSearch } from 'src/util/selectors';

export interface AccountSearchPageStoreInterface {
    status: string;
    getCounts(): AccountSearchCount,
    getAccounts(): AccountSearchInterface[],
    getTotalAccounts(): number,
    setSelected(status: string): void,
    fetchVerifiedCounts(fetch: FetchInterface<AccountSearchCount>): void,
    fetchUnverifiedCounts(fetch: FetchInterface<AccountSearchCount>): void,
    fetchExternalUserCount(fetch: FetchInterface<AccountSearchCount>): void,
    fetchNewUserCount(fetch: FetchInterface<AccountSearchCount>): void,
    fetchAllCount(fetch: FetchInterface<AccountSearchCount>): void,
    fetchAccounts(fetch: FetchInterface<AccountSearchInterface[]>, status: string): void,
}

const AccountSearchPageStore = (): AccountSearchPageStoreInterface =>
    makeAutoObservable({
        accounts: [] as AccountSearchInterface[],
        status: 'Verified',
        unverifiedCount: 0,
        verifiedCount: 0,
        externalUserCount: 0,
        newUserCount: 0,
        allCount: 0,
        getCounts(): AccountSearchCount {
            return {
                unverifiedCount: this.unverifiedCount,
                verifiedCount: this.verifiedCount,
                allCount: this.allCount,
                externalUserCount: this.externalUserCount,
                newUserCount: this.newUserCount,
            };
        },
        getAccounts(): AccountSearchInterface[] {
            return this.accounts;
        },
        getTotalAccounts(): number {
            return this.accounts.length;
        },
        setSelected(status: string) {
            this.status = status;
        },
        fetchVerifiedCounts(fetch: FetchInterface<AccountSearchCount>) {
            fetch({ url: SEARCH_ACCOUNTS_COUNT_URL('Verified') }, ({ data }) => {
                this.verifiedCount = data;
            });
        },
        fetchUnverifiedCounts(fetch: FetchInterface<AccountSearchCount>) {
            fetch({ url: SEARCH_ACCOUNTS_COUNT_URL('Unverified') }, ({ data }) => {
                this.unverifiedCount = data;
            });
        },
        fetchExternalUserCount(fetch: FetchInterface<AccountSearchCount>) {
            fetch({ url: SEARCH_ACCOUNTS_COUNT_URL('ExternalUser') }, ({ data }) => {
                this.externalUserCount = data;
            });
        },
        fetchNewUserCount(fetch: FetchInterface<AccountSearchCount>) {
            fetch({ url: SEARCH_ACCOUNTS_COUNT_URL('NewUser') }, ({ data }) => {
                this.newUserCount = data;
            });
        },
        fetchAllCount(fetch: FetchInterface<AccountSearchCount>) {
            fetch({ url: SEARCH_ACCOUNTS_COUNT_URL('All') }, ({ data }) => {
                this.allCount = data;
            });
        },
        fetchAccounts(fetch: FetchInterface<AccountSearchInterface[]>, status: string) {
            fetch({ url: SEARCH_ACCOUNTS_URL(status) }, (res: ResponseInterface<AccountSearchInterfaceResponse[]>) => {
                this.accounts = res.data.map(theAccount => selectAccountSearch(theAccount));
            },
                true
            );
        },
    });

export default AccountSearchPageStore;