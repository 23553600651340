import { Popup } from 'devextreme-react';
import React from 'react';
import Button from 'src/components/base/buttons/button/Button';
import CancelButton from 'src/components/base/buttons/cancelButton/CancelButton';
import NavigableCloseButton from 'src/components/base/buttons/navigableCloseButton/NavigableCloseButton';
import HRLine from 'src/components/base/hrLine/HRLine';
import styles from './SimpleConfirmPopup.module.scss';

interface SimpleConfirmPopupProps {
    isVisible: boolean;
    onAccept: () => void;
    onReject: () => void;
    title: string;
    titleClassName?: string
    modalId: string;
    children?: any;
    subtext?: string
    subtextClassName?: string
    height?: string;
    width?: string
    cancelCtaText?: string
    acceptCtaText?: string
    showCloseButton?: boolean
}



const SimpleConfirmPopup: React.FC<SimpleConfirmPopupProps> = ({
    isVisible,
    onAccept,
    onReject,
    title,
    titleClassName = '',
    modalId,
    children,
    subtext = '',
    subtextClassName = '',
    height = '240px',
    width = '448px',
    cancelCtaText = 'Cancel',
    acceptCtaText = 'Accept',
    showCloseButton = false
}): JSX.Element => {

    const handleAccept = () => {
        onAccept();
    };

    const handleReject = () => {
        onReject();
    };

    const renderTitle = () => <div className={titleClassName} > {title} {showCloseButton ? <NavigableCloseButton onClick={handleReject} /> : ''}</div>;

    return (
        <Popup
            data-testid="simpleConfirmationPopup"
            visible={isVisible}
            height={height}
            width={width}
            onHiding={handleReject}
            showCloseButton={showCloseButton}
            closeOnOutsideClick={true}
            titleComponent={renderTitle}
        >
            <div id={modalId} className={styles.contentWrapper} >
                <div className={styles.content}>
                    <div className={subtextClassName}> {subtext}</div>
                    {children}
                </div>
            </div>
            <HRLine />
            <div className={styles.buttonWrapper}>
                <CancelButton onClick={handleReject}>
                    {cancelCtaText}
                </CancelButton>

                <Button onClick={handleAccept}>
                    {acceptCtaText}
                </Button>

            </div>
        </Popup>
    );
};

export default SimpleConfirmPopup;
