/* eslint-disable react/require-default-props */
import React from 'react';
import styles from './NumberInput.module.scss';

type Props = {
    placeholder: string;
    value?: number;
    onChange: (e: any) => void;
};

const NumberInput: React.FC<Props> = ({ placeholder, value, onChange }) => (
    <input
        type="number"
        className={styles.numberInput}
        placeholder={placeholder}
        value={value}
        step="any"
        onChange={(e) => onChange(e.target.value)} />
);

export default NumberInput;
