import { observer } from 'mobx-react-lite';
import React, { memo } from 'react';
import BaseAuditHistoryPopup from 'src/components/base/popups/baseAuditHistoryPopup/BaseAuditHistoryPopup';
import PlanAuditHistoryItem from 'src/components/business/planAuditHistoryItem/PlanAuditHistoryItem';

import { AuditHistory } from 'src/pages/paidPlanControls/paidPlanControlsInterface/PaidPlanControlsInterface';
import { useStore } from 'src/store';
import styles from './PlanAuditHistoryPopup.module.scss';

interface PlanAuditHistory {
    title: string;
    popupVisible: boolean;
    hidePopup: () => void;
    downloadReport: () => void;
    isShowDownloadReportAction?: boolean
};

const PlanAuditHistoryPopup: React.FC<PlanAuditHistory> = observer((props: PlanAuditHistory) => {
    const { title, popupVisible, hidePopup, downloadReport, isShowDownloadReportAction = true } = props;
    const { PaidPlanControlsStore } = useStore();
    const audits = PaidPlanControlsStore.getAuditHistory();

    return (
        <BaseAuditHistoryPopup
            popupVisible={popupVisible}
            hidePopup={hidePopup}
            title={title}
            updateCallBack={downloadReport}
            isShowDownloadReportAction={isShowDownloadReportAction}
        >
            <div className={styles.wrapper}>
                {audits?.map((audit: AuditHistory) => (
                    <PlanAuditHistoryItem
                        key={audit.id}
                        audit={audit}
                    />
                ))}
            </div>
        </BaseAuditHistoryPopup>
    );
});

export default memo(PlanAuditHistoryPopup);