import React from 'react';
import { APP_VERSON } from '../../Constant';
import styles from './Trademark.module.scss';

const TradeMark: React.FC = () => (
    <div className={styles.trademark}>
        <p>Made by Hopscotch HQ® {APP_VERSON}</p>
    </div>
);

export default TradeMark;
