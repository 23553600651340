// eslint-disable-next-line
import cn from 'classnames';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { TOAST_IS_FAILURE, TOAST_IS_SUCCESS } from 'src/Constant';
import styles from 'src/static-assets/styles/base/_base.scss';
import { useStore } from 'src/store';

const ToastContainerWrapper: React.FC = () => {
    const { sharedStore } = useStore();
    const toast = sharedStore.getToast();

    return (
        <ToastContainer className={cn({ [styles.success]: toast.status === TOAST_IS_SUCCESS, [styles.error]: toast.status === TOAST_IS_FAILURE })} position='top-center' autoClose={4000} hideProgressBar={true} />
    );
};

export default ToastContainerWrapper;