// eslint-disable-next-line id-length
import { Column, HeaderFilter } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import {
    BlockButton,
    Row,
    Section,
    SectionLabel
} from 'src/components';
import LinkButton from 'src/components/base/buttons/linkButton/LinkButton';
import LinkButtonWrapper from 'src/components/base/buttons/linkButton/linkButtonWrapper/LinkButtonWrapper';
import DataGridWrapper from 'src/components/base/dataGridWrapper/DataGridWrapper';
import InvoiceLimitCell from 'src/components/base/invoiceLimitCell/InvoiceLimitCell';
import LbaMultipleAccountsTooltip from 'src/components/base/lbaMultipleAccountsTooltip/LbaMultipleAccountsTooltip';
import StatusCell from 'src/components/base/statusCell/StatusCell';
import PlanType from 'src/components/business/planType/PlanType';
import {
    ACCOUNT_DETAILS_DYNAMIC_APP_URL,
    GREY_TEXT_COLOR,
    VERTICAL_ALIGN
} from 'src/Constant';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import {
    accountStatusFilter,
    currencyColumnFilter,
    renderAccountLogo,
    renderAmountCell
} from 'src/util';
import renderTextCell from 'src/util/datagrid/renderTextCell';
import rowType from 'src/util/datagrid/rowType';
import { PaymentDetail, RowData } from 'src/util/interfaces';
import styles from './AccountSearchPage.module.scss';


const AccountSearchPage: React.FC = observer(() => {
    const { status } = useParams();
    const { AccountSearchPageStore, sharedStore } = useStore();
    const fetch = useFetchLegacy();
    const paramStatus = status;
    const navigate = useNavigate();

    useEffect(() => {
        if (paramStatus) {
            AccountSearchPageStore.setSelected(paramStatus);
            AccountSearchPageStore.fetchAccounts(fetch, paramStatus);
        }
    }, [AccountSearchPageStore, paramStatus]);

    useEffect(() => {
        AccountSearchPageStore.fetchVerifiedCounts(fetch);
        AccountSearchPageStore.fetchUnverifiedCounts(fetch);
        AccountSearchPageStore.fetchExternalUserCount(fetch);
        AccountSearchPageStore.fetchNewUserCount(fetch);
        AccountSearchPageStore.fetchAllCount(fetch);
    }, [AccountSearchPageStore, paramStatus]);

    const accounts = AccountSearchPageStore.getAccounts();
    const { unverifiedCount, verifiedCount, externalUserCount, newUserCount, allCount } = AccountSearchPageStore.getCounts();
    const renderAccountPlanType = (cell: { value: string }): JSX.Element => <PlanType planLabel={cell.value} />;

    const renderHeader = () => (
        <>
            <p>Account Name (Account Owner)</p>
            <p className={styles.secondaryHeader}>Email (BizID)</p>
        </>
    );

    const renderAccountInfoCell = (rowData: { name: string; dbaName: string; emailAddress: string, bizId: string }) => <>
        <p className={styles.userData}>{rowData.dbaName} ({rowData.name})</p>{rowData.emailAddress} ({rowData.bizId})
    </>;

    const onRowSelected = (rowData: RowData) => {
        const id = rowData.selectedRowKeys[0];
        navigate(ACCOUNT_DETAILS_DYNAMIC_APP_URL(id));
    };

    const onCellPrepared = (e: any) => {
        if (rowType(e)) {
            if (e.column) {
                e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
                e.cellElement.style.color = GREY_TEXT_COLOR;
            }
            if (e.column.dataField === 'invoiceLimit'
                || e.column.dataField === 'accountCreated'
                || e.column.dataField === 'caseStatus'
                || e.column.dataField === 'isFrozen') {
                e.cellElement.style.paddingRight = '15px';
            }
        }
    };

    const renderAccountStatus = (cell: { value: string, data: { isLocked: string, isLockedByOps: boolean } }): JSX.Element => {
        if (cell.data.isLocked) {
            return <StatusCell status={`Locked (${cell.data.isLockedByOps ? 'O' : 'H'})`} />;
        }
        return <StatusCell status={cell.value} />;
    };

    const renderInvoiceLimit = (cell: { value: number, data: { invoiceLimit: number, hasInvoiceLimitBeenUpdated: boolean } }): JSX.Element => <InvoiceLimitCell invoiceLimit={cell.value} displayIcon={cell.data.hasInvoiceLimitBeenUpdated} />;

    const renderpaymentStatus = (cell: { data: { paymentStatus: string, paymentDetails: [PaymentDetail] } }) => {
        const paymentDetails = cell.data.paymentDetails;
        if (cell.data.paymentStatus === 'NotSet') {
            return 'No Payment';
        }
        if (paymentDetails.length > 1) {
            return <LbaMultipleAccountsTooltip paymentStatuses={paymentDetails} id={paymentDetails[0].id} />;
        }
        return paymentDetails.map((paymentDetail: PaymentDetail) => <BlockButton
            isBlocked={paymentDetail.isBlocked}
            isOps={paymentDetail.hasOpsUpdated}
            key={paymentDetail.id}
            paymentType={paymentDetail.paymentType} />);
    };

    const textValue = sharedStore.textValue;

    const handleTextChange = (event: string) => {
        sharedStore.setTextValue(event);
    };

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Accounts</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <LinkButtonWrapper>
                            <LinkButton
                                isActive={paramStatus === 'All'}
                                store={AccountSearchPageStore}
                                to="/biz/account-search/status/All"
                                count={allCount}
                                label="All"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramStatus === 'Verified'}
                                store={AccountSearchPageStore}
                                to="/biz/account-search/status/Verified"
                                count={verifiedCount}
                                label="Verified"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramStatus === 'Unverified'}
                                store={AccountSearchPageStore}
                                to="/biz/account-search/status/Unverified"
                                count={unverifiedCount}
                                label="Unverified"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramStatus === 'NewUser'}
                                store={AccountSearchPageStore}
                                to="/biz/account-search/status/NewUser"
                                count={newUserCount}
                                label="New"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramStatus === 'ExternalUser'}
                                store={AccountSearchPageStore}
                                to="/biz/account-search/status/ExternalUser"
                                count={externalUserCount}
                                label="External"
                            />
                        </LinkButtonWrapper>
                        <DataGridWrapper
                            dataSource={accounts}
                            onCellPrepared={onCellPrepared}
                            keyExp='caseId'
                            navigator={onRowSelected}
                            headerFilter={true}
                            wordWrapEnabled={true}
                            textValue={textValue}
                            handleTextChange={handleTextChange}
                        >
                            <Column
                                dataField="businessLogo"
                                caption=''
                                cellRender={renderAccountLogo}
                                width="auto"
                                allowHeaderFiltering={false}
                                allowSorting={false}
                            />
                            <Column dataField="bizId" visible={false} />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                headerCellRender={renderHeader}
                                calculateCellValue={renderAccountInfoCell}
                                cellRender={renderTextCell}
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column width="100%" />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                dataField="planType"
                                caption="Plan type"
                                cellRender={renderAccountPlanType}
                                width={110}
                            />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                dataField="verificationStatus"
                                caption="Account status"
                                width="auto"
                                allowHeaderFiltering={true}
                            />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                dataField="frozen"
                                caption="Restriction Status"
                                width="auto"
                                cellRender={renderAccountStatus}
                                allowHeaderFiltering={true}>
                                <HeaderFilter dataSource={accountStatusFilter} />

                            </Column>
                            <Column
                                dataField="transactionVolume"
                                caption="Txn vol."
                                dataType="number"
                                cellRender={renderTextCell}
                                visible={false}
                                width="auto" />
                            <Column
                                dataField="invoiceLimit"
                                caption="Inv. limit"
                                alignment="left"
                                dataType="number"
                                cellRender={renderInvoiceLimit}
                                width="auto"
                                format="currency"
                            >
                                <HeaderFilter dataSource={currencyColumnFilter('invoiceLimit')} />
                            </Column>
                            <Column
                                dataField="factoringLimit"
                                caption="Factor limit"
                                alignment="left"
                                dataType="number"
                                cellRender={renderAmountCell}
                                width="auto"
                                format="currency"
                            />
                            <Column
                                dataField="paymentDetails"
                                caption="Payment Method"
                                width="auto"
                                cellRender={renderpaymentStatus}
                                allowHeaderFiltering={false}
                                allowSorting={false}
                                alignment="center"
                            />
                            <Column
                                dataField="createdTimestamp"
                                caption="Since"
                                alignment="left"
                                width={125}
                                dataType="date"
                                format="MMM dd, yyyy hh:mm a"
                            />
                            <Column width="auto" dataField="name" visible={false} />
                            <Column width="auto" dataField="dbaName" visible={false} />
                            <Column width="auto" dataField="emailAddress" visible={false} />
                        </DataGridWrapper>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default AccountSearchPage;
