import React from 'react';

type CancelIconProps = {
    width: number;
    height: number;
}

const CancelIcon: React.FC<CancelIconProps> = ({ width, height }): JSX.Element => (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.99996 1.06406C3.27394 1.06406 1.06406 3.27394 1.06406 5.99996C1.06406 8.72598 3.27394 10.9359 5.99996 10.9359C8.72598 10.9359 10.9359 8.72598 10.9359 5.99996C10.9359 3.27394 8.72598 1.06406 5.99996 1.06406ZM0.166626 5.99996C0.166626 2.7783 2.7783 0.166626 5.99996 0.166626C9.22162 0.166626 11.8333 2.7783 11.8333 5.99996C11.8333 9.22162 9.22162 11.8333 5.99996 11.8333C2.7783 11.8333 0.166626 9.22162 0.166626 5.99996Z"
            fill="#030D45"
        />
        <path
            d="M4.48609 4.48609C4.66132 4.31085 4.94543 4.31085 5.12067 4.48609L5.99996 5.36538L6.87925 4.48609C7.05448 4.31085 7.3386 4.31085 7.51383 4.48609C7.68907 4.66132 7.68907 4.94543 7.51383 5.12067L6.63454 5.99996L7.51383 6.87925C7.68907 7.05448 7.68907 7.3386 7.51383 7.51383C7.3386 7.68907 7.05448 7.68907 6.87925 7.51383L5.99996 6.63454L5.12067 7.51383C4.94543 7.68907 4.66132 7.68907 4.48609 7.51383C4.31085 7.3386 4.31085 7.05448 4.48609 6.87925L5.36538 5.99996L4.48609 5.12067C4.31085 4.94543 4.31085 4.66132 4.48609 4.48609Z"
            fill="#030D45"
        />
    </svg>
);

export default CancelIcon;