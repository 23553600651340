import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Button from 'src/components/base/buttons/button/Button';
import HRLine from 'src/components/base/hrLine/HRLine';
import Label from 'src/components/base/label/Label';
import NumberInput from 'src/components/base/numberInput/NumberInput';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import Gap from 'src/layouts/gap/Gap';
import LabelWrapper from 'src/layouts/labelWrapper/LabelWrapper';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import { useStore } from 'src/store';
import { FactoredRiskLabels } from 'src/util/FactoredRisk';
import TextInput from '../../components/base/textInput/TextInput';
import styles from './FactoredRiskModelPage.module.scss';

const FactoredRiskModelPage: React.FC = observer(() => {
    const { FactoredRiskModelPageStore } = useStore();
    const fetch = useFetchLegacy();

    useEffect(() => {
        FactoredRiskModelPageStore.fetchRiskModelData(fetch);
    }, [FactoredRiskModelPageStore]);

    const setValue = (label: string, value: number) => {
        FactoredRiskModelPageStore.setRiskModelValue(label, value);
    };

    const handleSaveClick = () => {
        FactoredRiskModelPageStore.updateRiskModelData(fetch);
    };

    const renderSectionHeader = (title: string) => (
        <>
            <h3>{title}</h3>
            <HRLine />
        </>
    );

    const handleAllowBusiness = useCallback(() => {
        FactoredRiskModelPageStore.allowBusinessForFlowFeature(fetch);
    }, [fetch, FactoredRiskModelPageStore.allowBusinessForFlowFeature]);

    const renderTwoColumnRow = (label: string, value: number, placeholder: string) => (
        <TwoColumnRow>
            <LabelWrapper>
                <Label>{label}</Label>
            </LabelWrapper>
            <NumberInput
                value={value}
                placeholder={placeholder}
                onChange={(newValue: number) => setValue(label, newValue)} />
        </TwoColumnRow>
    );

    const renderAllowBusinessForFlow = useCallback((label: string, value: string, placeholder: string) => (
        <TwoColumnRow>
            <LabelWrapper>
                <Label>{label}</Label>
            </LabelWrapper>
            <div className={styles['allow-business-container']}>
                <TextInput
                    onChange={(newValue: string) => FactoredRiskModelPageStore?.setAllowBusinessForFlowFeature(newValue)}
                    placeholder={placeholder}
                    value={value as string}
                />
                <Button onClick={handleAllowBusiness}>Save</Button>
            </div>
        </TwoColumnRow>
    ), [handleAllowBusiness, FactoredRiskModelPageStore?.setAllowBusinessForFlowFeature]);

    const {
        basePrice,
        dailyLimit,
        defaultDpd,
        hopscotchPayor,
        maxAdjustment,
        maxCurrDpd,
        maxDays,
        maxEverDpd,
        minDays,
        minHistory,
        minXActionSize,
        minXActions,
        minimumDays,
        numberOfBiggestTransactions,
        payee,
        payor,
        prevOnTimeAdj,
        pricePerDay,
        priorSuccess,
        ratioToBiggestTransactions,
        timesPrevOnTime,
        totalPortfolio,
        transaction,
        version
    } = FactoredRiskModelPageStore.riskModel;

    const sectionLabel = `Factored Model: Version ${version}`;

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Factored inv. controls</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <div className={styles.factoredRiskModelContainer}>
                            <SectionLabel>{sectionLabel}</SectionLabel>
                            <Gap />
                            {renderSectionHeader('Pricing')}
                            {renderTwoColumnRow(FactoredRiskLabels.BASE_PRICE, basePrice, 'Base Price (bps, decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.PRICE_PER_DAY, pricePerDay, 'Price per day (bps, decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.PRICING_MINIMUM_DAYS, minimumDays, 'Minimum Days')}

                            <Gap />
                            {renderSectionHeader('Pricing Adjustments')}
                            {renderTwoColumnRow(FactoredRiskLabels.PRIOR_SUCCESS, priorSuccess, 'Prior Success (bps, decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.HOPSCOTCH_PAYOR, hopscotchPayor, 'Hopscotch Payor (bps, decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.PREVIOUS_ON_TIME_ADJUSTMENT, prevOnTimeAdj, 'Previous on Time Adjustment (bps, decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.NUMBER_OF_TIMES_PREVIOUSLY_ON_TIME, timesPrevOnTime, 'Number of Times Previously on Time (bps, decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.MAXIMUM_ADJUSTMENT, maxAdjustment, 'Maximum Adjustment (bps, decimal)')}
                            <Gap />
                            {renderSectionHeader('Limits')}
                            {renderTwoColumnRow(FactoredRiskLabels.TRANSACTION, transaction, 'Transaction (decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.PAYEE, payee, 'Payee (decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.PAYOR, payor, 'Payor (decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.DAILY_LIMIT, dailyLimit, 'Daily Limit (decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.TOTAL_PORTFOLIO, totalPortfolio, 'Total Portfolio (decimal)')}
                            {renderAllowBusinessForFlow(FactoredRiskLabels.ALLOW_BUSINESS, FactoredRiskModelPageStore?.allowBusiness, 'Enter business id with comma seperated')}
                            <Gap />
                            {renderSectionHeader('Invoice Parameters')}
                            {renderTwoColumnRow(FactoredRiskLabels.MINIMUM_DAYS, minDays, 'Minimum Days (integer)')}
                            {renderTwoColumnRow(FactoredRiskLabels.MAXIMUM_DAYS, maxDays, 'Maximum Days (integer)')}
                            <Gap />
                            {renderSectionHeader('Plaid History')}
                            {renderTwoColumnRow(FactoredRiskLabels.MINIMUM_HISTORY, minHistory, 'Minimum History (months, decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.MINIMUM_TRANSACTIONS, minXActions, 'Minimum Transactions (integer)')}
                            {renderTwoColumnRow(FactoredRiskLabels.MINIMUM_TRANSACTION_SIZE, minXActionSize, 'Minimum Transaction Size (decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.RATIO_TO_BIGGEST_TRANSACTION, ratioToBiggestTransactions, 'Ratio to Biggest Transaction (decimal)')}
                            {renderTwoColumnRow(FactoredRiskLabels.NUMBER_OF_BIGGEST_TRANSACTIONS, numberOfBiggestTransactions, 'Number of Biggest Transactions (integer)')}
                            <Gap />
                            {renderSectionHeader('Payor')}
                            {renderTwoColumnRow(FactoredRiskLabels.MAXIMUM_CURRENT_DAYS_PAST_DUE, maxCurrDpd, 'Maximum Current Days Past Due (integer)')}
                            {renderTwoColumnRow(FactoredRiskLabels.MAXIMUM_EVER_DAYS_PAST_DUE, maxEverDpd, 'Maximum Ever Days Past Due (integer)')}
                            {renderTwoColumnRow(FactoredRiskLabels.DEFAULT_DAYS_PAST_DUE, defaultDpd, 'Default Days Past Due (integer)')}
                            <Gap />
                            <Button onClick={handleSaveClick}>Save</Button>
                        </div>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default FactoredRiskModelPage;
