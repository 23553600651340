import React from 'react';
import InstitutionIcon from 'src/components/logos/InstitutionIcon';
import { getCurrencyOrNotApplicable } from '../../../../util';
import InfoBlock from '../infoBlock/InfoBlock';
import styles from './LbaCardView.module.scss';

type LbaCardViewProps = {
    title: string;
    institutionName: string;
    accountNumber: number;
    availableBalance: string;
    valueOfTransactions: number;
    numberOfTransactions: number;
}

const LbaCardView: React.FC<LbaCardViewProps> = ({ title, availableBalance, valueOfTransactions, numberOfTransactions, institutionName, accountNumber }) => <div className={styles.lbaContainer}>
    <h4 className={styles.lbaTitle}>{title}</h4>
    <div className={styles.contentContainers}>
        <div>
            <div className={styles.lbaLogoInfoContainer}>
                <InstitutionIcon width={45} height={45} />
                <span className={styles.bankInfo}>{institutionName}<br /> xxxx{accountNumber}</span>
            </div>
        </div>
        <div className={styles.informationBlock}>
            <InfoBlock label="Available balance:" response={getCurrencyOrNotApplicable(availableBalance)} />
            <InfoBlock label="Value of txns:" response={getCurrencyOrNotApplicable(valueOfTransactions)} />
            <InfoBlock label="Txn volume:" response={numberOfTransactions} />
        </div>
    </div>
</div>;
export default LbaCardView;