import { useEffect } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import { DROP_DOWN_URL } from 'src/Constant';
import { dropDownInterface } from 'src/util/interfaces';
import mapDropDownOptions from 'src/util/mapDropDownOptions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFetchRemoteDropdown = (url: string, setCategories: (categories: dropDownInterface[]) => void): void => {
    const fetch = useFetchLegacy();

    // @TODO: Should write a unit test for this and model after - useHasAuthorization.test.ts
    useEffect(() => {
        fetch({ url: DROP_DOWN_URL(url) }, (res) => setCategories(mapDropDownOptions(res.data)));
    }, [url]);
};

export default useFetchRemoteDropdown;