// eslint-disable-next-line
import cn from 'classnames';
import React from 'react';
import styles from 'src/pages/paidPlanControls/PaidPlanControls.module.scss';

type FeatureTabProps = {
    label: string;
    isPlanTypeShown: boolean;
    handleClick: () => void;
}

const FeatureTab: React.FC<FeatureTabProps> = ({ handleClick, isPlanTypeShown, label }) => (
    <div className={styles.container}>
        <button type="button"
            className={cn(
                styles.button,
                { [styles.isPlanShown]: isPlanTypeShown })}
            onClick={handleClick}>
            {label}
        </button>
    </div>
);

export default FeatureTab;