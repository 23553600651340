import DataGrid, { Column, HeaderFilter, Paging, SearchPanel } from 'devextreme-react/data-grid';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import { GREY_TEXT_COLOR, VERTICAL_ALIGN } from 'src/Constant';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import { renderAccountLogo, renderTwoLevelHeader } from 'src/util';
import renderTextCell from 'src/util/datagrid/renderTextCell';
import rowType from 'src/util/datagrid/rowType';
import styles from './EngEventsSearchPage.module.scss';

const EngEventsSearchPage: React.FC = observer(() => {
    const { EngEventsSearchPageStore } = useStore();
    const fetch = useFetchLegacy();
    const events = EngEventsSearchPageStore.getEvents();

    useEffect(() => {
        EngEventsSearchPageStore.fetchEvents(fetch);
    }, [EngEventsSearchPageStore]);

    const renderHeader = () => (
        <>
            <p>Account Name (User name)</p>
            <p className={styles.secondaryHeader}>Email</p>
        </>
    );

    const onCellPrepared = (e: CellPreparedEvent) => {
        if (rowType(e) && e.column) {
            e.cellElement.style.color = GREY_TEXT_COLOR;
            e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
        }
        if ((rowType(e) && e.column.dataField === 'type')
            || e.column.dataField === 'subType') {
            e.cellElement.style.paddingRight = '60px';
        }
    };

    const renderAccountInfoCell = (cellData: { dbaName: string; userName: string; userEmail: string }) =>
        <>
            <p className={styles.accountInfo}>
                {cellData.dbaName} {cellData.userName ? <>({cellData.userName})</> : ''}
            </p> {cellData.userEmail}
        </>;

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Events</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <DataGrid
                            dataSource={events}
                            keyExpr="alertId"
                            onCellPrepared={onCellPrepared}
                            width="100%"
                            showColumnLines={false}
                            showRowLines={false}
                            showBorders={false}
                            allowColumnResizing={true}
                            wordWrapEnabled={true}
                        >
                            <HeaderFilter visible={true} />
                            <Paging defaultPageSize={10} />
                            <SearchPanel visible={true} width={440} placeholder="Search by keyword" />
                            <Column
                                width="auto"
                                caption=""
                                allowSorting={false}
                                dataField="businessLogo"
                                cellRender={renderAccountLogo}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                width="auto"
                                dataField=""
                                headerCellRender={renderHeader}
                                calculateCellValue={renderAccountInfoCell}
                                cellRender={renderTextCell}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                caption="Trace Id"
                                dataField="traceId"
                                width="auto"
                            />
                            <Column width="100%" />
                            <Column
                                caption="Type"
                                dataField="alertType"
                                width="auto"
                            />
                            <Column
                                caption="Sub-type"
                                dataField="alertSubType"
                                width="auto"
                            />
                            <Column
                                caption="Date Time"
                                headerCellRender={() => renderTwoLevelHeader('Date', 'Time')}
                                allowSearch={true}
                                allowFiltering={true}
                                allowSorting={true}
                                dataField="dateTime"
                                alignment="left"
                                width={133}
                                dataType="date"
                                format="MMM dd, yyyy hh:mm a"
                                allowHeaderFiltering={true}
                            />
                            <Column width="auto" dataField="userName" visible={false} />
                            <Column width="auto" dataField="dbaName" visible={false} />
                            <Column width="auto" dataField="userEmail" visible={false} />
                        </DataGrid>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});
export default EngEventsSearchPage;