import React from 'react';
import { DANGER } from 'src/Constant';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _react = typeof React

const RedWarningIcon = (): JSX.Element => (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.7632 14.31C19.2983 13.32 18.7404 12.06 18.0895 10.89C17.4387 9.63 16.7878 8.46 16.2299 7.38C15.1141 5.4 13.3474 2.88 12.0456 1.08C11.5807 0.45 10.8368 0 10 0C9.16315 0 8.41929 0.36 7.95437 1.08C6.65261 2.88 4.97891 5.4 3.77013 7.38C2.56135 9.45 1.1666 12.33 0.236772 14.31C-0.13516 15.03 -0.0421773 15.93 0.329755 16.56C0.794671 17.28 1.53854 17.64 2.37538 17.73C4.60698 17.82 7.67542 18 10 18C12.3246 18 15.486 17.82 17.6246 17.73C18.4615 17.73 19.2053 17.28 19.6702 16.56C20.0422 15.93 20.1352 15.03 19.7632 14.31ZM9.25613 7.47C9.25613 7.11 9.53508 6.75 10 6.75C10.4649 6.75 10.7439 7.02 10.7439 7.47V11.07C10.7439 11.43 10.4649 11.79 10 11.79C9.53508 11.79 9.25613 11.52 9.25613 11.07V7.47ZM10 14.67C9.53508 14.67 9.07017 14.22 9.07017 13.77C9.07017 13.32 9.53508 12.87 10 12.87C10.4649 12.87 10.9298 13.32 10.9298 13.77C10.9298 14.22 10.4649 14.67 10 14.67Z"
            fill={DANGER}
        />
    </svg>
);

export default RedWarningIcon;