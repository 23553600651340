import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavigableCloseButton from 'src/components/base/buttons/navigableCloseButton/NavigableCloseButton';
import PlanType from 'src/components/business/planType/PlanType';
import styles from './Header.module.scss';

type Props = {
    icon?: JSX.Element;
    title?: string;
    id?: string;
    status?: string;
    accountState?: string;
    url?: any;
    verificationStatus?: string;
    planType?: string;
}

const Header: React.FC<Props> = ({ icon, title, id, status, accountState, url = -1, verificationStatus, planType = '' }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <span className={styles.iconContainer}>{icon}</span>
                    <div className={styles.wrapper}>
                        <div className={styles.headerTextContainer}>
                            <h4 className={styles.pageHeaderText}>{title} {id && `(${id})`}  </h4>
                            <div className={styles.planContainer}>
                                <PlanType planLabel={planType} />
                            </div>
                        </div>
                        <h4 className={styles.verificationStatus}>{verificationStatus}</h4>
                    </div>
                </div>
                {accountState && <span className={styles.accountStatusVerified}>{status}</span>}
                <NavigableCloseButton onClick={() => navigate(url)} />
            </div>
            <hr className={styles.hr} />
        </>
    );
};

export default Header;

