// eslint-disable-next-line
import cn from 'classnames';
import React, { Component } from 'react';
import styles from 'src/static-assets/styles/base/_textarea.module.scss';

type Props = {
    placeholder?: string;
    maxLength: number;
    onChange?: any;
    defaultInput?: string;
    id?: string;
    isFullSize?: boolean;
};

type State = {
    charsPerBox: number;
    boxCount: number;
};
export default class Textarea extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            charsPerBox: 0,
            boxCount: 0,
        };
        this.wordCount = this.wordCount.bind(this);
    }

    public wordCount(event: React.ChangeEvent<HTMLTextAreaElement>): void {
        const typedText = event.target.value;
        const characterCount = typedText.length;
        const charsPerTextArea = this.state.charsPerBox;
        const totalCount = characterCount + charsPerTextArea;
        this.setState({ boxCount: totalCount });

        this.props.onChange(typedText);
    }

    public render(): JSX.Element {
        return (
            <div className={cn({ [styles.divFullSize]: this.props.isFullSize })}>
                <textarea key={this.props.id} className={cn(styles.textarea, { [styles.isFullSize]: this.props.isFullSize })} defaultValue={this.props?.defaultInput} placeholder={this.props?.placeholder} maxLength={this.props.maxLength} onChange={this.wordCount} />
                <br />
                <span className={styles.wordCount}>
                    {this.state.boxCount} of {this.props.maxLength}
                </span>
            </div>
        );
    }
}
