import { Popup, ScrollView } from 'devextreme-react';
import React from 'react';
import CancelButton from 'src/components/base/buttons/cancelButton/CancelButton';
import HRLine from 'src/components/base/hrLine/HRLine';
import styles from './BaseAuditHistoryPopup.module.scss';

type BaseAuditHistoryPopupProps = {
    popupVisible: boolean;
    hidePopup: any;
    children: any;
    title: string;
    updateCallBack: any;
    isShowDownloadReportAction?: boolean
};

const BaseAuditHistoryPopup: React.FC<BaseAuditHistoryPopupProps> = ({ popupVisible, hidePopup, children, title, updateCallBack, isShowDownloadReportAction = true }) => {

    const renderTitle = () => (
        <div className={styles.titleContainer}>
            <p className={styles.popupTitle}>{title}</p>
            {isShowDownloadReportAction &&
                <button type="button" className={styles.downloadReportLink} onClick={updateCallBack}>
                    Download Report
                </button>
            }
        </div>
    );

    return (
        <Popup
            visible={popupVisible}
            height="500px"
            width="500px"
            onHiding={hidePopup}
            closeOnOutsideClick={true}
            titleRender={renderTitle}
        >
            <ScrollView width='100%' height='90%'>
                {children}
            </ScrollView>
            <HRLine />
            <div className={styles.btnContainer}>
                <CancelButton onClick={hidePopup}>Close</CancelButton>
            </div>
        </Popup>
    );
};

export default BaseAuditHistoryPopup;