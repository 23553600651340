// eslint-disable-next-line
import cn from 'classnames';
import { Column, HeaderFilter, SearchPanel } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataGridWrapper from 'src/components/base/dataGridWrapper/DataGridWrapper';
import HRLine from 'src/components/base/hrLine/HRLine';
import { useStore } from 'src/store';
import { currencyColumnFilter, getInCurrencyFormat, getInNumberFormat, renderAccountLogo, renderTextCell } from 'src/util';
import { MonetaryInvoice } from '../../AccountDetailsPageStoreInterface';
import CompletedBlock from './completedBlock/CompletedBlock';
import PayableReceivableBlock from './payableReceivableBlock/PayableReceivableBlock';
import styles from './TransactionsSection.module.scss';

const TransactionsSection: React.FC = observer(() => {
    const { AccountDetailsPageStore } = useStore();
    const { view } = useParams();
    const caseId = AccountDetailsPageStore.getCaseId();
    const transactions = AccountDetailsPageStore.getTransactions();
    const count = AccountDetailsPageStore.getTotalTransactions();
    const transactionSummary = AccountDetailsPageStore.getTransactionSummaryDetails();
    const navigate = useNavigate();
    const onCellPrepared = () => {
        // if (e.rowType === 'data' && e.column) {
        //     e.cellElement.style.color = GREY_TEXT_COLOR;
        //     e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
        // } if (e.rowType === 'data' && e.column.dataField === 'dbaName') {
        //     e.cellElement.style.color = BLACK_TEXT_COLOR;
        // } if (e.rowType === 'data' && e.column.dataField === 'date') {
        //     e.cellElement.style.textAlign = 'center';

        //     const currentDate = new Date().getTime();
        //     const daysPastDue = (currentDate - e.data.date.getTime()) / 86400000;

        //     if (daysPastDue <= 0) {
        //         e.cellElement.style.color = GREY_TEXT_COLOR;
        //     } else if (daysPastDue >= 1) {
        //         e.cellElement.style.color = DANGER;
        //         e.cellElement.style.backgroundColor = LIGHT_RED_COLOR;
        //     } else if (currentDate) {
        //         e.cellElement.style.color = ORANGE_TEXT_COLOR;
        //         e.cellElement.style.backgroundColor = ORANGE_BACKGROUND_COLOR;
        //     }
        // }
    };

    // const renderHeader = () => (
    //     <>
    //         <p>Account Name</p>
    //         <p className={styles.greyText}>Email</p>
    //     </>
    // );
    const renderAmountCell = (data: { amount: string, negative: boolean }): string => {
        if (typeof data.amount === 'string') {
            return getInCurrencyFormat(Number(data.amount), data.negative);
        }
        return getInCurrencyFormat(data.amount, data.negative);
    };

    const renderAccountInfoCell = (rowData: { dbaName: string; type: string; accountNumbers: string }) => <>
        <p className={styles.userData}>{rowData.dbaName}</p>
        <p className={styles.greyText}>{rowData.type}</p>
        <p className={styles.greyText}>{rowData.accountNumbers}</p>
    </>;

    return (<>
        <div className={styles.container}>
            <p>Accounting</p>
        </div>
        <HRLine />
        <div className={cn(styles.row, styles.accountingLinksRow)}>
            <Link className={cn(styles.link, styles.accountingLink, { [styles.active]: view === 'Payable' })} to={`/biz/account-details/${caseId}/view/Payable`}>Payable</Link>
            <Link className={cn(styles.link, styles.accountingLink, styles.spacerLink, { [styles.active]: view === 'Receivable' })} to={`/biz/account-details/${caseId}/view/Receivable`}>Receivable</Link>
            <Link className={cn(styles.link, styles.accountingLink, styles.spacerLink, { [styles.active]: view === 'Completed' })} to={`/biz/account-details/${caseId}/view/Completed`}>Completed</Link>
        </div>
        <HRLine />
        <div className={styles.contentContainer}>
            <div className={styles.transactionsContainer}>
                <div className={styles.contentWrapper}>
                    <p className={styles.resultsText}>Results ({getInNumberFormat(count)})</p>
                    <DataGridWrapper
                        id='mini'
                        dataSource={transactions}
                        classNames={styles.grid}
                        onCellPrepared={onCellPrepared}
                        pageSize={100}
                        navigator={(rowData: { selectedRowsData: MonetaryInvoice[] }) => {
                            const monetaryInvoice = rowData.selectedRowsData[0];
                            if (monetaryInvoice.source === 'Invoice') {
                                navigate(`/biz/invoice-details/${monetaryInvoice.id}`);
                            } else {
                                navigate(`/biz/transaction-details/${monetaryInvoice.id}`);
                            }
                        }}
                        keyExp="id">
                        <SearchPanel width={350} visible={true} highlightCaseSensitive={true} />
                        <Column
                            caption=""
                            dataField="logo"
                            width="65"
                            cellRender={renderAccountLogo}
                            allowHeaderFiltering={false}
                            allowSorting={false}
                            allowFiltering={false}
                        />
                        <Column
                            caption="Counterparty"
                            width="100%"
                            allowHeaderFiltering={true}
                            allowSorting={true}
                            allowFiltering={true}
                            cellRender={renderTextCell}
                            calculateCellValue={renderAccountInfoCell}
                        />
                        <Column
                            caption="Id"
                            dataField="id"
                            width="100%"
                            allowHeaderFiltering={true}
                            allowSorting={true}
                            allowFiltering={true}
                        />
                        <Column dataField="accountNumbers" />
                        <Column dataField="source" />
                        <Column dataField="type" />
                        <Column
                            dataField="date"
                            caption="Date"
                            width={130}
                            dataType="string"
                            format="MMM dd, yyyy"
                            allowHeaderFiltering={true}
                            allowSorting={true}
                            allowFiltering={true}
                        />
                        <Column
                            dataField="amount"
                            caption="Amount"
                            alignment="left"
                            calculateCellValue={renderAmountCell}
                            width="auto"
                            format="currency"
                            allowHeaderFiltering={true}
                            allowSorting={true}
                            allowFiltering={true}>
                            <HeaderFilter dataSource={currencyColumnFilter('amount')} />
                        </Column>
                        <Column
                            dataField="dbaName"
                            visible={false}
                        />
                    </DataGridWrapper>
                </div>
            </div>
            {view === 'Payable' &&
                <PayableReceivableBlock
                    balance={transactionSummary.payablesBalance}
                    type="Payable"
                    pastDue={transactionSummary.payablesPastDue}
                    nextSevenDays={transactionSummary.payablesNextSeven}
                />
            }
            {view === 'Receivable' &&
                <PayableReceivableBlock
                    balance={transactionSummary.receivablesBalance}
                    type="Receivable"
                    pastDue={transactionSummary.receivablesPastDue}
                    nextSevenDays={transactionSummary.receivablesNextSeven}
                />
            }
            {view === 'Completed' &&
                <CompletedBlock
                    completedPayables={transactionSummary.completedPayables}
                    completedReceivables={transactionSummary.completedReceivables}
                    completedNetFlow={transactionSummary.completedNetFlow}
                />
            }
        </div>
    </>);
});

export default TransactionsSection;