
interface jwtResponse {
    auth_time: number,
    client_id: string,
    'cognito:groups': string[],
    exp: number,
    iat: number,
    iss: string,
    jti: string,
    origin_jti: string,
    scope: string,
    token_use: string,
    username: string,
    version: string
};

const parseJwt = (token: string): jwtResponse => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((item) => `%${(`00${item.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
};

export default parseJwt;