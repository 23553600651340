import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { MARK_AS_PAID, PENDING_STATUS, REJECTED_STATUS, REVERSE, UNWIND } from 'src/Constant';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Textarea from 'src/components/base/Textarea';
import Toggles from 'src/components/base/Toggles';
import AuditDropDown from 'src/components/base/auditDropDown/AuditDropDown';
import Button from 'src/components/base/buttons/button/Button';
import CancelButton from 'src/components/base/buttons/cancelButton/CancelButton';
import HRLine from 'src/components/base/hrLine/HRLine';
import Label from 'src/components/base/label/Label';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import HalfPanel from 'src/layouts/twoColumnRow/halfPanel/HalfPanel';
import { useStore } from 'src/store';
import styles from '../FactoredInvoiceDetailsPage.module.scss';
import FactoredInvoiceAuditPopup from '../factoredInvoiceAuditPopup/FactoredInvoiceAuditPopup';
import FactoredPopup from '../factoredPopup/FactoredPopup';

interface props {
    invoiceId: string;
}
const ActionsSection: React.FC<props> = observer(({ invoiceId }) => {
    const { FactoredInvoiceDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();
    const [popupVisible, setPopupVisible] = useState(false);
    const [historyPopupVisible, setHistoryPopupVisible] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const { modalStatus } = FactoredInvoiceDetailsPageStore.modal;
    const { hasBeenUnwoundToggle, hasBeenReversedToggle, hasBeenMarkedAsPaidToggle } = FactoredInvoiceDetailsPageStore.form;
    const isInvoicePending = FactoredInvoiceDetailsPageStore.factoredInvoiceDetails.invoiceState === PENDING_STATUS;
    const isInvoiceRejected = FactoredInvoiceDetailsPageStore.factoredInvoiceDetails.invoiceState === REJECTED_STATUS;

    const handleOnClick = (status: string) => {
        FactoredInvoiceDetailsPageStore.setModalStatus(status);
        FactoredInvoiceDetailsPageStore.setToggleValue(status);
    };

    const onHandleChange = (isDirt: boolean, status: string) => {
        if (!isDirt) {
            setIsDirty(true);
            handleOnClick(status);
        } else if (isDirt && modalStatus === status) {
            setIsDirty(false);
            handleOnClick(status);
        }
    };
    return <> <div className={styles.actionsHistoryContainer}>
        <Label>Actions</Label>
        <button type="button" className={styles.historyLink} onClick={() => setHistoryPopupVisible(true)}>
            History
        </button>
    </div>
        <div className={styles.hrlineContainer}>
            <HRLine />
        </div>
        <div className={styles.actionsContainer}>
            <FactoredPopup
                key={modalStatus}
                modalStatus={modalStatus}
                popupVisible={popupVisible}
                invoiceId={invoiceId}
                updateCallback={() => {
                    FactoredInvoiceDetailsPageStore.updateFactoredInvoice(fetch, invoiceId);
                }}
                hidePopup={() => {
                    setPopupVisible(false);
                }} />

            <div className={styles.actionRow}>
                <div className={styles.actionContainer}>
                    <div className={styles.titleToggleContainer}>
                        <Label>
                            Invoice payee
                        </Label>
                        <Toggles
                            key={UNWIND}
                            checked={hasBeenUnwoundToggle}
                            onChange={() => onHandleChange(isDirty, UNWIND)}
                        />
                    </div>
                    <p className={styles.smallGreyText}>This will send an invoice to the payee for repayment of the loan, principal and interest.</p>
                </div>
                {
                    isInvoiceRejected && (
                        <div className={styles.actionContainer}>
                            <div className={styles.titleToggleContainer}>
                                <Label>
                                    Reverse rejection
                                </Label>
                                <Toggles
                                    key={REVERSE}
                                    checked={hasBeenReversedToggle}
                                    onChange={() => onHandleChange(isDirty, REVERSE)}
                                />
                            </div>
                            <p className={styles.smallGreyText}>Reverses a payor’s rejection of a Flowed invoice. The invoice will revert back to pending status, enabling it to be paid by the Payor again.</p>
                        </div>
                    )
                }


            </div>

            {
                isInvoicePending &&
                <div className={styles.markedPaidActionContainer}>
                    <div className={styles.titleToggleContainer}>
                        <Label>
                            Mark as paid
                        </Label>
                        <Toggles
                            key={MARK_AS_PAID}
                            checked={hasBeenMarkedAsPaidToggle}
                            onChange={() => onHandleChange(isDirty, MARK_AS_PAID)}
                        />
                    </div>
                    <p className={styles.smallGreyText}>This will mark a Flowed Invoice as markedPaid in our system, and then send an invoice to the payee for repayment of the loan, principal and interest.</p>
                </div>
            }
            <div className={styles.astertikContainer}>
                * Only 1 toggle can be selected at a time
            </div>

            <TwoColumnRow>
                <HalfPanel isLeft={true}>
                    <AuditDropDown />
                </HalfPanel>
                <HalfPanel>
                    <p className={styles.reasoningLabel}>Description</p>
                    <Textarea
                        maxLength={2000}
                        isFullSize={true}
                        placeholder='Detailed reason for updating the status of the transaction.'
                        onChange={(e: string) => {
                            FactoredInvoiceDetailsPageStore.setUserReasoning(e);
                        }} />
                </HalfPanel>
            </TwoColumnRow>
            <div className={styles.buttonContainer}>
                <CancelButton />
                <Button onClick={() => setPopupVisible(true)} disabled={!FactoredInvoiceDetailsPageStore.getIsFormReady()}>
                    Continue
                </Button>
            </div>
            <FactoredInvoiceAuditPopup popupVisible={historyPopupVisible} hidePopup={() => {
                setHistoryPopupVisible(false);
            }} />
        </div>
    </>;
});

export default ActionsSection;