import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Button from 'src/components/base/buttons/button/Button';
import Score from 'src/components/base/score/Score';
import ToolTip from 'src/components/base/toolTip/ToolTip';
import LBASmallBlock from 'src/components/business/lbaSmallBlock/LBASmallBlock';
import { NOT_AVAILABLE_PLACEHOLDER } from 'src/Constant';
import Gap from 'src/layouts/gap/Gap';
import { useStore } from 'src/store';
import { getCurrencyOrNotApplicable, getInCurrencyFormat } from 'src/util';
import { AccountDetailsLinkedBankAccount } from 'src/util/interfaces';
import getPlanName from 'src/util/PlanUtils';
import { DEFAULT_SUBSCRIPTION_DETAILS, UserFinancialsValues } from '../../AccountDetailsPageStore';
import AccountInfoItem from './accountInfoItem/AccountInfoItem';
import styles from './AccountInfoSection.module.scss';
import UserDocsUploadModal from './UserDocsUploadModal/UserDocsUploadModal';

const AccountInfoSection: React.FC = observer(() => {
    const { AccountDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();

    const name = AccountDetailsPageStore.getPrimaryAccountOwnersName();
    const streetName = AccountDetailsPageStore.getStreetName();
    const streetNumber = AccountDetailsPageStore.getStreetNumber();
    const city = AccountDetailsPageStore.getCity();
    const state = AccountDetailsPageStore.getState();
    const postalCode = AccountDetailsPageStore.getPostalCode();
    const beneficialOwners = AccountDetailsPageStore.getBeneficialOwners();
    const businessType = AccountDetailsPageStore.getBusinessType() || 'N/A';;
    const website = AccountDetailsPageStore.getWebsite() || 'N/A';
    const email = AccountDetailsPageStore.getEmail();
    const industry = AccountDetailsPageStore.getIndustry() || 'N/A';
    const invoiceLimit = AccountDetailsPageStore.getInvoiceLimit();
    const flowCreditLimit = AccountDetailsPageStore.getFlowCreditLimit();
    const flowAvailableBalance = AccountDetailsPageStore.getFlowAvailableBalance();
    const flowApprovalStatus = AccountDetailsPageStore.getFlowApprovalStatus();
    const riskScore = AccountDetailsPageStore.getRiskScore();
    const caseStatus = AccountDetailsPageStore.getVerificationStatus();
    const hopscotchBalance = AccountDetailsPageStore.getHopscotchBalance();
    const externalDetails = AccountDetailsPageStore.getExternalDetails();
    const bankBalance = AccountDetailsPageStore.getBankBalance();
    const averageTransactionAmount = AccountDetailsPageStore.getAverageTransactionAmount();
    const averageTransactionVolume = AccountDetailsPageStore.getAverageTransactionVolume();
    const restrictedStatus = AccountDetailsPageStore.getRestrictionStatus();
    const hasInvoiceLimitBeenUpdated = AccountDetailsPageStore.getHasInvoiceLimitBeenUpdated();
    const lbaDetails = AccountDetailsPageStore.getLbaDetails();
    const tpAccountId = AccountDetailsPageStore.getTpAccountId();
    const bizID = AccountDetailsPageStore.getBizId();
    const { plan: planType, recurlyUrl: recurrlyProfileUrl, billingFrequency } = AccountDetailsPageStore.getSubscriptionDetails() ?? DEFAULT_SUBSCRIPTION_DETAILS;
    const planName = useMemo(() => getPlanName({ planType, billingFrequency, isFullPlanName: true }), [planType, billingFrequency]);
    const isAccountVerified = AccountDetailsPageStore.getIsAccountVerified();
    const isShowUserDocsUploadModal = AccountDetailsPageStore.getShowUserDocsUploadModal();
    const isLoading = AccountDetailsPageStore.getLoading();

    const openUserDocsUploadModal = useCallback(() => {
        AccountDetailsPageStore?.setShowUserDocsUploadModal(true);
    }, []);

    const closeUserDocsUploadModal = useCallback(() => {
        AccountDetailsPageStore?.setShowUserDocsUploadModal(false);
    }, []);

    const onSubmitUserDocsUpload = useCallback((values: UserFinancialsValues) => {
        AccountDetailsPageStore.onSubmitUserFinancials(fetch, values);
    }, []);

    const onTriggerReUnderwriting = useCallback(() => {
        AccountDetailsPageStore.onTriggerReUnderwriting(fetch);
    }, []);

    return (
        <>
            <div className={styles.sectionContainers}>
                <div className={styles.detailsContainer}>
                    <h4 className={styles.title}>Details</h4>
                    <AccountInfoItem label='Email:' value={email} />
                    <AccountInfoItem label="Business ID:" value={bizID} />
                    <AccountInfoItem label='Pri Account owner:' value={name} />
                    {!externalDetails.isPlatformUser && <AccountInfoItem label='Added by:' value={externalDetails.createdByDbaName} />}
                    {externalDetails.isPlatformUser && <>
                        <AccountInfoItem label='Beneficial owners:' value={beneficialOwners} />
                        <AccountInfoItem isBusinessAddress={true} label='Business address:' streetNumber={streetNumber} streetName={streetName} addressCity={city} addressState={state} addressPostalCode={postalCode} />
                        <AccountInfoItem label='Business type:' value={businessType} />
                        <AccountInfoItem label='Website:' value={website} />
                        <AccountInfoItem label='Industry:' value={industry} />
                        <div className={styles.urlItem}>
                            <h5 className={styles.fieldLabel}>
                                Verification profiles:
                            </h5>
                            <h5>
                                <Link className={styles.link} to={`/biz/aml-verification/${AccountDetailsPageStore.caseId}`}>Case Verification</Link>
                            </h5>
                        </div></>}
                    <AccountInfoItem label='Plan type:' value={planName || NOT_AVAILABLE_PLACEHOLDER} />
                    <div className={styles.urlItem}>
                        <h5 className={styles.fieldLabel}>
                            Plan profile:
                        </h5>
                        {recurrlyProfileUrl ? (
                            <h5>
                                <a className={styles.link} href={recurrlyProfileUrl} target="_blank" rel="noreferrer">Recurly Profile</a>
                            </h5>
                        ) : (<h5 className={styles.accountText}> {NOT_AVAILABLE_PLACEHOLDER}</h5>)}
                    </div>
                    {isAccountVerified && <AccountInfoItem label='TP account Id :' value={tpAccountId} />}


                </div>
                <div className={styles.statusContainer}>
                    <h4 className={styles.title}>Status</h4>
                    <AccountInfoItem label='Hopscotch balance:' value={getCurrencyOrNotApplicable(hopscotchBalance)} />
                    <AccountInfoItem label='Bank available balance:' value={getCurrencyOrNotApplicable(bankBalance)} />
                    <AccountInfoItem label='Avg txn size:' value={getCurrencyOrNotApplicable(averageTransactionAmount)} />
                    <AccountInfoItem label='Txn volume:' value={averageTransactionVolume} />
                    {externalDetails.isPlatformUser &&
                        <div className={styles.flexContainer}>
                            <div className={styles.flexItems}>
                                <h5 className={styles.fieldLabel}>
                                    Invoice limit: {
                                        hasInvoiceLimitBeenUpdated &&
                                        <ToolTip toolTipText='Invoice limit has been updated' />
                                    }
                                </h5>
                                <h5 className={styles.response}>
                                    {getInCurrencyFormat(invoiceLimit)}
                                </h5>
                            </div>
                        </div>}
                    {externalDetails.isPlatformUser && <AccountInfoItem label='Flow credit limit:' value={getInCurrencyFormat(flowCreditLimit)} />}
                    {externalDetails.isPlatformUser && <AccountInfoItem label='Flow available balance:' value={getInCurrencyFormat(flowAvailableBalance)} />}
                    {externalDetails.isPlatformUser && <AccountInfoItem label='Flow approval status:' value={flowApprovalStatus} />}
                    <AccountInfoItem label='Account status:' value={caseStatus} />
                    {externalDetails.isPlatformUser && <AccountInfoItem label='Restriction status:' value={restrictedStatus} />}
                    {externalDetails.isPlatformUser && <div className={styles.riskScoreItem}>
                        <h5 className={styles.fieldLabel}>
                            Risk status:
                        </h5>
                        <div>
                            <Score isAccountDetailsScore={true} score={riskScore} />
                        </div>
                    </div>}
                    <div className={styles.paymentStatus}>
                        <h5 className={styles.fieldLabel}>
                            Payment Method:
                        </h5>
                        <div className={styles.dataContainer}>
                            {lbaDetails ? lbaDetails.map((lba: AccountDetailsLinkedBankAccount) =>
                                <LBASmallBlock
                                    key={lba.id}
                                    institutionName={lba.institutionName}
                                    accountNumber={lba.accountNumber}
                                    isBackupAccount={lba.isFundingAccount}
                                    isBlocked={lba.isBlocked}
                                    isOps={lba.isOps}
                                    paymentType={lba.paymentType}
                                />
                            ) : <h5 className={styles.response}>N/A</h5>}
                        </div>
                    </div>
                    {externalDetails.isPlatformUser &&
                        <div className={styles.userUploadDocs}>
                            <h5 className={styles.fieldLabel}>
                                Upload user financials to Oatfi:
                            </h5>
                            <div className={styles.dataContainer}>
                                <Button data-testid="continueButton" onClick={openUserDocsUploadModal}>
                                    Upload
                                </Button>
                            </div>
                        </div>
                    }
                    {externalDetails.isPlatformUser &&
                        <div className={styles.userUploadDocs}>
                            <h5 className={styles.fieldLabel}>
                                Underwrite Business :
                            </h5>
                            <div className={styles.dataContainer}>
                                <Button data-testid="continueButton" onClick={onTriggerReUnderwriting} disabled={isLoading}>
                                    Underwrite
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Gap />
            {isShowUserDocsUploadModal &&
                <UserDocsUploadModal
                    isLoading={isLoading}
                    isVisible={isShowUserDocsUploadModal}
                    onCancel={closeUserDocsUploadModal}
                    onSubmit={onSubmitUserDocsUpload}
                />
            }
        </>
    );
});

export default AccountInfoSection;