import classNames from 'classnames';
import React from 'react';
import styles from 'src/pages/paidPlanControls/PaidPlanControls.module.scss';

type FeatureItemProps = {
    description: string;
    wrapperClassName?: string
    enabled: boolean
    featureId: string
    updatedFeature: string
    onChange?: (value: boolean, featureId: string) => void
}

const FeatureItem: React.FC<FeatureItemProps> = ({ description, updatedFeature, enabled, featureId, onChange, wrapperClassName = '' }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.checked, featureId);
    };

    return (
        <div className={classNames(styles.wrapper, wrapperClassName)}>
            <p className={styles.featuresList}>{description}</p>
            <input disabled={Boolean(updatedFeature && updatedFeature !== featureId)} onChange={handleChange} type="checkbox" checked={enabled} className={styles.checkBox} />
        </div>
    );
};

export default FeatureItem;