import React from 'react';
import CurrencyValue from 'src/components/base/currencyValue/CurrencyValue';
import { getDateFormatNoTime, getInCurrencyFormat } from 'src/util';
import styles from '../InvoiceDetailsPage.module.scss';

type Props = {
    transactionDetails: {
        causationIdType: string,
        transactionType: string,
        transactionStatus: string,
        transactionAmount: number,
        transferAmount: number,
        transferStatus: string,
        transactionDate: string,
        transactionDueDate: string,
        invoiceDueDate: string,
        transactionCompletedDate: string
    }
}

const StatusSection: React.FC<Props> = ({ transactionDetails }) => <div className={styles.statusMainComponent}>
    <div className={styles.twoColDiv}>
        <h2 className={styles.bigText}>{transactionDetails.causationIdType}</h2>
        <h2 className={styles.bigText}><CurrencyValue classNames={styles.currencyValue}>{transactionDetails.transactionAmount}</CurrencyValue></h2>
    </div>

    <div className={styles.twoColDiv}>
        <h4 className={styles.largerWhyteFont}>{transactionDetails.transactionType ? 'Flagged' : 'Not Flagged'}</h4>
        <h4 className={styles.whyteFont}>Initiation Date: {getDateFormatNoTime(transactionDetails.transactionDate)}</h4>
    </div>

    <div className={styles.twoColDiv}>
        <h4 className={styles.whyteFont}>{transactionDetails.transactionStatus}</h4>
        <h4 className={styles.whyteFont}>Invoice Due Date: {getDateFormatNoTime(transactionDetails.transactionDueDate)}</h4>
    </div>

    <div className={styles.twoColDiv}>
        <h4> </h4>
        <h4 className={styles.whyteFont}>{transactionDetails.transactionCompletedDate ? `Completed Date: ${transactionDetails.transactionCompletedDate}` : ' '}</h4>
    </div>
    {
        (transactionDetails.transferAmount) &&
        <div className={styles.transferContainer}>
            <p>Transfer status: {transactionDetails.transferStatus}</p>
            <p>Transfer amount: {getInCurrencyFormat(transactionDetails.transferAmount)}</p>
        </div>
    }
</div>;

export default StatusSection;