import React from 'react';
import styles from '../AccountInfoSection.module.scss';

type AccountInfoItemProps = {
    label: string;
    value?: any;
    isBusinessAddress?: boolean;
    streetName?: string;
    streetNumber?: string;
    addressCity?: string;
    addressState?: string;
    addressPostalCode?: number;
}

const AccountInfoItem: React.FC<AccountInfoItemProps> = ({ label, value, isBusinessAddress, streetNumber, streetName, addressCity, addressState, addressPostalCode }) => (
    isBusinessAddress ?
        <div className={styles.flexContainer}>
            <div className={styles.flexItems}>
                <h5 className={styles.fieldLabel}>
                    {label}
                </h5>
                <h5 className={styles.response}>
                    {streetNumber} {' '}
                    {streetName}, {' '}
                    {addressCity}, {addressState} {addressPostalCode}
                </h5>
            </div>
        </div>
        :
        <div className={styles.flexContainer}>
            <div className={styles.flexItems}>
                <h5 className={styles.fieldLabel}>
                    {label}
                </h5>
                <h5 className={styles.response}>
                    {value}
                </h5>
            </div>
        </div>
);

export default AccountInfoItem;