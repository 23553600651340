// eslint-disable-next-line id-length
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import NavigableCloseButton from 'src/components/base/buttons/navigableCloseButton/NavigableCloseButton';
import HRLine from 'src/components/base/hrLine/HRLine';
import AccountLink from 'src/components/business/accounts/accountLink/AccountLink';
import { useStore } from 'src/store';
import { AccountPageTab } from '../../AccountDetailsPageStoreInterface';
import styles from './HeaderSection.module.scss';

const HeaderSection: React.FC = observer(() => {
    const { AccountDetailsPageStore } = useStore();
    const isAccountVerified = AccountDetailsPageStore.getIsAccountVerified();
    const logo = AccountDetailsPageStore.getLogo();
    const name = AccountDetailsPageStore.getBusinessName();
    const externalDetails = AccountDetailsPageStore.getExternalDetails();
    const riskScore = AccountDetailsPageStore.getOldRiskScore();
    const accountCreatedDate = AccountDetailsPageStore.getAccountCreated();
    const pageView = AccountDetailsPageStore.getPageView();
    const planType = AccountDetailsPageStore.getPlanType();

    return (
        <>
            <div className={styles.container}>
                <AccountLink
                    dbaName={name}
                    isVerified={isAccountVerified}
                    score={riskScore}
                    createdTimestamp={accountCreatedDate}
                    accountLogo={logo}
                    isExternalUser={!externalDetails?.isPlatformUser}
                    planType={planType}
                />
                <div className={styles.linksContainer}>
                    <button onClick={() => { AccountDetailsPageStore.setPageView(AccountPageTab.info); }} type='button' className={cn(styles.navigation, { [styles.active]: pageView === AccountPageTab.info })}>Info</button>|
                    <button onClick={() => { AccountDetailsPageStore.setPageView(AccountPageTab.actions); }} type="button" className={cn(styles.navigation, { [styles.active]: pageView === AccountPageTab.actions })}>Actions</button>
                    <NavigableCloseButton />
                </div>
            </div>
            <HRLine />
        </>
    );
});

export default HeaderSection;
