import React from 'react';

const FileIcon = (): JSX.Element => (
    <svg width="20" height="20" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.00667 1.35889C4.9901 1.35889 3.62502 1.47047 2.67029 1.56305C2.27412 1.60147 1.96495 1.91532 1.93428 2.3132C1.83934 3.54495 1.7056 5.5418 1.7056 6.99992C1.7056 8.45804 1.83934 10.4549 1.93428 11.6866C1.96495 12.0845 2.27412 12.3984 2.67029 12.4368C3.62502 12.5294 4.9901 12.6409 6.00667 12.6409C7.02325 12.6409 8.38832 12.5294 9.34306 12.4368C9.73923 12.3984 10.0484 12.0845 10.0791 11.6866C10.174 10.4549 10.3077 8.45804 10.3077 6.99992C10.3077 6.5297 10.2938 6.00233 10.2717 5.46146H8.07173L8.07119 4.94864L8.07121 4.43582H10.2206C10.0935 2.81071 8.82535 1.51626 7.21097 1.39721V3.58112C7.21097 4.05315 7.59613 4.43582 8.07121 4.43582L8.07119 4.94864L8.07173 5.46146C7.02654 5.46146 6.17872 4.6196 6.17872 3.58112V1.3599C6.12032 1.35924 6.06293 1.35889 6.00667 1.35889ZM6.71127 0.347047C6.46739 0.338291 6.23011 0.333252 6.00667 0.333252C4.9357 0.333252 3.52728 0.449432 2.57002 0.542261C1.6714 0.629402 0.973704 1.34395 0.905033 2.23489C0.810061 3.46706 0.67334 5.49867 0.67334 6.99992C0.67334 8.50116 0.810061 10.5328 0.905033 11.765C0.973704 12.6559 1.6714 13.3704 2.57002 13.4576C3.52728 13.5504 4.9357 13.6666 6.00667 13.6666C7.07765 13.6666 8.48607 13.5504 9.44333 13.4576C10.3419 13.3704 11.0396 12.6559 11.1083 11.765C11.2033 10.5328 11.34 8.50116 11.34 6.99992C11.34 6.36785 11.3158 5.64365 11.2809 4.92672C11.2808 4.92451 11.2807 4.92231 11.2806 4.92012C11.2731 4.76588 11.265 4.612 11.2567 4.45945C11.1366 2.27325 9.41854 0.48487 7.19516 0.368572C7.03444 0.360166 6.8745 0.352945 6.71727 0.347263"
            fill="#0053F8"
        />
    </svg>
);

export default FileIcon;
