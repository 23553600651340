import { observer } from 'mobx-react-lite';
import React from 'react';
import StatusIndicator from 'src/components/base/statusIndicator/StatusIndicator';
import ToggleContainer from 'src/components/base/toggleContainer/ToggleContainer';
import { useStore } from 'src/store';
import styles from './ModuleCard.module.scss';

interface Props {
    reviewed: boolean,
    label: string,
    business: boolean,
    message?: string,
}

const ModuleCard: React.FC<Props> = observer(({ reviewed, label, business, message }) => {
    const { CaseVerificationDetailPageStore } = useStore();

    return (
        <div className={styles.card}>
            <div className={styles.cardContent}>
                <div className={styles.statusContainer}>
                    {reviewed ? <StatusIndicator isPositive={true} /> : <StatusIndicator />}
                </div>
                <div className={styles.textContainer}>
                    <h4 className={styles.moduleText}>{label}</h4>
                    {message && <p className={styles.moduleCode}>{message}</p>}
                </div>
                <div className={styles.toggleContainer}>
                    <ToggleContainer
                        key={label}
                        checked={!reviewed}
                        onHandleChange={() => {
                            if (business) {
                                if (message) {
                                    CaseVerificationDetailPageStore.setBusinessReviewed(message);
                                }
                            } else {
                                CaseVerificationDetailPageStore.setCustomerReviewed(label);
                            }
                        }} />
                </div>
            </div>
        </div>
    );
});

export default ModuleCard;