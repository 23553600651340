import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import '../../static-assets/styles/base/_toggle.scss';

type Props = {
    onChange: any;
    checked: boolean;
    disabled?: boolean;
};

const Toggles: React.FC<Props> = ({ checked, onChange, disabled }) => {
    const handleToggleChange = (value: boolean) => {
        onChange(value);
    };

    return (
        <Toggle
            icons={false}
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
                handleToggleChange(e.target.checked);
            }}
        />
    );

};

export default Toggles;