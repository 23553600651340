import React from 'react';
import getInCurrencyFormat from 'src/util/getInCurrencyFormat';
import ToolTip from '../toolTip/ToolTip';

type InvoiceLimitCellProps = {
    invoiceLimit: number;
    displayIcon: boolean;
}

const InvoiceLimitCell: React.FC<InvoiceLimitCellProps> = (({ invoiceLimit, displayIcon }) => (
    displayIcon ?
        <ToolTip content={` ${getInCurrencyFormat(invoiceLimit)}`} toolTipText='Invoice limit has been updated' />
        :
        <>
            {getInCurrencyFormat(invoiceLimit)}
        </>
));

export default InvoiceLimitCell;
