import React from 'react';
import styles from './TwoLevelHeaders.module.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _react = typeof React

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TwoLevelHeaders = (firstTitle: string, secondTitle: string) => (
    <div className={styles.headerContainer}>
        <p className={styles.firstTitle}>{firstTitle}</p>
        <p className={styles.secondTitle}>{secondTitle}</p>
    </div>
);

export default TwoLevelHeaders;