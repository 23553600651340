import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import Row from 'src/components/base/row/Row';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import useFetchLegacy from '../../../../api/useFetchLegacy';
import { useStore } from '../../../../store';
import Button from '../../../base/buttons/button/Button';
import Dropdown from '../../../base/dropDown/Dropdown';
import Section from '../../../base/section/Section';
import SectionLabel from '../../../base/sectionLabel/SectionLabel';
import TextInput from '../../../base/textInput/TextInput';
import styles from './SupportPage.module.scss';
import { Field } from './SupportPageStoreInterface';

const SupportPage: React.FC = observer(() => {
    const { SupportPageStore } = useStore();
    const fetch = useFetchLegacy();

    useEffect(() => {
        SupportPageStore.fetchSupportActions(fetch);
    }, [SupportPageStore]);

    const supports = SupportPageStore.supportActions;

    const temp = supports.result.map((aResult: string) => ({ value: aResult, description: aResult }));
    const form = SupportPageStore.getSupportActionForm();

    // const initialValues = useMemo(() => SupportPageStore.getInitialValues(form), [form]);
    const initialValues = SupportPageStore.getInitialValues(form);

    const formik = useFormik({
        initialValues: { supportAction: 'stub', ...initialValues },
        onSubmit: (e: any) => SupportPageStore.updateSupportAction(e, fetch),
    });
    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Support</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <fieldset>
                        <legend>Support Services</legend>
                        <Dropdown
                            onChange={(e) => {
                                SupportPageStore.fetchParamsForAction(e, fetch);
                                formik.resetForm();
                                formik.handleChange('supportAction')(e);
                            }}
                            options={temp}
                            value={formik.values.supportAction}
                            key="supportAction"
                            name='supportAction'
                            className={styles.paramsForActionDropDown}
                        />
                    </fieldset>
                </Row>
                {form?.result[0]?.nameOfField !== undefined && form?.result[0]?.nameOfField !== '' && <div >
                    <Row key="second">
                        <div className={styles.form}>
                            <fieldset >
                                {form.result.map((aResult: Field) => {
                                    if (aResult.type === 'String' || aResult.type === 'Number') { // haven't tested out number. 

                                        return <fieldset >
                                            <legend>
                                                <span className={styles.formLabel}>{aResult.label}</span>
                                            </legend>
                                            <TextInput
                                                onChange={formik.handleChange(aResult.nameOfField)}
                                                name={aResult.nameOfField}
                                                key={aResult.label || 'anotherKeyPlease'}
                                                value={formik.values[aResult.nameOfField] || ''} // {|| ''} = stops us from throwing uncontrolled / controlled console error logs.
                                            />
                                        </fieldset>;
                                    }
                                    if (aResult.type === 'Select') {
                                        return <fieldset className={styles.formContainer}>
                                            <legend><span className={styles.formLabel}>{aResult.label}</span></legend>
                                            <Dropdown
                                                options={aResult?.options?.map(((aSet: string) => ({ value: aSet, description: aSet }))) ?? []}
                                                name={aResult.nameOfField}
                                                value={formik.values[aResult.nameOfField]}
                                                onChange={formik.handleChange(aResult.nameOfField)}
                                            />
                                        </fieldset>;
                                    }
                                    return null;
                                })}
                            </fieldset>
                        </div>
                    </Row>
                </div>}
                <Row>
                    <div className={styles.submitContainer}>
                        <Button type="submit" classNames={styles.submit} onClick={formik.handleSubmit}>Submit</Button>
                    </div>
                </Row>
            </Content>
        </EntireLayout >
    );
});

export default SupportPage;