import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import rowType from 'src/util/datagrid/rowType';
import { GREY_TEXT_COLOR, VERTICAL_ALIGN } from '../../../Constant';

const greyOnCellPrepared = (e: CellPreparedEvent): void => {
    if (rowType(e)) {
        if (e.column) {
            e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
        }
        if (e.column.dataField || e.column.dataField === '') {
            e.cellElement.style.color = GREY_TEXT_COLOR;
        }
    }
};

export default greyOnCellPrepared;