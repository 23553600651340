import React, { useEffect } from 'react';
import { AWS_URI, REDIRECT_URI } from '../../Constant';
import styles from './Login.module.scss';

const Login: React.FC = () => {
    useEffect(() => {
        window.location.href = `${AWS_URI}${REDIRECT_URI}`;
    }, []);

    return (
        <div className={styles.login}>
            <div className={styles.logoContainer}>
                <img
                    alt="animation logo"
                    src="http://cdn.mcauto-images-production.sendgrid.net/5f31bddca44eed49/7edad36d-6b79-4b22-9661-9b895394c4c7/400x400.png"
                />
            </div>
        </div>
    );
};

export default Login;