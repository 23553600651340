import React from 'react';

type Props = {
    width: number;
    height: number;
    classNames?: string
}

const InstitutionIcon: React.FC<Props> = ({ width, height, classNames }): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={classNames} viewBox="0 0 64 64" fill="#030D45">
        <path d="M56 25.9999H61C61.2652 25.9999 61.5196 25.8946 61.7071 25.707C61.8946 25.5195 62 25.2651 62 24.9999V10.9999C61.9994 10.7823 61.9278 10.5708 61.796 10.3975C61.6643 10.2242 61.4796 10.0987 61.27 10.0399L32.27 2.03993C32.0935 1.98994 31.9065 1.98994 31.73 2.03993L2.73 10.0399C2.52042 10.0987 2.33574 10.2242 2.20399 10.3975C2.07224 10.5708 2.00062 10.7823 2 10.9999V24.9999C2 25.2651 2.10536 25.5195 2.29289 25.707C2.48043 25.8946 2.73478 25.9999 3 25.9999H8V49.9999H5C4.73478 49.9999 4.48043 50.1053 4.29289 50.2928C4.10536 50.4804 4 50.7347 4 50.9999V53.9999H3C2.73478 53.9999 2.48043 54.1053 2.29289 54.2928C2.10536 54.4804 2 54.7347 2 54.9999V60.9999C2 61.2651 2.10536 61.5195 2.29289 61.707C2.48043 61.8946 2.73478 61.9999 3 61.9999H61C61.2652 61.9999 61.5196 61.8946 61.7071 61.707C61.8946 61.5195 62 61.2651 62 60.9999V54.9999C62 54.7347 61.8946 54.4804 61.7071 54.2928C61.5196 54.1053 61.2652 53.9999 61 53.9999H60V50.9999C60 50.7347 59.8946 50.4804 59.7071 50.2928C59.5196 50.1053 59.2652 49.9999 59 49.9999H56V25.9999ZM4 11.7599L32 4.03993L60 11.7599V19.9999H4V11.7599ZM60 55.9999V59.9999H4V55.9999H60ZM58 51.9999V53.9999H6V51.9999H58ZM16 29.9999V45.9999H10V29.9999H16ZM10 27.9999V25.9999H16V27.9999H10ZM16 47.9999V49.9999H10V47.9999H16ZM18 49.9999V25.9999H27V49.9999H18ZM35 29.9999V45.9999H29V29.9999H35ZM29 27.9999V25.9999H35V27.9999H29ZM35 47.9999V49.9999H29V47.9999H35ZM37 49.9999V25.9999H46V49.9999H37ZM54 29.9999V45.9999H48V29.9999H54ZM48 27.9999V25.9999H54V27.9999H48ZM54 47.9999V49.9999H48V47.9999H54ZM4 23.9999V21.9999H60V23.9999H4Z" fill="#030D45" />
        <path d="M33 15H31C30.7348 15 30.4804 14.8946 30.2929 14.7071C30.1054 14.5196 30 14.2652 30 14H28C28 14.7956 28.3161 15.5587 28.8787 16.1213C29.4413 16.6839 30.2044 17 31 17V18H33V17C33.7956 17 34.5587 16.6839 35.1213 16.1213C35.6839 15.5587 36 14.7956 36 14C36 13.2044 35.6839 12.4413 35.1213 11.8787C34.5587 11.3161 33.7956 11 33 11H31C30.7348 11 30.4804 10.8946 30.2929 10.7071C30.1054 10.5196 30 10.2652 30 10C30 9.73478 30.1054 9.48043 30.2929 9.29289C30.4804 9.10536 30.7348 9 31 9H33C33.2652 9 33.5196 9.10536 33.7071 9.29289C33.8946 9.48043 34 9.73478 34 10H36C36 9.20435 35.6839 8.44129 35.1213 7.87868C34.5587 7.31607 33.7956 7 33 7V6H31V7C30.2044 7 29.4413 7.31607 28.8787 7.87868C28.3161 8.44129 28 9.20435 28 10C28 10.7956 28.3161 11.5587 28.8787 12.1213C29.4413 12.6839 30.2044 13 31 13H33C33.2652 13 33.5196 13.1054 33.7071 13.2929C33.8946 13.4804 34 13.7348 34 14C34 14.2652 33.8946 14.5196 33.7071 14.7071C33.5196 14.8946 33.2652 15 33 15Z" fill="#030D45" />
        <path d="M40 11H38V13H40V11Z" fill="#030D45" />
        <path d="M26 11H24V13H26V11Z" fill="#030D45" />
    </svg>
);

export default InstitutionIcon;