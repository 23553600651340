import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Textarea from 'src/components/base/Textarea';
import AuditDropDown from 'src/components/base/auditDropDown/AuditDropDown';
import CancelButton from 'src/components/base/buttons/cancelButton/CancelButton';
import ContinueButton from 'src/components/base/buttons/continueButton/ContinueButton';
import HRLine from 'src/components/base/hrLine/HRLine';
import TransactionActionPopup from 'src/components/base/popups/actionPopups/transactionActionPopup/TransactionActionPopup';
import ToggleContainer from 'src/components/base/toggleContainer/ToggleContainer';
import Gap from 'src/layouts/gap/Gap';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import HalfPanel from 'src/layouts/twoColumnRow/halfPanel/HalfPanel';
import { TransactionActions } from 'src/pages/transactionDetailsPage/TransactionDetailsPageStoreInterface';
import TransactionDetailsAuditPopup from 'src/pages/transactionDetailsPage/transactionDetailsAuditPopup/TransactionDetailsAuditPopup';
import { useStore } from 'src/store';
import styles from '../TransactionDetailsPage.module.scss';

const ActionsSection: React.FC = observer(() => {
    const { TransactionDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();
    const [popupVisible, setPopupVisible] = useState(false);
    const [actionPopupVisible, setActionPopupVisible] = useState(false);
    const isAmlRiskToggle = TransactionDetailsPageStore.getIsAmlRiskToggle();
    const isCoveredToggle = TransactionDetailsPageStore.getIsCoveredToggle();
    const isCoverable = TransactionDetailsPageStore.getIsCoverable();
    const isFormReady = TransactionDetailsPageStore.getIsFormReady();
    const transaction = TransactionDetailsPageStore.getBaseProps();
    const isApproved = TransactionDetailsPageStore.getIsApproved();
    const isDenied = TransactionDetailsPageStore.getIsDenied();
    const showCardTransaction = TransactionDetailsPageStore.getShowCardTransactionAction();
    const isOgApproved = TransactionDetailsPageStore.getOriginalIsApproved();

    return <>
        <div className={styles.container}>
            <h3>Actions</h3>
            <TransactionDetailsAuditPopup popupVisible={popupVisible} hidePopup={() => setPopupVisible(false)} />
            <button type="button" className={styles.historyLink} onClick={() => setPopupVisible(true)}>
                History
            </button>
        </div>

        <Gap />
        <HRLine />
        <Gap />
        <div className={styles.actionRow}>
            <ToggleContainer
                label="AML Flag"
                checked={isAmlRiskToggle}
                onHandleChange={() => { TransactionDetailsPageStore.handleToggleChange('amlRisk'); }}
                description="Updating this toggle will flag this transaction as a potential AML risk."
            />
            {
                (isCoverable || isCoveredToggle) &&
                <ToggleContainer
                    label="Cover transaction"
                    checked={isCoveredToggle}
                    disabled={isCoveredToggle}
                    onHandleChange={() => { TransactionDetailsPageStore.handleToggleChange(TransactionActions.isCovered); }}
                    description="Covering a transaction will book transfer funds from Hopscotch account to the payee’s acount."
                />
            }
        </div>
        <div className={styles.actionRow}>
            <ToggleContainer
                label="Reverse Transaction"
                classNames={styles.reverseTransaction}
                checked={false}
                onHandleChange={() => { }}
                description="Reverse this card transaction."
            />
            <div className={styles.actionColumns}>
                {showCardTransaction && (<>
                    <ToggleContainer
                        label="Approve Transaction"
                        classNames={styles.topRow}
                        checked={isApproved}
                        disabled={isOgApproved}
                        onHandleChange={() => { TransactionDetailsPageStore.handleToggleChange(TransactionActions.isApproved); }}
                        description="Approve this card transaction."
                    />
                    <ToggleContainer
                        label="Deny Transaction"
                        classNames={styles.bottomRow}
                        checked={isDenied}
                        onHandleChange={() => { TransactionDetailsPageStore.handleToggleChange(TransactionActions.isDenied); }}
                        description="Deny this card transaction."
                    />
                </>)}
            </div>
        </div>
        <Gap />
        <TwoColumnRow>
            <HalfPanel isLeft={true}>
                <AuditDropDown />
            </HalfPanel>
            <HalfPanel>
                <p className={styles.reasoningLabel}>Description</p>
                <Textarea
                    maxLength={2000}
                    isFullSize={true}
                    placeholder='Detailed reason for updating the status of the transaction'
                    onChange={(e: string) => {
                        TransactionDetailsPageStore.setReason(e);
                    }} />
            </HalfPanel>
        </TwoColumnRow>
        <div className={styles.buttonContainer}>
            <CancelButton />
            <ContinueButton onClick={() => {
                setActionPopupVisible(true);
            }} disabled={!isFormReady} />
        </div>
        <TransactionActionPopup
            popupVisible={actionPopupVisible}
            hidePopup={() => { setActionPopupVisible(false); }}
            modalId="action"
            accountDetails={transaction}
            updateCallback={() => {
                TransactionDetailsPageStore.updateAmlOrCover(fetch);
            }} />
    </>;
});

export default ActionsSection;