// eslint-disable-next-line
import cn from 'classnames';
import React from 'react';
import getInPercentageFormat from 'src/util/getInPercentageFormat';
import styles from './PercentBlock.module.scss';

type Props = {
    percent: number
}

const PercentBlock: React.FC<Props> = ({ percent }) => (
    <div className={cn(styles.percentText, {
        [styles.green]: percent >= 66,
        [styles.warning]: percent >= 33 && percent < 66,
        [styles.danger]: percent < 33
    })}><span className={styles.percentage}>{getInPercentageFormat(percent)}</span>
    </div>
);

export default PercentBlock;