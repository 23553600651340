import React from 'react';
// eslint-disable-next-line id-length
import cn from 'classnames';
import NavigableCloseButton from 'src/components/base/buttons/navigableCloseButton/NavigableCloseButton';
import styles from 'src/components/base/popups/popupTitle/PopupTitle.module.scss';
import { BlueWarningIcon, GreenWarningIcon, RedWarningIcon, YellowWarningIcon } from 'src/components/logos/warning';

import { Color } from 'src/Constant';

type titleProps = {
    handleClick: any;
    title: string;
    status?: keyof typeof Color;
}

const PopupTitle: React.FC<titleProps> = ({ title, handleClick, status = Color.warning }): JSX.Element => {
    const icon = () => {
        switch (status) {
            case Color.warning:
                return <YellowWarningIcon />;
            case Color.success:
                return <GreenWarningIcon />;
            case Color.info:
                return <BlueWarningIcon />;
            case Color.danger:
                return <RedWarningIcon />;
            default:
                return <YellowWarningIcon />;
        }
    };

    return (
        <div className={styles.titleContainer}>
            <div className={styles.titleWrapper}>
                <div className={styles.titleDiv}>
                    {icon()}
                    <p className={cn(styles.title, {
                        [styles.info]: status === Color.info,
                        [styles.success]: status === Color.success,
                        [styles.warning]: status === Color.warning,
                        [styles.danger]: status === Color.danger,
                    })}>{title}</p>
                </div>
                <div>
                    <NavigableCloseButton onClick={handleClick} />
                </div>
            </div>
        </div>
    );
};

export default PopupTitle;