import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './MenuItem.module.scss';

type Props = {
    url: string;
    text: string;
    reset?: () => void;
};

const MenuItem: React.FC<Props> = ({ url, children, text, reset }) => (
    <li className={styles.menuItem}>
        <div className={styles.container}>
            <NavLink
                to={url}
                end={true}
                className={styles.link}
                onClick={reset}
                style={({ isActive }) => (isActive ? { color: '#1d1d1b', background: '#f9f9f9' } : { color: '#1d1d1b', background: '#ffffff' })}
            >
                {children} {text}
            </NavLink>
        </div>
    </li>

);

export default MenuItem;