import fileDownload from 'js-file-download';
import { makeAutoObservable } from 'mobx';
import Moment from 'moment';
import { fetchTransactionDetails } from 'src/api/api';
import { FetchInterface } from 'src/api/useFetch';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { AUDIT_HISTORY_TRANSACTIONS_CSV, AUDIT_HISTORY_TRANSACTIONS_URL, NOT_AVAILABLE_PLACEHOLDER, TOAST_FAILURE_TEXT, TOAST_SUCCESS_TEXT, TRANSACTION_APPROVED_ACTIONS_URL, TRANSACTION_DENIED_ACTIONS_URL, TRANSACTION_DETAILS_ACTIONS_URL, TRANSACTION_DETAILS_BY_PAYMENT_ID_API } from 'src/Constant';
import { Action, Form, Meta, MutualTransaction, Payee, Payer, Transaction, TransactionActions, TransactionDetailAuditHistoryEntity } from 'src/pages/transactionDetailsPage/TransactionDetailsPageStoreInterface';
import { displayToast, getAccessToken } from 'src/store/SharedStore';
import { getCurrencyOrNotApplicable, Logger } from 'src/util';
import getPlanName from 'src/util/PlanUtils';
import { selectLinkedAccountDetails } from 'src/util/selectors';

export interface TransactionDetailsPageStoreInterface {
    form: Form;
    hasValueChanged: boolean;
    modalStatus: string;
    transaction: Transaction;
    auditHistory?: (TransactionDetailAuditHistoryEntity)[] | null;
    getFormReason(): string,
    getTransactionDetails(): any,
    getDateOfLastMutualTransaction(): string,
    getSumOfAllMutualTransactions(): number,
    getTransactionStatus(): string,
    getTotalMutualTransactionsCount(): number,
    getPayorDbaName(): string,
    getPayeeDbaName(): string,
    getPayeeTotalTransactionsCount(): number,
    getPayorTotalTransactionsCount(): number,
    getTransactionType(): string,
    getTransactionAmount(): number,
    getPayeeUserType(): boolean,
    getAmount(): number,
    getPayorUserType(): boolean,
    getPayeeAvgInvoice(): number,
    getAuditTransHistory(): TransactionDetailAuditHistoryEntity[],
    handleToggleChange(modal: keyof typeof TransactionActions): void,
    fetchTransactionDetails(id: string): void,
    fetchTransactionsAuditCSV(fetch: FetchInterfaceLegacy, transactionId: string): void,
    fetchTransactionDetailsByPaymentId(id: string, fetch: FetchInterfaceLegacy): void;
    fetchAuditHistoryTransactions(fetch: FetchInterface<TransactionDetailAuditHistoryEntity>, transactionId: string): void,
    updateAmlOrCover(fetch: FetchInterfaceLegacy): void,
    updateAmlRisk(fetch: FetchInterfaceLegacy): void,
    updateCoveredTransaction(fetch: FetchInterfaceLegacy): void;
    setModalStatus(status: string): void;
    setAmlRiskToggle(isAmlRisk: boolean): void;
    getBaseProps(): any;
    getIsAmlRiskToggle(): boolean;
    setIsCoveredToggle(toggleStatus: boolean): void;
    setHasValueChanged(changed: boolean): void;
    getIsCoveredToggle(): boolean;
    getIsCoverable(): boolean;
    getIsFormReady(): boolean;
    setReason(reason: string): void;
    getPayeeFirstLBADetails(): any;
    getPayorFirstLBADetails(): any;
    getModalStatus(): string;
    getIsWaitingForApproval(): boolean;
    getIsApproved(): boolean;
    getIsDenied(): boolean;
    getIsInitiated(): boolean;
    setIsApproved(toggleStatus: boolean): void;
    getShowCardTransactionAction(): boolean;
    updateApprovedTransaction(fetch: FetchInterfaceLegacy): void;
    getOriginalIsApproved(): boolean;
    setIsDenied(): void;
    getMeta(): Meta;
    getTotalReceived(): number;
    getFee(): number | null;
    getPaymentAmount(): number;
    getInvoiceAmount(): number;
}

const TransactionDetailsPageStore = (sharedStore: {
    getAuditDropdownCategory: () => string,
    getAuditDropdownSubCategory: () => string,
    getAccessToken: getAccessToken;
    displayToast: displayToast
}): TransactionDetailsPageStoreInterface =>
    makeAutoObservable({
        form: {} as Form,
        hasValueChanged: false as boolean,
        modalStatus: '',
        transaction: {
            totalReceived: -1,
            invoiceFeeAmount: -1,
            invoiceAmount: -1,
            isFeeOnPayorForInvoice: false as boolean,
            causationIdType: '',
            transactionId: '',
            transactionType: '',
            transactionAmount: -1,
            paymentAmount: -1,
            invoiceTotalAmount: -1,
            transferAmount: -1,
            transferStatus: '',
            transactionDate: '',
            invoiceDueDate: '',
            transactionStatus: '',
            isCovered: false as boolean,
            isAmlRisk: false as boolean,
            isCoverable: false as boolean,
            isWaitingForApproval: false as boolean,
            isApproved: false as boolean,
            isDenied: false as boolean,
            isInitiated: false as boolean,
            invoiceSubTotal: -1,
            meta: {} as Meta,
            amount: 0,
            payee: {} as Payee,
            payer: {} as Payer,
            mutualTransaction: {} as MutualTransaction,
            action: {} as Action,
            cardPartnerId: '',
            cardTransactionStatus: '',
            bankPartnerId: '',
            payorBankPartnerId: '',
            payeeBankPartnerId: '',
        },
        auditHistory: [] as TransactionDetailAuditHistoryEntity[],
        getIsCoveredToggle(): boolean {
            return this.form.isCovered;
        },
        getIsAmlRiskToggle(): boolean {
            return this.form.isAmlRisk;
        },
        setIsCoveredToggle(toggleStatus: boolean) {
            this.form.isCovered = toggleStatus;
        },
        setIsApproved() {
            this.form.isApproved = !this.form.isApproved;
        },
        setIsDenied() {
            this.form.isDenied = !this.form.isDenied;
        },
        getIsApproved() {
            return this.form.isApproved;
        },
        getAmount() {
            return this.transaction.amount;
        },
        getIsDenied() {
            return this.form.isDenied;
        },
        setAmlRiskToggle(toggleStatus: boolean) {
            this.form.isAmlRisk = toggleStatus;
        },
        getIsCoverable() {
            return this.form.isCoverable;
        },
        getIsFormReady(): boolean {
            return sharedStore.getAuditDropdownSubCategory() !== ''
                && this.getHasValueChanged();
        },
        setHasValueChanged(changed: boolean): void {
            this.hasValueChanged = changed;
        },
        setReason(reason: string) {
            this.form.reason = reason;
        },
        getModalStatus() {
            return this.modalStatus;
        },
        setModalStatus(status: string) {
            this.modalStatus = status;
        },
        getShowCardTransactionAction() {
            return this.transaction.isWaitingForApproval || this.transaction.isApproved || this.transaction.isDenied;
        },
        getPayeeFirstLBADetails() {
            if (this.transaction.payee?.linkedAccountDetails) {
                return {
                    ...this.transaction.payee.linkedAccountDetails,
                    availableBalance: this.transaction.payee.hopscotchBalance,
                    totalTransactionsCount: this.transaction.payee.totalTransactionVolume,
                    totalTransactionsSum: 0
                };
            }
            return [];
        },
        getPayorFirstLBADetails() {
            if (this.transaction.payer?.linkedAccountDetails) {
                return {
                    ...this.transaction.payer.linkedAccountDetails,
                    availableBalance: this.transaction.payer.hopscotchBalance,
                    totalTransactionsCount: this.transaction.payer.totalTransactionVolume,
                    totalTransactionsSum: 0
                };
            }
            return [];
        },
        getMeta() {
            return this.transaction?.meta ?? {
                feeAmount: 0,
                actualAmount: 0,
                feePercentage: 0,
                amountWithFee: 0,
            };
        },
        getBaseProps(): object {
            return {
                transactionDetails: {
                    causationIdType: this.transaction.causationIdType,
                    transactionType: this.transaction.transactionType,
                    transactionStatus: this.transaction.transactionStatus,
                    transactionAmount: this.transaction.transactionAmount,
                    invoiceAmount: this.transaction.invoiceAmount,
                    transferAmount: this.transaction.transferAmount,
                    transferStatus: this.transaction.transferStatus,
                    transactionDate: this.transaction.transactionDate,
                    transactionDueDate: this.transaction.invoiceDueDate || NOT_AVAILABLE_PLACEHOLDER,
                    transactionCompletedDate: this.transaction.transactionDate,
                    meta: this.transaction.meta,
                    transactionId: this.transaction.transactionId,
                    cardPartnerId: this.transaction.cardPartnerId,
                    cardTransactionStatus: this.transaction.cardTransactionStatus,
                    bankPartnerId: this.transaction.bankPartnerId,
                    payorBankPartnerId: this.transaction.payorBankPartnerId,
                    payeeBankPartnerId: this.transaction.payeeBankPartnerId,
                },
                payee: {
                    dbaName: this.transaction.payee.dbaName,
                    name: this.transaction.payee.name,
                    profileLink: this.transaction.payee.profileLink,
                    createdTimestamp: this.transaction.payee.sinceDate,
                    score: this.transaction.payee.score,
                    accountLogo: this.transaction.payee.logo,
                    isVerified: this.transaction.payee.status === 'Pass',
                    hopscotchBalance: this.transaction.payee.hopscotchBalance,
                    bankPartnerBalance: this.transaction.payee.bankPartnerBalance,
                    averageTransactionSize: this.transaction.payee.averageTransactionSize,
                    totalTransactionVolume: this.transaction.payee.totalTransactionVolume,
                    caseId: this.transaction.payee.caseId,
                    linkedAccountBlock: this.transaction.payee.linkedAccountDetails,
                    isFeeOnPayor: this.transaction.payee.isFeeOnPayor,
                    planType: this.transaction.payee.planType
                },
                payor: {
                    dbaName: this.transaction.payer.dbaName,
                    name: this.transaction.payer.name,
                    profileLink: this.transaction.payer.profileLink,
                    createdTimestamp: this.transaction.payer.sinceDate,
                    score: this.transaction.payer.score,
                    accountLogo: this.transaction.payer.logo,
                    isVerified: this.transaction.payer.status === 'Pass',
                    hopscotchBalance: getCurrencyOrNotApplicable(this.transaction.payer.hopscotchBalance),
                    bankPartnerBalance: getCurrencyOrNotApplicable(this.transaction.payer.bankPartnerBalance),
                    averageTransactionSize: this.transaction.payer.averageTransactionSize,
                    totalTransactionVolume: this.transaction.payer.totalTransactionVolume,
                    caseId: this.transaction.payer.caseId,
                    linkedAccountBlock: this.transaction.payer.linkedAccountDetails,
                    planType: this.transaction.payer.planType
                },
                mutualTransactions: {
                    ...this.transaction?.mutualTransaction ?? {
                        lastTransactionDate: '',
                        totalTransactions: 0,
                        totalAmount: 0,
                    },
                    avgTransactionSize: (this.transaction?.mutualTransaction?.totalAmount ?? 0 / (this.transaction?.mutualTransaction?.totalTransactions ?? 0)),
                }
            };
        },
        getTotalReceived() {
            return this.transaction.totalReceived;
        },
        getFee() {
            return this.transaction.invoiceFeeAmount;
        },
        getPaymentAmount() {
            return this.transaction.transferAmount;
        },
        getInvoiceAmount() {
            return this.transaction.invoiceTotalAmount || this.transaction.invoiceAmount;
        },
        getFormReason() {
            return this.form.reason;
        },
        getTransactionDetails(): Transaction {
            return this.transaction;
        },
        getDateOfLastMutualTransaction() {
            return Moment(this.transaction.mutualTransaction.lastTransactionDate).format('MMM DD, YYYY');
        },
        getSumOfAllMutualTransactions() {
            return this.transaction.mutualTransaction.totalAmount;
        },
        getTransactionStatus() {
            return this.transaction.transactionStatus;
        },
        getTotalMutualTransactionsCount() {
            return this.transaction.mutualTransaction.totalAmount;
        },
        getPayorDbaName() {
            return this.transaction.payer.dbaName;
        },
        getPayeeDbaName() {
            return this.transaction.payee.dbaName;
        },
        getPayeeTotalTransactionsCount() {
            return this.transaction.payee.totalTransactionVolume;
        },
        getPayorTotalTransactionsCount() {
            return this.transaction.payer.totalTransactionVolume;
        },
        getTransactionType() {
            return this.transaction.transactionType;
        },
        getTransactionAmount() {
            return this.transaction.transactionAmount;
        },
        getPayeeUserType() {
            return this.transaction.payee.hopscotchUser;
        },
        getPayorUserType() {
            return this.transaction.payer.hopscotchUser;
        },
        getPayeeAvgInvoice() {
            return this.transaction.payee.averageTransactionSize;
        },
        getAuditTransHistory(): TransactionDetailAuditHistoryEntity[] {
            return this.auditHistory;
        },
        getIsWaitingForApproval() {
            return this.transaction.isWaitingForApproval;
        },
        getIsInitiated() {
            return this.transaction.isInitiated;
        },
        getOriginalIsApproved() {
            return this.transaction.isApproved;
        },
        handleToggleChange(modal: keyof typeof TransactionActions): void {
            if (this.getHasValueChanged()) {
                this.resetModal(modal);
                return;
            }
            this.setModal(modal);
        },
        getHasValueChanged() {
            if (this.form.isAmlRisk !== this.transaction.isAmlRisk) {
                return true;
            }
            if (this.form.isApproved !== this.transaction.isApproved) {
                return true;
            }
            if (this.form.isCoverable !== this.transaction.isCoverable) {
                return true;
            }
            if (this.form.isCovered !== this.transaction.isCovered) {
                return true;
            }
            if (this.form.isDenied !== this.transaction.isDenied) {
                return true;
            }

            return false;
        },
        setModal(modal: keyof typeof TransactionActions) {
            if (modal === TransactionActions.amlRisk) {
                this.setAmlRiskToggle(!this.form.isAmlRisk);
                this.setModalStatus(TransactionActions.amlRisk);
            }
            if (modal === TransactionActions.isCovered) {
                this.setIsCoveredToggle(!this.form.isCovered);
                this.setModalStatus(TransactionActions.isCovered);
            }
            if (modal === TransactionActions.isApproved) {
                this.setIsApproved();
                this.setModalStatus(TransactionActions.isApproved);
            }
            if (modal === TransactionActions.isDenied) {
                this.setIsDenied();
                this.setModalStatus(TransactionActions.isDenied);
            }
        },
        resetModal(modal: keyof typeof TransactionActions) {
            if (this.modalStatus === TransactionActions.amlRisk && modal === TransactionActions.amlRisk) {
                this.setAmlRiskToggle(!this.form.isAmlRisk);
                this.setModalStatus('');
            }
            if (this.modalStatus === TransactionActions.isCovered && modal === TransactionActions.isCovered) {
                this.setIsCoveredToggle(!this.form.isCovered);
                this.setModalStatus('');
            }
            if (this.modalStatus === TransactionActions.isApproved && modal === TransactionActions.isApproved) {
                this.setIsApproved();
                this.setModalStatus('');
            }
            if (this.modalStatus === TransactionActions.isDenied && modal === TransactionActions.isDenied) {
                this.setIsDenied();
                this.setModalStatus('');
            }
        },
        fetchTransactionDetails(id: string): void {
            fetchTransactionDetails(sharedStore.getAccessToken(), id)
                .then(({ data }) => {
                    this.payloadHandler(data);
                })
                .catch((err) => {
                    Logger.error(err);
                });
        },
        payloadHandler(data: any) {
            this.transaction = data;
            this.form.isCovered = data.isCovered;
            this.form.isCoverable = data.isCoverable;
            this.form.isAmlRisk = data.isAmlFlagged;
            this.form.isApproved = data.isApproved || data.isInitiated;
            this.form.isDenied = data.isDenied;

            this.transaction.meta = data.transactionMeta;
            this.transaction.isCovered = data.isCovered;
            this.transaction.isCoverable = data.isCoverable;
            this.transaction.isAmlRisk = data.isAmlFlagged;
            this.transaction.isApproved = data.isInitiated || data.isInitiated;
            this.transaction.isDenied = data.isDenied;
            this.transaction.isInitiated = data.isInitiated;
            this.transaction.isWaitingForApproval = data.isWaitingForApproval;
            this.transaction.invoiceTotalAmount = data.invoiceTotalAmount;
            this.transaction.paymentAmount = data.paymentAmount;
            this.transaction.invoiceSubTotal = data.invoiceSubTotal;
            this.transaction.amount = data.amount;

            this.transaction.payer = data.payer;
            this.transaction.payer.linkedAccountDetails = selectLinkedAccountDetails(data.payer.linkedBankAccountDetails);

            this.transaction.payee = data.payee;
            this.transaction.payee.linkedAccountDetails = selectLinkedAccountDetails(data.payee.linkedBankAccountDetails);

            const payeePlan = (data?.payee?.subscriptionDetails?.plan || '') as string;
            const payeeBillingFrequency = (data?.payee?.subscriptionDetails?.billingFrequency || '') as string;
            this.transaction.payee.planType = getPlanName({ planType: payeePlan, billingFrequency: payeeBillingFrequency });

            const payorPlan = (data?.payer?.subscriptionDetails?.plan || '') as string;
            const payorBillingFrequency = (data?.payer?.subscriptionDetails?.billingFrequency || '') as string;
            this.transaction.payer.planType = getPlanName({ planType: payorPlan, billingFrequency: payorBillingFrequency });

            this.transaction.cardPartnerId = data.cardPartnerId || NOT_AVAILABLE_PLACEHOLDER;
            this.transaction.cardTransactionStatus = data.cardTransactionStatus || NOT_AVAILABLE_PLACEHOLDER;
            this.transaction.bankPartnerId = data.bankPartnerId || NOT_AVAILABLE_PLACEHOLDER;
            this.transaction.payorBankPartnerId = data.payorBankPartnerId || NOT_AVAILABLE_PLACEHOLDER;
            this.transaction.payeeBankPartnerId = data.payeeBankPartnerId || NOT_AVAILABLE_PLACEHOLDER;
        },
        fetchTransactionDetailsByPaymentId(id: string, fetch: FetchInterface<Transaction>): void {
            fetch({ url: TRANSACTION_DETAILS_BY_PAYMENT_ID_API(id) }, ({ data }) => {
                this.payloadHandler(data);
            });
        },
        fetchTransactionsAuditCSV(fetch: FetchInterfaceLegacy, transactionId: string): void {
            fetch({ url: AUDIT_HISTORY_TRANSACTIONS_CSV(transactionId) }, (res: any) => {
                fileDownload(res.data, 'trans_history.csv');
            });
        },
        fetchAuditHistoryTransactions(fetch: FetchInterface<TransactionDetailAuditHistoryEntity>, transactionId: string): void {
            fetch({ url: AUDIT_HISTORY_TRANSACTIONS_URL(transactionId) }, (res: any) => {
                this.auditHistory = res.data.map((theHistory: any) => ({
                    id: theHistory.id,
                    modalId: theHistory.modelId,
                    date: theHistory.date,
                    operator: theHistory.operator,
                    flagTransaction: theHistory.operations.flagTransaction,
                    flagType: theHistory.operations.flagType,
                    operationReason: theHistory.operationReason,

                }));
            });
        },
        updateAmlOrCover(fetch: FetchInterfaceLegacy): void {
            if (this.modalStatus === TransactionActions.amlRisk) {
                this.updateAmlRisk(fetch);
            } else if (this.modalStatus === TransactionActions.isCovered) {
                this.updateCoveredTransaction(fetch);
            } else if (this.modalStatus === TransactionActions.isApproved) {
                this.updateApprovedTransaction(fetch);
            } else if (this.modalStatus === TransactionActions.isDenied) {
                this.updateDeniedTransaction(fetch);
            }
        },
        updateAmlRisk(fetch: FetchInterfaceLegacy): void {
            fetch({
                url: TRANSACTION_DETAILS_ACTIONS_URL,
                method: 'PUT',
                data: {
                    transactionId: this.transaction.transactionId,
                    flagType: 'Aml',
                    reason: this.form.reason,
                    shouldFlag: this.form.isAmlRisk,
                    audit: {
                        category: sharedStore.getAuditDropdownCategory(),
                        subCategory: sharedStore.getAuditDropdownSubCategory(),
                    }
                }
            },
                () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        },
        updateCoveredTransaction(fetch: FetchInterfaceLegacy) {
            fetch({
                url: TRANSACTION_DETAILS_ACTIONS_URL,
                method: 'PUT',
                data: {
                    transactionId: this.transaction.transactionId,
                    flagType: 'CoverTransaction',
                    reason: this.form.reason,
                    shouldFlag: this.form.isCovered,
                    audit: {
                        category: sharedStore.getAuditDropdownCategory(),
                        subCategory: sharedStore.getAuditDropdownSubCategory(),
                    }
                }
            },
                () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        },
        updateApprovedTransaction(fetch: FetchInterfaceLegacy) {
            fetch({
                url: TRANSACTION_APPROVED_ACTIONS_URL,
                method: 'PUT',
                data: {
                    transactionId: this.transaction.transactionId,
                    reason: this.form.reason,
                    audit: {
                        category: sharedStore.getAuditDropdownCategory(),
                        subCategory: sharedStore.getAuditDropdownSubCategory(),
                    }
                }
            },
                () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        },
        updateDeniedTransaction(fetch: FetchInterfaceLegacy) {
            fetch({
                url: TRANSACTION_DENIED_ACTIONS_URL,
                method: 'PUT',
                data: {
                    transactionId: this.transaction.transactionId,
                    reason: this.form.reason,
                    audit: {
                        category: sharedStore.getAuditDropdownCategory(),
                        subCategory: sharedStore.getAuditDropdownSubCategory(),
                    }
                }
            },
                () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        }
    });

export default TransactionDetailsPageStore;
