import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { exchangeCodeForTokens, getUserDetails } from 'src/api/api';
import { getCookie, Logger, parseJwt, setCookie } from 'src/util';
import { FeatureInterface } from '../util/interfaces';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const TOKEN_EXPIRATION_TIME = 'tokenExpirationTime';
export const USERNAME_KEY = 'username';
export const IP_ADDRESS_KEY = 'ipAddress';
export const ROLES = 'roles';
export const DAYS = 1;
const MILLISECONDS = 1000;

export const NO_ROLES = 'NoRoles';
export const checkRoles = (roles: null | string): string => {
    if (!roles) {
        return JSON.stringify([NO_ROLES]);
    }
    return roles;
};

export const checkAccessToken = (): string => {
    const accessToken = getCookie(ACCESS_TOKEN_KEY);
    if (accessToken) {
        return accessToken;
    }
    return 'no-token!';
};

export const checkExpirationTime = (): number => {
    const expirationTime = getCookie(TOKEN_EXPIRATION_TIME);
    if (expirationTime) {
        return Number.parseInt(expirationTime);
    }
    return 0;
};

interface Toast { message: string, status: string };
export type getAccessToken = () => string;
export type displayToast = (message: string, status: 'isSuccess' | 'isFailure') => void;

export interface SharedStoreProp {
    isAuthenticated: any,
    accessToken: string,
    idToken: string,
    tokenExpirationTime: number,
    username: string,
    ipAddress: string,
    totalAccounts: number,
    totalUnverifiedAccounts: number,
    monthlyTransactionAmount: number,
    totalDeposits: number,
    generateReportPopupVisible: any,
    companies: string[],
    roles: string[],
    toast: { message: string, status: string },
    isLoading: any,
    auditDropDowns: {
        category: string;
        subCategory: string;
    },
    features: FeatureInterface[],
    setFeatures: (features: FeatureInterface[]) => void;
    getFeatures: () => FeatureInterface[];
    getToast: () => Toast,
    getIsLoading(): boolean,
    setIsLoading(isLoading: boolean): void,
    setAuditDropdownCategory(category: string): void,
    getAuditDropdownCategory(): string,
    setAuditDropdownSubCategory(subCategory: string): void,
    getAuditDropdownSubCategory(): string,
    displayToast: displayToast,
    getAccessToken: getAccessToken,
    getRoles: () => string[],
    authenticate: () => void,
    changeToken: (accessToken: string, idToken: string) => void,
    getTokenExpirationTime: () => number,
    setTokenExpirationTime: (expiresIn: number) => void,
    changeUserDetails(username: string, ipAddress: string): void
    getAuthDetail(): boolean
    getUsername(): string | null
    getIp(): string | null,
    setGenerateReportPopupVisble: (bool: boolean) => void
    fetchUserDetails: () => void,
    fetchExchangeCodeForTokens: (authCode: string) => void,
    setTextValue(value: string): void;
    textValue: string;
}

const SharedStore = (): SharedStoreProp =>
    makeAutoObservable({
        isAuthenticated: false,
        accessToken: '',
        idToken: '',
        tokenExpirationTime: 0,
        username: '',
        ipAddress: '',
        totalAccounts: 0,
        totalUnverifiedAccounts: 0,
        monthlyTransactionAmount: 0,
        totalDeposits: 0,
        generateReportPopupVisible: false,
        companies: [],
        roles: ['NoRoleYet'],
        toast: {
            message: '',
            status: '',
        },
        isLoading: false,
        auditDropDowns: {
            category: '',
            subCategory: '',
        },
        textValue: '',
        setTextValue(value: string) {
            this.textValue = value;
        },
        features: [{ id: '', name: '', isEnabled: false }],
        getFeatures() {
            return this.features;
        },
        setFeatures(features) {
            this.features = features;
        },
        getToast() {
            return this.toast;
        },
        displayToast(message: string, status: 'isSuccess' | 'isFailure') {
            this.toast.message = message;
            this.toast.status = status;
            if (status === 'isSuccess') {
                toast.success(message);
            } else if (status === 'isFailure') {
                toast.error(message);
            }
        },
        getIsLoading(): boolean {
            return this.isLoading;
        },
        setIsLoading(isLoading: boolean): void {
            this.isLoading = isLoading;
        },
        setAuditDropdownCategory(category: string): void {
            this.auditDropDowns.category = category;
        },
        getAuditDropdownCategory(): string {
            return this.auditDropDowns.category;
        },
        setAuditDropdownSubCategory(subCategory: string) {
            this.auditDropDowns.subCategory = subCategory;
        },
        getAuditDropdownSubCategory(): string {
            return this.auditDropDowns.subCategory;
        },
        getAccessToken(): string {
            return checkAccessToken();
        },
        getRoles(): string[] {
            return JSON.parse(checkRoles(getCookie(ROLES)));
        },
        authenticate(): void {
            this.isAuthenticated = true;
        },
        changeToken(accessToken: string, idToken: string): void {
            this.accessToken = accessToken;
            this.idToken = idToken;
            setCookie(ACCESS_TOKEN_KEY, this.accessToken, DAYS);
        },
        getTokenExpirationTime(): number {
            return checkExpirationTime();
        },
        /**
         *  
         * @param expiresIn e.g. 3600. To indicate 1 hour. 3600 seconds / 60 = 60 mins.s
         */
        setTokenExpirationTime(expiresIn: number) {
            this.tokenExpirationTime = Date.now() + expiresIn * MILLISECONDS;
            setCookie(TOKEN_EXPIRATION_TIME, this.tokenExpirationTime.toString(), DAYS);
        },
        changeUserDetails(username: string, ipAddress: string): void {
            this.username = username;
            this.ipAddress = ipAddress;
            setCookie(USERNAME_KEY, this.username, DAYS);
            setCookie(IP_ADDRESS_KEY, this.ipAddress, DAYS);
        },
        getAuthDetail(): boolean {
            return this.isAuthenticated;
        },
        getUsername(): string | null {
            return this.username || getCookie(USERNAME_KEY);
        },
        getIp(): string | null {
            return this.ipAddress || getCookie(IP_ADDRESS_KEY);
        },
        setGenerateReportPopupVisble(bool: boolean): void {
            this.generateReportPopupVisible = bool;
        },
        fetchUserDetails() {
            getUserDetails(this.accessToken, this.idToken)
                .then((res: any) => {
                    this.ipAddress = res.data.ipAddress;
                    this.username = res.data.username;
                    setCookie(USERNAME_KEY, this.username, DAYS);
                    setCookie(IP_ADDRESS_KEY, this.ipAddress, DAYS);
                    Logger.info(JSON.stringify(res.data));
                })
                .catch((err: any) => {
                    Logger.error(err);
                });
        },
        async fetchExchangeCodeForTokens(authCode: string) {
            this.setIsLoading(true);

            await exchangeCodeForTokens(authCode)
                .then((res) => {
                    if (!this.getAuthDetail()) {
                        this.authenticate();
                        Logger.info(`Authenticated == ${this.isAuthenticated}`);
                    }
                    this.accessToken = res.data.access_token;
                    this.idToken = res.data.id_token;
                    this.setTokenExpirationTime(res.data.expires_in);
                    this.changeToken(this.accessToken, this.idToken);
                    this.roles = parseJwt(res.data.access_token)['cognito:groups'];
                    Logger.info(`Is authenticated is equal to ${this.isAuthenticated}`);
                    setCookie(ACCESS_TOKEN_KEY, this.accessToken, DAYS);
                    setCookie(ROLES, JSON.stringify(this.roles), DAYS);
                })
                .catch((err) => {
                    Logger.error(`POST Token Error: ${err.response}`);
                });
            this.setIsLoading(false);
        },
    });

export default SharedStore;
