import React, { memo } from 'react';
import { AuditHistory } from 'src/pages/paidPlanControls/paidPlanControlsInterface/PaidPlanControlsInterface';
import { DateFormats, formatDate } from 'src/util/DateUtils';
import { getPlanAuditFullLabel } from 'src/util/PlanUtils';

import styles from 'src/pages/accountDetailsPage/sections/accountActionsSection/accountActionsAuditHistoryPopup/auditHistoryContent/auditHistoryItem/AuditHistoryItem.module.scss';

export interface AuditHistoryItemProps {
    audit: AuditHistory
}

const AuditHistoryItem: React.FC<AuditHistoryItemProps> = memo(({ audit }: AuditHistoryItemProps) => {
    const formattedDate = formatDate(audit.date, DateFormats.fullDate);
    const operator = audit?.operator;
    const operations = audit?.operations;
    const fullLabel = getPlanAuditFullLabel(operations);

    return (
        <div className={styles.container} id={audit.id}>
            <div>
                <h4 className={styles.title}>{formattedDate} - {operator}<span>{fullLabel}</span></h4>
            </div>
        </div>
    );
});

export default AuditHistoryItem;