// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import { getInCurrencyFormat } from 'src/util';
import styles from './InfoBlock.module.scss';

type InfoBlockProps = {
    label: string;
    response: number | string;
}

const InfoBlock: React.FC<InfoBlockProps> = ({ label, response }) =>
    <div className={styles.row}>
        <p className={cn(styles.greyText, styles.leftSideSpacer)}>{label}</p>
        <p className={styles.greyText}>{getInCurrencyFormat(response)}</p>
    </div>;

export default InfoBlock;