import classNames from 'classnames';
import React from 'react';
import CurrencyValue from 'src/components/base/currencyValue/CurrencyValue';
import GreyLabel from 'src/components/base/greyLabel/GreyLabel';
import { Transaction } from 'src/pages/transactionDetailsPage/TransactionDetailsPageStoreInterface';
import styles from '../TransactionDetailsPage.module.scss';

type Props = {
    transactionDetails: Transaction;
    invoiceAmount: number;
    totalReceived: number;
}

type Props2 = {
    label: string;
    value: number;
}
const LargeLabelCurrency: React.FC<Props2> = ({ label, value }) => <h4 className={classNames(styles.LabelCurrency)}>{label}: <h2 className={styles.bigText}><CurrencyValue classNames={classNames(styles.currencyValue, styles.bigText)}>{value}</CurrencyValue></h2></h4>;
const LabelCurrency: React.FC<Props2> = ({ label, value }) => <h4 className={classNames(styles.LabelCurrency)}>{label}: <CurrencyValue classNames={styles.currencyValue}>{value}</CurrencyValue></h4>;

const StatusSection: React.FC<Props> = ({ transactionDetails, invoiceAmount, totalReceived }) => {
    const { isFeeOnPayorForInvoice: isPayorPayingFee } = transactionDetails;

    return (<div className={styles.statusMainComponent}>
        <div className={styles.twoColDiv}>
            <h2 className={styles.bigText}>{transactionDetails.causationIdType}</h2>
            <LargeLabelCurrency label="Invoice amount" value={invoiceAmount} />
        </div>

        <div className={styles.twoColDiv}>
            <h4> </h4>
            <LargeLabelCurrency label="Payment amount" value={transactionDetails.paymentAmount} />
        </div>

        <div className={styles.twoColDiv}>
            <h4> </h4>
            {transactionDetails?.meta && (<div className={styles.feeAndAmount}>
                <GreyLabel label={isPayorPayingFee ? 'Customer pays fee' : 'Business pays fee'} />
                <span
                    className={classNames(styles.currencyValue, { [styles.feeWithNoFeeLabel]: !isPayorPayingFee })} >
                    <LabelCurrency
                        label={`Fee (${transactionDetails?.meta?.feePercentage ? (transactionDetails.meta.feePercentage * 10 ** 2).toFixed(1) : 0}%)`}
                        value={transactionDetails.meta.feeAmount}
                    />
                </span>
            </div>)}
        </div>

        <div className={styles.twoColDiv}>
            <h4> </h4>
            <LabelCurrency label="Total received" value={totalReceived} />
        </div>
    </div>);
};

export default StatusSection;