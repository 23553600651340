/* eslint-disable no-nested-ternary */
import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { CARD_SEARCH, CARD_SEARCH_COUNT, CARD_SEARCH_STATUS, CARD_SEARCH_UPDATED_BY } from 'src/Constant';
import { LbaSearchPayloadInterface, PaymentSearchInterface, PaymentType } from '../../util/interfaces';
import { selectPaymentSearch } from '../../util/selectors';
import { CountData } from './CardVerificationSearchPageInterface';

export interface CardVerificationSearchPageStoreInterface {
    status: string;
    updatedBy: string;
    allCount: number;
    blockedCount: number;
    unblockedCount: number;
    restrictedOrLockedUnblockedCount: number;
    restrictedOrLockedBlockedCount: number;
    blockedHopCount: number;
    blockedOpsCount: number;
    lockedAccount: number;
    unblockedHopCount: number;
    unblockedOpsCount: number;
    lbas: PaymentSearchInterface[];
    getCards(): PaymentSearchInterface[];
    getCounts(): CountData,
    setSelected(status?: string): void;
    getLinkUrl(status: string, updatedBy: string): string;
    getUpdatedBy(): string;
    fetchCards(fetch: FetchInterfaceLegacy, status: string, updatedBy: string): void;
    fetchCardsCounts(fetch: FetchInterfaceLegacy): void;
}

const CardVerificationSearchPageStore = (): CardVerificationSearchPageStoreInterface =>
    makeAutoObservable({
        status: '',
        updatedBy: '',
        allCount: 0,
        blockedCount: 0,
        unblockedCount: 0,
        restrictedOrLockedUnblockedCount: 0,
        restrictedOrLockedBlockedCount: 0,
        lockedAccount: 0,
        blockedHopCount: 0,
        blockedOpsCount: 0,
        unblockedHopCount: 0,
        unblockedOpsCount: 0,
        lbas: [
            {
                accountLogo: '',
                businessName: '',
                userName: '',
                email: '',
                businessType: '',
                caseID: '',
                dateReceived: '',
                status: '',
                paymentStatus: '',
                hopscotchStatus: '',
                pendingStatus: '',
                institutionName: '',
                accountNumber: '',
                institutionLogo: '',
                id: '',
                isBlocked: false,
                hasOpsUpdated: false,
                paymentType: PaymentType.NotSet,
            },
        ],
        getCards() {
            return this.lbas;
        },
        getCounts() {
            return {
                allCount: this.allCount,
                blockedCount: this.blockedCount,
                unblockedCount: this.unblockedCount,
                blockedHopCount: this.blockedHopCount,
                blockedOpsCount: this.blockedOpsCount,
                unblockedHopCount: this.unblockedHopCount,
                unblockedOpsCount: this.unblockedOpsCount,
                restrictedOrLockedUnblockedCount: this.restrictedOrLockedUnblockedCount,
                restrictedOrLockedBlockedCount: this.restrictedOrLockedBlockedCount,
            };
        },
        setSelected(status: string) {
            if (status === '') {
                this.status = CARD_SEARCH_UPDATED_BY.All;
            } else {
                this.status = status;
            }
        },
        getLinkUrl(status: string, updatedBy: string): string {
            return `/biz/card-verifications/status/${status}/updatedBy/${updatedBy}`;
        },
        getUpdatedBy() {
            return this.updatedBy;
        },
        fetchCards(fetch: FetchInterfaceLegacy, status: string, updatedBy: string) {
            fetch({ url: CARD_SEARCH(status, updatedBy) }, (res) => {
                this.lbas = res.data.map((theCase: LbaSearchPayloadInterface) => selectPaymentSearch(theCase));
            }, true);
        },
        fetchCardsCounts(fetch: FetchInterfaceLegacy) {
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.All, CARD_SEARCH_UPDATED_BY.All)}` }, (res) => {
                this.allCount = res.data;
            });
            fetch({ url: CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.Blocked, CARD_SEARCH_UPDATED_BY.All) }, (res) => {
                this.blockedCount = res.data;
            });
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.UnBlocked, CARD_SEARCH_UPDATED_BY.All)}` }, (res) => {
                this.unblockedCount = res.data;
            });
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.Blocked, CARD_SEARCH_UPDATED_BY.Hop)}` }, (res) => {
                this.blockedHopCount = res.data;
            });
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.Blocked, CARD_SEARCH_UPDATED_BY.Ops)}` }, (res) => {
                this.blockedOpsCount = res.data;
            });
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.UnBlocked, CARD_SEARCH_UPDATED_BY.Hop)}` }, (res) => {
                this.unblockedHopCount = res.data;
            });
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.UnBlocked, CARD_SEARCH_UPDATED_BY.Ops)}` }, (res) => {
                this.unblockedOpsCount = res.data;
            });
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.UnBlocked, CARD_SEARCH_UPDATED_BY.RestrictedOrLockedAccount)}` }, (res) => {
                this.restrictedOrLockedUnblockedCount = res.data;
            });
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.Blocked, CARD_SEARCH_UPDATED_BY.RestrictedOrLockedAccount)}` }, (res) => {
                this.restrictedOrLockedBlockedCount = res.data;
            });
        }
    });

export default CardVerificationSearchPageStore;