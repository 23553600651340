import fileDownload from 'js-file-download';
import { makeAutoObservable } from 'mobx';
import { FetchInterface } from 'src/api/useFetch';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import {
    AUDIT_HISTORY_INVOICES_CSV_URL,
    AUDIT_HISTORY_INVOICES_URL,
    FACTORED_INVOICE_DETAILS_API,
    MARK_AS_PAID,
    OPERATE_FACTORED_INVOICE_URL,
    RECOVER_FUNDS_FROM_PAYEE,
    REVERSE, STATUS_PASS, TOAST_FAILURE_TEXT,
    TOAST_SUCCESS_TEXT,
    UNWIND,
    WRITEOFF
} from 'src/Constant';
import getInPhoneNumberFormat from 'src/util/getInPhoneNumberFormat';
import { getPlanName } from 'src/util/PlanUtils';
import { invoiceDetailPayeePayorSelector } from 'src/util/selectors';
import { FactoredInvoiceDetails, FactoredInvoicesAuditHistory, Form } from './FactoredInvoiceDetailsPageInterface';

export interface FactoredInvoiceDetailsPageStoreInterface {
    getAmountFactored(): number;
    getIsFormReady(): boolean;
    getActionStatuses(): any;
    getInvoiceMonetaryDetails(): any;
    getBaseTransactionDetails(): any;
    getAuditInvoicesHistory(): FactoredInvoicesAuditHistory[];
    setUserReasoning(userReasoning: string): void;
    setToggleValue(modalStatus: string): void;
    getModalDetails(modalStatus: string): any;
    setModalStatus(status: string): void;
    updateFactoredInvoice(fetch: FetchInterfaceLegacy, invoiceId: string): void;
    fetchInvoicesAuditCSV(fetch: FetchInterfaceLegacy, invoiceId: string): void;
    fetchAuditHistoryInvoices(fetch: FetchInterface<FactoredInvoicesAuditHistory[]>, invoiceId: string): void;
    fetchFactoredInvoiceDetails(fetch: FetchInterface<FactoredInvoiceDetails>, invoiceId: string): void;
    modal: any;
    form: Form;
    factoredInvoiceDetails: FactoredInvoiceDetails;
}

const FactoredInvoiceDetailsPageStore = (sharedStore: {
    getAuditDropdownCategory: () => string,
    getAuditDropdownSubCategory: () => string
}): FactoredInvoiceDetailsPageStoreInterface =>
    makeAutoObservable({
        factoredInvoiceDetails: {
            id: '',
            advanceRate: 0,
            dateFactored: '',
            isFactored: false,
            amountFactored: 0,
            fee: 0,
            invoiceAmount: 0,
            invoiceDate: '',
            dueDate: '',
            invoiceState: '',
            paidDate: '',
            outstandingBalance: 0,
            payor: {
                dbaName: '',
                name: '',
                createdTimestamp: 0,
                logo: '',
                isHopscotchUser: false,
                hopscotchBalance: 0,
                bankPartnerBalance: 0,
                averageTransactionSize: 0,
                totalTransactionVolume: 0,
                bizId: '',
                caseId: '',
                userId: '',
                score: '',
                status: '',
                decision: '',
                sinceDate: '',
                linkedAccountDetails: [],
                planType: ''
            },
            payee: {
                dbaName: '',
                name: '',
                createdTimestamp: 0,
                logo: '',
                isHopscotchUser: false,
                hopscotchBalance: 0,
                bankPartnerBalance: 0,
                averageTransactionSize: 0,
                totalTransactionVolume: 0,
                bizId: '',
                caseId: '',
                userId: '',
                score: '',
                status: '',
                decision: '',
                sinceDate: '',
                linkedAccountDetails: [],
                planType: ''
            },
            mutualTransaction: {
                lastTransactionDate: '',
                totalTransactions: 0,
                totalAmount: 0,
            },
            hasBeenUnwound: false,
            hasBeenReversed: false,
            hasBeenWrittenOff: false,
            hasBeenRecoveredFromPayee: false,
            hasBeenMarkedAsPaid: false,
        },
        auditHistory: [] as FactoredInvoicesAuditHistory[],
        modal: {
            modalStatus: '',
            unwindFactor: {
                dbaName: '',
                name: '',
                isVerified: false,
                score: '',
                accountLogo: '',
                createdTimestamp: 0,
                title: 'You are unwinding a factor.',
                explanation: 'Clicking confirm below will execute a book transfer from the payee to Hopscotch',
                planType: ''
            },
            reverseRejectionFactor: {
                title: 'You are reversing a rejected factor.',
                explanation: 'Clicking confirm below will update the status of the invoice from “rejected” to “pending"',
                currentStatus: 'Rejected',
                updatedStatus: 'Pending'
            },
            writeOffFactor: {
                dbaName: '',
                name: '',
                isVerified: false,
                score: '',
                accountLogo: '',
                createdTimestamp: 0,
                title: 'You are writing off the remaining balance on an invoice.',
                explanation: 'Clicking confirm below will  write-off the remaining balance due by the payor on this invoice',
                planType: ''
            },
            recoverFundsFactor: {
                dbaName: '',
                name: '',
                isVerified: false,
                score: '',
                accountLogo: '',
                createdTimestamp: 0,
                title: 'You are recovering funds from a payee.',
                explanation: 'Clicking confirm below will execute a book transfer from the payee to Hopscotch',
                planType: ''
            },
            markAsPaidFactor: {
                title: 'You are marking a factored invoice as MarkAsPaid',
                explanation: 'Clicking confirm below will update the status of the invoice from “Pending” to “MarkAsPaid”',
                currentStatus: 'Pending',
                updatedStatus: 'MarkAsPaid'
            },
        },
        form: {} as Form,
        getAmountFactored(): number {
            return this.factoredInvoiceDetails.amountFactored;
        },
        getIsFormReady(): boolean {
            return sharedStore.getAuditDropdownSubCategory() !== ''
                && (this.form.hasBeenUnwoundToggle !== this.factoredInvoiceDetails.hasBeenUnwound
                    || this.form.hasBeenRecoveredFromPayeeToggle !== this.factoredInvoiceDetails.hasBeenRecoveredFromPayee
                    || this.form.hasBeenWrittenOffToggle !== this.factoredInvoiceDetails.hasBeenWrittenOff
                    || this.form.hasBeenReversedToggle !== this.factoredInvoiceDetails.hasBeenReversed
                    || this.form.hasBeenMarkedAsPaidToggle !== this.factoredInvoiceDetails.hasBeenMarkedAsPaid
                );
        },
        getActionStatuses(): object {
            return {
                hasBeenUnwound: this.factoredInvoiceDetails.hasBeenUnwound,
                hasBeenReversed: this.factoredInvoiceDetails.hasBeenReversed,
                hasBeenWrittenOff: this.factoredInvoiceDetails.hasBeenWrittenOff,
                hasBeenRecoveredFromPayee: this.factoredInvoiceDetails.hasBeenRecoveredFromPayee,
                hasBeenMarkedAsPaid: this.factoredInvoiceDetails.hasBeenMarkedAsPaid,
            };
        },
        getInvoiceMonetaryDetails(): object {
            return {
                invoiceAmount: this.factoredInvoiceDetails.invoiceAmount,
                fee: this.factoredInvoiceDetails.fee,
                amountFactored: this.factoredInvoiceDetails.amountFactored,
                outstandingBalance: this.factoredInvoiceDetails.outstandingBalance
            };
        },
        getBaseTransactionDetails(): object {
            return {
                transactionDetails: {
                    causationIdType: 'Invoice',
                    transactionType: this.factoredInvoiceDetails.id,
                    transactionStatus: this.factoredInvoiceDetails.invoiceState,
                    transactionAmount: this.factoredInvoiceDetails.invoiceAmount,
                    transactionDate: this.factoredInvoiceDetails.invoiceDate,
                    transactionDueDate: this.factoredInvoiceDetails.dueDate,
                    transactionCompletedDate: this.factoredInvoiceDetails.paidDate,
                },
                // @TODO LALA - there is a lot of mapping going on in this file. I sus we can cut some of it out. TransDetail store is far simpler.
                payee: invoiceDetailPayeePayorSelector(this.factoredInvoiceDetails.payee),
                payor: invoiceDetailPayeePayorSelector(this.factoredInvoiceDetails.payor),
                mutualTransactions: {
                    totalAmount: this.factoredInvoiceDetails?.mutualTransaction?.totalAmount ? this.factoredInvoiceDetails.mutualTransaction.totalAmount : 0,
                    lastTransactionDate: this.factoredInvoiceDetails?.mutualTransaction?.lastTransactionDate ? this.factoredInvoiceDetails.mutualTransaction.lastTransactionDate : 0,
                    averageTransactionSize: this.factoredInvoiceDetails?.mutualTransaction?.totalAmount ? (this.factoredInvoiceDetails.mutualTransaction.totalAmount / this.factoredInvoiceDetails.mutualTransaction.totalTransactions) : 0,
                    totalTransactions: this.factoredInvoiceDetails?.mutualTransaction?.totalTransactions ? this.factoredInvoiceDetails.mutualTransaction.totalTransactions : 0
                }
            };
        },
        getAuditInvoicesHistory(): FactoredInvoicesAuditHistory[] {
            return this.auditHistory;
        },
        setUserReasoning(userReasoning: string) {
            this.form.reason = userReasoning;
        },
        setToggleValue(modalStatus: string): void {
            switch (modalStatus) {
                case UNWIND:
                    this.form.hasBeenUnwoundToggle = !this.form.hasBeenUnwoundToggle;
                    break;
                case REVERSE:
                    this.form.hasBeenReversedToggle = !this.form.hasBeenReversedToggle;
                    break;
                case WRITEOFF:
                    this.form.hasBeenWrittenOffToggle = !this.form.hasBeenWrittenOffToggle;
                    break;
                case RECOVER_FUNDS_FROM_PAYEE:
                    this.form.hasBeenRecoveredFromPayeeToggle = !this.form.hasBeenRecoveredFromPayeeToggle;
                    break;
                case MARK_AS_PAID:
                    this.form.hasBeenMarkedAsPaidToggle = !this.form.hasBeenMarkedAsPaidToggle;
                    break;
                default:
                    this.form.hasBeenUnwoundToggle = this.factoredInvoiceDetails.hasBeenUnwound;
                    this.form.hasBeenReversedToggle = this.factoredInvoiceDetails.hasBeenReversed;
                    this.form.hasBeenWrittenOffToggle = this.factoredInvoiceDetails.hasBeenWrittenOff;
                    this.form.hasBeenRecoveredFromPayeeToggle = this.factoredInvoiceDetails.hasBeenRecoveredFromPayee;
                    this.form.hasBeenMarkedAsPaidToggle = this.factoredInvoiceDetails.hasBeenMarkedAsPaid;
                    break;
            }
        },
        getModalDetails(modalStatus: string): any {
            if (modalStatus === UNWIND) {
                return this.modal.unwindFactor;
            } if (modalStatus === REVERSE) {
                return this.modal.reverseRejectionFactor;
            } if (modalStatus === WRITEOFF) {
                return this.modal.writeOffFactor;
            } if (modalStatus === MARK_AS_PAID) {
                return this.modal.markAsPaidFactor;
            }
            return this.modal.recoverFundsFactor;
        },
        setModalStatus(status: string) {
            this.modal.modalStatus = status;
        },
        updateFactoredInvoice(fetch: FetchInterface<FactoredInvoicesAuditHistory>, invoiceId: string) {
            const data = {
                invoiceId,
                reason: this.form.reason,
                audit: {
                    category: sharedStore.getAuditDropdownCategory(),
                    subCategory: sharedStore.getAuditDropdownSubCategory(),
                },
                operationType: '',
                shouldFlag: false,
            };
            if (this.modal.modalStatus === UNWIND) {
                data.operationType = 'ReverseFactoredInvoice';
                data.shouldFlag = this.form.hasBeenUnwoundToggle;

            } else if (this.modal.modalStatus === REVERSE) {
                data.operationType = 'ReverseFactoredInvoiceRejection';
                data.shouldFlag = this.form.hasBeenReversedToggle;

            } else if (this.modal.modalStatus === RECOVER_FUNDS_FROM_PAYEE) {
                data.operationType = 'RecoverFactoredInvoice';
                data.shouldFlag = this.form.hasBeenRecoveredFromPayeeToggle;

            } else if (this.modal.modalStatus === WRITEOFF) {
                data.operationType = 'WriteOffFactoredInvoice';
                data.shouldFlag = this.form.hasBeenWrittenOffToggle;
            } else if (this.modal.modalStatus === MARK_AS_PAID) {
                data.operationType = 'FactoredInvoiceMarkAsPaid';
                data.shouldFlag = this.form.hasBeenMarkedAsPaidToggle;
            }

            fetch({
                url: OPERATE_FACTORED_INVOICE_URL,
                method: 'PUT',
                data
            },
                () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        },
        fetchInvoicesAuditCSV(fetch: FetchInterfaceLegacy, invoiceId: string) {
            fetch(
                { url: AUDIT_HISTORY_INVOICES_CSV_URL(invoiceId) },
                (res: any) => {
                    fileDownload(res.data, 'invoice_history.csv');
                }
            );
        },
        fetchAuditHistoryInvoices(fetch: FetchInterface<FactoredInvoicesAuditHistory[]>, invoiceId: string) {
            fetch({ url: AUDIT_HISTORY_INVOICES_URL(invoiceId) }, (res: any) => {
                this.auditHistory = res.data.map((theHistory: any) => ({
                    id: theHistory.id,
                    modalId: theHistory.modelId,
                    date: theHistory.date,
                    operator: theHistory.operator,
                    flagType: theHistory.operations.flagType,
                    operationReason: theHistory.operationReason,
                }));
            });
        },
        fetchFactoredInvoiceDetails(fetch: FetchInterface<FactoredInvoiceDetails>, invoiceId: string) {
            fetch({ url: FACTORED_INVOICE_DETAILS_API(invoiceId) }, ({ data }) => {
                this.factoredInvoiceDetails = {
                    ...data,
                    mutualTransaction: {
                        lastTransactionDate: data?.mutualTransaction?.lastTransactionDate,
                        totalAmount: data?.mutualTransaction?.totalAmount,
                        totalTransactions: data?.mutualTransaction?.totalTransactions
                    }
                };
                this.factoredInvoiceDetails.payee = data.payee;
                this.factoredInvoiceDetails.payor = data.payor;
                this.factoredInvoiceDetails.payee.logo = data.payee.logo;
                this.factoredInvoiceDetails.payor.logo = data.payor.logo;
                this.factoredInvoiceDetails.payee.createdTimestamp = data.payee.createdTimestamp;
                this.factoredInvoiceDetails.payor.createdTimestamp = data.payor.createdTimestamp;
                this.factoredInvoiceDetails.payor.hopscotchBalance = data.payor.hopscotchBalance;
                this.factoredInvoiceDetails.payee.linkedAccountDetails = data.payee.linkedAccountDetails.map((lba: any) => ({
                    id: lba.id,
                    institutionName: lba.institutionName,
                    ownerName: lba.ownerName,
                    ownerEmail: lba.ownerEmail,
                    ownerPhoneNumber: lba.ownerPhoneNumber ? getInPhoneNumberFormat(lba.ownerPhoneNumber) : null,
                    accountNumber: lba.accountNumber,
                    addressLine1: lba.address?.data?.street ? lba.address.data.street : '',
                    addressLine2: lba.address?.data?.city ? `${lba.address.data.city}, ${lba.address.data.region} ${lba.address.data.postalCode}` : '',
                    address: lba.address ? lba.address : '',
                    availableBalance: lba.availableBalance ? lba.availableBalance : '',
                    totalTransactionsCount: lba.totalTransactionsCount,
                    totalTransactionsSum: lba.totalTransactionsSum,
                    isFundingAccount: lba.isFundingAccount,
                    isDepositAccount: lba.isDepositAccount,
                    isBlocked: lba.isBlocked
                }));
                this.factoredInvoiceDetails.payor.linkedAccountDetails = data.payor.linkedAccountDetails.map((lba: any) => ({
                    id: lba.id,
                    institutionName: lba.institutionName,
                    ownerName: lba.ownerName,
                    ownerEmail: lba.ownerEmail,
                    ownerPhoneNumber: lba.ownerPhoneNumber ? getInPhoneNumberFormat(lba.ownerPhoneNumber) : null,
                    accountNumber: lba.accountNumber,
                    addressLine1: lba.address?.data?.street ? lba.address.data.street : '',
                    addressLine2: lba.address?.data?.city ? `${lba.address.data.city}, ${lba.address.data.region} ${lba.address.data.postalCode}` : '',
                    address: lba.address ? lba.address : '',
                    availableBalance: lba.availableBalance ? lba.availableBalance : '',
                    totalTransactionsCount: lba.totalTransactionsCount,
                    totalTransactionsSum: lba.totalTransactionsSum,
                    isFundingAccount: lba.isFundingAccount,
                    isDepositAccount: lba.isDepositAccount,
                    isBlocked: lba.isBlocked
                }));

                this.factoredInvoiceDetails.outstandingBalance = data.payorOutstandingBalance;

                const payeePlan = (data?.payee?.subscriptionDetails?.plan || '') as string;
                const payeeBillingFrequency = (data?.payee?.subscriptionDetails?.billingFrequency || '') as string;
                const payeePlanType = getPlanName({ planType: payeePlan, billingFrequency: payeeBillingFrequency });
                this.factoredInvoiceDetails.payee.planType = payeePlanType;

                const payorPlan = (data?.payor?.subscriptionDetails?.plan || '') as string;
                const payorBillingFrequency = (data?.payor?.subscriptionDetails?.billingFrequency || '') as string;
                const payorPlanType = getPlanName({ planType: payorPlan, billingFrequency: payorBillingFrequency });
                this.factoredInvoiceDetails.payor.planType = payorPlanType;

                this.modal.unwindFactor.dbaName = data.payee.dbaName;
                this.modal.unwindFactor.name = data.payee.name;
                this.modal.unwindFactor.score = data.payee.score;
                this.modal.unwindFactor.createdTimestamp = data.payee.sinceDate;
                this.modal.unwindFactor.accountLogo = data.payee.logo;
                this.modal.unwindFactor.isVerified = data.payee.status === STATUS_PASS;
                this.modal.unwindFactor.planType = payeePlanType;

                this.modal.writeOffFactor.dbaName = data.payor.dbaName;
                this.modal.writeOffFactor.accountLogo = data.payor.logo;
                this.modal.writeOffFactor.isVerified = data.payor.status === STATUS_PASS;
                this.modal.writeOffFactor.createdTimestamp = data.payor.sinceDate;
                this.modal.writeOffFactor.score = data.payor.score;
                this.modal.writeOffFactor.planType = payorPlanType;

                this.modal.recoverFundsFactor.dbaName = data.payee.dbaName;
                this.modal.recoverFundsFactor.accountLogo = data.payee.logo;
                this.modal.recoverFundsFactor.isVerified = data.payee.status === STATUS_PASS;
                this.modal.recoverFundsFactor.createdTimestamp = data.payee.sinceDate;
                this.modal.recoverFundsFactor.score = data.payee.score;
                this.modal.recoverFundsFactor.planType = payeePlanType;

                this.form.hasBeenUnwoundToggle = data.hasBeenUnwound;
                this.form.hasBeenReversedToggle = data.hasBeenReversed;
                this.form.hasBeenWrittenOffToggle = data.hasBeenWrittenOff;
                this.form.hasBeenRecoveredFromPayeeToggle = data.hasBeenRecoveredFromPayee;
                this.form.hasBeenMarkedAsPaidToggle = data.hasBeenMarkedAsPaid;
            });
        }
    });

export default FactoredInvoiceDetailsPageStore;