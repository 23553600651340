
// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import styles from './RadioButton.module.scss';

interface Props {
    disabled?: boolean;
    isActive: boolean;
    onClick: () => void;
    label: string;
    isPositive?: boolean
    className?: string
}

const RadioButton: React.FC<Props> = ({ disabled = false, isActive, onClick, label, isPositive = true, className }) => (<button
    type="button"
    disabled={disabled}
    className={
        cn({
            [styles.active]: isActive,
            [styles.statusContainerHigh]: !isPositive,
            [styles.statusContainerLow]: isPositive,
        },
            styles.statusContainer,
            className)}
    onClick={() => {
        if (!isActive) {
            onClick();
        }
    }
    }>{label}</button>
);

export default RadioButton;