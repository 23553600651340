import React, { useState } from 'react';
// eslint-disable-next-line id-length
import cn from 'classnames';
import { dropDownInterface } from 'src/util/interfaces';
import styles from './Dropdown.module.scss';

export interface DropdownPropInterface {
    name: string;
    onChange: (value: string) => void;
    className?: string;
    label?: string;
    value?: number | string;
}

interface DropdownPropOptionsInterface extends DropdownPropInterface {
    options: dropDownInterface[];
}

const Dropdown: React.FC<DropdownPropOptionsInterface> = ({ name, onChange, options, label, value, className }) => {
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    return <div
        data-testid="Dropdown"
        className={styles.Dropdown}>
        {label && (<span className={styles.label}>
            {label}
        </span>)}
        <select
            value={value}
            data-testid="select"
            name={name}
            id={name}
            className={cn(styles.select, { [styles.hasNotChanged]: !hasChanged }, className)}
            onChange={e => {
                setHasChanged(true);
                onChange(e.target.value);
            }}>
            {options?.map((option: dropDownInterface) => (<option
                value={option.value}
                key={option.value}
                className={styles.option}
            >
                {option.description}
            </option>
            ))}
        </select>
    </div>;
};

export default Dropdown;