import React from 'react';
import UnverifiedIcon from 'src/components/logos/UnverifiedIcon';
import VerifiedIcon from 'src/components/logos/VerifiedIcon';

type VerificationStatusProps = {
    verified: boolean;
}

const VerificationStatus: React.FC<VerificationStatusProps> = ({ verified }): JSX.Element => verified ? <VerifiedIcon /> : <UnverifiedIcon />;

export default VerificationStatus;