import axios, { AxiosResponse, Method } from 'axios';
import { TOAST_FAILURE_TEXT, TOAST_IS_FAILURE, TOAST_IS_SUCCESS } from 'src/Constant';
import { useStore } from 'src/store';
import { Logger } from 'src/util';
import { SharedStoreProp } from '../store/SharedStore';

export interface Request {
    url: string;
    method?: Method;
    data?: object;
    headers?: object;
}

export type ParseResponse = (res: AxiosResponse) => void;

export interface Toast {
    successMessage: string;
    failMessage: string;
}

/**
 * @deprecated use FetchInterface
 */
export interface FetchInterfaceLegacy {
    (request: Request, parseResponse: ParseResponse, isLoading?: boolean, toast?: Toast): Promise<any>;
}
/**
 * @deprecated use useFetch
 * @returns 
 */
const useFetchLegacy = (): FetchInterfaceLegacy => {
    const { sharedStore } = useStore();
    const accessToken = sharedStore.getAccessToken();
    const tokenExpirationTime = sharedStore.getTokenExpirationTime();

    if (tokenExpirationTime < Date.now()) {
        window.location.href = '/';
    }

    return async (request: Request, parseResponse: ParseResponse, isLoading?: any, toast?: Toast) => {

        if (isLoading === true) { sharedStore.setIsLoading(true); }

        try {
            const result = await axios({
                method: request.method,
                url: request.url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    ...request.headers,
                },
                data: request.data,
            });

            parseResponse(result);

            if (toast) {
                if (!toastIfResponseFollowExceptionPattern(sharedStore, result)) {
                    sharedStore.displayToast(toast.successMessage, TOAST_IS_SUCCESS);
                }
            }
        } catch (err: any) {
            Logger.error(err.message);
            sharedStore.displayToast(`${TOAST_FAILURE_TEXT} ${err.message}`, TOAST_IS_FAILURE);
        };

        if (isLoading) { sharedStore.setIsLoading(false); }
    };
};

/**
 * The Support service endpoints follow a pattern if there is an error. 
 * The pattern helps informs the front end that there was an issue.
 * This is not a pattern other areas give us, but maybe we can have them move in this direction.
 * 
 * Ultimately, if there is a `exception` property, we know there was an error, and that the property `message` will have the error.
 * So we go ahead and toast an error, otherwise, return false and continue moving forward.
 * @param result 
 * @returns 
 */
const toastIfResponseFollowExceptionPattern = (sharedStore: SharedStoreProp, result: AxiosResponse): boolean => {
    if (result.data?.exception) {
        sharedStore.displayToast(`${TOAST_FAILURE_TEXT} ${result.data.errorMessage}`, TOAST_IS_FAILURE);
        return true;
    }
    return false;
};

export default useFetchLegacy;
