// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import styles from './Button.module.scss';

type ButtonProps = {
    disabled?: boolean;
    onClick?: any;
    classNames?: any;
    type?: 'button' | 'submit' | 'reset';
};

const Button: React.FC<ButtonProps> = ({ disabled, onClick, children, classNames, type = 'button' }) => (
    <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={cn(styles.button, classNames)}>
        {children}
    </button>
);

export default Button;