import { useEffect } from 'react';
import { useStore } from '..';

const useFetchUserDetails = (): void => {
    const { sharedStore } = useStore();

    useEffect(() => {
        if (sharedStore.accessToken !== '' || sharedStore.accessToken !== null) {
            sharedStore.fetchUserDetails();
        }
    }, [sharedStore]);
};

export default useFetchUserDetails;