import React from 'react';
import FundingReportView from './fundingWithdrawingReportView/FundingReportView';
import WithdrawingReportView from './fundingWithdrawingReportView/WithdrawingReportView';

type LinkedBankAccountViewProps = {
    payee: any,
    payor: any,
    causationIdType: string,
}

const LinkedBankAccountView: React.FC<LinkedBankAccountViewProps> = ({ payee, payor, causationIdType }) =>
    causationIdType === 'Funding' ? <FundingReportView payee={payee} /> : <WithdrawingReportView payor={payor} />;

export default LinkedBankAccountView;