import Moment from 'moment';
import React from 'react';
import styles from 'src/pages/accountDetailsPage/sections/accountActionsSection/accountActionsAuditHistoryPopup/auditHistoryContent/auditHistoryItem/AuditHistoryItem.module.scss';
import { getInCurrencyFormat } from 'src/util';
import { AuditHistory } from '../../../../../AccountDetailsPageStoreInterface';

export interface props {
    audit: AuditHistory
}

const getLockStatus = (audit: AuditHistory): string => {
    if (audit?.operations?.toLockStatus === undefined) {
        return '';
    }

    if (audit.operations.toLockStatus === false) {
        return ', unlocked the account';
    }
    return ', locked the account';
};

const getFrozenStatus = (currentMessage: string, audit: AuditHistory): string => {
    if (audit?.operations?.freezeAccount === undefined) {
        return currentMessage;
    }

    if (audit.operations.freezeAccount === false) {
        return ', unfreeze the account';
    }
    return ', freeze the account';
};

const getInvoiceLimitStatus = (currentMessage: string, audit: AuditHistory): string => {
    if (audit?.operations?.limit === undefined) {
        return currentMessage;
    }

    return `, set invoicing limit to ${getInCurrencyFormat(Number(audit.operations.limit))}`;
};

const getRiskStatus = (currentMessage: string, audit: AuditHistory): string => {
    if (audit?.operations?.riskStatus === undefined) {
        return currentMessage;
    }

    return `, set risk status to ${audit.operations.riskStatus}`;
};

const getFactoringLimitStatus = (currentMessage: string, audit: AuditHistory): string => {
    if (audit?.operations?.toFactoring === undefined) {
        return currentMessage;
    }

    return `, set factoring limit to ${getInCurrencyFormat(Number(audit.operations.toFactoring))}`;
};

const getLbaStatus = (currentMessage: string, audit: AuditHistory): string => {
    if (audit?.operations?.linkedBankAccountId === undefined) {
        return currentMessage;
    }

    return `, set lba to ${audit.operations.toBlockedStatus}`;
};

const getCardStatus = (currentMessage: string, audit: AuditHistory): string => {
    if (audit?.operations?.CardId === undefined) {
        return currentMessage;
    }

    return `, set card to ${audit.operations.toBlockedStatus}`;
};

const AuditHistoryItem: React.FC<props> = ({ audit }) => {

    const formattedDate = Moment(audit.date).format('MMMM DD, YYYY');
    const operator = audit.operator;

    const lockStatus = getLockStatus(audit);
    const frozenAccountLabel = getFrozenStatus(lockStatus, audit);
    const factoringLimitLabel = getFactoringLimitStatus(frozenAccountLabel, audit);
    const riskLabel = getRiskStatus(factoringLimitLabel, audit);
    const lbaLabel = getLbaStatus(riskLabel, audit);
    const cardLabel = getCardStatus(lbaLabel, audit);
    const fullLabel = getInvoiceLimitStatus(cardLabel, audit);

    const category = audit?.operations?.Category;
    const subCategory = audit?.operations?.SubCategory;

    return (<div className={styles.container} key={audit.id} id={audit.modalId}>
        <div>
            <h4 className={styles.title}>{formattedDate} - {operator}<span>{fullLabel}</span></h4>
            <p>{category}: {subCategory}</p>
        </div>
    </div>);
};

export default AuditHistoryItem;