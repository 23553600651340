import { today } from '../DateUtils';
/**
 * Negative number means it has already past, positive means date is in the future
 * @param dateString 
 * @returns 
 */
export const daysFromToday = (dateString: string): number => {
    const todaysDate = today();
    const comparedDate = new Date(dateString);
    const differenceBetweenDates = comparedDate.getTime() - todaysDate.getTime();

    const dateDifference = Math.ceil(differenceBetweenDates / (1000 * 3600 * 24));
    if (Object.is(dateDifference, -0)) {
        return 0;
    }

    return dateDifference;
};

export default daysFromToday;