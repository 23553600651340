import { observer } from 'mobx-react-lite';
import React from 'react';
import InvoiceDetailsAuditItem from 'src/pages/invoiceDetailsPage/invoiceDetailsAuditPopup/invoiceDetailsAuditItem/InvoiceDetailsAuditItem';
import { AuditHistoryEntity } from 'src/pages/invoiceDetailsPage/InvoiceDetailsPageStoreInterface';

interface InvoiceDetailsAuditContentProps {
    histories: AuditHistoryEntity[];
}

const InvoiceDetailsAuditContent: React.FC<InvoiceDetailsAuditContentProps> = observer(({ histories }) => (
    <div>
        {histories.map((theHistory: AuditHistoryEntity) => (
            <InvoiceDetailsAuditItem key={theHistory.id} audit={theHistory} />
        ))}
    </div>
));

export default InvoiceDetailsAuditContent;