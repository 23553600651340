const getInPhoneNumberFormat = (numbers: string): string => {
    const stringNumbers = numbers;
    const matches = stringNumbers.match(/^(\+?1?)(\d{3})(\d{3})(\d{4})/);
    const countryCode = matches && matches[1];
    const firstThreeNumbers = matches && matches[2];
    const secondThreeNumbers = matches && matches[3];
    const lastFourNumbers = matches && matches[4];
    const fullString = `${countryCode && countryCode} (${firstThreeNumbers}) ${secondThreeNumbers}-${lastFourNumbers}`;
    return fullString;
};

export default getInPhoneNumberFormat;