import { makeAutoObservable } from 'mobx';
import { FetchInterface } from 'src/api/useFetch';
import { EVENTS_SEARCH_URL } from 'src/Constant';
import { selectEvents } from 'src/util/selectors';
import { EventsSearchInterface } from './EventsSearchPageStoreInterface';

export interface EventsSearchPageStoreInterface {
    events?: EventsSearchInterface[];
    getEvents(): EventsSearchInterface[] | undefined;
    fetchEvents(fetch: FetchInterface<EventsSearchInterface[]>): void;
}

const EventsSearchPageStore = (): EventsSearchPageStoreInterface =>
    makeAutoObservable({
        events: [] as EventsSearchInterface[],
        getEvents() {
            return this.events;
        },
        fetchEvents(fetch: FetchInterface<EventsSearchInterface[]>) {
            fetch({ url: EVENTS_SEARCH_URL }, (res) => {
                this.events = res.data.map((theEvent: any) => (selectEvents(theEvent)));
            },
                true,
            );
        },
    });

export default EventsSearchPageStore;