
// @TODO: LALA - Beginning to migrate the specific interfaces out into specific folders. I really dislike scrolling large files. Will keep common ones here tho (for now)
export type riskScoreType = 'High' | 'Low' | 'NotSet';
export const RiskScore = {
    High: 'High',
    Low: 'Low',
    NotSet: 'NotSet'
} as const;

// @TODO: LALA - Probs move these over to constant.ts
export const PaymentType = {
    'DebitCard': 'DebitCard',
    'CreditCard': 'CreditCard',
    'LinkedBankAccount': 'LinkedBankAccount',
    'DirectBankAccount': 'DirectBankAccount',
    'NotSet': 'NotSet'
} as const;

export const PaymentTypeLabel = {
    'CC': 'CC',
    'DC': 'DC',
    'L': 'L',
    'D': 'D',
    '': '',
    'N/A': 'N/A'
} as const;

export const TransactionType = {
    'Funding': 'Funding',
    'Withdrawing': 'Withdrawing'
} as const;

export const TransferSource = {
    Card: 'Card',
    Book: 'Book',
    ACH: 'ACH',
    NotSet: 'NotSet'
} as const;

export const SearchPaymentType = {
    all: 'all',
    flagged: 'flagged',
    returned: 'returned',
    failed: 'failed',
    chargeback: 'chargeback',
    rejected: 'rejected',
    pastDue: 'pastDue',
    cardWaitingApproval: 'cardWaitingApproval',

} as const;

export const SearchStatus = {
    Blocked: 'Blocked',
    All: 'All',
    UnBlocked: 'UnBlocked',
    undefined,
} as const;

export const SearchUpdatedBy = {
    All: 'All',
    Hop: 'Hop',
    Ops: 'Ops',
    RestrictedOrLockedAccount: 'RestrictedOrLockedAccount',
} as const;

export type SearchParams = {
    status: keyof typeof SearchStatus;
    updatedBy: keyof typeof SearchUpdatedBy;
};

export interface userInterface {
    dbaName: string;
    name: string;
}

export interface dataGridInterface {
    payee: userInterface;
    payor: userInterface;
}

export interface RowData {
    selectedRowKeys: string[];
}

export interface cellDataInterface {
    value: string;
}

export interface payeeInterface {
    dbaName: string,
    name: string,
    profileLink: string,
    caseId: string,
    createdTimestamp: string,
    score: string,
    accountLogo: string,
    isVerified: boolean,
    hopscotchBalance: number,
    bankPartnerBalance: number,
    averageTransactionSize: number,
    totalTransactionVolume: number,
    institutionName: any,
};

export interface mutualTransactionPayloadInterface {
    lastTransactionDate?: string;
    totalAmount?: number;
    totalTransactions?: number;
}

export interface mutualTransactionInterface {
    lastTransactionDate: string;
    totalAmount: number;
    avgTransactionSize: number;
    totalTransactions?: number;
};

export interface payorInterface {
    dbaName: string,
    name: string,
    profileLink: string,
    caseId: string,
    createdTimestamp: string,
    score: string,
    accountLogo: string,
    isVerified: boolean,
    hopscotchBalance: number,
    bankPartnerBalance: number,
    averageTransactionSize: number,
    totalTransactionVolume: number,
    institutionName: any,
};


export interface invoiceDetailPayeePayorPayloadInterface {
    planType: string;
    dbaName: string;
    name: string;
    profileLink?: string;
    sinceDate?: string;
    score: string;
    logo: string;
    status: string;
    hopscotchBalance: number;
    bankPartnerBalance: number;
    averageTransactionSize: number;
    totalTransactionVolume: number;
    caseId: string;
    linkedAccountDetails: any; // need an interface for this
}

export interface invoiceDetailPayeePayorInterface {
    planType: string;
    dbaName: string;
    name: string;
    profileLink: string;
    createdTimestamp: string;
    score: string;
    accountLogo: string;
    isVerified: boolean;
    hopscotchBalance: number;
    bankPartnerBalance: number;
    averageTransactionSize: number;
    totalTransactionVolume: number;
    caseId: string;
    linkedAccountBlock: any; // need an interface for this
}

export interface InvoiceHeaderPayload {
    type?: string
    isAmlRisk: boolean,
    invoiceAmount: number,
    invoiceState: string,
    invoiceDate: string,
    dueDate: string,
}
export interface InvoiceHeader {
    causationIdType: string;
    transactionType: boolean;
    transactionStatus: string;
    transactionAmount: number;
    transferAmount: string;
    transferStatus: string;
    transactionDate: string;
    transactionDueDate: string;
    transactionCompletedDate: string;
}

export interface MatchPercentageInterface {
    addressMatchResult: string;
    emailMatchResult: string;
    nameMatchResult: string;
    phoneNoMatchResult: string;
}


export interface LbaAddressInterface {
    data?: {
        street?: string;
        city?: string;
        region?: string;
        postalCode?: string;
    }
}
export interface transactionSummaryInterface {
    availableBalance?: number;
    numberOfTransactions?: number;
    valueOfTransactions?: number;
}

export interface transactionDetailsPayloadInterface {
    amount: number;
    counterPartyDbaName: string;
    counterPartyEmail: string;
    counterPartyFullName: string;
    counterPartyLogo: string;
    dueDate?: string;
    invoiceId: string;
    paymentDate?: string;
}

export interface TransactionDetails {
    counterPartyLogo: string;
    counterPartyDbaName: string;
    invoiceId: string;
    transactionDate: string;
    amount: number;
    name: string;
    email: string;
}

export interface LinkedBankAccountDto {
    id: string;
    institutionName?: string;
    ownerName: string;
    ownerEmail: string;
    ownerPhoneNumber: string | null;
    accountNumber: string;
    address: LbaAddressInterface;
    transactionSummary: transactionSummaryInterface;
    isFundingAccount: boolean;
    isDepositAccount: boolean;
    isBlocked: boolean;
    hasOpsUpdated: boolean;
    matchPercentage: MatchPercentageInterface | null
    paymentType?: keyof typeof PaymentType;
}

export interface LinkedAccountDetails {
    id: string;
    institutionName: string;
    ownerName: string;
    ownerEmail: string;
    ownerPhoneNumber: string | null;
    accountNumber: string;
    addressLine1: string;
    addressLine2: string;
    address: LbaAddressInterface | string;
    availableBalance: number;
    totalTransactionsCount: number;
    totalTransactionsSum: number;
    paymentType: keyof typeof PaymentType;
    isFundingAccount: boolean;
    isDepositAccount: boolean;
    isBlocked: boolean;
    isOps: boolean;
    ownerNameConfidence: Nullable<number>;
    addressConfidence: Nullable<number>;
    emailConfidence: Nullable<number>;
    phoneNumberConfidence: Nullable<number>;
}
export interface dropDownInterface {
    value: string;
    description: string;
};

export interface EventInterfaceResponse {
    alertType: string;
    alertSubType: string;
    dateTime: string;
    dbaName?: string;
    userName?: string;
    alertId: string;
    businessLogo: string;
    userEmail?: string;
    traceId: string;
};

export interface EventInterface {
    alertType: string;
    alertSubType: string;
    dateTime: string;
    dbaName: string;
    userName: string;
    alertId: string;
    businessLogo: string;
    userEmail: string;
    traceId: string;
};

export interface LbaSearchPayloadInterface {
    id: string;
    fullName?: string;
    dbaName?: string;
    logo: string;
    email: string;
    type: string;
    createdAt: string;
    caseId: string;
    isLocked: boolean;
    isBlocked: boolean;
    hasOpsUpdated: boolean;
    isFrozen: boolean;
    status: string;
    pendingStatus: string;
    institutionName: string;
    accountNumber: string;
    institutionLogo: string;
    paymentType: keyof typeof PaymentType;
}

export interface PaymentSearchInterface {
    id: string;
    businessName: string;
    userName: string;
    accountLogo: string;
    email: string;
    businessType: string;
    dateReceived: string;
    caseID: string;
    status?: string;
    isBlocked: boolean;
    hasOpsUpdated: boolean;
    paymentStatus: string;
    hopscotchStatus: string;
    pendingStatus: string;
    institutionName: string;
    accountNumber: string;
    institutionLogo: string;
    paymentType: keyof typeof PaymentType;
}

export interface PaymentDetail {
    hasOpsUpdated: boolean,
    id: string,
    isBlocked: boolean,
    paymentType: keyof typeof PaymentType
}

export type AccountDetailsLinkedBankAccount = {
    id: string;
    isBlocked: boolean;
    isOps: boolean;
    institutionName: string;
    ownerName: string;
    ownerEmail: string;
    ownerPhoneNumber: string;
    accountNumber: string;
    isDepositAccount: boolean;
    isFundingAccount: boolean;
    address: any[];
    addressLine1: string,
    addressLine2: string,
    ownerNameConfidence: number;
    addressConfidence: number;
    emailConfidence: number;
    phoneNumberConfidence: number;
    availableBalance: number;
    totalTransactionsCount: number;
    totalTransactionsSum: number;
    paymentType: keyof typeof PaymentType;
}

export interface FeatureInterface {
    id: string;
    name: string;
    isEnabled: any;
}

export interface ResponseInterface<T> {
    data: T
}

export type AccountDetail = {
    factoringLimit: number,
    invoiceLimit: number,
    hasInvoiceLimitBeenUpdated: boolean,
    isFrozen: boolean,
    isLocked: boolean,
    linkedAccounts: LinkedAccountDetails[]
    externalDetails: ExternalDetails,
    verificationStatus: string
}

export type Address = {
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    postal_code: number;
    country_code: string;
};

export type ExternalDetails = {
    createdByBizId: string;
    createdByDbaName: string;
    isPlatformUser: boolean;
}

export type SubscriptionDetails = {
    plan: string
    billingFrequency: string
    recurlyUrl: string
};

export type AccountDetailPayload = {
    dbaName: string;
    bizId: string;
    userId: string;
    name: string;
    legalBusinessName: string;
    email: string;
    caseStatus: string;
    hopscotchBalance: number;
    columnBalance: number;
    invoiceLimit: number;
    factoringLimit: number;
    hasInvoiceLimitBeenUpdated: boolean;
    transactionVolume: number;
    vendorId: string;
    createdTimestamp: number;
    address: Address,
    transactions: TransactionDetails[],
    businessLogo: string;
    beneficialOwners?: any[],
    lastUpdatedBy: string;
    lastUpdatedDate: string;
    isFrozen: boolean;
    isLocked: boolean;
    reason: string;
    averageTransactionAmount: number;
    riskScore: riskScoreType;
    businessType: string;
    website: string;
    industry: string;
    linkedBankAccountDetails?: LinkedBankAccountDto[],
    externalDetails?: ExternalDetails,
    verificationStatus: string,
    tpAccountId: string
}

export type CardDto = {
    blocked: boolean;
    city: string;
    countryCode: string;
    deleted: boolean;
    depositAccount: boolean;
    expirationDate: string;
    expired: boolean;
    firstName: string;
    fundingAccount: boolean;
    hasOpsUpdated: boolean;
    insertedTs: string;
    institutionName: string;
    isBlocked: boolean;
    lastFourDigits: string;
    lastName: string;
    line1: string;
    line2: string;
    linkingMechanism: string;
    network: string;
    postalCode: string;
    region: string;
    paymentType: keyof typeof PaymentType.CreditCard | keyof typeof PaymentType.DebitCard;
    updatedTs: number;
    zuroAccountId: string;
}

export type AuditDropDownInterface = {
    getAuditDropdownCategory: () => string,
    getAuditDropdownSubCategory: () => string,
    setAuditDropdownCategory(category: string): void
}