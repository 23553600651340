import React from 'react';

type Props = {
    width: number;
    height: number;
    classNames?: string
}

const InvoiceLogo: React.FC<Props> = ({ width, height, classNames }): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={classNames} id="Layer_1" viewBox="0 0 32 32">
        <polygon points="21 1.8 21 7 26.2 7 21 1.8" />
        <path d="M20,9c-.55,0-1-.45-1-1V1H6c-.55,0-1,.45-1,1V30c0,.55,.45,1,1,1H26c.55,0,1-.45,1-1V9h-7Zm-2.59,6c.48,0,.93,.21,1.23,.56,.27,.32,.4,.73,.36,1.14v2.03c.03,.36-.1,.7-.36,.95-.29,.28-.65,.35-.97,.35-.13,0-.25-.01-.35-.03h-.32c0,.55-.45,1-1,1s-1-.45-1-1h-1c-.55,0-1-.45-1-1s.45-1,1-1h3v-1h-2.41c-.78,0-1.3-.68-1.53-1.31-.04-.11-.06-.22-.06-.34v-1.71c0-.91,.74-1.65,1.65-1.65h.35c0-.55,.45-1,1-1s1,.45,1,1h1c.55,0,1,.45,1,1s-.45,1-1,1h-3v1h2.41Z" />
    </svg>
);

export default InvoiceLogo;