import { DEV_URL } from '../Constant';

/**
 * This might prove to be un-necessary. I see we are doing something similar in Hop and just going with it ATM.
 * @param base 
 * @returns 
 */
const determineBaseAPI = (base: string | undefined): string => {
    if (base === 'localhost') {
        return DEV_URL;
    }
    return `https://api.${base}/`;
};

export default determineBaseAPI;