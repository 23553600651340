import React from 'react';

type BellIconProps = {
    width: number;
    height: number;
}

const BellIcon: React.FC<BellIconProps> = ({ width, height }): JSX.Element => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" fill="#030D45" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 50 50" xmlSpace="preserve">
        <g id="Layer_1_1_">
            <path d="M24,49h2c2.414,0,4.434-1.721,4.899-4H49v-6.618l-6-3V23c0-8.2-5.517-15.122-13.029-17.286C29.819,3.092,27.659,1,25,1   s-4.819,2.092-4.971,4.714C12.517,7.878,7,14.8,7,23v12.382l-6,3V45h18.101C19.566,47.279,21.586,49,24,49z M26,47h-2   c-1.304,0-2.416-0.836-2.829-2h7.657C28.416,46.164,27.304,47,26,47z M25,3c1.396,0,2.561,0.962,2.895,2.255   C26.95,5.101,25.988,5,25,5s-1.95,0.101-2.895,0.255C22.439,3.962,23.604,3,25,3z M3,39.618l6-3V23c0-8.822,7.178-16,16-16   s16,7.178,16,16v13.618l6,3V43H31H19H3V39.618z" />
        </g>
    </svg>
);

export default BellIcon;