// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import BaseRadioLabel from 'src/components/base/radioLabels/baseRadioLabel/BaseRadioLabel';
import styles from './RadioLabelDynamic.module.scss';

interface RadioLabelDynamicProps {
    label: string;
    setChecked: (isChecked: boolean) => void;
    isChecked: any;
    isPositive?: boolean;
    isSmallLabel?: boolean;
    classNames?: any;
    disabled?: boolean;
}

const RadioLabelDynamic: React.FC<RadioLabelDynamicProps> = ({ label, isChecked, setChecked, isPositive, isSmallLabel, classNames, disabled }) =>
    <BaseRadioLabel
        classNames={cn(styles.statusContainer, classNames,
            {
                [styles.radioLabel]: isSmallLabel,
            })
        }
        label={label}
        isChecked={isChecked}
        setChecked={setChecked}
        isGreen={isPositive}
        disabled={disabled}
    />;

export default RadioLabelDynamic;