// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import DisplayAccountLogo from 'src/components/base/displayAccountLogo/DisplayAccountLogo';
import VerificationStatus from 'src/components/base/verificationStatus/VerificationStatus';
import PlanType from 'src/components/business/planType/PlanType';
import { riskScoreType } from 'src/util/interfaces';
import ExternalUserIcon from '../../../../logos/ExternalUserIcon';
import styles from '../AccountLink.module.scss';

type Props = {
    dbaName: string;
    isVerified: boolean;
    score: riskScoreType;
    date: string;
    logo?: string;
    isExternalUser: boolean;
    planType: string;
};

const AccountLinkContent: React.FC<Props> = ({ dbaName, isVerified, score, date, logo, isExternalUser = false, planType }) => (
    <div className={styles.linkContainer} data-testid="linkContainer">
        <div className={cn(styles.bizDetailsContainer, styles.noZoom)}>
            <div className={styles.logoContainer}>
                <DisplayAccountLogo accountLogo={logo} />
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.infoFirstLayer}>
                    <div className={styles.businessNameContainer}>
                        <p className={styles.businessName}>{dbaName}</p>
                    </div>
                    <div className={styles.verificationContainer}>
                        {
                            isExternalUser
                                ? <ExternalUserIcon />
                                : <VerificationStatus verified={isVerified} />
                        }
                    </div>
                </div>
                <div className={styles.infoSecondLayer}>
                    <div className={cn(styles.statusContainer, { [styles.high]: score === 'High', [styles.low]: score === 'Low' })} data-testid="riskScore">
                        <p className={styles.statusText}>{score}</p>
                    </div>
                    <PlanType planLabel={planType} />
                    <div className={styles.sinceContainer}>
                        <p className={cn(styles.greyText, styles.smallGreyText)} data-testid="date">Since:  {date}</p>
                    </div>
                </div>
            </div>
        </div>
    </div >
);
export default AccountLinkContent;