import React from 'react';
import ReactTooltip from 'react-tooltip';
import WarningIcon from 'src/components/logos/WarningIcon';
import styles from './ToolTip.module.scss';

type ToolTipProps = {
    content?: any;
    toolTipText: string;

}

const ToolTip: React.FC<ToolTipProps> = ({ content, toolTipText }) => (
    <>
        <span className={styles.toolTipContainer} data-tip={true} data-for="registerTip">
            <WarningIcon width={15} height={15} />
            {content}
        </span>
        <ReactTooltip id="registerTip" place="right" effect="solid">
            {toolTipText}
        </ReactTooltip>
    </>
);

export default ToolTip;