import React from 'react';
import LinkButton from 'src/components/base/buttons/linkButton/LinkButton';
import LinkButtonWrapper from 'src/components/base/buttons/linkButton/linkButtonWrapper/LinkButtonWrapper';
import { AccountDetailsLinkedBankAccount, CardDto } from 'src/util/interfaces';
import { DirectBankAccountDto } from '../../../AccountDetailsPageStoreInterface';
import { PaymentTypeTab } from '../AccountActionsSection';

type props = {
    caseId: string;
    paymentType: keyof typeof PaymentTypeTab,
    linkedAccounts: AccountDetailsLinkedBankAccount[];
    directBankAccounts: DirectBankAccountDto[];
    cards: CardDto[];
    deletedPayments: (AccountDetailsLinkedBankAccount | DirectBankAccountDto | CardDto)[];
    classNames: string;
}

const PaymentMethodLinks: React.FC<props> = ({ caseId, paymentType, cards, linkedAccounts, directBankAccounts, deletedPayments, classNames }) => (<div className={classNames}>
    <LinkButtonWrapper>
        <LinkButton
            isActive={paymentType === PaymentTypeTab.LBA}
            store={{ setSelected: () => { } }}
            to={`/biz/account-details/${caseId}/view/Payable/${PaymentTypeTab.LBA}`}
            count={linkedAccounts.length}
            label="LBA"
        />
        <LinkButton
            isActive={paymentType === PaymentTypeTab.Card}
            store={{ setSelected: () => { } }}
            to={`/biz/account-details/${caseId}/view/Payable/${PaymentTypeTab.Card}`}
            count={cards.length}
            label="Card"

        />
        <LinkButton
            isActive={paymentType === PaymentTypeTab.DBA}
            store={{ setSelected: () => { } }}
            to={`/biz/account-details/${caseId}/view/Payable/${PaymentTypeTab.DBA}`}
            count={directBankAccounts.length}
            label="DBA"
            showDelimiter={true}
        />
        <LinkButton
            isActive={paymentType === PaymentTypeTab.Deleted}
            store={{ setSelected: () => { } }}
            to={`/biz/account-details/${caseId}/view/Payable/${PaymentTypeTab.Deleted}`}
            count={deletedPayments.length}
            label="Deleted"
        />
    </LinkButtonWrapper>
</div>);

export default PaymentMethodLinks;