import React from 'react';
import HRLine from 'src/components/base/hrLine/HRLine';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import LeftBlock from './LeftBlock';
import styles from './PlanDetails.module.scss';
// import RightBlock from './RightBlock';

interface PlanDetailsProps {
    // @TODO  pass plan details here then remove the below commented plan details interface 
    planDetails?: any
    openModal: () => void
    onHandleHistoryModal: (value: boolean) => void
}

const PlanDetails: React.FC<PlanDetailsProps> = ({ openModal, planDetails, onHandleHistoryModal }) => (
    <>
        {/* commenting this code out for now as we plan to integrate with the BE in a later sprint */}
        {/* {<div className={styles.header}>
            <h4>Plan details</h4>
            <button
                type="button"
                className={styles['history-link']}
                onClick={() => onHandleHistoryModal(true)}
            >
                History
            </button>
        </div>} */}
        <HRLine />
        <TwoColumnRow classNames={styles.gap}>
            <LeftBlock planDetails={planDetails} openModal={openModal} onHandleHistoryModal={onHandleHistoryModal} />
            {/* commenting this code out for now as we plan to integrate with the BE in a later sprint */}
            {/* <RightBlock totalAccounts={planDetails?.totalAccounts} /> */}
        </TwoColumnRow>
    </>
);

export default PlanDetails;