import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Button from 'src/components/base/buttons/button/Button';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import TextCurrencyRow from 'src/components/base/textCurrencyRow/TextCurrencyRow';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import Gap from 'src/layouts/gap/Gap';
import { useStore } from 'src/store';
import { CARD_CONTROLS_INPUTS, TCardLimits } from './CardControlsPageStore';

const CardControlsPage: React.FC = observer(() => {
    const fetch = useFetchLegacy();
    const { CardControlsPageStore } = useStore();

    useEffect(() => {
        CardControlsPageStore?.fetchCardLimits(fetch);
    }, [CardControlsPageStore]);

    const cardLimits = CardControlsPageStore?.getFormLimits();
    const isChanged = CardControlsPageStore?.getChangedField();




    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <div>
                            <SectionLabel>Card controls</SectionLabel>
                        </div>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <div>
                            <TextCurrencyRow label="Card transaction limit" value={cardLimits?.CardTransactionLimit} handleChange={(val: number) => { CardControlsPageStore?.updateFormCardLimits(CARD_CONTROLS_INPUTS.CardTransactionLimit as keyof TCardLimits, val); }} />
                            <TextCurrencyRow label="Card transaction value threshold" value={cardLimits?.CardTransactionValueThreshold} handleChange={(val: number) => { CardControlsPageStore?.updateFormCardLimits(CARD_CONTROLS_INPUTS.CardTransactionValueThreshold as keyof TCardLimits, val); }} />


                            {/* @TODO : Commented as not being used but might have use in future */}

                            {/* <TextCurrencyRow label="Card transaction limit value - 1 day" value={13.45} />
                            <TextNumberRow label="Card transaction limit volume - 1 day" value={5} placeholder="Placeholder" />
                            <TextCurrencyRow label="Card transaction limit value - 7 day" value={50.15} />
                            <TextNumberRow label="Card transaction limit volume - 7 day" value={5} placeholder="Placeholder" />

                            <TextCurrencyRow label="Card transaction limit value - 30 day" value={70} />
                            <TextNumberRow label="Card transaction limit volume - 30 day" value={5} placeholder="Placeholder" />
                            <TextNumberRow label="Card input failure limit" value={5} placeholder="Placeholder" />
                            <TextNumberRow label="Velocity limit" value={5} placeholder="Placeholder" />

                            <TextNumberRow label="Storage limit" value={7} placeholder="Placeholder" />
                            {renderTwoColumnRow('Card pull fee %', 5, '', 'percent')}
                            {renderTwoColumnRow('Card disbursement fee %', 5, '', 'percent')}
                            <TextCurrencyRow label="Card disbursement fee max" value={50} /> */}

                            <Gap />
                            <Button disabled={!isChanged} onClick={() => CardControlsPageStore?.saveCardLimits(fetch)}>Save</Button>
                        </div>
                    </Section>
                </Row>
            </Content >
        </EntireLayout >
    );
});


export default CardControlsPage;