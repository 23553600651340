import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Params, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import { useStore } from 'src/store';
import TransactionDetailContent from './TransactionDetailContent';

const TransactionDetailsPageByPaymentId: React.FC = observer(() => {
    const { paymentId }: Readonly<Params<string>> = useParams();
    const { TransactionDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();

    useEffect(() => {
        TransactionDetailsPageStore.fetchTransactionDetailsByPaymentId(paymentId ?? '', fetch);
        TransactionDetailsPageStore.fetchAuditHistoryTransactions(fetch, paymentId ?? '');
    }, [TransactionDetailsPageStore, paymentId]);


    return (<TransactionDetailContent />);
});

export default TransactionDetailsPageByPaymentId;