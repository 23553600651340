import React from 'react';
import SimpleConfirmPopup from 'src/components/base/popups/simpleConfirmPopup/SimpleConfirmPopup';
import styles from '../PaidPlanControls.module.scss';

type UpdatePlanPopupProps = {
    popupVisible: boolean;
    modalId: string;
    title: string
    subtext: string
    acceptCallback: () => void;
    rejectCallback: () => void

};

const UpdatePlanPopup: React.FC<UpdatePlanPopupProps> = ({ popupVisible, modalId, title, subtext, acceptCallback, rejectCallback
}) => (

    <SimpleConfirmPopup
        isVisible={popupVisible}
        title={title}
        titleClassName={styles.titleClassName}
        subtext={subtext}
        onAccept={acceptCallback}
        onReject={rejectCallback}
        modalId={modalId}
        acceptCtaText='Update'
        subtextClassName={styles.subtextClassName}
        showCloseButton={true}
    />
);

export default UpdatePlanPopup;
