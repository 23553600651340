import React, { useEffect } from 'react';

const CardForm: React.FC = () => {

    useEffect(() => {
        window.addEventListener('message', pfReceivedMessage, false);
        return () => {
            window.removeEventListener('message', pfReceivedMessage, true);
        };
    }, []);

    const pfReceivedMessage = (event: { data: string; }) => {
        if (event.data !== 'Close') {
            if (event.data.toString().slice(0, 7) === 'Error: ') {
                // Error
            }
            else {
                const asData = event.data.toString().split('|');
                if (asData.length === 3) {
                    // this will be where we add the body for the request we send to hopscotch BE
                    window.console.log('asData', asData);
                }
                else {
                    // Data Error ... this is where we handle the errors 
                    window.console.log('error');
                }
            }
        }
        else {
            // Close or Cancel
            window.console.log('testing');
        }
    };

    return (
        <iframe title="tabapayIframe" id='sso' src='https://sso.sandbox.tabapay.com:8443/SSOHopscotch.html' />
    );
};

export default CardForm;