import { Popup } from 'devextreme-react';
import React, { memo } from 'react';
import { CloseButton } from 'src/components/base/buttons/closeButton/CloseButton';
import { UserFinancialsValues as IValues } from '../../../AccountDetailsPageStore';
import FileUploadForm from '../FileUploadForm/FileUploadForm';

import styles from './UserDocsUploadModal.module.scss';

interface UserDocsUploadModalProps {
    isLoading: boolean;
    isVisible: boolean;
    onCancel: () => void;
    onSubmit: (values: IValues) => void;
}

const UserDocsUploadModal: React.FC<UserDocsUploadModalProps> = memo((props: UserDocsUploadModalProps) => {
    const {
        isLoading,
        isVisible,
        onCancel,
        onSubmit,
    } = props;

    const renderTitle = () => (
        <div className={styles.titleContainer}>
            <div className={styles.titleWrapper}>
                <div className={styles.titleDiv}>
                    <p className={styles.generateTitle}>Upload user financials to Oatfi</p>
                    <CloseButton onClick={onCancel} />
                </div>
            </div>
        </div>
    );

    return (
        <Popup
            visible={isVisible}
            height="480px"
            width="500px"
            showCloseButton={true}
            onHiding={onCancel}
            closeOnOutsideClick={true}
            titleComponent={renderTitle}
        >
            <div>
                <FileUploadForm
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                />
            </div>
        </Popup>
    );
});

export default UserDocsUploadModal;
