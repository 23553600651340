import React from 'react';
import styles from './Title.module.scss';


const Title: React.FC = () => (
    <div className={styles.titleDiv}>
        <h1 className={styles.title}>Intuition</h1>
    </div>

);

export default Title;