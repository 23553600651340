/* eslint-disable react/jsx-props-no-spreading */
import {
    Field,
    FileList,
    FileUpload,
    Input,
    Label,
    Radio
} from '@zendeskgarden/react-forms';
import { Col, Row } from '@zendeskgarden/react-grid';
import { useFormik } from 'formik';
import React, { memo, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { ReactComponent as UploadIcon } from 'src/static-assets/logos/upload-file.svg';

import Button from 'src/components/base/buttons/button/Button';
import { UserFinancialsValues as IValues } from '../../../AccountDetailsPageStore';
import FileItem from './FileItem';

import styles from './FileUploadForm.module.scss';

const validationSchema = Yup.object().shape({
    files: Yup.array().of(Yup.mixed().required('File is required')).min(1, 'At least one file is required'),
});

export interface FileUploadFormProps {
    isLoading: boolean;
    onSubmit: (values: IValues) => void;
}

export interface Attachment {
    id: string;
    file: File;
}

export interface IFormFields {
    type: string;
    files: Attachment[];
}

const FileUploadForm: React.FC<FileUploadFormProps> = memo((props: FileUploadFormProps) => {
    const { isLoading, onSubmit } = props;
    const attachmentsValues = useRef<Attachment[]>([]);

    const formik = useFormik({
        initialValues: {
            type: 'BANK_STATEMENTS',
            files: [] as Attachment[],
        },
        validationSchema,
        onSubmit: (values: IFormFields) => {
            const data = {
                type: values.type,
                files: values.files.map(item => item.file)
            };
            onSubmit(data);
        },
    });

    const {
        values,
        errors,
        handleSubmit,
        setFieldValue,
    } = formik;

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles = acceptedFiles?.map((item: File) => ({
            id: uuidv4(),
            file: item
        }));
        const attachments = [...values.files, ...newFiles];
        attachmentsValues.current = attachments;
        setFieldValue('files', attachments);
    }, [values?.files]);

    const removeFile = useCallback((id: string) => {
        const updateFiles = attachmentsValues?.current?.filter(item => item.id !== id);
        attachmentsValues.current = updateFiles;
        setFieldValue('files', updateFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: isLoading,
        multiple: true
    });

    const attachmentsLength = values?.files?.length;

    return (
        <form onSubmit={handleSubmit} className={styles['file-upload-form']}>
            <Row>
                <Col>
                    <Field>
                        <div>
                            <Label>Document Type:</Label>
                        </div>
                    </Field>
                    <div role="group">
                        <Field>
                            <Radio
                                name="type"
                                value="BANK_STATEMENTS"
                                checked={values.type === 'BANK_STATEMENTS'}
                                onChange={(e) => setFieldValue('type', e.target.value)}
                            >
                                <Label isRegular={true}>Bank Statement</Label>
                            </Radio>
                        </Field>
                        <Field>
                            <Radio
                                name="type"
                                value="ACCOUNTING_DATA"
                                checked={values.type === 'ACCOUNTING_DATA'}
                                onChange={(e) => setFieldValue('type', e.target.value)}
                            >
                                <Label isRegular={true}>Accounting data</Label>
                            </Radio>
                        </Field>
                    </div>
                </Col>
            </Row>
            <br />
            <Row justifyContent="center">
                <Col>
                    <Field>
                        <div>
                            <Label>Attachments {attachmentsLength ? `(${attachmentsLength})` : ''}</Label>
                        </div>
                        <FileList>
                            {values?.files?.map((item) => (
                                <FileItem
                                    key={item.id}
                                    file={item.file}
                                    onRemove={() => removeFile(item.id)}
                                />
                            ))}
                        </FileList>
                    </Field>
                    <Field>
                        {errors?.files && <p className={styles['form-error']}>{errors?.files as string}</p>}
                        <FileUpload {...getRootProps()} isDragging={isDragActive} disabled={isLoading} className={styles['form-file-upload']}>
                            <UploadIcon />
                            <span>Add files</span>
                            <Input {...getInputProps()} />
                        </FileUpload>
                    </Field>
                </Col>
            </Row>
            <br />
            <Row>
                <Col textAlign="end">
                    <Button
                        type="submit"
                        disabled={isLoading || !formik?.isValid}
                        onClick={handleSubmit}
                    >
                        Send
                    </Button>
                </Col>
            </Row>
        </form>
    );
});

export default FileUploadForm;
