import React from 'react';
// eslint-disable-next-line id-length
import cn from 'classnames';
import styles from './HalfPanel.module.scss';

interface PanelProps {
    children?: any;
    isLeft?: boolean;
    classNames?: string;
}
const HalfPanel: React.FC<PanelProps> = ({ children, isLeft, classNames }) => (<div data-testid="HalfPanel" className={cn(styles.panel, classNames, { [styles.isLeft]: isLeft })}>{children}</div>);

export default HalfPanel;