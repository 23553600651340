type FactoredRiskLabelToAttribute = {
    [key: string]: string;
};

export const FactoredRiskLabels = {
    BASE_PRICE: 'Base Price',
    PRICE_PER_DAY: 'Price per day',
    PRICING_MINIMUM_DAYS: 'Pricing Minimum Days',
    ALLOW_BUSINESS: 'Allowed Business',
    PRIOR_SUCCESS: 'Prior Success',
    HOPSCOTCH_PAYOR: 'Hopscotch Payor',
    PREVIOUS_ON_TIME_ADJUSTMENT: 'Previous on Time Adjustment',
    NUMBER_OF_TIMES_PREVIOUSLY_ON_TIME: 'Number of Times Previously on Time',
    MAXIMUM_ADJUSTMENT: 'Maximum Adjustment',
    TRANSACTION: 'Transaction',
    PAYEE: 'Payee',
    PAYOR: 'Payor',
    DAILY_LIMIT: 'Daily Limit',
    TOTAL_PORTFOLIO: 'Total Portfolio',
    MINIMUM_DAYS: 'Minimum Days',
    MAXIMUM_DAYS: 'Maximum Days',
    MINIMUM_HISTORY: 'Minimum History',
    MINIMUM_TRANSACTIONS: 'Minimum Transactions',
    MINIMUM_TRANSACTION_SIZE: 'Minimum Transaction Size',
    RATIO_TO_BIGGEST_TRANSACTION: 'Ratio to Biggest Transaction',
    NUMBER_OF_BIGGEST_TRANSACTIONS: 'Number of Biggest Transactions',
    MAXIMUM_CURRENT_DAYS_PAST_DUE: 'Maximum Current Days Past Due',
    MAXIMUM_EVER_DAYS_PAST_DUE: 'Maximum Ever Days Past Due',
    DEFAULT_DAYS_PAST_DUE: 'Default Days Past Due',
};

export const FactoredRiskLabelToAttribute: FactoredRiskLabelToAttribute = {
    [FactoredRiskLabels.BASE_PRICE]: 'basePrice',
    [FactoredRiskLabels.PRICE_PER_DAY]: 'pricePerDay',
    [FactoredRiskLabels.PRICING_MINIMUM_DAYS]: 'minimumDays',
    [FactoredRiskLabels.PRIOR_SUCCESS]: 'priorSuccess',
    [FactoredRiskLabels.HOPSCOTCH_PAYOR]: 'hopscotchPayor',
    [FactoredRiskLabels.PREVIOUS_ON_TIME_ADJUSTMENT]: 'prevOnTimeAdj',
    [FactoredRiskLabels.NUMBER_OF_TIMES_PREVIOUSLY_ON_TIME]: 'timesPrevOnTime',
    [FactoredRiskLabels.MAXIMUM_ADJUSTMENT]: 'maxAdjustment',
    [FactoredRiskLabels.TRANSACTION]: 'transaction',
    [FactoredRiskLabels.PAYEE]: 'payee',
    [FactoredRiskLabels.PAYOR]: 'payor',
    [FactoredRiskLabels.DAILY_LIMIT]: 'dailyLimit',
    [FactoredRiskLabels.TOTAL_PORTFOLIO]: 'totalPortfolio',
    [FactoredRiskLabels.MINIMUM_DAYS]: 'minDays',
    [FactoredRiskLabels.MAXIMUM_DAYS]: 'maxDays',
    [FactoredRiskLabels.MINIMUM_HISTORY]: 'minHistory',
    [FactoredRiskLabels.MINIMUM_TRANSACTIONS]: 'minXActions',
    [FactoredRiskLabels.MINIMUM_TRANSACTION_SIZE]: 'minXActionSize',
    [FactoredRiskLabels.RATIO_TO_BIGGEST_TRANSACTION]: 'ratioToBiggestTransactions',
    [FactoredRiskLabels.NUMBER_OF_BIGGEST_TRANSACTIONS]: 'numberOfBiggestTransactions',
    [FactoredRiskLabels.MAXIMUM_CURRENT_DAYS_PAST_DUE]: 'maxCurrDpd',
    [FactoredRiskLabels.MAXIMUM_EVER_DAYS_PAST_DUE]: 'maxEverDpd',
    [FactoredRiskLabels.DEFAULT_DAYS_PAST_DUE]: 'defaultDpd',
};
