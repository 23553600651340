import React from 'react';

const ExitIcon: React.FC = (): JSX.Element => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.226756 0.226756C0.529097 -0.0755854 1.01929 -0.0755854 1.32163 0.226756L8 6.90513L14.6784 0.226756C14.9807 -0.0755854 15.4709 -0.0755854 15.7732 0.226756C16.0756 0.529097 16.0756 1.01929 15.7732 1.32163L9.09487 8L15.7732 14.6784C16.0756 14.9807 16.0756 15.4709 15.7732 15.7732C15.4709 16.0756 14.9807 16.0756 14.6784 15.7732L8 9.09487L1.32163 15.7732C1.01929 16.0756 0.529097 16.0756 0.226756 15.7732C-0.0755854 15.4709 -0.0755854 14.9807 0.226756 14.6784L6.90513 8L0.226756 1.32163C-0.0755854 1.01929 -0.0755854 0.529097 0.226756 0.226756Z"
            fill="#005FDA"
        />
    </svg>
);

export default ExitIcon;