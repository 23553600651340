import React, { useState } from 'react';
import { dropDownInterface } from '../../../util/interfaces';
// eslint-disable-next-line id-length
import Dropdown, { DropdownPropInterface } from '../dropDown/Dropdown';
import useFetchRemoteDropdown from './useFetchRemoteDropdown';

export interface RemoteDropdownProp extends DropdownPropInterface {
    url: string;
}

const RemoteDropdown: React.FC<RemoteDropdownProp> = ({ url, name, onChange, label, className, value }) => {
    const [options, setOptions] = useState<dropDownInterface[]>([]);

    useFetchRemoteDropdown(url, setOptions);

    return <Dropdown
        onChange={onChange}
        label={label}
        value={value}
        name={name}
        className={className}
        options={options}
    />;
};

export default RemoteDropdown;