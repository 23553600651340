import React from 'react';
import {
    Row,
    Section,
    SectionLabel
} from 'src/components';

interface props {
    title: string;
}

const SearchTitle: React.FC<props> = ({ title }) => (<Row>
    <Section>
        <SectionLabel>{title}</SectionLabel>
    </Section>
</Row>);

export default SearchTitle;