import React from 'react';
// eslint-disable-next-line id-length
import cn from 'classnames';
import { getPaymentTypeLabel } from 'src/util';
import { PaymentType } from 'src/util/interfaces';
import HopOpsToolTip from './hopOpsToolTip/HopOpsToolTip';

import styles from './BlockButton.module.scss';

type BlockButtonProps = {
    isBlocked: boolean;
    isOps: boolean;
    paymentType: keyof typeof PaymentType
}

const BlockButton: React.FC<BlockButtonProps> = ({ isBlocked, isOps, paymentType }): JSX.Element => (
    <div
        data-testid="BlockButton"
        className={styles.wrapper}>
        <span
            data-testid="typeSpan"
            data-tip={true}
            className={cn(styles.tagContainer, styles.typeSpan)}>
            {getPaymentTypeLabel(paymentType)}
        </span>

        <div className={cn(
            styles.labelContainer, {
            [styles.unblockLabel]: !isBlocked,
            [styles.blockLabel]: isBlocked
        })}>

            <span data-testid="isBlocked">{isBlocked ? 'Block' : 'Unblock'}</span>

        </div>
        <HopOpsToolTip tagLabel={isOps ? 'O' : 'H'} />
    </div>
);

export default BlockButton;