// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import styles from './GreyLabel.module.scss';

interface props {
    label: string;
    classNames?: string;
}

const GreyLabel: React.FC<props> = ({ label, classNames }) => <span
    className={cn(styles.GreyLabel, classNames)}
    data-testid="greyLabel">
    {label}
</span>;

export default GreyLabel;