/* eslint-disable no-nested-ternary */
import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { CASE_LBA_SEARCH, CASE_LBA_SEARCH_COUNT } from 'src/Constant';
import { LbaSearchPayloadInterface, PaymentSearchInterface, PaymentType, SearchStatus, SearchUpdatedBy } from '../../util/interfaces';
import { selectPaymentSearch } from '../../util/selectors';
import { CountInformationInterface } from './LBAVerificationSearchPageInterface';


export interface LBAVerificationSearchPageStoreInterface {
    status: keyof typeof SearchStatus;
    updatedBy: keyof typeof SearchUpdatedBy;
    allCount: number;
    blockedCount: number;
    unblockedCount: number;
    restrictedOrLockedUnblockedCount: number;
    restrictedOrLockedBlockedCount: number;
    blockedHopCount: number;
    blockedOpsCount: number;
    unblockedHopCount: number;
    unblockedOpsCount: number;
    lbas: PaymentSearchInterface[];
    getLBAs(): PaymentSearchInterface[];
    getCounts(): CountInformationInterface,
    setSelected(status?: keyof typeof SearchStatus): void;
    getLinkUrl(status: keyof typeof SearchStatus, updatedBy: keyof typeof SearchUpdatedBy): string;
    getUpdatedBy(): keyof typeof SearchUpdatedBy;
    fetchLBAs(fetch: FetchInterfaceLegacy, status: keyof typeof SearchStatus, updatedBy: keyof typeof SearchUpdatedBy): void;
    fetchLBAsCount(fetch: FetchInterfaceLegacy): void;
}

const LBAVerificationSearchPageStore = (): LBAVerificationSearchPageStoreInterface =>
    makeAutoObservable({
        status: SearchStatus.All as keyof typeof SearchStatus,
        updatedBy: SearchUpdatedBy.Hop,
        allCount: 0,
        blockedCount: 0,
        unblockedCount: 0,
        restrictedOrLockedUnblockedCount: 0,
        restrictedOrLockedBlockedCount: 0,
        lockedAccount: 0,
        blockedHopCount: 0,
        blockedOpsCount: 0,
        unblockedHopCount: 0,
        unblockedOpsCount: 0,
        lbas: [
            {
                accountLogo: '',
                businessName: '',
                userName: '',
                email: '',
                businessType: '',
                caseID: '',
                dateReceived: '',
                status: '',
                paymentStatus: '',
                hopscotchStatus: '',
                pendingStatus: '',
                institutionName: '',
                accountNumber: '',
                institutionLogo: '',
                id: '',
                isBlocked: false,
                hasOpsUpdated: false,
                paymentType: PaymentType.NotSet
            },
        ],
        getLBAs() {
            return this.lbas;
        },
        getCounts() {
            return {
                allCount: this.allCount,
                blockedCount: this.blockedCount,
                unblockedCount: this.unblockedCount,
                blockedHopCount: this.blockedHopCount,
                blockedOpsCount: this.blockedOpsCount,
                unblockedHopCount: this.unblockedHopCount,
                unblockedOpsCount: this.unblockedOpsCount,
                restrictedOrLockedUnblockedCount: this.restrictedOrLockedUnblockedCount,
                restrictedOrLockedBlockedCount: this.restrictedOrLockedBlockedCount,
            };
        },
        setSelected(status) {
            if (status === undefined) {
                this.status = SearchStatus.All;
            } else {
                this.status = status;
            }
        },
        getLinkUrl(status, updatedBy) {
            return `/biz/lba-verifications/status/${status}/updatedBy/${updatedBy}`;
        },
        getUpdatedBy() {
            return this.updatedBy;
        },
        fetchLBAs(fetch, status, updatedBy) {
            fetch({ url: CASE_LBA_SEARCH(status, updatedBy) }, (res) => {
                this.lbas = res.data.map((theCase: LbaSearchPayloadInterface) => selectPaymentSearch(theCase));
            }, true);
        },
        fetchLBAsCount(fetch) {
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.All, SearchStatus.All)}` }, (res) => {
                this.allCount = res.data;
            });
            fetch({ url: CASE_LBA_SEARCH_COUNT(SearchStatus.Blocked, SearchUpdatedBy.All) }, (res) => {
                this.blockedCount = res.data;
            });
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.UnBlocked, SearchUpdatedBy.All)}` }, (res) => {
                this.unblockedCount = res.data;
            });
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.Blocked, SearchUpdatedBy.Hop)}` }, (res) => {
                this.blockedHopCount = res.data;
            });
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.Blocked, SearchUpdatedBy.Ops)}` }, (res) => {
                this.blockedOpsCount = res.data;
            });
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.UnBlocked, SearchUpdatedBy.Hop)}` }, (res) => {
                this.unblockedHopCount = res.data;
            });
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.UnBlocked, SearchUpdatedBy.Ops)}` }, (res) => {
                this.unblockedOpsCount = res.data;
            });
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.UnBlocked, SearchUpdatedBy.RestrictedOrLockedAccount)}` }, (res) => {
                this.restrictedOrLockedUnblockedCount = res.data;
            });
            fetch({ url: `${CASE_LBA_SEARCH_COUNT(SearchStatus.Blocked, SearchUpdatedBy.RestrictedOrLockedAccount)}` }, (res) => {
                this.restrictedOrLockedBlockedCount = res.data;
            });
        }
    });

export default LBAVerificationSearchPageStore;