import React from 'react';
import { CurrencyValue } from 'src/components';
import styles from './CompletedBlock.module.scss';

type Props = {
    completedPayables: number;
    completedReceivables: number;
    completedNetFlow: number;
}

const CompletedBlock: React.FC<Props> = ({ completedPayables, completedReceivables, completedNetFlow }) => (
    <div className={styles.content}>
        <div className={styles.balanceContainer}>
            <p className={styles.balanceText}>Completed Payables</p>
            <CurrencyValue>
                {completedPayables}
            </CurrencyValue>
        </div>
        <div className={styles.balanceContainer}>
            <p className={styles.balanceText}>Completed Receivables</p>
            <CurrencyValue>
                {completedReceivables}
            </CurrencyValue>
        </div>
        <div className={styles.balanceContainer}>
            <p className={styles.balanceText}>Completed AR/AP flow</p>
            <CurrencyValue>
                {completedNetFlow}
            </CurrencyValue>
        </div>
    </div>
);

export default CompletedBlock;