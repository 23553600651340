/* eslint-disable react/no-unused-state */
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import HRLine from 'src/components/base/hrLine/HRLine';
import InfoCard from 'src/components/base/infoCard/InfoCard';
import Label from 'src/components/base/label/Label';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import TextValue from 'src/components/base/textValue/TextValue';
import CancelIcon from 'src/components/logos/CancelIcon';
import FastTrackIcon from 'src/components/logos/FastTrackIcon';
import PeopleLogo from 'src/components/logos/PeopleLogo';
import ReceiptLogo from 'src/components/logos/ReceiptLogo';
import WarningIcon from 'src/components/logos/WarningIcon';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import Gap from 'src/layouts/gap/Gap';
import LabelWrapper from 'src/layouts/labelWrapper/LabelWrapper';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import ValueWrapper from 'src/layouts/valueWrapper/ValueWrapper';
import { useStore } from 'src/store';
import useFetchUserDetails from 'src/store/hooks/useFetchUserDetails';
import { FACTORED_INVOICE_SEARCH_APP_URL, TRANSACTION_SEARCH_APP_URL } from '../../Constant';
import CardApprovalLogo from '../../components/logos/CardApprovalLogo';
import CardVerficationLogo from '../../components/logos/CardVerficationLogo';
import { SearchPaymentType } from '../../util/interfaces';
import styles from './BusinessPage.module.scss';

const BusinessPage: React.FC = observer(() => {
    const { sharedStore, BusinessPageStore } = useStore();
    useFetchUserDetails();
    const fetch = useFetchLegacy();

    useEffect(() => {
        BusinessPageStore.fetchTransactionVolumeOver30Days(fetch);
        BusinessPageStore.fetchTotalDeposits(fetch);
        BusinessPageStore.fetchCasesCount(fetch);
        BusinessPageStore.fetchFlaggedTransactionsCount(fetch);
        BusinessPageStore.fetchFlaggedTransactionsLastWeekCount(fetch);
        BusinessPageStore.fetchReturnedTransactionsCount(fetch);
        BusinessPageStore.fetchReturnedTransactionsLastWeekCount(fetch);
        BusinessPageStore.fetchFactoredInvoicesCount(fetch);
        BusinessPageStore.fetchLBACounts(fetch);
        BusinessPageStore.fetchAwaitingTransactionsCount(fetch);
        BusinessPageStore.fetchBlockedHopCount(fetch);
        // @TODO: fetch currently Blocked & Auto-Updated Card Verifications.
    }, [BusinessPageStore]);

    const totalVerified = BusinessPageStore.getTotalAccounts();
    const totalReview = BusinessPageStore.getTotalReviewedAccounts();
    const totalFlagged = BusinessPageStore.getTotalFlaggedTransactions();
    const totalReturned = BusinessPageStore.getTotalReturned();
    const totalFactored = BusinessPageStore.getTotalFactored();
    const totalFlaggedPast7Days = BusinessPageStore.getTotalFlaggedPast7Days();
    const totalReturnedPast7Days = BusinessPageStore.getTotalReturnedPast7Days();
    const totalFactoredAwaiting = BusinessPageStore.getTotalFactoredAwaiting();
    const totalBlockedBankAccounts = BusinessPageStore.getTotalBlockedBankAccounts();
    const totalUnblockedBankAccounts = BusinessPageStore.getTotalUnblockedBankAccounts();
    const totalAwaitingTransactions = BusinessPageStore.getAwaitingTransactions();
    const blockedHopCount = BusinessPageStore.getBlockedHopCardCount();

    const ipAddress = sharedStore.getIp();
    const username = sharedStore.getUsername();

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <div className={styles.wrapper}>
                        <h3 className={styles.title}>Exceptions</h3>
                        <div className={styles.hr}>
                            <HRLine />
                        </div>
                        <div className={styles.exceptionsContainer}>
                            <InfoCard route='/biz/case-verifications/status/Review' header='KYB/KYC verifications' totalText='Verifications' halfOfTotalText='currently in review' totalNum={totalReview} logo={<PeopleLogo width={21} height={21} />} />
                            <InfoCard route='/biz/lba-verifications/status/Blocked/updatedBy/Hop' header='LBA verifications' totalText='LBA’s in blocked' halfOfTotalText='status for review' totalNum={totalBlockedBankAccounts} logo={<ReceiptLogo width={36} height={25} />} />

                            <InfoCard route='/biz/card-verifications/status/Blocked/updatedBy/Hop' header='Card verifications' totalText='Cards in blocked' halfOfTotalText='status for review' totalNum={blockedHopCount} logo={<CardVerficationLogo width={36} height={30} />} />
                        </div>
                        <div className={styles.exceptionsContainer}>
                            <InfoCard route={TRANSACTION_SEARCH_APP_URL(SearchPaymentType.cardWaitingApproval)} header='Card approvals' totalText='Payments made by' halfOfTotalText='card awaiting approval' totalNum={totalAwaitingTransactions} logo={<CardApprovalLogo width={36} height={30} />} />
                            <InfoCard route={TRANSACTION_SEARCH_APP_URL(SearchPaymentType.flagged)} header='Flagged' totalText='Flagged payments' halfOfTotalText='& invoices in past week' totalNum={totalFlaggedPast7Days} logo={<WarningIcon width={21} height={21} />} />
                            <InfoCard route={TRANSACTION_SEARCH_APP_URL(SearchPaymentType.returned)} header='Returned' totalText='Returned payments' halfOfTotalText='in past Week' totalNum={totalReturnedPast7Days} logo={<CancelIcon width={21} height={21} />} />
                            <InfoCard route={FACTORED_INVOICE_SEARCH_APP_URL(SearchPaymentType.rejected)} header='Factored invoices' totalText='Rejected or past' halfOfTotalText='due awaiting review' totalNum={totalFactoredAwaiting} logo={<FastTrackIcon width={21} height={21} />} />
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className={styles.wrapper}>
                        <h3 className={styles.title}>Totals</h3>
                        <div className={styles.hr}>
                            <HRLine />
                        </div>
                        <div className={styles.exceptionsContainer}>
                            <InfoCard header='KYB/KYC verifications' totalText='Total accounts' halfOfTotalText='verified' totalNum={totalVerified} logo={<PeopleLogo width={21} height={21} />} />
                            <InfoCard header='LBA verifications' totalText='Total LBA’s' halfOfTotalText='unblocked' totalNum={totalUnblockedBankAccounts} logo={<ReceiptLogo width={21} height={21} />} />
                            <InfoCard header='Flagged transactions' totalText='Total flagged' halfOfTotalText='transactions' totalNum={totalFlagged} logo={<WarningIcon width={21} height={21} />} />
                            <InfoCard header='Returned transactions' totalText='Total returned' halfOfTotalText='transactions' totalNum={totalReturned} logo={<CancelIcon width={21} height={21} />} />
                            <InfoCard header='Factored invoices' totalText='Total factored' halfOfTotalText='invoices' totalNum={totalFactored} logo={<FastTrackIcon width={21} height={21} />} />
                        </div>
                    </div>
                </Row>
                <Row>
                    <Section>
                        <SectionLabel>Current Session</SectionLabel>
                        <div className={styles.bigGap}>
                            <HRLine />
                        </div>
                        <TwoColumnRow>
                            <LabelWrapper>
                                <Label>Name:</Label>
                            </LabelWrapper>
                            <ValueWrapper>
                                <TextValue>{username}</TextValue>
                            </ValueWrapper>
                        </TwoColumnRow>
                        <Gap />
                        <TwoColumnRow>
                            <LabelWrapper>
                                <Label>IP Address:</Label>
                            </LabelWrapper>
                            <ValueWrapper>
                                <TextValue>{ipAddress}</TextValue>
                            </ValueWrapper>
                        </TwoColumnRow>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default BusinessPage;
