import React from 'react';

import styles from './RenderBusinessCell.module.scss';

interface props { businessName: string; userName: string; email: string; }

const RenderBusinessCell: React.FC<props> = ({ businessName, userName, email }) => <>
    <p className={styles.businessName}>{businessName} ({userName})</p> {email}
</>;

export default RenderBusinessCell; 