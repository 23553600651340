import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'src/components/base/buttons/button/Button';
import Logo from 'src/components/base/Logo';
import styles from './Navbar.module.scss';

const Navbar: React.FC = () => {
    const navigate = useNavigate();

    const logOut = () => (
        navigate('/')
    );

    return (
        <nav className={styles.navbar}>
            <div className={styles.logoContainer}>
                <Link to="/home">
                    <Logo />
                </Link>
            </div>
            <Button onClick={logOut}>
                Logout
            </Button>
        </nav>
    );
};

export default Navbar;