import React from 'react';
import styles from 'src/pages/invoiceDetailsPage/sections/invoiceSection/InvoiceSection.module.scss';
import { getInCurrencyFormat } from 'src/util';
import getInNumberFormat from 'src/util/getInNumberFormat';

export interface props {
    invoice: {
        id: string,
        description: string,
        qty: number;
        rate: number,
        amount: number,
    }
}

const InvoiceItem: React.FC<props> = ({ invoice }) => {
    const id = invoice.id;
    const description = invoice.description;
    const qty = getInNumberFormat(invoice.qty);
    const rate = getInCurrencyFormat(invoice.rate);
    const amount = getInCurrencyFormat(invoice.amount);

    return (
        <div className={styles.invoiceItemsContainer}>

            <div className={styles.itemsWrapper} key={id}>
                <div>
                    <h4 className={styles.item}>{description}</h4>
                </div>
                <div className={styles.qtyContainer}>
                    <h4 className={styles.item}>{qty}</h4>
                </div>
            </div>
            <div className={styles.itemsWrapper}>
                <div>
                    <h4 className={styles.item}>{rate}</h4>
                </div>
                <div className={styles.amountContainer}>
                    <h4 className={styles.item}>{amount}</h4>
                </div>
            </div>
        </div>
    );
};

export default InvoiceItem;