import React from 'react';

interface props {
    isPositive?: boolean;
}

const StatusIndicator: React.FC<props> = ({ isPositive = false }) => (
    <>
        {isPositive && (
            <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="29" height="30" rx="5" fill="#00C39E" />
            </svg>
        )}
        {
            !isPositive && ((<svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="29" height="30" rx="5" fill="#EB5757" />
            </svg>))
        }
    </>);

export default StatusIndicator;