import React from 'react';
import styles from './SectionLabel.module.scss';

type Props = {
    children: string;
}

const SectionLabel: React.FC<Props> = ({ children }): JSX.Element => (
    <h2 className={styles.label}>{children}</h2>
);

export default SectionLabel;
