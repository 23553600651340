import React from 'react';
import AccountLink from 'src/components/business/accounts/accountLink/AccountLink';
import { riskScoreType } from 'src/util/interfaces';
import { getCurrencyOrNotApplicable } from '../../../../util';
import InfoBlock from '../infoBlock/InfoBlock';
import styles from './PayeePayorCardView.module.scss';

type PayeePayorCardViewProps = {
    title: string;
    dbaName: string;
    isVerified: boolean;
    score: riskScoreType;
    createdTimestamp: string;
    caseId: string;
    accountLogo: string;
    hopscotchBalance: number;
    bankPartnerBalance: number;
    averageTransactionSize: number;
    totalTransactionVolume: number;
    planType: string;
}

const PayeePayorCardView: React.FC<PayeePayorCardViewProps> = ({ title, dbaName, isVerified, score, createdTimestamp, caseId, accountLogo, hopscotchBalance, bankPartnerBalance, averageTransactionSize, totalTransactionVolume, planType }) => <div className={styles.payeeContainer}>
    <h4 className={styles.payeeTitle}>{title}</h4>
    <div className={styles.contentContainer}>
        <AccountLink
            dbaName={dbaName}
            isVerified={isVerified}
            score={score}
            createdTimestamp={createdTimestamp}
            caseId={caseId}
            accountLogo={accountLogo}
            key="payee"
            planType={planType}
        />
        <div className={styles.informationBlock}>
            <InfoBlock label='Hopscotch balance:' response={getCurrencyOrNotApplicable(hopscotchBalance)} />
            <InfoBlock label="Bank balance:" response={getCurrencyOrNotApplicable(bankPartnerBalance)} />
            <InfoBlock label="Avg txn size:" response={getCurrencyOrNotApplicable(averageTransactionSize)} />
            <InfoBlock label='Txn volume:' response={totalTransactionVolume} />
        </div>
    </div>
</div>;

export default PayeePayorCardView;