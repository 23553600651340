import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import PeopleLogo from 'src/components/logos/PeopleLogo';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import Header from 'src/layouts/header/Header';
import { useStore } from 'src/store';
import AMLSection from './amlSection/AMLSection';
import LBASection from './lbaSection/LBASection';
import ModuleActionsSection from './moduleActionsSection/ModuleActionsSection';
import OverallActionSection from './overallActionSection/OverallActionSection';

/**
 * We want to be able to support bizId, for Hopscotch email notification links
 */
const CaseBizVerificationDetailPage: React.FC = () => {
    const { bizId } = useParams();
    const { CaseVerificationDetailPageStore } = useStore();
    const fetch = useFetchLegacy();
    const verificationStatus = CaseVerificationDetailPageStore.getVerificationStatus();
    const caseId = CaseVerificationDetailPageStore.getCaseId();

    useEffect(() => {
        if (bizId) { CaseVerificationDetailPageStore.fetchCaseDetailsByBizId(fetch, bizId); }
    }, [CaseVerificationDetailPageStore, bizId]);

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <Header icon={<PeopleLogo width={18} height={18} />} title="KYB/KYC Verification report" id={caseId} verificationStatus={verificationStatus} />
                        <AMLSection />
                        <ModuleActionsSection />
                        <LBASection />
                        <OverallActionSection />
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
};

export default CaseBizVerificationDetailPage;