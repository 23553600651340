import React from 'react';
import styles from './LinkButtonWrapper.module.scss';

type LinkButtonWrapperProps = {
    children: any;
};

const LinkButtonWrapper: React.FC<LinkButtonWrapperProps> = ({ children }): JSX.Element =>
    <div className={styles.wrapper}>
        <div className={styles.container}>
            {children}
        </div>
    </div>;

export default LinkButtonWrapper;