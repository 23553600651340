import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import HRLine from 'src/components/base/hrLine/HRLine';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import PlanAuditHistoryPopup from 'src/components/business/planAuditHistoryPopup/PlanAuditHistoryPopup';
import PageTitle from 'src/components/pageTitle/PageTitle';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import styles from 'src/pages/paidPlanControls/PaidPlanControls.module.scss';
import { useStore } from 'src/store';
import FeatureList from './featureList/FeatureList';
import FeatureTab from './featureTab/FeatureTab';
import { PlanTypeEnums } from './PaidPlanControlsStore';
import PlanDetails from './planDetails/PlanDetails';
import UpdatePlanPopup from './updatePlanPopup/UpdatePlanPopup';

// @TODO :- remove when api becomes available, Keeping this for static data as of now
const DEFAULT_PLAN_DETAILS = {
    trialLength: '1 month',
    planCost: '$0',
    annualDiscount: '0',
    cardFeeCharged: '2.9%',
    totalAccounts: 24
};

const PaidPlanControls: React.FC = observer(() => {
    const fetch = useFetchLegacy();
    const { PaidPlanControlsStore } = useStore();
    const [open, setOpen] = useState(false);
    const [isOpenHistoryModal, setHistoryModal] = useState(false);

    // @TODO :- remove/update when api becomes available
    const planDetails = DEFAULT_PLAN_DETAILS;

    useEffect(() => {
        PaidPlanControlsStore.fetchFreemiumPlan(fetch);
        PaidPlanControlsStore.fetchPlanAudits(fetch);
        PaidPlanControlsStore.setIsFreemiumPlan(true);
    }, [PaidPlanControlsStore]);

    const freemiumPlan = PaidPlanControlsStore.getFreemiumPlan();
    const premiumPlan = PaidPlanControlsStore.getPremiumPlan();
    const freePlan = PaidPlanControlsStore.getFreePlan();
    const freeTrialNoPayPlan = PaidPlanControlsStore.getFreeTrialNoPayPlan();

    const isFreemiumPlanShown = PaidPlanControlsStore.getIsFreemiumPlan();
    const isPremiumPlanShown = PaidPlanControlsStore.getIsPremiumPlan();
    const isFreePlanShown = PaidPlanControlsStore.getIsFreePlan();
    const isFreeTrialNoPayPlanShown = PaidPlanControlsStore.getIsFreeTrialNoPayPlan();

    const handleFreemiumPlanClick = () => {
        PaidPlanControlsStore.fetchFreemiumPlan(fetch);
        PaidPlanControlsStore.setIsFreemiumPlan(true);
    };

    const handlePremiumPlanClick = () => {
        PaidPlanControlsStore.fetchPremiumPlan(fetch);
        PaidPlanControlsStore.setIsPremiumPlan(true);
    };

    const handleFreePlanClick = () => {
        PaidPlanControlsStore.fetchFreePlan(fetch);
        PaidPlanControlsStore.setIsFreePlan(true);
    };

    const handleFreeTrialPlanClick = () => {
        PaidPlanControlsStore.fetchFreeTrialNoPayPlan(fetch);
        PaidPlanControlsStore.setIsFreeTrialNoPayPlan(true);
    };

    const openModal = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updatePlanTypeStore = (value: boolean, featureId: string, planType: string) => {
        PaidPlanControlsStore.updatePlanType(value, featureId, planType);
    };

    const handleUpdatePlan = () => {
        PaidPlanControlsStore.updatePlan(fetch);
        handleClose();
    };

    const onHandleHistoryModal = useCallback((value: boolean) => {
        setHistoryModal(value);
    }, []);

    const downloadReport = useCallback(() => {
        PaidPlanControlsStore.fetchPlanAuditsCSV(fetch);
    }, []);

    return (
        <>
            <EntireLayout>
                <Content>
                    <PageTitle title="Paid plan feature toggle controls" />
                    <Row>
                        <Section>
                            <div className={classnames(styles.row, styles.containerRow)}>
                                <FeatureTab label="Hopscotch" isPlanTypeShown={isFreePlanShown} handleClick={handleFreePlanClick} />
                                <FeatureTab label="Pro (Free trial)" isPlanTypeShown={isFreeTrialNoPayPlanShown} handleClick={handleFreeTrialPlanClick} />
                                <FeatureTab label="Pro (Monthly)" isPlanTypeShown={isFreemiumPlanShown} handleClick={handleFreemiumPlanClick} />
                                <FeatureTab label="Pro (Annual)" isPlanTypeShown={isPremiumPlanShown} handleClick={handlePremiumPlanClick} />
                            </div>
                            <HRLine />
                            <FeatureList updatePlanType={updatePlanTypeStore} isPlanShown={isFreePlanShown} planType={PlanTypeEnums.freePlan} planFeatures={freePlan} />
                            <FeatureList updatePlanType={updatePlanTypeStore} isPlanShown={isFreeTrialNoPayPlanShown} planType={PlanTypeEnums.freeTrialNoPayPlan} planFeatures={freeTrialNoPayPlan} />
                            <FeatureList updatePlanType={updatePlanTypeStore} isPlanShown={isFreemiumPlanShown} planType={PlanTypeEnums.freemiumPlan} planFeatures={freemiumPlan} />
                            <FeatureList updatePlanType={updatePlanTypeStore} isPlanShown={isPremiumPlanShown} planType={PlanTypeEnums.premiumPlan} planFeatures={premiumPlan} />

                            {/* @TODO :- Pass plan details here as props later on when api becomes available */}
                            <PlanDetails planDetails={planDetails} openModal={openModal} onHandleHistoryModal={onHandleHistoryModal} />

                        </Section>
                    </Row>
                </Content>

            </EntireLayout>
            <UpdatePlanPopup
                title="Update Plan"
                subtext="Are you sure you want to make this change?"
                popupVisible={open}
                modalId='updatePlan'
                acceptCallback={handleUpdatePlan}
                rejectCallback={handleClose}
            />
            <PlanAuditHistoryPopup
                title="Paid Plan Controls History"
                popupVisible={isOpenHistoryModal}
                hidePopup={() => onHandleHistoryModal(false)}
                downloadReport={downloadReport}
                isShowDownloadReportAction={false}
            />
        </>
    );
});

export default PaidPlanControls;