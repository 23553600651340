import React from 'react';
import { Link } from 'react-router-dom';
import NumberValue from '../numberValue/NumberValue';
import styles from './InfoCard.module.scss';

type InfoCardProps = {
    header: string;
    totalText: string;
    halfOfTotalText?: string;
    totalNum: number;
    logo: React.ReactNode;
    route?: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ route, header, totalText, totalNum, halfOfTotalText = '', logo }) => (
    route ?
        <div className={styles.contentContainer}>
            <Link to={route} className={styles.link}>
                <div className={styles.content}>
                    {logo}
                    <h3 className={styles.containerHeader}>{header}</h3>
                </div>
                <div className={styles.totalContainer}>
                    <h5 className={styles.totalText}>{totalText}<br />{halfOfTotalText ? `${halfOfTotalText}:` : ''}</h5>
                    <h2 className={styles.totalNum}><NumberValue>{totalNum}</NumberValue></h2>
                </div>
            </Link>
        </div> :
        <div className={styles.contentWrapper}>
            <div className={styles.content}>
                {logo}
                <h3 className={styles.containerHeader}>{header}</h3>
            </div>
            <div className={styles.totalContainer}>
                <h5 className={styles.totalText}>{totalText}<br />{halfOfTotalText ? `${halfOfTotalText}:` : ''}</h5>
                <h2 className={styles.totalsNum}><NumberValue>{totalNum}</NumberValue></h2>
            </div>
        </div>
);

export default InfoCard;