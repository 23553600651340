import React from 'react';
import MenuBarBiz from 'src/components/base/menuBar/menuBarBiz/MenuBarBiz';
import MenuBarEng from 'src/components/base/menuBar/menuBarEng/MenuBarEng';
import useFetchFeatures from '../../store/hooks/features/useFetchFeatures';
import styles from './MenuBarContentLayout.module.scss';

type MenuBarContentLayoutProps = {
    children: JSX.Element

}

const MenuBarContentLayout: React.FC<MenuBarContentLayoutProps> = ({ children }) => {
    const isBiz = window.location.href.search(/biz/i);
    useFetchFeatures();

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.menubarDiv}>
                {isBiz > -1 ? <MenuBarBiz /> : <MenuBarEng />}
            </div>
            {children}
        </div>
    );
};

export default MenuBarContentLayout;
