// eslint-disable-next-line
import React from 'react';
import CurrencyValue from 'src/components/base/currencyValue/CurrencyValue';
import AccountLink from 'src/components/business/accounts/accountLink/AccountLink';
import { riskScoreType } from 'src/util/interfaces';
import styles from './Overview.module.scss';

type OverviewProps = {
    dbaName: string;
    score: riskScoreType,
    createdTimestamp: string;
    accountLogo: string;
    isVerified: boolean;
    amount: number;
    planType: string;
};

const Overview: React.FC<OverviewProps> = ({ dbaName, score, createdTimestamp, accountLogo, isVerified, amount, planType }) => (
    <div className={styles.infoContent}>
        <div className={styles.accountContainer}>
            <h4 className={styles.accountText}>Account</h4>
            <div className={styles.accountLinkContainer}>
                <AccountLink dbaName={dbaName} score={score} isVerified={isVerified} createdTimestamp={createdTimestamp} accountLogo={accountLogo} planType={planType} />
            </div>
        </div>

        <div className={styles.amountContainer}>
            <h4 className={styles.secondTitle}>Amount</h4>
            <CurrencyValue>{amount}</CurrencyValue>
        </div>
    </div>
);

export default Overview;
