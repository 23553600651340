// eslint-disable-next-line
import cn from 'classnames';
import React from 'react';
import { CurrencyValue } from 'src/components';
import { getInCurrencyFormat } from 'src/util';
import styles from './PayableReceivableBlock.module.scss';

type Props = {
    balance: number;
    type: string;
    pastDue: number;
    nextSevenDays: number;
}

const PayableReceivableBlock: React.FC<Props> = ({ balance, type, pastDue, nextSevenDays }) => (
    <div className={styles.balanceContainer}>
        <p className={styles.smallRow}>{type} Balance</p>
        <div className={styles.currencyWrapper}>
            <CurrencyValue>{balance}</CurrencyValue>
        </div>
        <p className={cn(styles.smallRow, styles.smallGreyText)}>Past due: {getInCurrencyFormat(pastDue)}</p>
        <p className={cn(styles.smallRow, styles.smallGreyText)}>Next 7 days: {getInCurrencyFormat(nextSevenDays)}</p>
    </div>
);

export default PayableReceivableBlock;