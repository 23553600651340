import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import BaseConfirmationPopup from 'src/components/base/popups/baseConfirmationPopup/BaseConfirmationPopup';
import { MARK_AS_PAID, REVERSE, WRITEOFF } from 'src/Constant';
import { useStore } from 'src/store';
import MarkedAsPaidOverview from './factoredContent/overview/markedAsPaidOverview/MarkedAsPaidOverview';
import Overview from './factoredContent/overview/Overview';
import ReversalOverview from './factoredContent/overview/reversalOverview/ReversalOverview';
import styles from './FactoredPopup.module.scss';

type FactoredPopupProps = {
    popupVisible: boolean;
    hidePopup: any;
    modalStatus: string;
    invoiceId?: string;
    updateCallback: any;
};

const FactoredPopup: React.FC<FactoredPopupProps> = observer(({ popupVisible, hidePopup, modalStatus, invoiceId, updateCallback }) => {
    const { FactoredInvoiceDetailsPageStore } = useStore();
    const modalDetails = FactoredInvoiceDetailsPageStore.getModalDetails(modalStatus);
    const { invoiceAmount, amountFactored } = FactoredInvoiceDetailsPageStore.getInvoiceMonetaryDetails();

    const overview = useMemo(() => {
        if (modalStatus === REVERSE) {
            return <ReversalOverview
                currentStatus={modalDetails.currentStatus}
                updatedStatus={modalDetails.updatedStatus}
            />;
        }
        if (modalStatus === MARK_AS_PAID) {
            return <MarkedAsPaidOverview
                currentStatus={modalDetails.currentStatus}
                updatedStatus={modalDetails.updatedStatus}
            />;
        }
        return <Overview
            accountLogo={modalDetails.accountLogo}
            dbaName={modalDetails.dbaName}
            score={modalDetails.score}
            createdTimestamp={modalDetails.createdTimestamp}
            isVerified={modalDetails.isVerified}
            amount={modalStatus === WRITEOFF ? invoiceAmount : amountFactored}
            planType={modalDetails.planType}
        />;
    }, [modalStatus, modalDetails, amountFactored, invoiceAmount]);

    return (
        <BaseConfirmationPopup
            modalTitle={modalDetails.title}
            popupVisible={popupVisible}
            hidePopup={hidePopup}
            modalId={invoiceId}
            updateCallback={updateCallback}
            reason={FactoredInvoiceDetailsPageStore.form.reason}
        >
            <h4 className={styles.explanation}>
                {modalDetails.explanation}
            </h4>
            {overview}
            <br />
        </BaseConfirmationPopup>
    );
});

export default FactoredPopup;