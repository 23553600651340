import { makeAutoObservable } from 'mobx';
import { FetchInterface } from 'src/api/useFetch';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { TRANSACTION_SEARCH_COUNT_URL, TRANSACTION_SEARCH_URL } from 'src/Constant';
import { SearchTransaction, SearchTransactionDto, TransactionSearchCount, TRANSACTION_LINK } from 'src/pages/transactionSearchPage/TransactionsSearchPageStoreInterface';
import { SearchPaymentType } from 'src/util/interfaces';
import { selectSearchTransaction } from 'src/util/selectors';

export interface TransactionsSearchPageStoreInterface {
    activeTransactionsLink: string,
    transactions: SearchTransaction[];
    getTransactions(): SearchTransaction[];
    getCounts(): TransactionSearchCount;
    fetchTransactionCounts(fetch: FetchInterface<number>): void
    fetchTransactions(fetch: FetchInterface<SearchTransaction[]>, type: string): void;
}

const TransactionsSearchPageStore = (): TransactionsSearchPageStoreInterface =>
    makeAutoObservable({
        activeTransactionsLink: TRANSACTION_LINK.flagged,
        transactions: [] as SearchTransaction[],
        flaggedCount: 0,
        returnedCount: 0,
        failedCount: 0,
        allCount: 0,
        cardWaitingApprovalCount: 0,
        chargebackCount: 0,
        getTransactions(): SearchTransaction[] {
            return this.transactions;
        },
        getCounts(): TransactionSearchCount {
            return {
                flaggedCount: this.flaggedCount,
                returnedCount: this.returnedCount,
                allCount: this.allCount,
                chargebackCount: this.chargebackCount,
                failedCount: this.failedCount,
                cardWaitingApprovalCount: this.cardWaitingApprovalCount,
            };
        },
        fetchTransactionCounts(fetch: FetchInterfaceLegacy): void {
            fetch({ url: TRANSACTION_SEARCH_COUNT_URL(SearchPaymentType.flagged) },
                ({ data }) => {
                    this.flaggedCount = data;
                });
            fetch({ url: TRANSACTION_SEARCH_COUNT_URL(SearchPaymentType.returned) },
                ({ data }) => {
                    this.returnedCount = data;
                });
            fetch({ url: TRANSACTION_SEARCH_COUNT_URL() },
                ({ data }) => {
                    this.allCount = data;
                });
            fetch({ url: TRANSACTION_SEARCH_COUNT_URL(SearchPaymentType.chargeback) },
                ({ data }) => {
                    this.chargebackCount = data;
                });
            fetch({ url: TRANSACTION_SEARCH_COUNT_URL(SearchPaymentType.failed) },
                ({ data }) => {
                    this.failedCount = data;
                });
            fetch({ url: TRANSACTION_SEARCH_COUNT_URL(SearchPaymentType.cardWaitingApproval) },
                ({ data }) => {
                    this.cardWaitingApprovalCount = data;
                });
        },
        fetchTransactions(fetch: FetchInterface<SearchTransaction[]>, type: string): void {
            if (type === 'all') {
                fetch(
                    { url: TRANSACTION_SEARCH_URL(undefined) },
                    ({ data }) => {
                        this.transactions = data.map((theTransaction: SearchTransactionDto) => selectSearchTransaction(theTransaction));
                        this.activeTransactionsLink = TRANSACTION_LINK.flagged;
                    },
                    true
                );
            } else {
                fetch({ url: TRANSACTION_SEARCH_URL(type) },
                    ({ data }) => {
                        this.transactions = data.map((theTransaction: SearchTransactionDto) => selectSearchTransaction(theTransaction));
                        this.activeTransactionsLink = TRANSACTION_LINK.flagged;
                    },
                    true
                );
            }
        },
    });

export default TransactionsSearchPageStore;