import classnames from 'classnames';
import React, { memo } from 'react';

import { PlanTypes, PLAN_NAMES_SHORT } from 'src/Constant';

import Styles from 'src/components/business/planType/PlanType.module.scss';

type IPlanTypeProps = {
    planLabel: string;
}

const PlanType: React.FC<IPlanTypeProps> = memo((props: IPlanTypeProps) => {
    const { planLabel } = props;

    return (
        <span
            className={classnames(Styles['plan-type'], {
                [Styles.basic]: planLabel === PLAN_NAMES_SHORT[PlanTypes.basic],
                [Styles.freemium]: planLabel === PLAN_NAMES_SHORT[PlanTypes.freemium],
                [Styles['premium-monthly']]: planLabel === PLAN_NAMES_SHORT[PlanTypes.premiumMonthly],
                [Styles['premium-annually']]: planLabel === PLAN_NAMES_SHORT[PlanTypes.premiumYearly],
            })}
        >
            {planLabel}
        </span>
    );
});

export default PlanType;