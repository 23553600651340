import Moment from 'moment';
import React from 'react';
import styles from 'src/pages/invoiceDetailsPage/invoiceDetailsAuditPopup/InvoiceDetailsAuditPopup.module.scss';

export interface props {
    audit: {
        id: string,
        modalId: string,
        date: string,
        operator: string,
        operationType: string,
        catergory: string,
        subCategory: string,
        operationReason: string,
    }
}

const InvoiceDetailAuditItem: React.FC<props> = ({ audit }) => {
    const formattedDate = Moment(audit.date).format('MMMM DD, YYYY');
    const operator = audit.operator;
    const operationReason = audit.operationReason;
    const operationType = audit.operationType;
    const catergory = audit.catergory;
    const subCategory = audit.subCategory;

    return (
        <div className={styles.container} key={audit.id} id={audit.modalId}>
            <div>
                <h4 className={styles.title}>
                    {formattedDate} - {operator} <br />
                    Operation type: {operationType}
                    {catergory && <span>, Category: {catergory}</span>}
                    {subCategory && <span>, Subcatergory: {subCategory}</span>}
                </h4>
                <p>{operationReason}</p>
            </div>
        </div>
    );
};

export default InvoiceDetailAuditItem;