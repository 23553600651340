import React from 'react';
import { BlockButton } from 'src/components';
import { PaymentType } from '../../../util/interfaces';
import GreyLabel from '../../base/greyLabel/GreyLabel';
import styles from './LBASmallBlock.module.scss';

type LBASmallBlockProps = {
    institutionName: string;
    accountNumber: string;
    isBackupAccount: boolean;
    isBlocked: boolean;
    isOps: boolean;
    paymentType: keyof typeof PaymentType;
}

const LBASmallBlock: React.FC<LBASmallBlockProps> = ({ institutionName, accountNumber, isBackupAccount, isBlocked, isOps, paymentType }) => (
    <div className={styles.accountWrapperContainer}>
        <div className={styles.accountDataContainer}>
            <h5 className={styles.accountText} data-testid="institutionName">{institutionName}</h5>
            <h5 className={styles.accountText} data-testid="accountNumber">xxxx{accountNumber}</h5>
        </div>
        <div className={styles.blockUnblockContainer}>
            <BlockButton isBlocked={isBlocked} isOps={isOps} paymentType={paymentType} />
        </div>
        <div className={styles.backupContainer}>
            {isBackupAccount && <GreyLabel label='Backup' />}
        </div>
    </div>
);

export default LBASmallBlock;