import { PDFExport } from '@progress/kendo-react-pdf';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import CurrencyValue from 'src/components/base/currencyValue/CurrencyValue';
import HRLine from 'src/components/base/hrLine/HRLine';
import { TRANSACTION_DETAILS_URL } from 'src/Constant';
import Gap from 'src/layouts/gap/Gap';
import InvoiceItem from 'src/pages/invoiceDetailsPage/sections/invoiceSection/InvoiceItem';
import { useStore } from 'src/store';
import { getInCurrencyFormat } from 'src/util';
import styles from './InvoiceSection.module.scss';

export interface InvoiceItem {
    id: string,
    description: string,
    qty: number;
    rate: number,
    amount: number,
}

const InvoicesSection: React.FC = observer(() => {
    const { InvoiceDetailsPageStore } = useStore();
    const invoices = InvoiceDetailsPageStore.getInvoiceItems();
    const { totalDetails } = InvoiceDetailsPageStore.getInvoiceTotalDetails();
    const transactionId = InvoiceDetailsPageStore.getTransactionId();

    const pdfExportComponent = useRef<PDFExport>(null);
    const handlePdfExport = () => pdfExportComponent?.current?.save();

    return (
        <>
            <div className={styles.headerContainer}>
                <h4>Details</h4>
                <div className={styles.linksContainer}>
                    <button type="button" className={styles.pdfLink} onClick={handlePdfExport}>
                        Invoice PDF
                    </button>
                    {transactionId !== null && <Link className={styles.link} to={`${TRANSACTION_DETAILS_URL}/${transactionId}`}>Link to payment</Link>}
                </div>
            </div>

            <HRLine />
            <Gap />

            <PDFExport ref={pdfExportComponent} paperSize="A4" landscape={true} fileName='Invoice.pdf'>
                <div className={styles.invoicesTitleContainer}>
                    <div className={styles.titleWrapper}>
                        <h4>Description</h4>
                        <h4 className={styles.qtyTitle}>Qty</h4>
                    </div>
                    <div className={styles.titleWrapper}>
                        <h4>Rate</h4>
                        <h4>Amount</h4>
                    </div>
                </div>

                <HRLine />
                <div className={styles.wrapper}>
                    {invoices.map((theInvoice: any) => (
                        <InvoiceItem invoice={theInvoice} key={theInvoice.id} />
                    ))}
                </div>
                <div className={styles.totalContainer}>
                    <HRLine />
                    <Gap />
                    <div className={styles.itemWrapper}>
                        <h4 className={styles.item}>Sub-total</h4>
                        <h4>{getInCurrencyFormat(totalDetails.subTotal)}</h4>
                    </div>
                    <div className={styles.itemWrapper}>
                        <h4 className={styles.item}>Tax</h4>
                        <h4>{getInCurrencyFormat(totalDetails.tax)}</h4>
                    </div>
                    <HRLine />
                    <div className={styles.itemWrapper}>
                        <h3>Total</h3>
                        <h4><CurrencyValue>{totalDetails.total}</CurrencyValue></h4>
                    </div>
                </div>
            </PDFExport>
        </>
    );
});

export default InvoicesSection;