import React from 'react';

const InvoicesIcon = (): JSX.Element => (
    <svg fill="#030D45" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 64 64">
        <path d="M61 28.94C61.0041 28.8968 61.0041 28.8532 61 28.81C61.0057 28.7669 61.0057 28.7231 61 28.68C61.0058 28.6402 61.0058 28.5998 61 28.56C61 28.56 61 28.56 61 28.5C61 28.44 61 28.5 61 28.5C60.9759 28.4616 60.9492 28.4248 60.92 28.39C60.8916 28.3549 60.8579 28.3246 60.82 28.3C60.7881 28.2647 60.751 28.2344 60.71 28.21V28.21L53 23.85V4C53 3.73478 52.8947 3.48043 52.7072 3.29289C52.5196 3.10536 52.2653 3 52 3H12C11.7348 3 11.4805 3.10536 11.2929 3.29289C11.1054 3.48043 11 3.73478 11 4V23.85L3.50005 28.13C3.45912 28.1544 3.42205 28.1847 3.39005 28.22L3.29005 28.31C3.26092 28.3448 3.23419 28.3816 3.21005 28.42V28.42C3.21005 28.42 3.21005 28.42 3.21005 28.48C3.2043 28.5198 3.2043 28.5602 3.21005 28.6C3.20439 28.6431 3.20439 28.6869 3.21005 28.73C3.20596 28.7732 3.20596 28.8168 3.21005 28.86C3.21005 28.86 3.00005 29 3.00005 29V60C3.00005 60 3.00005 60.08 3.00005 60.12C2.98973 60.1863 2.98973 60.2537 3.00005 60.32C3.0197 60.3877 3.05009 60.4519 3.09005 60.51C3.09177 60.5466 3.09177 60.5834 3.09005 60.62C3.16423 60.6974 3.24829 60.7646 3.34005 60.82L3.43005 60.88C3.60614 60.9696 3.80279 61.011 4.00005 61H60C60.2653 61 60.5196 60.8946 60.7072 60.7071C60.8947 60.5196 61 60.2652 61 60V29C61 29 61 29 61 28.94ZM5.00005 30.72L25.1 42.21L5.00005 58V30.72ZM27 43.28L31.52 45.87L31.77 45.97C31.8453 45.9889 31.9225 45.999 32 46C32.0831 46.0105 32.167 46.0105 32.25 46L32.5 45.9L37 43.32L57 59H6.90005L27 43.28ZM38.85 42.28L59 30.72V58L38.85 42.28ZM58 29L53 31.85V26.15L58 29ZM51 5V33L36.7701 41.12C36.5905 41.1698 36.4258 41.2625 36.2901 41.39L32 43.85L13 33V5H51ZM11 31.85L6.00005 29L11 26.15V31.85Z" fill="#030D45" />
        <path d="M28.5801 16.0701H45.6801C45.9453 16.0701 46.1996 15.9647 46.3872 15.7772C46.5747 15.5896 46.6801 15.3353 46.6801 15.0701C46.6801 14.8049 46.5747 14.5505 46.3872 14.363C46.1996 14.1754 45.9453 14.0701 45.6801 14.0701H28.5801C28.3149 14.0701 28.0605 14.1754 27.873 14.363C27.6854 14.5505 27.5801 14.8049 27.5801 15.0701C27.5801 15.3353 27.6854 15.5896 27.873 15.7772C28.0605 15.9647 28.3149 16.0701 28.5801 16.0701Z" fill="#030D45" />
        <path d="M28.5801 23.21H45.6801C45.9453 23.21 46.1996 23.1046 46.3872 22.9171C46.5747 22.7295 46.6801 22.4752 46.6801 22.21C46.6801 21.9447 46.5747 21.6904 46.3872 21.5029C46.1996 21.3153 45.9453 21.21 45.6801 21.21H28.5801C28.3149 21.21 28.0605 21.3153 27.873 21.5029C27.6854 21.6904 27.5801 21.9447 27.5801 22.21C27.5801 22.4752 27.6854 22.7295 27.873 22.9171C28.0605 23.1046 28.3149 23.21 28.5801 23.21Z" fill="#030D45" />
        <path d="M17.1801 30.3601H45.6801C45.9453 30.3601 46.1996 30.2547 46.3872 30.0672C46.5747 29.8797 46.6801 29.6253 46.6801 29.3601C46.6801 29.0949 46.5747 28.8405 46.3872 28.653C46.1996 28.4655 45.9453 28.3601 45.6801 28.3601H17.1801C16.9148 28.3601 16.6605 28.4655 16.4729 28.653C16.2854 28.8405 16.1801 29.0949 16.1801 29.3601C16.1801 29.6253 16.2854 29.8797 16.4729 30.0672C16.6605 30.2547 16.9148 30.3601 17.1801 30.3601Z" fill="#030D45" />
        <path d="M20.51 22.1599C19.9561 22.1956 19.4105 22.0105 18.9926 21.6451C18.5747 21.2797 18.3185 20.7637 18.28 20.2099C18.2429 19.979 18.1184 19.7712 17.9322 19.6297C17.746 19.4882 17.5124 19.4238 17.28 19.4499C17.0476 19.4238 16.8141 19.4882 16.6279 19.6297C16.4417 19.7712 16.3171 19.979 16.28 20.2099C16.3322 20.9865 16.634 21.7254 17.1404 22.3165C17.6468 22.9076 18.3306 23.3191 19.09 23.4899L19.53 23.6099V25.2299C19.5624 25.4637 19.6854 25.6755 19.8724 25.8195C20.0595 25.9636 20.2957 26.0283 20.53 25.9999C20.7644 26.0283 21.0006 25.9636 21.1876 25.8195C21.3747 25.6755 21.4976 25.4637 21.53 25.2299V23.6099L21.96 23.4799C22.7138 23.307 23.3916 22.8953 23.8925 22.3061C24.3934 21.7169 24.6907 20.9817 24.74 20.2099C24.74 18.2999 22.85 16.7399 20.52 16.7399C19.9677 16.7757 19.4235 16.5919 19.0059 16.2286C18.5883 15.8653 18.331 15.3519 18.29 14.7999C18.331 14.2479 18.5883 13.7344 19.0059 13.3711C19.4235 13.0078 19.9677 12.8241 20.52 12.8599C21.0707 12.8268 21.6123 13.0118 22.0277 13.3748C22.4431 13.7378 22.699 14.2497 22.74 14.7999C22.74 15.0651 22.8454 15.3194 23.0329 15.507C23.2205 15.6945 23.4748 15.7999 23.74 15.7999C24.0052 15.7999 24.2596 15.6945 24.4471 15.507C24.6347 15.3194 24.74 15.0651 24.74 14.7999C24.69 14.0344 24.3974 13.3048 23.9047 12.7168C23.4121 12.1289 22.7449 11.7131 22 11.5299L21.57 11.4099V9.66988C21.5376 9.43602 21.4147 9.22425 21.2276 9.08022C21.0406 8.93618 20.8044 8.87142 20.57 8.89988C20.3357 8.87142 20.0995 8.93618 19.9124 9.08022C19.7254 9.22425 19.6024 9.43602 19.57 9.66988V11.3999L19.13 11.5299C18.3715 11.6986 17.6878 12.1083 17.1814 12.6977C16.6749 13.2871 16.3727 14.0246 16.32 14.7999C16.32 16.7099 18.22 18.2699 20.55 18.2699C21.1007 18.2368 21.6423 18.4218 22.0577 18.7848C22.4731 19.1478 22.729 19.6597 22.77 20.2099C22.7511 20.4868 22.6774 20.7572 22.5532 21.0055C22.4291 21.2538 22.257 21.475 22.0468 21.6563C21.8366 21.8377 21.5926 21.9755 21.3288 22.062C21.0651 22.1484 20.7868 22.1817 20.51 22.1599V22.1599Z" fill="#030D45" />
    </svg>
);

export default InvoicesIcon;
