/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { encode } from 'base-64';
import { Logger } from 'src/util';
import {
    ACCOUNTS_CSV_API,
    ACCOUNT_AUDIT_HISTORY_API,
    ALL_ACCOUNT_DETAILS_URL,
    AUDIT_CSV_API,
    BUSINESS_ACCOUNTS_CSV_API,
    CLIENT_ID,
    CLIENT_SECRET,
    FACTORED_INVOICES_URL,
    FACTORED_INVOICE_DETAILS_API,
    FLAGGED_TRANSACTIONS_SEARCH_URL,
    GRANT_TYPE,
    LBA_DISCONNECT_CSV_API,
    LIMITS_CSV_API,
    LOGIN_URL,
    REDIRECT_URI,
    RETURNED_TRANSACTIONS_URL,
    TOKEN_URI,
    TRANSACTIONS_CSV_API,
    TRANSACTIONS_URL,
    TRANSACTION_DETAILS_API,
    VERIFIED_DISCONNECT_CSV_API
} from '../Constant';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const qsPackage = require('qs');

export const getUserDetails = async (accessToken: string, idToken: string): Promise<any> => {
    Logger.info(`getUserDetails - Access token is ${accessToken}`);
    const details = {
        headers: {
            // prettier-ignore
            'Authorization': `Bearer  ${idToken}`,
            'Access-Token': accessToken,
        },
    };

    return axios.get(LOGIN_URL, details);
};

export const exchangeCodeForTokens = async (authCode: string): Promise<any> => {
    const codeToBase = `${CLIENT_ID}:${CLIENT_SECRET}`;
    const encodedString = encode(codeToBase);

    const details = {
        client_id: CLIENT_ID,
        grant_type: GRANT_TYPE,
        redirect_uri: REDIRECT_URI,
        code: authCode,
    };
    return axios({
        method: 'POST',
        url: TOKEN_URI,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            // prettier-ignore
            'Authorization': `Basic ${encodedString}`,
        },
        data: qsPackage.stringify(details),
    });
};

export const fetchFlaggedTransactions = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: FLAGGED_TRANSACTIONS_SEARCH_URL,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchReturnedTransactions = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: RETURNED_TRANSACTIONS_URL,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchAllTransactions = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: TRANSACTIONS_URL,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchAllAccountDetails = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: ALL_ACCOUNT_DETAILS_URL,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchTransactionDetails = async (accessToken: string, transactionId: string): Promise<any> =>
    axios({
        method: 'GET',
        url: TRANSACTION_DETAILS_API(transactionId),
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchTransactionsCSV = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: TRANSACTIONS_CSV_API,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchBusinessAccountsCSV = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: BUSINESS_ACCOUNTS_CSV_API,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchLimitsCSV = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: LIMITS_CSV_API,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchVerificationsCSV = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: ACCOUNTS_CSV_API,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchAuditCSV = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: AUDIT_CSV_API,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchLbaDisconnectCSV = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: LBA_DISCONNECT_CSV_API,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchVerifiedDisconnectCSV = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: VERIFIED_DISCONNECT_CSV_API,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchAccountAuditHistory = async (accessToken: string, caseId: string): Promise<any> =>
    axios({
        method: 'GET',
        url: ACCOUNT_AUDIT_HISTORY_API(caseId),
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchFactoredInvoices = async (accessToken: string): Promise<any> =>
    axios({
        method: 'GET',
        url: FACTORED_INVOICES_URL,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const fetchFactoredInvoiceDetails = async (accessToken: string, invoiceId: string): Promise<any> =>
    axios({
        method: 'GET',
        url: FACTORED_INVOICE_DETAILS_API(invoiceId),
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
