import { makeAutoObservable } from 'mobx';
import { ALLOW_FLOW_FEATURE_FOR_BUSINESS, FACTORED_RISK_URL, TOAST_FAILURE_TEXT, TOAST_SUCCESS_TEXT, UPDATE_FACTORED_RISK_URL } from 'src/Constant';
import { FetchInterface } from 'src/api/useFetch';
import { FactoredRiskLabelToAttribute } from 'src/util/FactoredRisk';
import { AllowBusinessForFlowFeature, FactoredRiskModelPageInterface } from './FactoredRiskModelPageInterface';


export interface FactoredRiskModelPageStoreInterface {
    fetchRiskModelData(fetch: FetchInterface<FactoredRiskModelPageInterface>): void;
    updateRiskModelData(fetch: FetchInterface<FactoredRiskModelPageInterface>): void;
    setRiskModelValue(label: string, value: number): void;
    riskModel: FactoredRiskModelPageInterface;
    allowBusinessForFlowFeature(fetch: FetchInterface<AllowBusinessForFlowFeature>): void;
    setAllowBusinessForFlowFeature(business: string): void;
    allowBusiness: string
}

const FactoredRiskModelPageStore = (): FactoredRiskModelPageStoreInterface =>
    makeAutoObservable({
        riskModel: {} as FactoredRiskModelPageInterface,
        allowBusiness: '',
        fetchRiskModelData(fetch: FetchInterface<FactoredRiskModelPageInterface>) {
            fetch({ url: FACTORED_RISK_URL, method: 'GET' }, ({ data }) => {
                this.riskModel = data;
            });
        },
        updateRiskModelData(fetch: FetchInterface<FactoredRiskModelPageInterface>) {
            fetch({
                url: UPDATE_FACTORED_RISK_URL(this.riskModel.id),
                method: 'PUT',
                data: this.riskModel
            },
                () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        },

        allowBusinessForFlowFeature(fetch: FetchInterface<AllowBusinessForFlowFeature>) {
            fetch({
                url: ALLOW_FLOW_FEATURE_FOR_BUSINESS,
                method: 'POST',
                data: {
                    bizIds: this.allowBusiness
                }
            },
                () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        },

        setRiskModelValue(label: string, value: number): void {
            const attribute = FactoredRiskLabelToAttribute[label];
            this.riskModel = { ...this.riskModel, [attribute]: value };
        },
        setAllowBusinessForFlowFeature(business: string) {
            this.allowBusiness = business;
        }
    });

export default FactoredRiskModelPageStore;
