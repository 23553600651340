// eslint-disable-next-line id-length
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Label from 'src/components/base/label/Label';
import Toggles from 'src/components/base/Toggles';
import styles from './ToggleContainer.module.scss';

type Props = {
    label?: string;
    checked: boolean;
    onHandleChange: any;
    description?: string;
    disabled?: boolean;
    classNames?: string;
}

const ToggleContainer: React.FC<Props> = observer(({
    label,
    checked,
    onHandleChange,
    description = '',
    disabled = false,
    classNames }) => (
    <div className={cn(styles.actionContainer, classNames)}>
        <div className={styles.titleToggleContainer}>
            <Label>{label}</Label>
            <Toggles
                checked={checked}
                disabled={disabled}
                onChange={() => { onHandleChange(); }}
            />
        </div>
        <p className={styles.smallGreyText}>{description}</p>
    </div>
));

export default ToggleContainer;