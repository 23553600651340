/* eslint-disable deprecation/deprecation */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { CASE_DETAILS_BY_BIZ_ID_URL, CASE_DETAILS_URL, TOAST_AML_VERIFICATION_TEXT, TOAST_FAILURE_TEXT, UPDATE_CASE_DETAILS_URL, UPDATE_CASE_PENDING_STATUS_URL } from 'src/Constant';
import getPlanName from 'src/util/PlanUtils';
import { selectLinkedAccountDetails } from 'src/util/selectors';
import { Business, BusinessModule, Customer, CustomerModule, labelReview } from './CaseVerificationDetailPageStoreInterface';

export type CaseVerificationDetailPageStoreInterface = {
    sharedStore: {
        getAuditDropdownCategory: () => string,
        getAuditDropdownSubCategory: () => string,
        setAuditDropdownCategory(category: string): void
    },
    form: {
        reason: string,
        id: string,
        status: string,
        account: {
            verificationStatus: string
        },
        score: string,
        decision: string,
        customers: Customer[],
        business: Business,
        audit: {
            category: string,
            subCategory: string,
        }
    }
    selectedCustomerID: string,
    isFormReady: boolean,
    planType: string,
    case:
    {
        createdTimestamp: number,
        status: string,
        account: {
            username: string,
            email: string,
            userId: string,
            bizId: string
        },
        score: string,
        decision: string,
        verificationReason: string,
        id: string,
        documentStatus: string,
        pendingStatus: string,
        customersAndActions: any,
        business: Business,
        customers: Customer[],
        businessActions: [{ message: 'problem', reviewed: 'problem' }],
    },
    account: {
        bizId: string,
        userId: string,
        name: string,
        dbaName: string,
        legalBusinessName: string,
        email: string,
        caseStatus: string,
        hopscotchBalance: number,
        columnBalance: number,
        invoiceLimit: number,
        factoringLimit: number,
        hasInvoiceLimitBeenUpdated: boolean,
        transactionVolume: number,
        vendorId: string,
        createdTimestamp: number,
        address: {
            address_line1: string,
            address_line2: string,
            city: string,
            state: string,
            postal_code: number,
            country_code: string,
        },
        transactions: [],
        businessLogo: string,
        primaryAccountOwner: string,
        beneficialOwners: [],
        lastUpdatedBy: string,
        lastUpdatedDate: string,
        isFrozen: boolean,
        isLocked: boolean,
        reason: string,
        averageTransactionAmount: number,
        riskScore: string,
        businessType: string,
        website: string,
        industry: string,
        linkedBankAccounts: [{
            id: string,
            isBlocked: boolean,
            ownerEmail: string,
            ownerName: string,
            ownerPhoneNumber: string,
            addressLine1: string,
            addressLine2: string,
            institutionName: string,
            accountNumber: string,
            isDepositAccount: boolean,
            isFundingAccount: boolean,
            address: [],
            ownerNameConfidence: number,
            addressConfidence: number,
            emailConfidence: number,
            phoneNumberConfidence: number,
            availableBalance: number,
            transactionSummary: [],
        }]
    }
    getReviewStatus(moduleLabel: string): any;
    getSelectedCustomer(): any;
    getVerificationStatus(): string;
    getForm(): any;
    getCase(): any;
    getCaseId(): string;
    getCaseStatus(): string;
    getIsAccountVerified(): boolean;
    getCaseDecision(): string;
    getBusinessModules(): any;
    getDocumentStatus(): string;
    getPendingStatus(): string;
    getScore(): string
    getFirstLBADetails(): any;
    setScore(score: string): void;
    setSelectedCustomerID(customerID: string): void;
    setCustomerReviewed(labelText: string): any;
    setBusinessReviewed(message: string): any;
    getDecision(): string;
    setDecisionAndStatus(pass: string): void
    getStatus(): string;
    setStatus(overallStatus: string): void
    setReasoning(reason: string): void;
    getReasoning(): string;
    getIsFormReady(): boolean;
    getIsAuditDropdownReady(): boolean;
    isCustomerActionModulesReady(): boolean;
    isBusinessActionModulesReady(): boolean;
    fetchCaseDetails(fetch: FetchInterfaceLegacy, caseId: string): void;
    fetchCaseDetailsByBizId(fetch: FetchInterfaceLegacy, bizId: string): void;
    setPendingStatus(fetch: FetchInterfaceLegacy, status: string): void;
    renderVendorStatus(): string;
    updateCase(fetch: FetchInterfaceLegacy): void;
    cancel(): void;
    handlePayload(info: any): void;
    getCustomerIfPossible(data: { customers?: Customer[] }): any;
    getPlanType(): string;
}


const CaseVerificationDetailPageStore = (sharedStore: {
    getAuditDropdownCategory: () => string,
    getAuditDropdownSubCategory: () => string,
    setAuditDropdownCategory(category: string): void
}): CaseVerificationDetailPageStoreInterface =>
    makeAutoObservable({
        form: {
            reason: '',
            id: '',
            status: '',
            account: {
                verificationStatus: ''
            },
            score: '',
            decision: '',
            customers: [
                {
                    vendorId: '',
                    firstName: '',
                    lastName: '',
                    fullName: '',
                    link: '',
                    email: '',
                    profileUrl: '',
                    actionModules: [
                        { label: '', reviewed: false, message: [], count: 0, status: '' },
                    ]
                },
            ],
            business: {
                id: '',
                name: '',
                dbaName: '',
                link: '',
                email: '',
                website: '',
                vendorStatus: '',
                actionModules: [
                    { label: '', reviewed: true as boolean, message: '', count: 0 },
                ]
            },
            audit: {
                category: sharedStore.getAuditDropdownCategory(),
                subCategory: sharedStore.getAuditDropdownSubCategory(),
            }
        },
        selectedCustomerID: '',
        isFormReady: false,
        case: {
            createdTimestamp: 0,
            status: '',
            account: {
                username: '',
                email: '',
                userId: '',
                bizId: ''
            },
            score: '',
            decision: '',
            verificationReason: '',
            id: '',
            documentStatus: '',
            pendingStatus: 'Ops',
            customersAndActions: new Map(),
            business: {
                id: '',
                name: '',
                dbaName: '',
                link: '',
                email: '',
                website: '',
                vendorStatus: '',
                actionModules: [
                    { label: '', reviewed: true as boolean, message: '', count: 0 },
                ]
            },
            customers: [
                {
                    vendorId: '',
                    firstName: '',
                    lastName: '',
                    fullName: '',
                    link: '',
                    email: '',
                    profileUrl: '',
                    actionModules: [
                        { label: '', reviewed: false, message: [], count: 0, status: '' },
                    ]
                },
            ],
            businessActions: [{ message: 'problem', reviewed: 'problem' }],
        },
        planType: '',
        account: {
            bizId: '',
            userId: '',
            name: '',
            dbaName: '',
            legalBusinessName: '',
            email: '',
            caseStatus: '',
            hopscotchBalance: 0,
            columnBalance: 0,
            invoiceLimit: 0,
            factoringLimit: 0,
            hasInvoiceLimitBeenUpdated: false,
            transactionVolume: 0,
            vendorId: '',
            createdTimestamp: 0,
            address: {
                address_line1: '',
                address_line2: '',
                city: '',
                state: '',
                postal_code: 0,
                country_code: '',
            },
            transactions: [],
            businessLogo: '',
            primaryAccountOwner: '',
            beneficialOwners: [],
            lastUpdatedBy: '',
            lastUpdatedDate: '',
            isFrozen: false,
            isLocked: false,
            reason: '',
            averageTransactionAmount: 0,
            riskScore: '',
            businessType: '',
            website: '',
            industry: '',
            linkedBankAccounts: [{
                id: '',
                isBlocked: false,
                ownerEmail: '',
                ownerName: '',
                ownerPhoneNumber: '',
                addressLine1: '',
                addressLine2: '',
                institutionName: '',
                accountNumber: '',
                isDepositAccount: false,
                isFundingAccount: true,
                address: [],
                ownerNameConfidence: 0,
                addressConfidence: 0,
                emailConfidence: 0,
                phoneNumberConfidence: 0,
                availableBalance: 0,
                transactionSummary: [],
            }],
        },
        getPlanType() {
            return this.planType;
        },
        getReviewStatus(moduleLabel: string) {
            const customer = this.getSelectedCustomer();
            return customer?.actionModules.find((module: { label: string; reviewed: boolean; }) => module.label === moduleLabel);
        },
        getSelectedCustomer() {
            return this.form.customers?.find((customer: { vendorId: string; }) => customer.vendorId === this.selectedCustomerID);
        },
        getVerificationStatus() {
            return this.form.account.verificationStatus;
        },
        getForm() {
            return this.form;
        },
        getCase() {
            return this.case;
        },
        getCaseId() {
            return this.case.id;
        },
        getCaseStatus() {
            return this.form.status;
        },
        getIsAccountVerified(): boolean {
            return this.case.status === 'Pass';
        },
        getCaseDecision() {
            return this.form.decision;
        },
        getBusinessModules() {
            return this.form.business?.actionModules || [];
        },
        getDocumentStatus() {
            return this.case.documentStatus;
        },
        getPendingStatus() {
            return this.case.pendingStatus;
        },
        getScore() {
            return this.form.score;
        },
        getFirstLBADetails() {
            if (this.account?.linkedBankAccounts && this.account.linkedBankAccounts.length > 0) {
                return this.account.linkedBankAccounts[0];
            }
            return [];
        },
        setScore(score: string) {
            this.form.score = score;
        },
        setSelectedCustomerID(customerID: string) {
            this.selectedCustomerID = customerID;
        },
        setCustomerReviewed(labelText: string) {
            const customer = this.getSelectedCustomer();
            if (customer?.actionModules !== undefined) {
                customer.actionModules = customer.actionModules.map((module: CustomerModule) => {
                    if (module.label === labelText) {
                        return { ...module, reviewed: !module.reviewed };
                    }
                    return module;
                });
            }
        },
        setBusinessReviewed(message: string) {
            const businessModules = this.getBusinessModules();
            this.form.business.actionModules = businessModules.map((module: BusinessModule) => {
                if (module.message === message) {
                    return { ...module, reviewed: !module.reviewed };
                }
                return module;
            });
        },
        getDecision() {
            return this.form.decision;
        },
        setDecisionAndStatus(pass: string) {
            this.form.decision = pass;
            this.form.status = pass;
        },
        getStatus() {
            return this.form.status;
        },
        setStatus(overallStatus: string) {
            this.form.status = overallStatus;
        },
        setReasoning(reason: string) {
            this.form.reason = reason;
        },
        getReasoning() {
            return this.form.reason;
        },
        getIsFormReady(): boolean {
            const areCustomersActionModulesReady = this.isCustomerActionModulesReady();
            const areBusinessActionModulesReady = this.isBusinessActionModulesReady();

            if (this.form.decision === 'Pass') {
                return this.getIsAuditDropdownReady()
                    && areCustomersActionModulesReady
                    && areBusinessActionModulesReady;
            } if (this.form.decision === 'Fail' && this.form?.business?.actionModules === null) {
                return this.getIsAuditDropdownReady() && areCustomersActionModulesReady;
            } if (this.form.decision === 'Fail') {
                return this.getIsAuditDropdownReady()
                    && (areCustomersActionModulesReady
                        || areBusinessActionModulesReady);
            }

            return false;
        },
        getIsAuditDropdownReady() {
            return sharedStore.getAuditDropdownSubCategory() !== '';
        },
        isCustomerActionModulesReady(): boolean {
            if (this.form.customers !== null) {
                if (this.form.decision === 'Pass') {
                    if (this.form.customers) {
                        return !this.form.customers
                            .map(formCustomer => formCustomer.actionModules
                                .map(formCustomerModules => formCustomerModules.reviewed)
                                .includes(false))
                            .includes(true);
                    }

                }
                return this.form.customers
                    .map(formCustomer => formCustomer.actionModules
                        .map(formCustomerModules => formCustomerModules.reviewed)
                        .includes(false))
                    .includes(true);
            }
            return true;
        },
        isBusinessActionModulesReady(): boolean {
            if (this.form.business?.actionModules) {
                if (this.form.decision === 'Pass') {
                    return !this.form.business.actionModules
                        .map(actionModule => actionModule.reviewed)
                        .includes(false);
                }
                return this.form.business.actionModules
                    .map(actionModule => actionModule.reviewed)
                    .includes(false);
            }
            return true;
        },
        fetchCaseDetails(fetch: FetchInterfaceLegacy, caseId: string) {
            fetch({ url: CASE_DETAILS_URL(caseId) }, (payload) => this.handlePayload(payload), true);
            // fetch({ url: '/caseDetails.json' }, (payload) => this.handlePayload(payload), true);
        },
        fetchCaseDetailsByBizId(fetch: FetchInterfaceLegacy, bizId: string) {
            fetch({ url: CASE_DETAILS_BY_BIZ_ID_URL(bizId) }, (payload) => this.handlePayload(payload), true);
        },
        setPendingStatus(fetch: FetchInterfaceLegacy, status: string) {
            this.case.pendingStatus = status;
            fetch({ url: UPDATE_CASE_PENDING_STATUS_URL(this.case.id, status), method: 'PUT' }, (payload) => this.handlePayload(payload));
        },
        renderVendorStatus(): string {
            switch (this.case.business?.vendorStatus) {
                case 'in_review':
                    return 'Review';
                case 'open':
                    return 'Open';
                case 'in_audit':
                    return 'Audit';
                case undefined:
                    return '';
                default:
                    return '';
            }
        },
        updateCase(fetch: FetchInterfaceLegacy) {
            const data = {
                business: {
                    ...this.form.business,
                    actionModules: (this.form?.business?.actionModules && this.form.business.actionModules !== null)
                        ? this.form.business.actionModules
                        : []
                },
                customers: this.form.customers,
                decision: this.form.decision,
                verificationReason: this.form.reason,
                score: this.form.score,
                status: this.form.decision,
                pendingStatus: this.case.pendingStatus,
                audit: {
                    category: sharedStore.getAuditDropdownCategory(),
                    subCategory: sharedStore.getAuditDropdownSubCategory(),
                }
            };

            fetch({ url: UPDATE_CASE_DETAILS_URL(this.form.id), method: 'PUT', data }, (payload) => this.handlePayload(payload), true, {
                successMessage: TOAST_AML_VERIFICATION_TEXT,
                failMessage: TOAST_FAILURE_TEXT
            });
        },
        cancel() {
            this.form.customers = this.case.customers.map((customer: any) => ({
                ...customer,
                actionModules: customer.actionModules
                    .map((module: any) => ({
                        label: module.label,
                        reviewed: module.reviewed,
                        decision: module.decision,
                        status: module.status,
                        message: module.message.length > 0 ? module?.message[0] : '',
                        count: module.message.length
                    }))
            }));
            this.form.decision = this.case.decision;
            this.form.score = this.case.score;
            this.form.business = this.case.business;
            this.form.reason = this.case.verificationReason;
            this.isFormReady = false;
            sharedStore.setAuditDropdownCategory('');
        },
        handlePayload({ data }: any) {
            this.form = data;
            this.account = data.account;
            this.account.linkedBankAccounts = data?.account?.linkedBankAccounts?.map((lba: any) => (selectLinkedAccountDetails(lba)));
            this.form.customers = this.getCustomerIfPossible(data);
            this.form.business.email = data.account.email;
            this.form.business.link = data.business.vendorReportUrl;
            this.form.business.vendorStatus = data.business.vendorStatus;
            this.form.decision = data.decision;
            this.form.score = data.score;
            this.form.reason = data.verificationReason;
            this.case = data;
            this.case.account = data.account;
            this.case.documentStatus = 'Not Submitted';
            this.case.businessActions = data.business.actionModules;
            this.isFormReady = false;
            this.case.customers = data.customers;
            this.case.business = data.business;

            const check = new Map();
            data.customers?.forEach((customer: Customer) => {
                const actions = customer.actionModules
                    .map((module: CustomerModule): labelReview => ({ label: module.label, reviewed: module.reviewed }));
                check.set(customer.vendorId, actions);
            });
            this.case.customersAndActions = check;
            this.case.verificationReason = data.verificationReason;
            this.case.pendingStatus = data?.pendingStatus ? data.pendingStatus : 'Ops';
            const plan = (data.account?.subscriptionDetails?.plan || '') as string;
            const billingFrequency = (data.account?.subscriptionDetails?.billingFrequency || '') as string;
            this.planType = getPlanName({ planType: plan, billingFrequency });
        },
        /**
         * There are times when customers come back with nothing. We have seen a particular fraudster make so many accounts that his
         * KYC report never showed up. This makes sure we do not bomb out if this scenario pops back up.
         * @param data payload
         * @returns Array of customers or an empty array.
         */
        getCustomerIfPossible(data: { customers?: Customer[]; }) {
            return (data?.customers) ? data.customers.map((customer: any) => ({
                ...customer,
                actionModules: customer.actionModules
                    .map((module: any) => ({
                        label: module.label,
                        reviewed: module.reviewed,
                        decision: module.decision,
                        status: module.status,
                        message: module.message.length > 0 ? module?.message[0] : '',
                        count: module.message.length
                    }))
            })) : [];
        },
        sharedStore: {
            getAuditDropdownCategory(): string {
                throw new Error('Function not implemented.');
            },
            getAuditDropdownSubCategory(): string {
                throw new Error('Function not implemented.');
            },
            setAuditDropdownCategory(category: string): void {
                throw new Error('Function not implemented.');
            }
        }
    });


export default CaseVerificationDetailPageStore;