// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import styles from './StatusCell.module.scss';

// Account status
const UNRESTRICTED_STATUS = 'Unrestricted';
const RESTRICTED_STATUS = 'Restricted';
const LOCKED_STATUS = 'Locked';

// KYB/KYC Verifications - Hopscotch verification status
const VERIFIED_STATUS = 'Verified';
const ADDITIONAL_DOCS_SUBMITTED_STATUS = 'AdditionalDocsSubmitted';
const ADDITIONAL_DOCS_REQUIRED_STATUS = 'AdditionalDocsRequired';
const DOC_VERIFICATION_STATUS = 'DocVerification';
const INAUDIT_STATUS = 'InAudit';
const MANUAL_REVIEW_STATUS = 'ManualReview';
const DENIED_STATUS = 'Denied';

// Invoices - Invoice status
const PAID_STATUS = 'Paid';
const MARKED_PAID_STATUS = 'MarkedPaid';
const SETTLED_STATUS = 'Settled';
const CANCELLED_STATUS = 'Cancelled';
const REJECTED_STATUS = 'Rejected';
const PENDING_STATUS = 'Pending';

// Payment status
const COMPLETED_STATUS = 'Completed';
const FAILED_STATUS = 'Failed';
const ANALYZING_STATUS = 'Analyzing';
const RETURNED_STATUS = 'Returned';
const FLAGGED_STATUS = 'Flagged';
const RESOLVED_STATUS = 'Resolved';

// Factored Invoice status
const FACTORED_STATUS = 'Factored';
const WRITE_OFF_STATUS = 'Written Off';
const PASTDUE_STATUS = 'Past Due';

// Business status
const PASS_STATUS = 'Pass';
const FAIL_STATUS = 'Fail';
const REVIEW_STATUS = 'Review';

type StatusCellProps = {
    status: string;
}

const StatusCell: React.FC<StatusCellProps> = ({ status }) => <div>
    <p className={cn(styles.defaultColor,
        {
            [styles.isAmber]:
                status === ADDITIONAL_DOCS_SUBMITTED_STATUS
                || status === INAUDIT_STATUS
                || status === DOC_VERIFICATION_STATUS
                || status === FLAGGED_STATUS
                || status === REVIEW_STATUS
                || status === PENDING_STATUS
                || status === RESTRICTED_STATUS
                || status === ANALYZING_STATUS
                || status === FACTORED_STATUS,
            [styles.isDarkAmber]:
                status === MANUAL_REVIEW_STATUS
                || status === ADDITIONAL_DOCS_REQUIRED_STATUS,
            [styles.isRed]:
                status === RETURNED_STATUS
                || status === LOCKED_STATUS
                || status === FAIL_STATUS
                || status === DENIED_STATUS
                || status === CANCELLED_STATUS
                || status === REJECTED_STATUS
                || status === FAILED_STATUS
                || status === PASTDUE_STATUS
                || status === WRITE_OFF_STATUS,
            [styles.isGreen]:
                status === PASS_STATUS
                || status === UNRESTRICTED_STATUS
                || status === VERIFIED_STATUS
                || status === PAID_STATUS
                || status === MARKED_PAID_STATUS
                || status === SETTLED_STATUS
                || status === COMPLETED_STATUS
                || status === RESOLVED_STATUS,
        })}>{status}</p>
</div >;

export default StatusCell;