import { Account } from '../AccountDetailsPageStoreInterface';
import { PaymentTypeTab } from '../sections/accountActionsSection/AccountActionsSection';

// @TODO: UT this
const usePaymentTypeTab = (account: Account, paymentType?: keyof typeof PaymentTypeTab): keyof typeof PaymentTypeTab => {
    if (paymentType) { return paymentType; }
    if (account.linkedAccounts?.length > 0) {
        return PaymentTypeTab.LBA;
    }
    if (account.cards?.length > 0) {
        return PaymentTypeTab.Card;
    }

    if (account.directBankAccounts?.length > 0) {
        return PaymentTypeTab.DBA;
    }

    return PaymentTypeTab.LBA;
};

export default usePaymentTypeTab;