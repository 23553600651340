import { Column } from 'devextreme-react/data-grid';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import LinkButton from 'src/components/base/buttons/linkButton/LinkButton';
import LinkButtonWrapper from 'src/components/base/buttons/linkButton/linkButtonWrapper/LinkButtonWrapper';
import DataGridWrapper from 'src/components/base/dataGridWrapper/DataGridWrapper';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import { AML_VERIFICATION_URL, GREY_TEXT_COLOR, VERTICAL_ALIGN } from 'src/Constant';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import { renderAccountLogo, renderStatusCell, renderTextCell } from 'src/util';
import TwoLevelHeaders from 'src/util/datagrid/cellRenders/twoLevelHeaders/TwoLevelHeaders';
import rowType from 'src/util/datagrid/rowType';
import styles from './CaseVerificationSearchPageV2.module.scss';

const CaseVerificationSearchPageV2: React.FC = observer(() => {
    const { status: paramStatus } = useParams();
    const fetch = useFetchLegacy();
    const { CaseVerificationSearchPageStoreV2 } = useStore();

    useEffect(() => {
        CaseVerificationSearchPageStoreV2.setSelected(paramStatus);
        CaseVerificationSearchPageStoreV2.fetchCases(fetch);
    }, [CaseVerificationSearchPageStoreV2, paramStatus]);

    useEffect(() => {
        CaseVerificationSearchPageStoreV2.fetchCasesCount(fetch);
    }, [CaseVerificationSearchPageStoreV2]);

    const cases = CaseVerificationSearchPageStoreV2.cases;
    const { allCount, passCount, failCount, reviewCount, opsCount, userCount, dormantCount } = CaseVerificationSearchPageStoreV2.getCounts();

    const onCellPrepared = (e: CellPreparedEvent<{ inProgress: boolean; value: string; id: string; dateCreated: number; }, unknown>) => {
        if (rowType(e)) {
            if (e.column) {
                e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
            }
            if (e.column.dataField || e.column.dataField === '') {
                e.cellElement.style.color = GREY_TEXT_COLOR;
            }
            if (e.column.dataField === 'caseID') {
                e.cellElement.style.paddingRight = '10px';
            }
            if (e.column.dataField === 'dateReceived') {
                e.cellElement.style.color = GREY_TEXT_COLOR;

                const currentDate = new Date().getTime();
                const dateCreated = e.data.dateCreated;
                const dayHasPassed = currentDate - dateCreated >= 86400000;

                if (dayHasPassed) {
                    e.cellElement.style.color = '#EB5757';
                }
            }
        }
    };

    const renderBusinessCell = (cellData: { businessName: string; userName: string; email: string; }) => <><p className={styles.businessName}>{cellData.businessName} ({cellData.userName})</p> {cellData.email}</>;

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>KYB/KYC Verifications</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <LinkButtonWrapper>
                            <LinkButton
                                isActive={paramStatus === 'Review' || paramStatus === 'Ops' || paramStatus === 'User' || paramStatus === 'isDormant'}
                                store={CaseVerificationSearchPageStoreV2}
                                status={paramStatus}
                                to={CaseVerificationSearchPageStoreV2.getLinkUrl('Review')}
                                count={reviewCount}
                                label="Review"
                            />
                            <LinkButton
                                isActive={paramStatus === 'Pass'}
                                store={CaseVerificationSearchPageStoreV2}
                                status={paramStatus}
                                to={CaseVerificationSearchPageStoreV2.getLinkUrl('Pass')}
                                count={passCount}
                                label="Verified"
                            />
                            <LinkButton
                                isActive={paramStatus === 'Fail'}
                                store={CaseVerificationSearchPageStoreV2}
                                status={paramStatus}
                                to={CaseVerificationSearchPageStoreV2.getLinkUrl('Fail')}
                                count={failCount}
                                label="Denied"
                            />
                            <LinkButton
                                isActive={paramStatus === 'All'}
                                store={CaseVerificationSearchPageStoreV2}
                                status={paramStatus}
                                to={CaseVerificationSearchPageStoreV2.getLinkUrl('All')}
                                count={allCount}
                                label="All"
                                showDelimiter={true}
                            />

                            {(paramStatus === 'Review' || paramStatus === 'Ops' || paramStatus === 'User' || paramStatus === 'isDormant') &&
                                <div className={styles.subFilterContainer}>
                                    <LinkButton
                                        isActive={paramStatus === 'Ops'}
                                        store={CaseVerificationSearchPageStoreV2}
                                        status={paramStatus}
                                        to={CaseVerificationSearchPageStoreV2.getLinkUrl('Ops')}
                                        count={opsCount}
                                        label="Ops-review"
                                    />
                                    <LinkButton
                                        isActive={paramStatus === 'User'}
                                        store={CaseVerificationSearchPageStoreV2}
                                        status={paramStatus}
                                        to={CaseVerificationSearchPageStoreV2.getLinkUrl('User')}
                                        count={userCount}
                                        label="User-review"
                                    />
                                    <LinkButton
                                        isActive={paramStatus === 'isDormant'}
                                        store={CaseVerificationSearchPageStoreV2}
                                        status={paramStatus}
                                        to={CaseVerificationSearchPageStoreV2.getLinkUrl('isDormant')}
                                        count={dormantCount}
                                        label="Dormant"
                                        showDelimiter={true}
                                    />
                                </div>
                            }
                        </LinkButtonWrapper>

                        <DataGridWrapper
                            dataSource={cases}
                            onCellPrepared={onCellPrepared}
                            keyExp='caseID'
                            wordWrapEnabled={true}
                            navigator={AML_VERIFICATION_URL}
                            headerFilter={true}
                        >
                            <Column
                                allowSorting={false}
                                cellRender={renderAccountLogo}
                                caption=""
                                dataField="accountLogo"
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField=""
                                allowSorting={false}
                                headerCellRender={() => TwoLevelHeaders('Business name (User name)', 'Email')}
                                allowSearch={true}
                                allowFiltering={true}
                                width="auto"
                                cellRender={renderTextCell}
                                calculateCellValue={renderBusinessCell}
                                allowHeaderFiltering={false}
                            />
                            <Column width="100%" />
                            <Column width="auto" dataField='businessName' visible={false} />
                            <Column width="auto" dataField='userName' visible={false} />
                            <Column width="auto" dataField='email' visible={false} />
                            <Column dataField="caseID" caption="Case ID" width="auto" allowHeaderFiltering={false} />
                            <Column
                                dataField="status"
                                headerCellRender={() => TwoLevelHeaders('Intuition', 'Verification')}
                                cellRender={renderStatusCell}
                                width="auto"
                            />
                            <Column
                                dataField="hopscotchStatus"
                                headerCellRender={() => TwoLevelHeaders('Hopscotch', 'Verification')}
                                cellRender={renderStatusCell}
                                width="auto"
                            />
                            <Column
                                headerCellRender={() => TwoLevelHeaders('Pending', 'status')}
                                dataField="pendingStatus"
                                width="auto"
                            />
                            <Column
                                dataField="businessType"
                                headerCellRender={() => TwoLevelHeaders('Business', 'type')}
                                caption="Business Type"
                                width="auto"
                            />
                            <Column
                                caption="Date Received"
                                allowSearch={true}
                                allowFiltering={true}
                                allowSorting={true}
                                alignment="left"
                                dataField="dateReceived"
                                width={133}
                                headerCellRender={() => TwoLevelHeaders('Date received', 'Time received')}
                                dataType="date"
                                format="MMM dd, yyyy hh:mm a"
                            />
                        </DataGridWrapper>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});
export default CaseVerificationSearchPageV2;