import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { EVENTS_SEARCH_URL } from 'src/Constant';
import { EventInterface, ResponseInterface } from 'src/util/interfaces';
import { selectEvents } from 'src/util/selectors';

export interface EngEventsSearchPageStoreInterface {
    events: EventInterface[];
    getEvents(): EventInterface[];
    fetchEvents(fetch: FetchInterfaceLegacy): void;
}

const EngEventsSearchPageStore = (): EngEventsSearchPageStoreInterface =>
    makeAutoObservable({
        events: [{
            alertType: '',
            alertSubType: '',
            dateTime: '',
            dbaName: '',
            userName: '',
            alertId: '',
            businessLogo: '',
            userEmail: '',
            traceId: '',
        }],
        getEvents() {
            return this.events;
        },
        fetchEvents(fetch: FetchInterfaceLegacy) {
            fetch({ url: EVENTS_SEARCH_URL }, (res: ResponseInterface<EventInterface[]>) => {
                this.events = res.data.map((theEvent) => (selectEvents(theEvent)));
            },
                true,
            );
        },
    });

export default EngEventsSearchPageStore;