import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from 'src/pages/accountDetailsPage/sections/accountActionsSection/accountActionsAuditHistoryPopup/auditHistoryContent/AuditHistoryContent.module.scss';
import AuditHistoryItem from 'src/pages/accountDetailsPage/sections/accountActionsSection/accountActionsAuditHistoryPopup/auditHistoryContent/auditHistoryItem/AuditHistoryItem';
import { useStore } from 'src/store';

export interface AuditHistory {
    id: string,
    modalId: string,
    date: string,
    operator: string,
    operationReason: string,
    freezeAccount: boolean,
    invoicingLimit: number,
}

const AuditHistoryPopup: React.FC = observer(() => {
    const { AccountDetailsPageStore } = useStore();
    const audits = AccountDetailsPageStore.getAuditHistory();

    return (
        <div className={styles.wrapper}>
            {audits.map((audit: any) => (
                <AuditHistoryItem audit={audit} key={audit.id} />
            ))}
        </div>
    );
});

export default AuditHistoryPopup;