import { determineBaseAPI, determineBaseRedirectURI } from './bootstrap';
import { PaymentTypeTab } from './pages/accountDetailsPage/sections/accountActionsSection/AccountActionsSection';
import { SearchPaymentType } from './util/interfaces';

export const BASE = process.env.REACT_APP_HOST_NAME;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const GRANT_TYPE = 'authorization_code';
export const AWS_URI = `https://${process.env.REACT_APP_AWS_URI}.auth.us-east-1.amazoncognito.com/login?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=`;

export const TOKEN_URI = `https://${process.env.REACT_APP_DOMAIN}.auth.us-east-1.amazoncognito.com/oauth2/token/`;
export const DEV_URL = 'https://api.dev.intuition.zurohq.com/';

// Setup
export const BASE_API = determineBaseAPI(BASE);
export const REDIRECT_URI = determineBaseRedirectURI(BASE);

// Roles
export const ROLES = {
    technology: 'Technology',
    creditRisk: 'CreditRisk',
    business: 'Business',
    frontline: 'Frontline',
};

// In App Routes
export const ACCOUNT_DETAILS_URL = '/biz/account-details';
export const AML_VERIFICATION_URL = '/biz/aml-verification';
export const FACTORED_INVOICE_SEARCH_APP_URL = (type?: keyof typeof SearchPaymentType): string => {
    if (type) {
        return `/biz/factored-invoices?type=${type}`;
    }
    return '/biz/factored-invoices';
};
export const TRANSACTION_DETAILS_URL = '/biz/transaction-details';
export const ACCOUNT_ACTIONS_URL = '/biz/account-actions';
export const ACCOUNT_SEARCH_URL = '/biz/account-search?status=Verified';
export const INVOICE_DETAILS_URL = '/biz/invoice-details';
export const TRANSACTION_SEARCH_APP_URL = (type?: keyof typeof SearchPaymentType): string => type ? `/biz/search-transactions/${type}` : '/biz/search-transactions/';

// @TODO: UT this
export const ACCOUNT_DETAILS_DYNAMIC_APP_URL = (caseID: string, selectedInvoiceType?: string, paymentMethodId?: string, paymentMethodType?: keyof typeof PaymentTypeTab): string => {
    const invoiceType = selectedInvoiceType ? `/view/${selectedInvoiceType}` : '/view/Payable'; // @TODO: LALA - this is really like invoice type or something, not view. View is too ambiguous.
    const id = paymentMethodId ? `/${paymentMethodId}` : '';
    const type = paymentMethodType ? `/${paymentMethodType}` : '';
    return `${ACCOUNT_DETAILS_URL}/${caseID}${invoiceType}${type}${id}`;
};
export const LBA_SEARCH_APP_URL = (status: string, updatedBy: string): string => `/biz/lba-verifications/status/${status}/updatedBy/${updatedBy}`;
export const CASE_SEARCH_VERIFICATION_APP_URL = (status: string): string => `/biz/case-verifications/status/${status}`;
export const CASE_SEARCH_VERIFICATION_APP_URL_V2 = (status: string): string => `/biz/case-verifications-new/status/${status}`;
export const INVOICE_URL = (invoiceId: string): string => `/biz/invoice-details/${invoiceId}`;

// API Routes
export const LOGIN_URL = `${BASE_API}me`;
export const BUSINESS_PROFILE_URL = `${BASE_API}business/profilesummary`;
export const CASES_URL = `${BASE_API}cases/search`;
export const CASES_COUNT_URL = `${BASE_API}cases/search-count`;
export const CASES_COUNT_URL_V2 = `${BASE_API}cases/search-count/v2`;
export const CASE_SEARCH_VERIFICATION_URL = (status: string): string => `${CASES_URL}?status=${status}`;
export const CASE_SEARCH_VERIFICATION_URL_V2 = (status: string): string => `${CASES_URL}/v2?status=${status}`;
export const SEARCH_ACCOUNTS_URL = (status: string): string => `${BASE_API}cases/accounts/search?status=${status}`;
export const SEARCH_ACCOUNTS_COUNT_URL = (status: string): string => `${BASE_API}cases/accounts/search-count?status=${status}`;
export const EVENTS_SEARCH_URL = `${BASE_API}tech/alerts`;
export const SUPPORT_ACTIONS_URL = `${BASE_API}tech/get-support-actions`;
export const OPERATE_SUPPORT_ACTIONS_URL = `${BASE_API}tech/do-support`;
export const SUPPORT_PARAMS_ACTIONS_URL = (action: string): string => `${BASE_API}tech/get-support-action-params/${action}`;
export const FLAGGED_TRANSACTIONS_COUNT_URL = `${BASE_API}transactions/flagged-all/count`;
export const FLAGGED_TRANSACTIONS_LASTWEEK_COUNT_URL = `${BASE_API}transactions/flagged-last-week/count`;
export const RETURNED_TRANSACTIONS_COUNT_URL = `${BASE_API}transactions/search-count?type=returned`;
export const RETURNED_TRANSACTIONS_LASTWEEK_COUNT_URL = `${BASE_API}transactions/returned-last-week/count`;
export const TRANSACTIONS_URL = `${BASE_API}transactons`;
export const INVOICES_SEARCH_URL = `${BASE_API}invoices/search`;
export const INVOICES_SEARCH_COUNT_URL = `${BASE_API}invoices/search/count`;
export const INVOICE_LINE_ITEMS_URL = (invoiceId: string): string => `${BASE_API}invoices/${invoiceId}/line-items`;
export const ALL_ACCOUNT_DETAILS_URL = `${BASE_API}cases`;
export const FACTORED_INVOICE_DETAILS_API = (invoiceId: string): string => `${BASE_API}invoices/${invoiceId}`;
export const TRANSACTIONS_FOR_ACCOUNT = (bizId: string, type: string): any => `${BASE_API}cases/account/${bizId}/invoices?type=${type}`;
export const TRANSACTION_DETAILS_API = (transactionID: string): string => `${BASE_API}transactions/${transactionID}`;
export const TRANSACTION_DETAILS_BY_PAYMENT_ID_API = (paymentID: string): string => `${BASE_API}transactions/payment/${paymentID}`;
export const UPDATE_CASE_PROGRESS_URL = (caseID: string): string => `${BASE_API}cases/${caseID}/review`;
export const UPDATE_CASE_PENDING_STATUS_URL = (caseID: string, pendingStatus: string): string => `${BASE_API}cases/${caseID}/pendingStatus/${pendingStatus}`;
export const ACCOUNT_DETAILS_URL_API = (caseID: string): string => `${BASE_API}cases/${caseID}/account-details`;
export const CASE_DETAILS_URL = (caseID: string): string => `${BASE_API}cases/${caseID}`;
export const CASE_DETAILS_BY_BIZ_ID_URL = (bizID: string): string => `${BASE_API}cases/bizid/${bizID}`;
export const UPDATE_CASE_DETAILS_URL = (caseID: string): string => `${BASE_API}cases/update/${caseID}`;
export const RISK_CONFIG_URL = `${BASE_API}cases/risk-config`;
export const CASE_LBA_SEARCH = (status: string, updatedBy: string): string => `${BASE_API}cases/lba/search?status=${status}&updatedBy=${updatedBy}`;
export const CASE_LBA_SEARCH_COUNT = (status: string, updatedBy: string): string => `${BASE_API}cases/lba/search-count?status=${status}&updatedBy=${updatedBy}`;
export const CARD_SEARCH = (status: string, updatedBy: string): string => `${BASE_API}cases/cards/search?status=${status}&updatedBy=${updatedBy}`;
export const CARD_SEARCH_COUNT = (status: string, updatedBy: string): string => `${BASE_API}cases/cards/search-count?status=${status}&updatedBy=${updatedBy}`;
export const FACTORED_INVOICES_URL = `${BASE_API}invoices/search-factored`;
export const FACTORED_INVOICES_COUNT_URL = `${BASE_API}invoices/search-factored-count`;
export const FACTORED_RISK_URL = `${BASE_API}credit-risk-config`;
export const UPDATE_FACTORED_RISK_URL = (modelId: number): string => `${BASE_API}credit-risk-config/${modelId}`;
export const FLAGGED_TRANSACTIONS_SEARCH_URL = `${BASE_API}transactions/search?type=flagged`;
export const TRANSACTION_SEARCH_URL = (type: string | undefined): string => type ? `${BASE_API}transactions/search?type=${type}` : `${BASE_API}transactions/search`;
export const TRANSACTION_SEARCH_COUNT_URL = (type?: keyof typeof SearchPaymentType): string => type ? `${BASE_API}transactions/search-count?type=${type}` : `${BASE_API}transactions/search-count`;
export const NEW_RETURNED_TRANSACTIONS_SEARCH_URL = `${BASE_API}transactions/search?type=returned`;
export const ALL_TRANSACTIONS_SEARCH_URL = `${BASE_API}transactions/search`;
export const PROGRAM_CONTROLS_URL = `${BASE_API}program-controls`;
export const PAID_PLAN_FEATURES = (groupId: string): string => `${BASE_API}features/groups/${groupId}`;
export const RETURNED_TRANSACTIONS_URL = `${BASE_API}transactions`;
export const TRANSACTION_VOLUME_API = `${BASE_API}invoices/transaction-volume`;
export const TOTAL_DEPOSIT_API = `${BASE_API}business/totaldeposits`;
export const TRANSACTIONS_CSV_API = `${BASE_API}transactions/csv`;
export const BUSINESS_ACCOUNTS_CSV_API = `${BASE_API}business/csv`;
export const LIMITS_CSV_API = `${BASE_API}audit/invoice-limits/csv`;
export const ACCOUNTS_CSV_API = `${BASE_API}audit/accounts/csv`;
export const AUDIT_CSV_API = `${BASE_API}audit/csv`;
export const LBA_DISCONNECT_CSV_API = `${BASE_API}cases/disconnected-lbas`;
export const VERIFIED_DISCONNECT_CSV_API = `${BASE_API}cases/disconnected-verified-users`;
export const ACCOUNT_AUDIT_HISTORY_CSV_API = (caseID: string): string => `${BASE_API}audit/account/${caseID}/csv`;
export const ACCOUNT_AUDIT_HISTORY_API = (caseID: string): string => `${BASE_API}audit/account/${caseID}`;
export const OPERATE_FACTORED_INVOICE_URL = `${BASE_API}invoices/factor-actions`;
export const AUDIT_HISTORY_TRANSACTIONS_URL = (transactionId: string): string => `${BASE_API}audit/transaction/${transactionId}`;
export const AUDIT_HISTORY_TRANSACTIONS_CSV = (transactionId: string): string => `${BASE_API}audit/transaction/${transactionId}/csv`;
export const AUDIT_HISTORY_INVOICES_URL = (invoiceId: string): string => `${BASE_API}audit/invoice/${invoiceId}`;
export const AUDIT_HISTORY_INVOICES_CSV_URL = (invoiceId: string): string => `${BASE_API}audit/invoice/${invoiceId}/csv`;
export const ACCOUNT_DETAILS_TRANSACTION_SUMMARY = (bizId: string): string => `${BASE_API}cases/account-details/${bizId}/invoices`;
export const ACCOUNT_TRANSACTIONS_CSV = (bizId: string): string => `${BASE_API}transactions/csv/${bizId}`;
export const TRANSACTION_DETAILS_ACTIONS_URL = `${BASE_API}transactions/actions`;
export const TRANSACTION_APPROVED_ACTIONS_URL = `${BASE_API}transactions/approve`;
export const TRANSACTION_DENIED_ACTIONS_URL = `${BASE_API}transactions/deny`;
export const INVOICE_DETAILS_ACTIONS_URL = `${BASE_API}invoices/flag`;
export const CHANGE_GLOBAL_INVOICE_LIMIT_URL = `${BASE_API}program-controls/update-global-limit`;
export const DROP_DOWN_URL = (suffixUrl: string): string => `${BASE_API}${suffixUrl}`;
export const FEATURE_TOGGLE_API = `${BASE_API}cases/feature-toggle`;
export const UPDATE_PLAN_FEATURE = `${BASE_API}features/groups`;
export const PLAN_AUDIT_HISTORY_API = `${BASE_API}audit/feature-toggle`;
export const PLAN_AUDIT_HISTORY_CSV_API = `${BASE_API}audit/feature-toggle/csv`;
export const GET_CARD_CONTROLS_API = `${BASE_API}program-controls/get-card-controls`;
export const UPDATE_CARD_CONTROLS_API = `${BASE_API}program-controls/update-card-controls`;
export const ALLOW_FLOW_FEATURE_FOR_BUSINESS = `${BASE_API}supports/add-flow-to-biz`;
export const SUBMIT_USER_FINANCIALS = `${BASE_API}program-controls/submit-user-financials`;
export const TRIGGER_RE_UNDERWRITING = `${BASE_API}program-controls/underwrite-business`;

// Global Style Variables - which correspond with variables we use in variables.scss file.
export const VERTICAL_ALIGN = 'middle';
export const GREY_TEXT_COLOR = '#8388AB';
export const BLACK_TEXT_COLOR = '#1d191f';
export const BLACK_LIGHT_TEXT_COLOR = '#34374C';
export const ORANGE_TEXT_COLOR = '#FFBE5B';
export const ORANGE_BACKGROUND_COLOR = '#FFBE5B';
export const ACCENT_BLUE_TEXT_COLOR = '#005fda';
export const ACCENT_PINK_COLOR = '#ff9fe0';
export const LIGHT_RED_COLOR = '#ffdbdb'; // can we swap this with an existing color?

export const PINK_SVG_COLOR = 'iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAA+BJREFUeF7tm0FW2zAQhmes0E0J5AalJyicIFkQHrvkBu0NeoTCDThCewNn10dYKCcgvQHcIBC6obGmb0TMcxTbkoPtJER+jw2SpZlvZn7JQiDs+IM77j/UD6DVaYnoQw+AOgDAP/xIAJSReB7ARE7qDEotABr73bZihwPsI8FxnoOEMAZFYQAoZ0/DUdUwqgHQOj8KVNRD4igjR7m16AgOCJUUEUr+fSSog4R9AGgbDk8ASBKiVIEYwOT3XdlAygGg07rRRgo6hMSOHCUNJYA/AYF2JJpeh3lOiOZZn8EphA4CfDH63iFhyPAiMRuVUS4rA9j72D2OkHqIOsJxLcf2PgAAOyojIWRW5ESzSzoDpsN0O1rnRyKK4vEZ7KEBRBKRFISDf3+H41Wywx2ANka15+LFxhhpDSNElBhRaDOmcXB2QUTfE2NMiOhKPd1c5jnB0Elgn3RppZULQ2cxDUau5ZILQDRPe5zWKuAaXRKveyQIdVqLZ+majo397hUhsPNLDyJezh6vL5wi+bKadLhcCIED8mmh7BDGgUKpy2V6M8gacwGATmsB7bl48aDJ54Hp8oAqaISuhM2JRbPLy9xhoOAkzhSeVwVwCwB30XT42QmA2UkL76zPAZtnqVEupIMlIhglM/QVQHDQvTWjrMULMQSl5OzpRiv2W580AG8dM+39xv5pB4Kgo4j6ppjyUqsehyf83iuAWJAA4NeLeP0LXdM6X9UXhW5e/z+Md5Y0wCqQRajpctnjjGbt+JoU3iUAmYpcZMJE3zRH5hC+mXWb1IBSAeTYUzkAF26laIDLRABggq0dgEUE76PpcGET5eiXc7e1A8jQAO1AoWXQ2eXFjmsHwOakaMA9Iv503gOs6Dy/thEAYvurEroiq1LtGvCG4JXy6kZlQCkeFRzECqDgeFvbPd7vpO0Et9apIoZnAih7J1jEqDr6Wktg5wHUEYVNmGOpBIJmd5xyBrcJtpZuA3/mq+lQn05bj8RsmxVbe9J6W19bexVjeQC2/EoclOR2dRHPTRzLOQNsoN49gCwH11G3aV91ZoBc7XLOAA8g4683rqTLjFqZY/kMsImbLcK29irWbp8BKUdbXgQNAq6Z6TXAa4CFgC2VbO1eBBMEbLBs7VXA9BrgNcBrQD4BW13a2quoW78TXMdO0KYV7/5AZOcB+AORXTkQSbsvaEv/bWlP3g+MbV7aCboeXW+L06adZilnAnBR9W2CkLVf8QDiKL7n2jcz1XZXeJsye2Vb/Q2R+bLur8nFOVTkq27lvNuAF/0dIeMftfw1uddl0N8R2oACXYMJ1lPhNdhU65T/AXJQCX3mluxaAAAAAElFTkSuQmCC';

export const WARNING = ORANGE_TEXT_COLOR;
export const DANGER = '#eb5757';
export const INFO = '#3166ef';
export const SUCCESS = '#00c39e';

export const Color = {
    info: 'info',
    warning: 'warning',
    danger: 'danger',
    success: 'success'
} as const;

// Toast related variables
export const TOAST_SUCCESS_TEXT = 'Your update has been saved!';
export const TOAST_FAILURE_TEXT = 'Something went wrong';
export const TOAST_AML_VERIFICATION_TEXT = 'KYB/KYC Verification status has been saved!';
export const TOAST_IS_SUCCESS = 'isSuccess';
export const TOAST_IS_FAILURE = 'isFailure';
export const TOAST_CANCEL_INVOICE_TEXT = 'Invoice status has been updated!';

// Factored related modal varibales
export const UNWIND = 'unwind';
export const REVERSE = 'reverse';
export const WRITEOFF = 'writeoff';
export const RECOVER_FUNDS_FROM_PAYEE = 'recover';
export const MARK_AS_PAID = 'MarkedPaid';
export const MARK_AS_UN_PAID = 'MarkedUnPaid';

// Factored variables
export const FACTORED_INVOICE_WRITEOFF_DAY_LIMIT = -120;

// Risk variables
export const LOW_RISK_SCORE = 'Low';
export const HIGH_RISK_SCORE = 'High';
export const NOT_SET_SCORE = 'NotSet';

// Transactions for account details grid variables
export const PAYABLES = 'payables';
export const RECEIVABLES = 'receivables';
export const WITHDRAWALS = 'withdrawals';
export const DEPOSITS = 'deposits';
export const COMPLETED_PAYABLES_AND_RECEIVABLES = 'completedPayablesAndReceivables';
export const COMPLETED = 'completed';

// paid plans variables
export const FREEMIUM_PLAN = 'zuro-freemium-users';
export const PREMIUM_PLAN = 'zuro-premium-users';
export const BASIC_PLAN = 'zuro-basic-users';

export const TRANSACTION_TYPE = {
    Funding: 'Funding',
    Withdrawing: 'Withdrawing'
} as const;

export const CARD_SEARCH_STATUS = {
    Blocked: 'Blocked',
    UnBlocked: 'UnBlocked',
    All: 'All'
} as const;

export const CARD_SEARCH_UPDATED_BY = {
    Hop: 'Hop',
    Ops: 'Ops',
    All: 'All',
    RestrictedOrLockedAccount: 'RestrictedOrLockedAccount'
} as const;

export const NOT_AVAILABLE_PLACEHOLDER = 'N/A';

export enum PlanTypes {
    basic = 'Basic',
    freemium = 'Freemium',
    premiumMonthly = 'PremiumMonthly',
    premiumYearly = 'PremiumYearly',
};

export const PLAN_NAMES_SHORT = {
    [PlanTypes.basic]: 'Hopscotch',
    [PlanTypes.freemium]: 'Pro (Free)',
    [PlanTypes.premiumMonthly]: 'Pro (Mon)',
    [PlanTypes.premiumYearly]: 'Pro (Ann)'
} as const;

export const PLAN_NAMES_FULL = {
    [PlanTypes.basic]: 'Hopscotch',
    [PlanTypes.freemium]: 'Hopscotch Pro (Free trial)',
    [PlanTypes.premiumMonthly]: 'Hopscotch Pro - Monthly',
    [PlanTypes.premiumYearly]: 'Hopscotch Pro - Annual'
} as const;

export const STATUS_PASS = 'Pass';
export const PENDING_STATUS = 'Pending';
export const REJECTED_STATUS = 'Rejected';

// Modal related titles
export const MARK_PAID_MODAL_TITLE = 'You are marking a factored invoice as MarkAsPaid';
export const GENERIC_MODAL_TITLE = ' You are making changes below';

export enum PlanIds {
    basic = 'zuro-basic-users',
    freemium = 'zuro-freemium-users',
    premium = 'zuro-premium-users'
};

export const PLAN_NAMES_FOR_AUDIT = {
    [PlanIds.basic]: 'Hopscotch',
    [PlanIds.freemium]: 'Hopscotch Pro (Free trial)',
    [PlanIds.premium]: 'Hopscotch Pro'
} as const;

export const APP_VERSON = 'v1.6.1';