// eslint-disable-next-line
import cn from 'classnames';
import React from 'react';
import styles from './BaseRadioLabel.module.scss';

interface BaseRadioLabelProps {
    label: string;
    isChecked: boolean;
    setChecked: (isChecked: boolean) => void;
    isGreen?: boolean;
    classNames?: any;
    disabled?: boolean;
}

const BaseRadioLabel: React.FC<BaseRadioLabelProps> = ({ label, isChecked, setChecked, isGreen = false, classNames, disabled }) => (<>
    <div className={styles.radioContainer}>
        <input type="radio" className={styles.radio} checked={isChecked} disabled={disabled} onChange={() => setChecked(!isChecked)} />
    </div>
    <div className={styles.radioLabelContainer}>
        <div className={cn(styles.radioLabel, { [styles.isGreen]: isGreen }, classNames)}>
            {label}
        </div>
    </div>
</>);

export default BaseRadioLabel;