// eslint-disable-next-line
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'src/store';
import getInNumberFormat from 'src/util/getInNumberFormat';
import RadioLabelDynamic from '../../base/radioLabels/radioLabelDynamic/RadioLabelDynamic';
import ToolTip from '../../base/toolTip/ToolTip';
import styles from './LBALargeBlock.module.scss';
import PercentBlock from './percentBlock/PercentBlock';

type Props = {
    selectedId: string;
    lba: any;
    isDisabled: boolean;
}

const percentageWithAsterics = (percentage: Nullable<number>) => {
    if (percentage === null) {
        return <span className={styles.percentage}>
            <ToolTip toolTipText='No match yet' />
            <PercentBlock percent={0} />
        </span>;
    }
    return <PercentBlock percent={percentage} />;
};

const LBALargeBlock: React.FC<Props> = observer(({ selectedId, isDisabled, lba }) => {
    const { AccountDetailsPageStore } = useStore();

    const { id, institutionName, ownerName, ownerEmail, accountNumber: truncatedAccountNumber, isBlocked: blocked, isFundingAccount: isBackupAccount, addressLine1, addressLine2, ownerPhoneNumber: phoneNumber, ownerNameConfidence, addressConfidence, emailConfidence, phoneNumberConfidence, availableBalance, totalTransactionsCount: numberOfTransactions, totalTransactionsSum: valueOfTransactions } = lba;

    return (
        <div className={cn(styles.linkedAccountBlock, { [styles.highlightBorder]: selectedId === id })}>
            <div className={cn(styles.row, styles.headerRow)}>
                <div className={styles.headerWrapper}>
                    <div className={styles.leftContainer}>
                        <p className={cn(styles.label, styles.institutionName)}>{institutionName}</p>
                        <p className={cn(styles.label, styles.smallLabel)}>xxxx{truncatedAccountNumber}</p>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.buttonWrapper}>
                            <RadioLabelDynamic
                                setChecked={() => AccountDetailsPageStore.setFormLinkedAccountStatus(id, false)}
                                isPositive={true}
                                isChecked={!blocked}
                                label="Unblock"
                                disabled={isDisabled}
                                classNames={styles.RadioLabelDynamic}
                            />
                            <RadioLabelDynamic
                                setChecked={() => AccountDetailsPageStore.setFormLinkedAccountStatus(id, true)}
                                isPositive={false}
                                isChecked={blocked}
                                label="Block"
                                disabled={isDisabled}
                                classNames={styles.RadioLabelDynamic}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.contentBlock}>
                <div className={styles.ownerDetails}>
                    <div className={styles.availableBalanceRow}>
                        <p className={styles.availableBalanceLabel}>{ownerName}</p>
                        <div className={styles.availableBalance}>
                            {percentageWithAsterics(ownerNameConfidence)}
                        </div>
                    </div>
                    <div className={styles.availableBalanceRow}>
                        <div className={styles.addressBlock}>
                            <p className={styles.availableBalanceLabel}>{addressLine1}</p>
                            <p className={styles.availableBalanceLabel}>{addressLine2}</p>
                        </div>
                        <div className={cn(styles.availableBalance, styles.addressConfidence)}>
                            {percentageWithAsterics(addressConfidence)}
                        </div>
                    </div>
                    <div className={styles.availableBalanceRow}>
                        <p className={cn(styles.availableBalanceLabel, styles.mt8)}>{ownerEmail}</p>
                        <div className={cn(styles.availableBalance, styles.marginTop4)}>
                            {percentageWithAsterics(emailConfidence)}
                        </div>
                    </div>
                    <div className={styles.availableBalanceRow}>
                        <p className={styles.availableBalanceLabel}>{phoneNumber}</p>
                        <div className={cn(styles.availableBalance, styles.lastPercentContainer)}>
                            {percentageWithAsterics(phoneNumberConfidence)}
                        </div>
                    </div>
                </div>
                <div className={styles.accountDetails}>
                    <div className={styles.backupContainer}>
                        {isBackupAccount && <p className={cn(styles.statusContainer, styles.statusContainerDarkGrey)}>Backup</p>}
                    </div>
                    <div className={styles.availableBalanceRow}>
                        <p className={cn(styles.availableBalanceLabel)}>Avail. Balance:</p>
                        <p className={cn(styles.availableBalance)}>{availableBalance ? `$${getInNumberFormat(availableBalance)}` : ''}</p>
                    </div>
                    <div className={styles.availableBalanceRow}>
                        <p className={cn(styles.availableBalanceLabel)}>No. of tx:</p>
                        <p className={cn(styles.availableBalance)}>{getInNumberFormat(numberOfTransactions)}</p>
                    </div>
                    <div className={styles.accountDetailsRow}>
                        <p className={cn(styles.availableBalanceLabel)}>Value of tx:</p>
                        <p className={cn(styles.availableBalance)}>{valueOfTransactions ? `$${getInNumberFormat(valueOfTransactions)}` : ''}</p>
                    </div>
                </div>
            </div>
        </div >
    );
});

export default LBALargeBlock;