import { makeAutoObservable } from 'mobx';
import { OPERATE_SUPPORT_ACTIONS_URL, SUPPORT_ACTIONS_URL, SUPPORT_PARAMS_ACTIONS_URL, TOAST_FAILURE_TEXT, TOAST_SUCCESS_TEXT } from 'src/Constant';
import { FetchInterfaceLegacy } from '../../../../api/useFetchLegacy';
import { Field, SupportActionForm, SupportActions } from './SupportPageStoreInterface';

export interface SupportPageStoreInterface {
    supportActions: SupportActions,
    supportActionForm: SupportActionForm,
    getSupportActionForm(): SupportActionForm,
    getInitialValues(form: SupportActionForm): any,
    fetchSupportActions(fetch: FetchInterfaceLegacy): void;
    fetchParamsForAction(action: string, fetch: FetchInterfaceLegacy): void;
    updateSupportAction(action: any, fetch: FetchInterfaceLegacy): void;
}

const SupportPageStore = (): SupportPageStoreInterface =>
    makeAutoObservable({
        supportActions: {
            exception: '',
            errorMessage: '',
            result: []
        },
        supportActionForm: {
            exception: '',
            errorMessage: '',
            result: [{
                nameOfField: '',
                label: '',
                type: 'String',
            }] as Field[]
        },
        getSupportActionForm() {
            return this.supportActionForm;
        },
        getInitialValues(form: SupportActionForm) {
            const initialValues: { [char: string]: string } = {};
            form?.result?.forEach((aResult) => {
                const nameOfField = aResult.nameOfField;
                if (aResult.nameOfField !== '') { // do we even have a form yet
                    if (aResult.options !== undefined) {
                        // we have a select option, let's set the value to the first
                        initialValues[nameOfField] = aResult.options[0];
                    } else {
                        initialValues[nameOfField] = '';
                    }
                }
            });

            return initialValues;
        },
        updateSupportAction(action: any, fetch: FetchInterfaceLegacy) {
            fetch({ url: OPERATE_SUPPORT_ACTIONS_URL, data: action, method: 'POST' }, () => { },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT }
            );
        },
        fetchSupportActions(fetch) {
            fetch({ url: SUPPORT_ACTIONS_URL }, (res) => {
                this.supportActions = res.data;
            },
                true,
            );
        },
        fetchParamsForAction(action, fetch) {
            fetch({ url: SUPPORT_PARAMS_ACTIONS_URL(action) }, (res: { data: SupportActionForm }) => {
                this.supportActionForm = {
                    ...res.data,
                    result: res.data.result.map(aResult => {
                        const newResult = { ...aResult };

                        if (aResult.options !== undefined) {
                            newResult.options = ['Pick an Option', ...aResult.options];
                        }

                        return newResult;
                    })
                };
            },
                true,
            );
        }
    });

export default SupportPageStore;