/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line id-length
import cn from 'classnames';
import { Popup } from 'devextreme-react';
import fileDownload from 'js-file-download';
import React from 'react';
import { fetchAuditCSV, fetchBusinessAccountsCSV, fetchLbaDisconnectCSV, fetchTransactionsCSV, fetchVerificationsCSV, fetchVerifiedDisconnectCSV } from 'src/api/api';
import { CloseButton } from 'src/components/base/buttons/closeButton/CloseButton';
import InvoiceLogo from 'src/components/logos/InvoiceLogo';
import PaymentsIcon from 'src/components/logos/PaymentsIcon';
import PeopleLogo from 'src/components/logos/PeopleLogo';
import SearchIcon from 'src/components/logos/SearchIcon';
import { useStore } from 'src/store';
import { Logger } from 'src/util';
import { ReceiptLogo } from '../../../logos';

import styles from './GenerateReportPopup.module.scss';

type Props = {
    popupVisible: boolean;
    hidePopup: any;
};

const GenerateReportPopup: React.FC<Props> = ({ popupVisible, hidePopup }) => {
    const renderTitle = () => (
        <div className={styles.titleContainer}>
            <div className={styles.titleWrapper}>
                <div className={styles.titleDiv}>
                    <p className={styles.generateTitle}>Generate report</p>
                    <CloseButton onClick={hidePopup} />
                </div>
            </div>
        </div>
    );

    const { sharedStore } = useStore();
    return (
        <Popup
            visible={popupVisible}
            height="480px"
            width="500px"
            showCloseButton={true}
            onHiding={hidePopup}
            closeOnOutsideClick={true}
            titleRender={renderTitle}
        >
            <div className={styles.wrapper}>
                <div className={styles.container} onClick={() => {
                    fetchVerificationsCSV(sharedStore.getAccessToken())
                        .then((res) => {
                            fileDownload(res.data, 'verification_audit.csv');
                        })
                        .catch((err) => {
                            Logger.error(err);
                        });
                }}>
                    <div className={styles.svgDiv}>
                        <div className={cn(styles.iconColorBlock, styles.greenBlock)}>
                            <PeopleLogo width={15} height={15} classNames={styles.noMargin} />
                        </div>
                    </div>
                    <div className={styles.headingDiv}>
                        <h4 className={styles.title}>Verifications</h4>
                        <p>Audit of verification decisions</p>
                    </div>
                </div>

                <div className={styles.container} onClick={() => {
                    fetchBusinessAccountsCSV(sharedStore.getAccessToken())
                        .then((res) => {
                            fileDownload(res.data, 'business_accounts.csv');
                        })
                        .catch((err) => {
                            Logger.error(err);
                        });
                }}>
                    <div className={styles.svgDiv}>
                        <div className={cn(styles.iconColorBlock, styles.blueBlock)}>
                            <SearchIcon width={15} height={15} classNames={styles.noMargin} />
                        </div>
                    </div>
                    <div className={styles.headingDiv}>
                        <h4 className={styles.title}>Businesses</h4>
                        <p>Status of businesses on Hopscotch</p>
                    </div>
                </div>

                <div className={styles.container} onClick={() => {
                    fetchTransactionsCSV(sharedStore.getAccessToken())
                        .then((res) => {
                            fileDownload(res.data, 'payments.csv');
                        })
                        .catch((err) => {
                            Logger.error(err);
                        });
                }}>
                    <div className={styles.svgDiv}>
                        <div className={cn(styles.iconColorBlock, styles.yellowBlock)}>
                            <PaymentsIcon width={15} height={15} classNames={styles.noMargin} />
                        </div>
                    </div>
                    <div className={styles.headingDiv}>
                        <h4 className={styles.title}>Payments</h4>
                        <p>All payments made across Hopscotch</p>
                    </div>
                </div>

                <div className={styles.container} onClick={() => {
                    fetchAuditCSV(sharedStore.getAccessToken())
                        .then((res) => {
                            fileDownload(res.data, 'audit.csv');
                        });
                }}>
                    <div className={styles.svgDiv}>
                        <div className={cn(styles.iconColorBlock, styles.redBlock)}>
                            <InvoiceLogo width={15} height={15} classNames={styles.noMargin} />
                        </div>
                    </div>
                    <div className={styles.headingDiv}>
                        <h4 className={styles.title}>Controls</h4>
                        <p>Audit of controls applied to accounts & payments</p>
                    </div>
                </div>

                <div className={styles.container} onClick={() => {
                    fetchLbaDisconnectCSV(sharedStore.getAccessToken())
                        .then((res) => {
                            fileDownload(res.data, 'lba_disconnect.csv');
                        });
                }}>
                    <div className={styles.svgDiv}>
                        <div className={cn(styles.iconColorBlock, styles.greyBlock)}>
                            <ReceiptLogo width={15} height={15} classNames={styles.noMargin} />
                        </div>
                    </div>
                    <div className={styles.headingDiv}>
                        <h4 className={styles.title}>LBA check</h4>
                        <p>Status of LBAs between Hopscotch & Intuition</p>
                    </div>
                </div>
                <div className={styles.container} onClick={() => {
                    fetchVerifiedDisconnectCSV(sharedStore.getAccessToken())
                        .then((res) => {
                            fileDownload(res.data, 'verified_disconnect.csv');
                        });
                }}>
                    <div className={styles.svgDiv}>
                        <div className={cn(styles.iconColorBlock, styles.greyBlock)}>
                            <ReceiptLogo width={15} height={15} classNames={styles.noMargin} />
                        </div>
                    </div>
                    <div className={styles.headingDiv}>
                        <h4 className={styles.title}>Verified check</h4>
                        <p>Status of Verified between Hopscotch & Intuition</p>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default GenerateReportPopup;