import React from 'react';
import LinkedBankAccountView from 'src/components/business/transactions/linkedBankAccountView/LinkedBankAccountView';
import { TRANSACTION_TYPE } from 'src/Constant';
import PayeePayorSection from 'src/pages/transactionDetailsPage/sections/PayeePayorSection';
import { Meta } from 'src/pages/transactionDetailsPage/TransactionDetailsPageStoreInterface';
import { riskScoreType } from 'src/util/interfaces';

export interface payee {
    dbaName: string,
    name: string,
    profileLink: string,
    caseId: string,
    createdTimestamp: string,
    score: riskScoreType,
    accountLogo: string,
    isVerified: boolean,
    hopscotchBalance: string,
    bankPartnerBalance: string,
    averageTransactionSize: number,
    totalTransactionVolume: number,
    linkedAccountBlock: any,
    planType: string,
};

export interface payor {
    dbaName: string,
    name: string,
    profileLink: string,
    caseId: string,
    createdTimestamp: string,
    score: riskScoreType,
    accountLogo: string,
    isVerified: boolean,
    hopscotchBalance: string,
    bankPartnerBalance: string,
    averageTransactionSize: string,
    totalTransactionVolume: string,
    linkedAccountBlock: any,
    planType: string,
};

type Props = {
    transactionDetails: {
        causationIdType: string,
        transactionType: string,
        transactionStatus: string,
        transactionAmount: number,
        invoiceAmount?: number,
        transferAmount: number,
        transferStatus: string,
        transactionDate: string,
        transactionDueDate: string,
        invoiceDueDate: string,
        transactionCompletedDate: string
        meta?: Meta
    },
    payee: payee,
    payor: payor,
    mutualTransactions: {
        lastTransactionDate: string,
        averageTransactionSize: number,
        totalTransactions: number,
        totalAmount: number,
    }
};

const TransactionStatus: React.FC<Props> = ({ transactionDetails, payee, payor, mutualTransactions }) =>
    (transactionDetails.causationIdType === TRANSACTION_TYPE.Funding || transactionDetails.causationIdType === TRANSACTION_TYPE.Withdrawing)
        ? <LinkedBankAccountView payee={payee} payor={payor} causationIdType={transactionDetails.causationIdType} />
        : <PayeePayorSection payee={payee} payor={payor} mutualTransactions={mutualTransactions} />;

export default TransactionStatus;