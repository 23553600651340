import React from 'react';
import { SearchStatus, SearchUpdatedBy } from '../../util/interfaces';
import LinkButton from '../base/buttons/linkButton/LinkButton';
import LinkButtonWrapper from '../base/buttons/linkButton/linkButtonWrapper/LinkButtonWrapper';

import styles from './PaymentLinks.module.scss';

interface props {
    store: {
        getLinkUrl: (status: string, updatedBy: string) => string;
        setSelected(status?: string): void;
    };
    updatedBy?: keyof typeof SearchUpdatedBy;
    status?: keyof typeof SearchStatus;
    counts: {
        blockedCount: number;
        unblockedCount: number;
        allCount: number;
        blockedOpsCount: number;
        unblockedOpsCount: number;
        blockedHopCount: number;
        unblockedHopCount: number;
        restrictedOrLockedBlockedCount: number;
        restrictedOrLockedUnblockedCount: number;

    }
}
const PaymentLinks: React.FC<props> = ({ store, updatedBy, status, counts }) => {
    const {
        blockedCount,
        unblockedCount,
        allCount,
        blockedOpsCount,
        unblockedOpsCount,
        blockedHopCount,
        unblockedHopCount,
        restrictedOrLockedBlockedCount,
        restrictedOrLockedUnblockedCount
    } = counts;

    return <LinkButtonWrapper>
        <LinkButton
            isActive={status === 'All'}
            store={store}
            status={status}
            to={store.getLinkUrl('All', 'All')}
            count={allCount}
            label="All"
            showDelimiter={true}
        />
        <LinkButton
            isActive={status === 'Blocked'}
            store={store}
            status={status}
            to={store.getLinkUrl('Blocked', updatedBy || '')}
            count={blockedCount}
            label="Blocked"
            showDelimiter={true}

        />
        <LinkButton
            isActive={status === 'UnBlocked'}
            store={store}
            status={status}
            to={store.getLinkUrl('UnBlocked', updatedBy || '')}
            count={unblockedCount}
            label="Unblocked"
        />

        {(status !== 'All') &&
            <div className={styles.subFilterContainer}>
                <LinkButton
                    isActive={updatedBy === 'Hop'}
                    store={store}
                    status={updatedBy as string}
                    to={store.getLinkUrl(status || '', 'Hop')}
                    count={status === 'Blocked' ? blockedHopCount : unblockedHopCount}
                    label="Auto-update"
                    showDelimiter={true}

                />
                <LinkButton
                    isActive={updatedBy === 'Ops'}
                    store={store}
                    status={updatedBy}
                    to={store.getLinkUrl(status || '', 'Ops')}
                    count={status === 'Blocked' ? blockedOpsCount : unblockedOpsCount}
                    label="Manual-update"
                    showDelimiter={true}

                />
                <LinkButton
                    isActive={updatedBy === 'RestrictedOrLockedAccount'}
                    store={store}
                    status={updatedBy || ''}
                    to={store.getLinkUrl(status || '', 'RestrictedOrLockedAccount')}
                    count={status === 'Blocked' ? restrictedOrLockedBlockedCount : restrictedOrLockedUnblockedCount}
                    label="Restricted / Locked"
                />
            </div>}

    </LinkButtonWrapper>;
};

export default PaymentLinks;