import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import {
    CASES_COUNT_URL_V2, CASE_SEARCH_VERIFICATION_APP_URL_V2,
    CASE_SEARCH_VERIFICATION_URL_V2
} from 'src/Constant';

const CaseVerificationListPageStore = (): any =>
    makeAutoObservable({
        status: '',
        allCount: 0,
        passCount: 0,
        failCount: 0,
        reviewCount: 0,
        opsCount: 0,
        userCount: 0,
        dormantCount: 0,
        cases: [
            {
                inProgress: false,
                accountLogo: '',
                businessName: '',
                userName: '',
                email: '',
                businessType: '',
                caseID: '',
                dateReceived: 0,
                status: ' ',
                hopscotchStatus: '',
                pendingStatus: '',
            },
        ],
        getCases() {
            return this.cases;
        },
        getSelected() {
            return this.status;
        },
        setSelected(status: string) {
            this.status = status;
        },
        getAllCases() {
            return this.cases;
        },
        getCounts(): { allCount: number; failCount: number; passCount: number; reviewCount: number; opsCount: number; userCount: number; dormantCount: number; } {
            return {
                allCount: this.allCount,
                failCount: this.failCount,
                passCount: this.passCount,
                reviewCount: this.reviewCount,
                opsCount: this.opsCount,
                userCount: this.userCount,
                dormantCount: this.dormantCount,
            };
        },
        getLinkUrl(status: string) {
            return CASE_SEARCH_VERIFICATION_APP_URL_V2(status);
        },
        fetchCases(fetch: FetchInterfaceLegacy) {
            fetch({ url: CASE_SEARCH_VERIFICATION_URL_V2(this.status) }, (res) => {
                this.cases = res.data.map((theCase: any) => ({
                    businessName: theCase?.businessName || 'N/A',
                    userName: theCase?.userName || 'N/A',
                    accountLogo: theCase.logo,
                    email: theCase.emailAddress,
                    businessType: theCase.type,
                    inProgress: theCase.inProgress,
                    dateReceived: theCase.dateReceived,
                    caseID: theCase.caseID,
                    status: theCase.status,
                    hopscotchStatus: theCase.verificationStatus || 'N/A',
                    pendingStatus: theCase.pendingStatus,
                }));
            }, true);
        },
        fetchCasesCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: CASES_COUNT_URL_V2 }, (res) => {
                this.allCount = res.data;
            });
            fetch({ url: `${CASES_COUNT_URL_V2}?status=Pass` }, (res) => {
                this.passCount = res.data;
            });
            fetch({ url: `${CASES_COUNT_URL_V2}?status=Fail` }, (res) => {
                this.failCount = res.data;
            });
            fetch({ url: `${CASES_COUNT_URL_V2}?status=Review` }, (res) => {
                this.reviewCount = res.data;
            });
            fetch({ url: `${CASES_COUNT_URL_V2}?status=Ops` }, (res) => {
                this.opsCount = res.data;
            });
            fetch({ url: `${CASES_COUNT_URL_V2}?status=User` }, (res) => {
                this.userCount = res.data;
            });
            fetch({ url: `${CASES_COUNT_URL_V2}?status=isDormant` }, (res) => {
                this.dormantCount = res.data;
            });
        }
    });

export default CaseVerificationListPageStore;
