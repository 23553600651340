import React from 'react';
import styles from './Content.module.scss';

type Props = {
    children: React.ReactNode,
}

const Content: React.FC<Props> = ({ children }) => (
    <div className={styles.contentWrapper}>{children}</div>
);

export default Content;