import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Params, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import { useStore } from 'src/store';
import TransactionDetailContent from './TransactionDetailContent';

const TransactionDetailsPage: React.FC = observer(() => {
    const { transactionId }: Readonly<Params<string>> = useParams();
    const { TransactionDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();

    useEffect(() => {
        if (transactionId) {
            TransactionDetailsPageStore.fetchTransactionDetails(transactionId);
            TransactionDetailsPageStore.fetchAuditHistoryTransactions(fetch, transactionId);
        }
    }, [TransactionDetailsPageStore, transactionId]);


    return (<TransactionDetailContent />);
});

export default TransactionDetailsPage;