import React from 'react';

type Props = {
    width: number;
    height: number;
    classNames?: string
}

const PeopleLogo: React.FC<Props> = ({ width, height, classNames }): JSX.Element => (
    <svg width={width} height={height} className={classNames} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.2418 1.35865C4.06845 1.35865 3.11726 2.30984 3.11726 3.48319C3.11726 4.65654 4.06845 5.60773 5.2418 5.60773C6.41515 5.60773 7.36634 4.65654 7.36634 3.48319C7.36634 2.30984 6.41515 1.35865 5.2418 1.35865ZM2.09162 3.48319C2.09162 1.74339 3.502 0.333008 5.2418 0.333008C6.9816 0.333008 8.39198 1.74339 8.39198 3.48319C8.39198 5.22299 6.9816 6.63337 5.2418 6.63337C3.502 6.63337 2.09162 5.22299 2.09162 3.48319Z"
            fill="#030D45"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.94106 9.27074C2.51504 9.27074 1.35901 10.4268 1.35901 11.8528C1.35901 11.9237 1.37126 11.9728 1.38355 12.0005C1.39345 12.0229 1.40075 12.0271 1.40398 12.029C1.75572 12.2357 2.7676 12.6407 5.2418 12.6407C7.716 12.6407 8.72757 12.2359 9.07932 12.0292C9.08255 12.0273 9.09015 12.0229 9.10005 12.0005C9.11234 11.9728 9.12458 11.9237 9.12458 11.8528C9.12458 10.4268 7.96856 9.27074 6.54254 9.27074H3.94106ZM0.333374 11.8528C0.333374 9.86031 1.94859 8.2451 3.94106 8.2451H6.54254C8.53501 8.2451 10.1502 9.86031 10.1502 11.8528C10.1502 12.196 10.0342 12.6576 9.599 12.9134C9.02541 13.2505 7.80596 13.6663 5.2418 13.6663C2.67763 13.6663 1.45819 13.2505 0.884594 12.9134C0.449358 12.6576 0.333374 12.196 0.333374 11.8528Z"
            fill="#030D45"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.36213 1.59115C9.43602 1.31774 9.71757 1.15599 9.99098 1.22989C11.1222 1.53562 11.9085 2.65267 11.9085 3.92275C11.9085 5.26476 10.9886 6.47475 9.69857 6.62971C9.41736 6.66349 9.16202 6.46292 9.12824 6.18171C9.09447 5.90051 9.29504 5.64517 9.57624 5.61139C10.2642 5.52876 10.8828 4.83794 10.8828 3.92275C10.8828 3.05443 10.3504 2.38948 9.72339 2.22001C9.44997 2.14611 9.28823 1.86456 9.36213 1.59115Z"
            fill="#030D45"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8923 8.65975C10.9465 8.38177 11.2158 8.20036 11.4938 8.25458C12.7559 8.50071 13.6667 9.60636 13.6667 10.8922V11.2487C13.6667 11.5643 13.572 12.0138 13.1603 12.277C12.8831 12.4541 12.4532 12.6429 11.7904 12.777C11.5128 12.8332 11.2422 12.6537 11.186 12.3761C11.1299 12.0985 11.3094 11.8279 11.587 11.7718C12.1525 11.6574 12.4581 11.5085 12.6079 11.4128L12.6091 11.4116C12.6103 11.4102 12.6142 11.4048 12.6192 11.3927C12.6301 11.3662 12.6411 11.3186 12.6411 11.2487V10.8922C12.6411 10.0971 12.0779 9.41345 11.2975 9.26125C11.0195 9.20704 10.8381 8.93774 10.8923 8.65975Z"
            fill="#030D45"
        />
    </svg>
);

export default PeopleLogo;