import React from 'react';
import { Link } from 'react-router-dom';
import styles from './HomeCard.module.scss';


type HomeCardProps = {
    link: string;
}

const HomeCard: React.FC<HomeCardProps> = ({ link, children }) => (
    <Link className={styles.container} to={link}>
        <h1>{children}</h1>
    </Link>
);

export default HomeCard;