import React from 'react';
import styles from './ValueWrapper.module.scss';

type Props = {
    children: JSX.Element;
};

const ValueWrapper: React.FC<Props> = ({ children }) => (
    <div className={styles.valueWrapper}>{children}</div>
);

export default ValueWrapper;