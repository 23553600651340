import React from 'react';
import LabelWrapper from 'src/layouts/labelWrapper/LabelWrapper';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import DollarInput from '../dollarInput/DollarInput';
import Label from '../label/Label';


type TextCurrencyRowProps = {
    label: string,
    value: number,
    handleChange: (value: number) => void
}

const TextCurrencyRow: React.FC<TextCurrencyRowProps> = ({ label, value, handleChange }) => (
    <TwoColumnRow>
        <LabelWrapper>
            <Label>{label}</Label>
        </LabelWrapper>
        <DollarInput
            value={value}
            placeholder={value}
            onChangeHandler={handleChange} />
    </TwoColumnRow>
);

export default TextCurrencyRow;
