import React from 'react';
import ReactTooltip from 'react-tooltip';
import { PaymentDetail } from '../../../util/interfaces';
import BlockButton from '../buttons/blockButton/BlockButton';
import styles from './LbaMultipleAccountsTooltip.module.scss';

type LbaMultipleAccountsTooltipProps = {
    paymentStatuses: any;
    id: string;
}

const LbaMultipleAccountsTooltip: React.FC<LbaMultipleAccountsTooltipProps> = (({ paymentStatuses, id }): JSX.Element => (
    <>
        <p data-tip={true} data-for={id}>Multiple</p>
        <ReactTooltip id={id} place="right" effect="solid" className={styles.toolTipContainer}>
            {paymentStatuses.map((paymentMethod: PaymentDetail) =>
                <div className={styles.blockButtonContainer} key={paymentMethod.id}>
                    <BlockButton isBlocked={paymentMethod.isBlocked} isOps={paymentMethod.hasOpsUpdated} paymentType={paymentMethod.paymentType} />
                </div>)}
        </ReactTooltip>
    </>
));

export default LbaMultipleAccountsTooltip;