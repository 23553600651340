import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { GET_CARD_CONTROLS_API, UPDATE_CARD_CONTROLS_API } from 'src/Constant';

export type TCardLimits = {
    CardTransactionLimit: number,
    CardTransactionValueThreshold: number
}

export const CARD_CONTROLS_INPUTS = {
    CardTransactionLimit: 'CardTransactionLimit',
    CardTransactionValueThreshold: 'CardTransactionValueThreshold',

} as const;

export interface CardControlsPageStoreInterface {
    cardLimits: TCardLimits
    form: TCardLimits
    changedField: string
    setCardLimits: (limits: TCardLimits) => void
    setFormLimits: (limits: TCardLimits) => void
    updateFormCardLimits: (key: keyof TCardLimits, value: number) => void
    getFormLimits: () => TCardLimits
    getHasAFieldChanged: (changedField: string) => boolean
    setFieldChange: (hasChanged: boolean, fieldOrId: string) => void
    getChangedField: () => string
    fetchCardLimits: (fetch: FetchInterfaceLegacy) => void
    saveCardLimits: (fetch: FetchInterfaceLegacy) => void

}

const CardControlsPageStore = (): CardControlsPageStoreInterface =>
    makeAutoObservable({
        cardLimits: {
            CardTransactionLimit: 0,
            CardTransactionValueThreshold: 0

        },
        form: {
            CardTransactionLimit: 0,
            CardTransactionValueThreshold: 0
        },
        changedField: '',
        getHasAFieldChanged(changedField: string) {
            if (this.changedField === '') {
                return false;
            }
            return this.changedField !== changedField;
        },
        /**
         * 
         * @param hasChanged: boolean determining if an input has changed from it's original value.
         * @param fieldOrId: field name of an input that changed.
        */
        setFieldChange(hasChanged: boolean, fieldOrId: string): void {
            this.changedField = hasChanged ? fieldOrId : '';
        },
        setCardLimits(limits: TCardLimits) {
            this.cardLimits = limits;
        },
        setFormLimits(limits: TCardLimits) {
            this.form = limits;
        },
        updateFormCardLimits(key: keyof TCardLimits, value: number) {
            if (!this.getHasAFieldChanged(key)) {
                this.form[key] = value || 0;
                this.setFieldChange(this.form[key] !== this.cardLimits[key], key);
            }
        },
        getFormLimits() {
            return this.form;
        },
        getChangedField() {
            return this.changedField;
        },
        saveCardLimits(fetch: FetchInterfaceLegacy) {
            if (!this.changedField) {
                return;
            }
            fetch({
                url: UPDATE_CARD_CONTROLS_API,
                method: 'PUT',
                data: {
                    cardControlKey: this.changedField,
                    cardControlValue: this.form[this.changedField as keyof TCardLimits],
                    // TODO:- can add the reson text input to save the reason for updating the card limits
                    reason: ''
                }
            }, () => {
                // On success 
                this.changedField = '';
                this.fetchCardLimits(fetch);
            },
                true,
                { successMessage: 'Card Limits Saved successfully', failMessage: 'Failed to save card limts' }
            );
        },
        fetchCardLimits(fetch: FetchInterfaceLegacy) {
            // Fetch the card limits and set the cardLimits object here
            fetch({
                url: GET_CARD_CONTROLS_API,
                method: 'GET',
            }, (res) => {
                //  On success
                this.setCardLimits(res?.data);
                this.setFormLimits(res?.data);
            },
                true,
            );
        }
    }
    );

export default CardControlsPageStore;