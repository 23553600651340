/* eslint-disable jsx-a11y/click-events-have-key-events */
import { observer } from 'mobx-react-lite';
import React from 'react';
import HRLine from 'src/components/base/hrLine/HRLine';
import MenuItem from 'src/components/base/menuItem/MenuItem';
import {
    BellIcon,
    CardLogo,
    ChartIcon,
    FastTrackIcon,
    HomeIcon,
    InstitutionIcon,
    InvoiceLogo,
    InvoicesIcon,
    PaymentsIcon,
    PeopleLogo,
    ReceiptLogo,
    SearchIcon
} from 'src/components/logos';
import MoneySignIcon from 'src/components/logos/MoneySignIcon';
import { ROLES, TRANSACTION_SEARCH_APP_URL } from 'src/Constant';
import { useStore } from 'src/store';
import useIsFeatureEnabled from 'src/store/hooks/features/useIsFeatureEnabled';
import useHasAuthorization from 'src/store/hooks/useHasAuthorization';
import { SearchPaymentType, SearchUpdatedBy } from 'src/util/interfaces';
import styles from '../MenuBar.module.scss';

const MenuBarBiz: React.FC = observer(() => {
    const { sharedStore } = useStore();
    const isCreditAuthorized = useHasAuthorization(ROLES.creditRisk);
    const isBusinessAuthorized = useHasAuthorization(ROLES.business);
    const isFrontlineEnabled = useHasAuthorization(ROLES.frontline);
    const isVerificationSearchEnabled = useIsFeatureEnabled('VerificationSearch');

    const resetTextValue = () => {
        sharedStore.setTextValue('');
    };

    return (
        <div className={styles.menuContainer}>
            <ul className={styles.list}>
                {(isBusinessAuthorized || isFrontlineEnabled) && (
                    <>
                        <MenuItem text="Home" url="/biz" reset={resetTextValue}>
                            <HomeIcon />
                        </MenuItem>
                        <MenuItem text="Accounts" url={`/biz/account-search/status/${SearchUpdatedBy.All}`} reset={resetTextValue}>
                            <SearchIcon width={12} height={12} />
                        </MenuItem>
                        <MenuItem text="Events" url="/biz/events-search" reset={resetTextValue}>
                            <BellIcon width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="KYB/KYC verifications" url={`/biz/case-verifications/status/${SearchUpdatedBy.All}`} reset={resetTextValue}>
                            <PeopleLogo width={14} height={14} />
                        </MenuItem>
                        {isVerificationSearchEnabled && (
                            <MenuItem text="KYB/KYC verifications (N)" url={`/biz/case-verifications-new/status/${SearchUpdatedBy.All}`} reset={resetTextValue}>
                                <PeopleLogo width={14} height={14} />
                            </MenuItem>
                        )}

                        <MenuItem text="LBA verifications" url={`/biz/lba-verifications/status/All/updatedBy/${SearchUpdatedBy.All}`} reset={resetTextValue}>
                            <ReceiptLogo width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="Card verifications" url={`/biz/card-verifications/status/All/updatedBy/${SearchUpdatedBy.All}`} reset={resetTextValue}>
                            <CardLogo width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="Invoices" url={`/biz/invoices/search?type=${SearchPaymentType.all}`} reset={resetTextValue}>
                            <InvoicesIcon />
                        </MenuItem>
                        <MenuItem text="Payments" url={TRANSACTION_SEARCH_APP_URL(SearchPaymentType.all)} reset={resetTextValue}>
                            <PaymentsIcon width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="Factored invoices" url={`/biz/factored-invoices?type=${SearchPaymentType.all}`} reset={resetTextValue}>
                            <FastTrackIcon width={14} height={14} />
                        </MenuItem>
                    </>
                )}
                <HRLine />
                {isCreditAuthorized && (
                    <>
                        <MenuItem text="Paid plan controls" url="/biz/paid-plan-controls" reset={resetTextValue}>
                            <MoneySignIcon width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="Program controls" url="/biz/program-controls" reset={resetTextValue}>
                            <InstitutionIcon width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="Factored inv. controls" url="/biz/factored-risk-model" reset={resetTextValue}>
                            <InvoiceLogo width={14} height={14} />
                        </MenuItem>
                        <MenuItem text="Card controls" url="/biz/card-controls" reset={resetTextValue}>
                            <InstitutionIcon width={14} height={14} />
                        </MenuItem>
                    </>
                )}

                {(isBusinessAuthorized || isFrontlineEnabled) && (
                    <div className={styles.menuItem} onClick={() => {
                        sharedStore.setGenerateReportPopupVisble(true);
                    }}>

                        <div className={styles.container}>
                            <div className={styles.svgContainer}>
                                <ChartIcon />
                            </div>
                            <p className={styles.link}>Generate report</p>
                        </div>
                    </div>
                )}
            </ul>
        </div>
    );
});

export default MenuBarBiz;