// eslint-disable-next-line
import cn from 'classnames';
import React from 'react';
import RadioLabelDynamic from 'src/components/base/radioLabels/radioLabelDynamic/RadioLabelDynamic';
import { CardDto } from 'src/util/interfaces';
import styles from '../lbaLargeBlock/LBALargeBlock.module.scss';

type Props = {
    card: CardDto,
    setPaymentStatus: (id: string, blocked: boolean) => void;
    isDisabled: boolean;
    selectedId: string;
}

const CardLargeBlock: React.FC<Props> = ({ card, setPaymentStatus, isDisabled, selectedId }) => {
    const {
        institutionName,
        lastFourDigits: accountNumber,
        zuroAccountId: id,
        firstName,
        isBlocked,
        lastName,
        line1: addressLine1,
        line2: addressLine2,
        city,
        region,
        postalCode,
        fundingAccount: isBackupAccount
    } = card;
    return (
        <div className={cn(styles.linkedAccountBlock, { [styles.highlightBorder]: selectedId === id })}>
            <div className={cn(styles.row, styles.headerRow)}>
                <div className={styles.headerWrapper}>
                    <div className={styles.leftContainer}>
                        <p className={cn(styles.institutionName)}>{institutionName}</p>
                        <p className={cn(styles.label, styles.smallLabel)}>xxxx{accountNumber}</p>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.buttonWrapper}>
                            <RadioLabelDynamic
                                setChecked={() => setPaymentStatus(id, false)}
                                isPositive={true}
                                isChecked={!isBlocked}
                                label="Unblock"
                                classNames={styles.RadioLabelDynamic}
                                disabled={isDisabled}
                            />
                            <RadioLabelDynamic
                                setChecked={() => setPaymentStatus(id, true)}
                                isPositive={false}
                                isChecked={isBlocked}
                                label="Block"
                                classNames={styles.RadioLabelDynamic}
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.contentBlock}>
                <div className={styles.ownerDetails}>
                    <div className={styles.availableBalanceRow}>
                        <p className={styles.availableBalanceLabel}>{`${firstName} ${lastName}`}</p>
                        <div className={styles.availableBalance}>
                            {/* <PercentBlock percent={ownerNameConfidence} /> */}
                        </div>
                    </div>
                    <div className={styles.ownerDetailsDoubleRow}>
                        <div className={styles.addressBlock}>
                            <p className={styles.availableBalanceLabel}>{addressLine1}</p>
                            <p className={styles.availableBalanceLabel}>{addressLine2}</p>
                            <p className={styles.availableBalanceLabel}>{`${city}, ${region} ${postalCode}`}</p>
                        </div>
                        <div className={cn(styles.availableBalance, styles.addressPercentContainer)}>
                            {/* <PercentBlock percent={addressConfidence} /> */}
                        </div>
                    </div>
                    <div className={styles.availableBalanceRow}>
                        {/* <p className={styles.secondaryText}>{ownerEmail}</p>
                        <div className={styles.percentContainer}>
                            <PercentBlock percent={emailConfidence} />
                        </div> */}
                    </div>
                    <div className={styles.availableBalanceRow}>
                        {/* <p className={styles.secondaryText}>{phoneNumber}</p> */}
                        {/* <div className={cn(styles.percentContainer, styles.lastPercentContainer)}>
                            <PercentBlock percent={phoneNumberConfidence} />
                        </div> */}
                    </div>
                </div>
                <div className={styles.accountDetails}>
                    <div className={styles.backupContainer}>
                        {isBackupAccount && <p className={cn(styles.statusContainer, styles.statusContainerDarkGrey)}>Backup</p>}
                    </div>
                    <div className={styles.availableBalanceRow}>
                        {/* <p className={cn(styles.secondaryText, styles.spacerText)}>Avail. Balance:</p>
                        <p className={cn(styles.secondaryText, styles.leftAlign)}>{availableBalance ? `$${getInNumberFormat(availableBalance)}` : ''}</p> */}
                    </div>
                    <div className={styles.availableBalanceRow}>
                        {/* <p className={cn(styles.secondaryText, styles.spacerText)}>No. of tx:</p>
                        <p className={cn(styles.secondaryText, styles.leftAlign)}>{getInNumberFormat(numberOfTransactions)}</p> */}
                    </div>
                    <div className={styles.availableBalanceRow}>
                        {/* <p className={cn(styles.secondaryText, styles.spacerText)}>Value of tx:</p>
                        <p className={cn(styles.secondaryText, styles.leftAlign)}>{valueOfTransactions ? `$${getInNumberFormat(valueOfTransactions)}` : ''}</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardLargeBlock;