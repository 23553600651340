import React from 'react';
import Navbar from '../navbar/Navbar';
import styles from './MainLayout.module.scss';

type Props = {
    children: JSX.Element[],
}

const MainLayout: React.FC<Props> = ({ children }) => (
    <div className={styles.contentWrapper}>
        <Navbar />
        {children}
    </div>
);

export default MainLayout;
