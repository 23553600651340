/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import { useStore } from 'src/store';
import SoleProprietorCard from '../amlSection/soleProprietorCard/SoleProprietorCard';
import styles from './ModuleActionsSection.module.scss';
import ModuleCard from './moduleCard/ModuleCard';

const ModuleSection: React.FC = observer(() => {
    const { CaseVerificationDetailPageStore } = useStore();
    const { caseId } = useParams();
    const fetch = useFetchLegacy();
    const form = CaseVerificationDetailPageStore.getForm();
    const selectedCustomer = CaseVerificationDetailPageStore.getSelectedCustomer();
    const pendingStatus = CaseVerificationDetailPageStore.getPendingStatus();
    const isAccountVerified = CaseVerificationDetailPageStore.getIsAccountVerified();

    useEffect(() => {
        const vendorId = form?.customers !== null && form.customers[0]?.vendorId;
        if (vendorId) {
            CaseVerificationDetailPageStore.setSelectedCustomerID(vendorId);
        }
    }, [form]);

    return (
        <>
            <div className={styles.headerContainer}>
                <div className={styles.moduleTextContainer}>
                    <h4>Module Actions</h4>
                </div>

                {isAccountVerified && <Link className={styles.accountLink} to={`/biz/account-details/${caseId}/view/payables`}>Account</Link>}

                <div className={cn(styles.contentContainer, { [styles.noPendingContentContainer]: isAccountVerified })}>
                    {!isAccountVerified && <div className={styles.pendingContainer}>
                        <div className={styles.pendingContent}>
                            <div className={cn(styles.pendingTextContainer, styles.textContainer)}>
                                <h4 className={styles.darkerText}>Pending Status:</h4>
                            </div>
                            <div className={styles.inputsContainer}>
                                <div className={styles.inputContainer}>
                                    <input className={styles.input} type='radio' id="ops" checked={pendingStatus === 'Ops'} onChange={() => { CaseVerificationDetailPageStore.setPendingStatus(fetch, 'Ops'); }} />
                                    <label htmlFor="ops" >Ops</label>
                                </div>
                                <div className={styles.inputContainer}>
                                    <input className={styles.input} type='radio' id="user" checked={pendingStatus === 'User'} onChange={() => { CaseVerificationDetailPageStore.setPendingStatus(fetch, 'User'); }} />
                                    <label htmlFor="user" >User</label>
                                </div>
                                <div className={styles.inputContainer}>
                                    <input className={styles.input} type='radio' id="dormant" checked={pendingStatus === 'isDormant'} onChange={() => { CaseVerificationDetailPageStore.setPendingStatus(fetch, 'isDormant'); }} />
                                    <label htmlFor="dormant" className={styles.dormantText}>Dormant</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className={cn(styles.documentsContainer, { [styles.noPendingDocumentsContainer]: isAccountVerified })}>
                        <div className={styles.zendeskContainer}>
                            <div className={cn(styles.linkContainer, styles.textContainer)}>
                                <a href="https://hopscotchsupport.zendesk.com/agent/dashboard" target="_blank" rel="noreferrer" className={cn(styles.zendeskLink, styles.smallerText)}>Zendesk</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <hr className={styles.hr} />
            <div className={styles.moduleActionsContainer}>
                <div className={styles.sectionHalf}>
                    {form?.business?.actionModules && form.business.actionModules.length > 0 ?
                        form.business.actionModules.map((module: { reviewed: boolean; label: string; message: string; }) =>
                            <ModuleCard key={module.message} reviewed={module.reviewed} label={module.label} business={true} message={module.message} />)
                        :
                        <SoleProprietorCard />
                    }
                </div>

                <div className={styles.sectionHalf}>
                    <div className={styles.normalContainer}>
                        {selectedCustomer?.actionModules.map((module: { reviewed: boolean; label: string; message: string; count: number }) =>
                            <ModuleCard key={module.label} reviewed={module.reviewed} label={module.label} business={false} message={module.count > 1 ? `${module.message}, +${module.count - 1}` : module.message} />)}
                    </div>
                    <div className={styles.toolTipContainer}>
                        <p>*All beneficial owners must be checked before making a decision on the account</p>
                    </div>
                </div>
            </div>
        </>
    );
});

export default ModuleSection;