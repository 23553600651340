// eslint-disable-next-line id-length
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Row from 'src/components/base/row/Row';
import { useStore } from 'src/store';
import { Customer } from '../CaseVerificationDetailPageStoreInterface';
import styles from './AMLSection.module.scss';
import BusinessCard from './businessCard/BusinessCard';
import CustomerCard from './customerCard/CustomerCard';
import SoleProprietorCard from './soleProprietorCard/SoleProprietorCard';

const AMLSection: React.FC = observer(() => {
    const { CaseVerificationDetailPageStore } = useStore();
    const { dbaName } = CaseVerificationDetailPageStore.case.business;
    return (
        <>
            <div className={styles.amlHeaderContainer}>
                <div className={styles.amlHeader}>
                    <h3 className={styles.amlText}>KYB</h3>
                </div>
                <div className={cn(styles.amlHeader, styles.kycHeader)}>
                    <h3 className={styles.amlText}>KYC</h3>
                </div>
            </div>

            <div className={styles.amlCardContainer}>
                <Row>
                    <div className={styles.amlCard}>
                        {dbaName ? <BusinessCard /> : <SoleProprietorCard />}
                    </div>
                    <div className={cn(styles.amlCard, styles.kycCardContainer)}>
                        {CaseVerificationDetailPageStore?.form?.customers?.map((customer: Customer) =>
                            <CustomerCard
                                key={customer.vendorId}
                                id={customer.vendorId}
                                name={customer.fullName}
                                linkText="Socure Profile"
                                link={customer.profileUrl}
                                externalUrl={CaseVerificationDetailPageStore.case.account.email}
                            />
                        )}
                    </div>
                </Row>
            </div>
        </>
    );
});

export default AMLSection;
