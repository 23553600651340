import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from 'src/store';
import RemoteDropdown from '../remoteDropdown/RemoteDropdown';
import styles from './AuditDropDown.module.scss';

/**
 * Only show the second Dropdown when we have selected a category. No need to show the second one when the first hasn't been selected yet.
 */
const AuditDropDown: React.FC = observer(() => {
    // @TODO: LALA - need to write a unit test for this; Can mock useStore by using example in useHasAuthorization.test.ts
    const { sharedStore } = useStore();
    const category = sharedStore.getAuditDropdownCategory();

    useEffect(() => () => {
        // this makes sure that we clear out the drop downs when we change pages.
        sharedStore.setAuditDropdownCategory('');
        sharedStore.setAuditDropdownSubCategory('');
    }, []);

    return (
        <div className={styles.auditDropDown}>
            <RemoteDropdown
                url="audit/categories"
                label="Action"
                name="audit"
                onChange={(e) => sharedStore.setAuditDropdownCategory(e)} />

            {category && <RemoteDropdown
                url={`audit/sub-categories?categoryId=${category}`}
                label="Reason"
                name="sub-audit"
                onChange={(e) => sharedStore.setAuditDropdownSubCategory(e)}
                className={styles.subCategory} />}
        </div>
    );
});


export default AuditDropDown;