import { PlanIds, PlanTypes, PLAN_NAMES_FOR_AUDIT, PLAN_NAMES_FULL, PLAN_NAMES_SHORT } from 'src/Constant';
import { Operations } from 'src/pages/paidPlanControls/paidPlanControlsInterface/PaidPlanControlsInterface';

interface IGetPlanName {
    planType: string,
    billingFrequency: string,
    isFullPlanName?: boolean
}

/**
 * @function getPlanName
 * @description Get the plan short or full name based on the plan type
 * @params object
 * {
 *  planType: string,
 *  billingFrequency: string,
 *  isFullPlanName: boolean - optional value - if want to get full name of plan
 * }
 * @returns string
 *
 * Examples:
 * - Hopscotch
 * - Pro (Free) / Hopscotch Pro (Free trial)
 * - Pro (Mon) / Hopscotch Pro - Monthly
 * - Pro (Ann) / Hopscotch Pro - Annual 
 */
export const getPlanName = ({ planType, billingFrequency, isFullPlanName = false }: IGetPlanName): string => {
    const plan = `${planType}${billingFrequency ?? ''}` as PlanTypes;

    return isFullPlanName ? PLAN_NAMES_FULL[plan] : PLAN_NAMES_SHORT[plan];
};

/**
 * @function getPlanAuditFullLabel
 * @description Get the plan short or full name based on the plan type
 * @params object operations
 * {
 *  fromEnabled: boolean;
 *  groupId: string;
 *  toEnabled: boolean;
 *  featureId: string
 *  description?: string;
 * }
 * @returns string
 *
 * Examples:
 * - " , removed Track invoices, bills, and payments in a single ledger from Hopscotch Pro"
 * - " , added Manually add and pay bills to Hopscotch Pro (Free trial)"
 */
export const getPlanAuditFullLabel = (operations: Operations): string => {
    const featureName = operations?.description ?? '';
    const planName = PLAN_NAMES_FOR_AUDIT[operations.groupId as PlanIds];
    let action = '';
    let actionFor = '';

    if (operations?.fromEnabled && !operations?.toEnabled) {
        action = 'removed';
        actionFor = 'from';

    } else if (!operations?.fromEnabled && operations?.toEnabled) {
        action = 'added';
        actionFor = 'to';
    }

    return `, ${action} ${featureName} ${actionFor} ${planName}`;
};

export default getPlanName;