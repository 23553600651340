import React from 'react';
import styles from './Section.module.scss';

type Props = {
    children: any;
}

const Section: React.FC<Props> = ({ children }): JSX.Element => (
    <div className={styles.section}>{children}</div>
);

export default Section;