import { makeAutoObservable } from 'mobx';
import { FetchInterface } from 'src/api/useFetch';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { FACTORED_INVOICES_COUNT_URL, FACTORED_INVOICES_URL } from 'src/Constant';
import { FactoredInvoices, FactoredInvoicesSearchCount } from './FactoredInvoiceSearchPageInterface';

export interface FactoredInvoicesSearchPageStoreInterface {
    getCounts(): FactoredInvoicesSearchCount;
    getInvoiceUrl(): void;
    fetchCasesCount(fetch: FetchInterface<FactoredInvoicesSearchCount>): void;
    fetchInvoices(fetch: FetchInterface<FactoredInvoices[]>): void;
    setType(type: any): void;
    getFactoredInvoices(): FactoredInvoices[];
    factoredInvoices: FactoredInvoices[],
}

const FactoredInvoicesSearchPageStore = (): FactoredInvoicesSearchPageStoreInterface =>
    makeAutoObservable({
        type: '',
        allCount: 0,
        rejectedCount: 0,
        pastDueCount: 0,
        factoredInvoices: [] as FactoredInvoices[],
        getCounts(): FactoredInvoicesSearchCount {
            return {
                rejectedCount: this.rejectedCount,
                pastDueCount: this.pastDueCount,
                allCount: this.allCount,
            };
        },
        getFactoredInvoices() {
            return this.factoredInvoices;
        },
        getInvoiceUrl() {
            if (this.type === 'all') {
                return `${FACTORED_INVOICES_URL}`;
            }
            if (this.type === 'pastDue') {
                return `${FACTORED_INVOICES_URL}?type=pastDue`;
            }
            if (this.type === 'rejected') {
                return `${FACTORED_INVOICES_URL}?type=rejected`;
            }

            return `${FACTORED_INVOICES_URL}?type=rejected`;
        },
        fetchInvoices(fetch: FetchInterface<FactoredInvoices[]>) {
            fetch({ url: this.getInvoiceUrl() }, (res) => {
                this.factoredInvoices = res.data;
            });
        },
        fetchCasesCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: `${FACTORED_INVOICES_COUNT_URL}` }, (res) => {
                this.allCount = res.data;
            });
            fetch({ url: `${FACTORED_INVOICES_COUNT_URL}?type=rejected` }, (res) => {
                this.rejectedCount = res.data;
            });
            fetch({ url: `${FACTORED_INVOICES_COUNT_URL}?type=pastDue` }, (res) => {
                this.pastDueCount = res.data;
            });
        },
        setType(type: string) {
            this.type = type;
        }
    });

export default FactoredInvoicesSearchPageStore;
