import React from 'react';
import CardLargeBlock from 'src/components/business/cardLargeBlock/CardLargeBlock';
import DBALargeBlock from 'src/components/business/dbaLargeBlock/DBALargeBlock';
import LBALargeBlock from 'src/components/business/lbaLargeBlock/LBALargeBlock';
import { AccountDetailsLinkedBankAccount, CardDto } from 'src/util/interfaces';
import { DeletedPayments, DirectBankAccountDto } from '../../../AccountDetailsPageStoreInterface';
import { PaymentTypeTab } from '../AccountActionsSection';
import DeletedPaymentMethodList from './deletedPaymentMethodList/DeletedPaymentMethodList';

type props = {
    selectedId: string;
    paymentType: keyof typeof PaymentTypeTab,
    linkedAccounts: AccountDetailsLinkedBankAccount[];
    directBankAccounts: DirectBankAccountDto[];
    cards: CardDto[];
    deletedPayments: DeletedPayments;
    classNames: string;
    isDisabled: (id: string) => boolean;
    setCardPaymentStatus: (id: string, blocked: boolean) => void;
}

const PaymentMethodList: React.FC<props> = ({ selectedId, paymentType, linkedAccounts, cards, directBankAccounts, deletedPayments, isDisabled, setCardPaymentStatus, classNames }) => <div className={classNames}>
    {paymentType === PaymentTypeTab.LBA && linkedAccounts.map(lba => <LBALargeBlock
        selectedId={selectedId ?? ''}
        key={lba.id}
        lba={lba}
        isDisabled={isDisabled(lba.id)}
    />)}
    {paymentType === PaymentTypeTab.Card && cards
        .map(card => <CardLargeBlock
            key={card.zuroAccountId}
            card={card}
            setPaymentStatus={setCardPaymentStatus}
            isDisabled={isDisabled(card.zuroAccountId)}
            selectedId={selectedId ?? ''}
        />)}
    {paymentType === PaymentTypeTab.DBA && directBankAccounts
        .map(directBankAccount =>
            <DBALargeBlock
                key={directBankAccount.zuroAccountId}
                directBankAccount={directBankAccount}
                selectedId={selectedId ?? ''}
            />)}

    {paymentType === PaymentTypeTab.Deleted && <DeletedPaymentMethodList
        selectedId={selectedId}
        deletedPayments={deletedPayments} />}
</div>;

export default PaymentMethodList;