// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import styles from 'src/static-assets/styles/base/_greyText.module.scss';

type Props = {
    children: React.ReactNode;
    isCurrency?: boolean;
    title?: string
    classNames?: string;

};

const GreyText: React.FC<Props> = ({ children, isCurrency = false, title, classNames }) => (
    <p className={cn(styles.text, classNames)}>
        {!isCurrency && children}
        {title} {isCurrency && Number(children).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })}
    </p>
);

export default GreyText;