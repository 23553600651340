import { observer } from 'mobx-react-lite';
import React from 'react';
import { Params, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import BaseAuditHistoryPopup from 'src/components/base/popups/baseAuditHistoryPopup/BaseAuditHistoryPopup';
import { useStore } from 'src/store';
import InvoiceDetailsAuditContent from './invoiceDetailsAuditContent/InvoiceDetailsAuditContent';

type InvoiceDetailsAuditPopupProps = {
    popupVisible: boolean;
    hidePopup: any;
};

const InvoiceDetailsAuditPopup: React.FC<InvoiceDetailsAuditPopupProps> = observer(({ popupVisible, hidePopup }) => {
    const { InvoiceDetailsPageStore } = useStore();
    const { invoiceId }: Readonly<Params<string>> = useParams();
    const fetch = useFetchLegacy();
    const histories = InvoiceDetailsPageStore.getAuditInvoicesHistory();

    return (
        <BaseAuditHistoryPopup
            popupVisible={popupVisible}
            hidePopup={hidePopup}
            title="Invoice History"
            updateCallBack={() => InvoiceDetailsPageStore.fetchInvoicesAuditCSV(fetch, invoiceId ?? '')}
        >
            <InvoiceDetailsAuditContent histories={histories} />
        </BaseAuditHistoryPopup>
    );
});

export default InvoiceDetailsAuditPopup;