import { AccountDetailsLinkedBankAccount, CardDto, ExternalDetails, PaymentType, RiskScore, SubscriptionDetails } from 'src/util/interfaces';

export const AccountPageTab = {
    info: 'info',
    actions: 'actions'
};

export type DeletedPayments = (AccountDetailsLinkedBankAccount | DirectBankAccountDto | CardDto)[];

export type Form = {
    isRestricted: boolean;
    riskScore: keyof typeof RiskScore;
    invoiceLimit: number;
    factoringLimit: number;
    customLimits: {
        transaction: number;
        payee: number;
        payor: number;
        dailyLimit: number;
    }
    reason: string;
    linkedAccountDetails: any;
    paymentIdChange: string;
    isLocked: boolean;
    cards: CardDto[];
    directBankAccounts: DirectBankAccountDto[];
    flowData: {
        availableCreditLimit: number;
        creditLimit: number;
        approvalStatus: string;
    }
}

export interface AuditHistory {
    id: string;
    modalId: string;
    date: string;
    operator: string;
    freezeAccount: boolean;
    invoicingLimit: number;
    operationReason?: string;
    operations?: Operations
}

type Operations = {
    Category: string;
    SubCategory: string;
    freezeAccount?: boolean;
    toLockStatus?: boolean;
    fromLockStatus?: boolean;
    limit?: string;
    fromLimit?: string;
    toFactoring?: string;
    fromFactoring?: string;
    riskStatus?: string;
    fromBlockedStatus?: boolean;
    toBlockedStatus?: boolean;
    linkedBankAccountId?: boolean;
    CardId?: string;
}

export type Account = {
    verificationStatus: string;
    dbaName: string;
    bizId: string;
    userId: string;
    name: string;
    legalBusinessName: string;
    email: string;
    caseStatus: string;
    hopscotchBalance: string;
    columnBalance: string;
    invoiceLimit: number;
    factoringLimit: number;
    customLimits: {
        transaction: number;
        payee: number;
        payor: number;
        dailyLimit: number;
    }
    hasInvoiceLimitBeenUpdated: boolean;
    transactionVolume: string;
    vendorId: string;
    createdTimestamp: number;
    address: Address,
    transactions: MonetaryInvoice[],
    businessLogo: string;
    beneficialOwners: string[],
    lastUpdatedBy: string;
    lastUpdatedDate: string;
    isFrozen: boolean;
    isLocked: boolean;
    reason: string;
    averageTransactionAmount: string;
    riskScore: keyof typeof RiskScore;
    businessType: string;
    website: string;
    industry: string;
    primaryAccountOwner: string;
    externalDetails: ExternalDetails,
    subscriptionDetails: Nullable<SubscriptionDetails>;
    linkedAccounts: AccountDetailsLinkedBankAccount[],
    cards: CardDto[],
    directBankAccounts: DirectBankAccountDto[]
    deletedPayments: DeletedPayments;
    flowData: {
        availableCreditLimit: number;
        creditLimit: number;
        approvalStatus: string;
    },
    tpAccountId: string
}

type Address = {
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    postal_code: number;
    country_code: string;
}

export type TransactionSummary = {
    payablesBalance: number;
    payablesPastDue: number;
    payablesNextSeven: number;
    receivablesBalance: number;
    receivablesPastDue: number;
    receivablesNextSeven: number;
    completedPayables: number;
    completedReceivables: number;
    completedNetFlow: number;
    completedFlowTotal: number;
}

export type TransactionSummaryPayload = {
    payablesTotal: number;
    pastDuePayablesTotal: number;
    duePayablesTotalInNext7Days: number;
    receivablesTotal: number;
    pastDueReceivablesTotal: number;
    dueReceivablesTotalInNext7Days: number;
    completedPayablesTotal: number;
    completedReceivablesTotal: number;
    completedFlowTotal: number;
}

export type DirectBankAccountDto = {
    accountHolderName: string,
    accountNumber: string,
    createdAt: number,
    hasOpsUpdated: boolean;
    institutionName: string;
    isBlocked: boolean;
    isDeleted: boolean;
    isDepositAccount: boolean;
    isFundingAccount: boolean;
    routingNumber: string;
    zuroAccountId: string;
    paymentType: keyof typeof PaymentType;
}

export type MonetaryInvoice = {
    amount: string;
    bizId: string;
    date: string;
    dbaName: string
    id: string;
    logo: Nullable<string>;
    type: Nullable<string>;
    status: Nullable<string>;
    accountNumbers: Nullable<string>;
    source: 'Invoice' | 'Transaction'
}