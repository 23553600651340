import className from 'classnames';
import React from 'react';
import CountUp from 'react-countup';
import styles from './CurrencyValue.module.scss';

type Props = {
    children: any;
    isAnimated?: boolean;
    classNames?: string;
}

const CurrencyValue: React.FC<Props> = ({ children, isAnimated = false, classNames }): JSX.Element => (
    <span className={className(styles.currency, classNames)}>
        <span className={styles.dollarSymbol}>$</span>
        {!isAnimated && Number(children).toLocaleString('en-US', { currency: 'USD', minimumFractionDigits: 2 })}
        {isAnimated && <CountUp className={styles.countUpAnimation} end={children} separator=',' decimal="." decimals={2} />}
    </span>
);

export default CurrencyValue;
