/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
// eslint-disable-next-line id-length
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'src/store';
import styles from './CustomerCard.module.scss';

interface Props {
    name: string;
    link: string;
    linkText: string;
    externalUrl: string;
    id: string;
    business?: boolean
}

const CustomerCard: React.FC<Props> = observer(({ id, name, link, linkText, externalUrl, business }) => {
    const { CaseVerificationDetailPageStore } = useStore();
    const isSelected = CaseVerificationDetailPageStore.selectedCustomerID === id;
    return (
        <div className={cn([styles.card, { [styles.cardActive]: isSelected }])} onClick={() => business ?? CaseVerificationDetailPageStore.setSelectedCustomerID(id)}>
            <div className={styles.cardContentWrapper}>
                <div className={styles.companyNameLinkContainer}>
                    <h3 className={styles.name}>{name}</h3>
                    <div className={styles.linkContainer}>
                        <a href={link} className={styles.link} target="_blank" rel="noreferrer">
                            {linkText}
                        </a>
                    </div>
                </div>
                <div className={styles.emailContainer}>
                    <p>{externalUrl}</p>
                </div>
            </div>
        </div>
    );
});

export default CustomerCard;
