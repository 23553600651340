// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import styles from './Loader.module.scss';

interface LoaderProps {
    isLoading: boolean;
};

const Loader: React.FC<LoaderProps> = ({ isLoading }) => <div className={cn({
    [styles.center]: isLoading,
    [styles.loader]: isLoading
})}>
    <i className="fa fa-spinner fa-spin" />
</div>;

export default Loader;