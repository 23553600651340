import React from 'react';
import Button from '../button/Button';
import styles from './CancelButton.module.scss';

type ButtonProps = {
    disabled?: boolean;
    onClick?: () => void;
};

const CancelButton: React.FC<ButtonProps> = ({ disabled, onClick, children }) => (
    <Button
        disabled={disabled}
        onClick={onClick}
        classNames={styles.isCancel}
        data-testid="Button"
    >
        {children || 'Cancel'}
    </Button>
);

export default CancelButton;