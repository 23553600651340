import React from 'react';
import BaseConfirmationPopup from 'src/components/base/popups/baseConfirmationPopup/BaseConfirmationPopup';
import AccountLink from 'src/components/business/accounts/accountLink/AccountLink';
import styles from 'src/pages/invoiceDetailsPage/cancelActionPopup/CancelActionPopup.module.scss';
import { riskScoreType } from 'src/util/interfaces';

type InvoiceDetailsPopupProps = {
    popupVisible: boolean;
    hidePopup: () => void;
    modalId: string;
    updateCallback: any;
    reason?: string;
    dbaName: string;
    riskScore: riskScoreType,
    isAccountVerified: boolean;
    createdTimeStamp: string
    accountLogo: string;
    planType: string;
};

const CancelActionPopup: React.FC<InvoiceDetailsPopupProps> = ({ popupVisible, hidePopup, modalId, updateCallback, reason, dbaName, riskScore, isAccountVerified, createdTimeStamp, accountLogo, planType }) => (
    <BaseConfirmationPopup
        popupVisible={popupVisible}
        hidePopup={hidePopup}
        modalId={modalId}
        updateCallback={updateCallback}
        reason={reason}
    >
        <>
            <p className={styles.accountText}>Hopscotch Account</p><div className={styles.accountContainer}>
                <div className={styles.accountLinkContainer}>
                    <AccountLink dbaName={dbaName} score={riskScore} isVerified={isAccountVerified} createdTimestamp={createdTimeStamp} accountLogo={accountLogo} planType={planType} />
                </div>
                <br />
                <p>Clicking confirm will cancel the invoice and remove it from the payee & payor’s queue.</p>

                <div className={styles.statusContainer}>
                    <p className={styles.statusHeader}>
                        Current status
                        <br />
                        <br />
                        <span className={styles.pendingStatus}>Pending</span>
                    </p>
                    <p className={styles.statusHeader}>
                        Updated status
                        <br />
                        <br />
                        <span className={styles.cancelledStatus}>Cancelled</span>
                    </p>
                </div>
            </div>
        </>
    </BaseConfirmationPopup>
);

export default CancelActionPopup;
