import React from 'react';

const InfoIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Outlined" transform="translate(-442.000000, -288.000000)">
                <g id="Action" transform="translate(100.000000, 100.000000)">
                    <g id="Outlined-/-Action-/-info" transform="translate(340.000000, 186.000000)">
                        <g>
                            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                            <path d="M11,7 L13,7 L13,9 L11,9 L11,7 Z M11,11 L13,11 L13,17 L11,17 L11,11 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z" id="🔹-Icon-Color" fill="#1D1D1D" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default InfoIcon;