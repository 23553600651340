import Moment from 'moment';
import React from 'react';
import styles from './AuditHistoryTransactionsContent.module.scss';

export interface props {
    auditTransaction: {
        id: string,
        modalId: string,
        date: string,
        operator: string,
        operationReason: string,
        flagType: string,
        flagTransaction: boolean,
    }
}

const AuditHistoryTransactionsItem: React.FC<props> = ({ auditTransaction }) => {

    const id = auditTransaction.id;
    const modalId = auditTransaction.modalId;
    const formattedDate = Moment(auditTransaction.date).format('MMMM DD, YYYY');
    const operator = auditTransaction.operator;
    const flagType = auditTransaction.flagType;
    const flagTransaction = auditTransaction.flagTransaction;
    const flagTypeLabel = auditTransaction.flagType ? 'this transaction' : '';
    const flaggingLabel = auditTransaction.flagTransaction === true ? 'Flagged this transaction' : '';
    const unflaggingLabel = auditTransaction.flagTransaction === false ? 'Unflagged this transaction' : '';

    return (<div className={styles.historyContainer} key={id} id={modalId}>
        <div>
            <h4 className={styles.info}>{formattedDate} - {operator}, {flagTransaction}{flagType} {flagTypeLabel}{flaggingLabel}{unflaggingLabel}</h4>
            <p>{auditTransaction.operationReason}</p>
        </div>
    </div>);
};

export default AuditHistoryTransactionsItem;

