/* eslint-disable react/require-default-props */
import React from 'react';
import styles from './TextInput.module.scss';

type Props = {
    placeholder?: string;
    value?: string;
    onChange?: any;
    name?: string;
};

const TextInput: React.FC<Props> = ({ placeholder, value, onChange, name }) => <input name={name} type="text" placeholder={placeholder} className={styles.input} onChange={(e) => onChange(e.target.value)} value={value} />;

export default TextInput;


