/* eslint-disable @typescript-eslint/no-non-null-assertion */
// eslint-disable-next-line id-length
import { Column, HeaderFilter } from 'devextreme-react/data-grid';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import LinkButton from 'src/components/base/buttons/linkButton/LinkButton';
import LinkButtonWrapper from 'src/components/base/buttons/linkButton/linkButtonWrapper/LinkButtonWrapper';
import DataGridWrapper from 'src/components/base/dataGridWrapper/DataGridWrapper';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import { GREY_TEXT_COLOR, INVOICE_DETAILS_URL, VERTICAL_ALIGN } from 'src/Constant';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import { renderAccountLogo, renderAmountCell, renderStatusCell, renderTwoLevelHeader } from 'src/util';
import currencyColumnFilter from 'src/util/datagrid/filters/currencyColumnFilter';
import renderTextCell from 'src/util/datagrid/renderTextCell';
import rowType from 'src/util/datagrid/rowType';
import RenderPayorPaymentCell from '../../util/datagrid/cellRenders/renderPayorPaymentCell/RenderPayorPaymentCell';
import styles from './InvoicesSearchPage.module.scss';

const InvoicesSearchPage: React.FC = observer(() => {
    const [searchParam] = useSearchParams();
    const { InvoicesSearchPageStore, sharedStore } = useStore();
    const fetch = useFetchLegacy();
    const paramType = searchParam.get('type');
    InvoicesSearchPageStore.setType(paramType!);
    const invoices = InvoicesSearchPageStore.getInvoices();
    const { submittedCount, rejectedCount, paidCount, allCount } = InvoicesSearchPageStore.getCounts();

    useEffect(() => {
        InvoicesSearchPageStore.fetchInvoices(fetch);
        InvoicesSearchPageStore.fetchInvoicesCount(fetch);
    }, [InvoicesSearchPageStore, paramType]);

    const onCellPrepared = (e: CellPreparedEvent<{ value: string, invoiceAmount: number }, unknown>) => {
        if (rowType(e) && e.column) {
            e.cellElement.style.color = GREY_TEXT_COLOR;
            e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
        }
    };

    const renderPayeeCell = (rowData: { payeeUserName: string; payeeBusinessName: string; }) => <p className={styles.userData}><span className={styles.businessName}>{rowData.payeeBusinessName}</span> <br /> {rowData.payeeUserName}</p>;
    const renderPayorCell = (rowData: { payorUserName: string; payorBusinessName: string; }) => <p className={styles.userData}><span className={styles.businessName}>{rowData.payorBusinessName}</span><br /> {rowData.payorUserName}</p>;
    const textValue = sharedStore.textValue;

    const handleTextChange = (event: string) => {
        sharedStore.setTextValue(event);
    };

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Invoices</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <LinkButtonWrapper>
                            <LinkButton
                                isActive={paramType === 'all'}
                                store={InvoicesSearchPageStore}
                                to="?type=all"
                                count={allCount}
                                label="All"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramType === 'submitted'}
                                store={InvoicesSearchPageStore}
                                to="?type=submitted"
                                count={submittedCount}
                                label="Submitted"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramType === 'rejected'}
                                store={InvoicesSearchPageStore}
                                to="?type=rejected"
                                count={rejectedCount}
                                label="Rejected"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramType === 'paid'}
                                store={InvoicesSearchPageStore}
                                to="?type=paid"
                                count={paidCount}
                                label="Paid"
                            />
                        </LinkButtonWrapper>

                        <DataGridWrapper
                            dataSource={invoices}
                            onCellPrepared={onCellPrepared}
                            keyExp='invoiceID'
                            navigator={INVOICE_DETAILS_URL}
                            wordWrapEnabled={true}
                            headerFilter={true}
                            textValue={textValue}
                            handleTextChange={handleTextChange}
                        >
                            <Column
                                caption="Payee"
                                dataField="payeeAccountLogo"
                                allowSorting={false}
                                cellRender={renderAccountLogo}
                                width="auto"
                                allowHeaderFiltering={false}
                                allowFiltering={true}
                            />
                            <Column
                                caption=""
                                allowSorting={false}
                                cellRender={renderTextCell}
                                calculateCellValue={renderPayeeCell}
                                width="auto"
                            />
                            <Column
                                caption="Payor"
                                dataField="payorAccountLogo"
                                allowSorting={false}
                                cellRender={renderAccountLogo}
                                allowFiltering={true}
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                caption=""
                                allowSorting={false}
                                cellRender={renderTextCell}
                                calculateCellValue={renderPayorCell}
                                width="auto"
                            />
                            <Column width="100%" />
                            <Column
                                caption="ID"
                                dataField="invoiceID"
                                allowSorting={false}
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                caption="Type"
                                dataField="invoiceType"
                                width="auto"
                            />
                            <Column
                                dataField="payorPaymentType"
                                caption="Payment Method"
                                cellRender={RenderPayorPaymentCell}
                                dataType="string"
                                width={200}
                            />
                            <Column
                                caption="Status"
                                dataField="invoiceState"
                                cellRender={renderStatusCell}
                                width="auto"
                            />
                            <Column
                                caption="Amount"
                                dataField="invoiceAmount"
                                cellRender={renderAmountCell}
                                width="auto"
                                alignment="left"
                                format="currency"
                            >
                                <HeaderFilter dataSource={currencyColumnFilter('invoiceAmount')} />
                            </Column>
                            <Column
                                headerCellRender={() => renderTwoLevelHeader('Submitted', 'date')}
                                allowSearch={true}
                                allowFiltering={true}
                                allowSorting={true}
                                dataField="submittedDate"
                                alignment="left"
                                width={110}
                                dataType="date"
                                format="MMM dd, yyyy"
                            />
                            <Column
                                caption="Due date"
                                allowSearch={true}
                                allowFiltering={true}
                                allowSorting={true}
                                dataField="dueDate"
                                alignment="left"
                                width={110}
                                dataType="date"
                                format="MMM dd, yyyy"
                            />
                            <Column
                                dataField="payorUserName"
                                allowFiltering={true}
                                visible={false}
                            />
                            <Column
                                dataField="payeeUserName"
                                allowFiltering={true}
                                visible={false}
                            />
                            <Column
                                dataField="payeeBusinessName"
                                allowFiltering={true}
                                visible={false}
                            />
                            <Column
                                dataField="payorBusinessName"
                                allowFiltering={true}
                                visible={false}
                            />
                        </DataGridWrapper>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});
export default InvoicesSearchPage;