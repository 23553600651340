import getInCurrencyFormat from '../../getInCurrencyFormat';

const renderAmountCell = (data: { value: number | string }): string => {
    const amountValue = data.value ?? 0;
    if (typeof amountValue === 'string') {
        // eslint-disable-next-line no-restricted-globals
        const val = isNaN(Number(amountValue)) ? 0 : Number(amountValue);
        return getInCurrencyFormat(val);
    }
    return getInCurrencyFormat(amountValue);
};
export default renderAmountCell;
