import React from 'react';
import CardLargeBlock from 'src/components/business/cardLargeBlock/CardLargeBlock';
import DBALargeBlock from 'src/components/business/dbaLargeBlock/DBALargeBlock';
import LBALargeBlock from 'src/components/business/lbaLargeBlock/LBALargeBlock';
import { AccountDetailsLinkedBankAccount, CardDto, PaymentType } from 'src/util/interfaces';
import { DeletedPayments, DirectBankAccountDto } from '../../../../AccountDetailsPageStoreInterface';

type props = {
    selectedId: string;
    deletedPayments: DeletedPayments;
}
const DeletedPaymentMethodList: React.FC<props> = ({ selectedId, deletedPayments }) => <div>
    {deletedPayments.map(deletedPaymentMethod => {
        if ((deletedPaymentMethod as AccountDetailsLinkedBankAccount)?.paymentType === PaymentType.LinkedBankAccount) {
            return (<LBALargeBlock
                selectedId={selectedId ?? ''}
                key={(deletedPaymentMethod as AccountDetailsLinkedBankAccount).id}
                lba={deletedPaymentMethod}
                isDisabled={true}
            />);
        }

        if ((deletedPaymentMethod as CardDto)?.paymentType === (PaymentType.CreditCard as keyof typeof PaymentType.CreditCard)
            || (deletedPaymentMethod as CardDto)?.paymentType === (PaymentType.CreditCard as keyof typeof PaymentType.DebitCard)) {
            return (<CardLargeBlock
                key={(deletedPaymentMethod as CardDto).zuroAccountId}
                card={(deletedPaymentMethod as CardDto)}
                setPaymentStatus={() => { }}
                isDisabled={true}
                selectedId={selectedId ?? ''}
            />);

        }

        if ((deletedPaymentMethod as DirectBankAccountDto)?.paymentType === (PaymentType.DirectBankAccount)) {
            return (<DBALargeBlock
                key={(deletedPaymentMethod as DirectBankAccountDto).zuroAccountId}
                directBankAccount={(deletedPaymentMethod as DirectBankAccountDto)}
                selectedId={selectedId ?? ''}
            />);
        }

        return null;
    })}
</div>;

export default DeletedPaymentMethodList;