import React from 'react';
import styles from './ReasoningSection.module.scss';

type ReasoningSectionProps = {
    userReasoning: string;
}
const ReasoningSection: React.FC<ReasoningSectionProps> = ({ userReasoning }): JSX.Element => (
    <div>
        <h4 className={styles.reasonHeader}>
            Reasoning.
        </h4>
        <div className={styles.userInput}>
            {userReasoning}
        </div>
    </div>
);

export default ReasoningSection;