import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { CHANGE_GLOBAL_INVOICE_LIMIT_URL, PROGRAM_CONTROLS_URL, TOAST_FAILURE_TEXT, TOAST_SUCCESS_TEXT } from 'src/Constant';
import { BankPartnerInterface } from './ProgramControlsPageInterface';

export interface ProgramControlsPageInterface {
    limits: {
        invoiceLimit: number
    },
    form: {
        invoiceLimit: number
    },
    bankPartner: BankPartnerInterface,
    getInvoiceLimit(): any,
    setInvoiceLimit(limit: number): void,
    getProgramAvailableBalance(): number,
    getProgramCurrentBalance(): number,
    getStandInAvailableBalance(): number,
    getStandInCurrentBalance(): number,
    getFactoringAvailableBalance(): number,
    getFactoringCurrentBalance(): number,
    getFeeAndChargebackAvailableBalance(): number,
    getFeeAndChargebackCurrentBalance(): number,
    setSystemInvoiceLimit(fetch: FetchInterfaceLegacy): void,
    fetchProgramControlsBalances(fetch: FetchInterfaceLegacy): void
}

const ProgramControlsPageStore = (): ProgramControlsPageInterface =>
    makeAutoObservable({
        limits: {
            invoiceLimit: 0
        },
        form: {
            invoiceLimit: 0
        },
        bankPartner: {
            programBalance: {
                available: 0,
                current: 0,
            },
            standInBalance: {
                available: 0,
                current: 0,
            },
            factoringBalance: {
                available: 0,
                current: 0,
            },
            feeAndChargebackBalance: {
                available: 0,
                current: 0,
            }
        },
        getInvoiceLimit(): number {
            return this.form.invoiceLimit;
        },
        setInvoiceLimit(limit: number) {
            this.form.invoiceLimit = limit;
        },
        getProgramAvailableBalance() {
            return this.bankPartner.programBalance.available;
        },
        getProgramCurrentBalance() {
            return this.bankPartner.programBalance.current;
        },
        getStandInAvailableBalance() {
            return this.bankPartner.standInBalance.available;
        },
        getStandInCurrentBalance() {
            return this.bankPartner.standInBalance.current;
        },
        getFactoringAvailableBalance() {
            return this.bankPartner.factoringBalance.available;
        },
        getFactoringCurrentBalance() {
            return this.bankPartner.factoringBalance.current;
        },
        getFeeAndChargebackAvailableBalance() {
            return this.bankPartner.feeAndChargebackBalance.available;
        },
        getFeeAndChargebackCurrentBalance() {
            return this.bankPartner.feeAndChargebackBalance.current;
        },
        setSystemInvoiceLimit(fetch: FetchInterfaceLegacy) {
            fetch({
                url: CHANGE_GLOBAL_INVOICE_LIMIT_URL,
                method: 'PUT',
                data: {
                    globalLimitKey: 'GlobalInvoiceLimit',
                    globalLimitValue: this.form.invoiceLimit,
                    reason: 'Testing Global Limit Update via Admin Service in Hop'
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            }, (res: any) => {

            },
                true,
                { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT }
            );
        },
        fetchProgramControlsBalances(fetch: FetchInterfaceLegacy): void {
            fetch({ url: PROGRAM_CONTROLS_URL }, ({ data }) => {
                this.form.invoiceLimit = data.GlobalInvoiceLimit;
                this.limits = data.GlobalInvoiceLimit;
                this.bankPartner = {
                    ...data,
                    programBalance: {
                        available: data.fboBalance.availableBalance.number,
                        current: data.fboBalance.pendingBalance.number,
                    },
                    standInBalance: {
                        available: data.standInBalance.availableBalance.number,
                        current: data.standInBalance.pendingBalance.number,
                    },
                    factoringBalance: {
                        available: data.factoringBalance.availableBalance.number,
                        current: data.factoringBalance.pendingBalance.number,
                    },
                    feeAndChargebackBalance: {
                        available: data.FeeAndChargebackBalance.availableBalance.number,
                        current: data.FeeAndChargebackBalance.pendingBalance.number,
                    }
                };
            },
                true
            );
        },
    });

export default ProgramControlsPageStore;

