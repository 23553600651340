/* eslint-disable no-unsafe-optional-chaining */
import fileDownload from 'js-file-download';
import { makeAutoObservable } from 'mobx';
import Moment from 'moment';
import { FetchInterface } from 'src/api/useFetch';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import {
    AUDIT_HISTORY_INVOICES_CSV_URL,
    AUDIT_HISTORY_INVOICES_URL,
    FACTORED_INVOICE_DETAILS_API,
    INVOICE_LINE_ITEMS_URL, MARK_AS_UN_PAID, OPERATE_FACTORED_INVOICE_URL,
    REVERSE, TOAST_FAILURE_TEXT,
    TOAST_SUCCESS_TEXT
} from 'src/Constant';
import getPlanName from 'src/util/PlanUtils';
import {
    invoiceDetailPayeePayorSelector,
    selectInvoiceHeader,
    selectMutualTransactions
} from 'src/util/selectors';
import { Action, AuditHistoryEntity, Form, Invoice, InvoiceItemsEntity, MutualTransaction, TotalDetails } from './InvoiceDetailsPageStoreInterface';

export interface InvoiceDetailsPageStoreInterface {
    form: Form;
    modalStatus: string;
    invoice: Invoice;
    auditHistory?: (AuditHistoryEntity)[] | null;
    invoiceItems?: (InvoiceItemsEntity)[] | null;
    transactionId: string;
    totalDetails: TotalDetails;

    getAuditInvoicesHistory(): AuditHistoryEntity[],
    getInvoiceState(): string,
    getInvoiceItems(): InvoiceItemsEntity[],
    getInvoiceTotalDetails(): any,
    getTransactionId(): string,
    getIsCoveredToggle(): boolean,
    getIsCancelledToggle(): boolean,
    setIsCancelledToggle(toggleStatus: boolean): void,
    setIsCoveredToggle(toggleStatus: boolean): void,
    setAmlRiskToggle(toggleStatus: boolean): void,
    setReversedRejectToggle(toggleStatus: boolean): void,
    setMarkAsUnPaidToggle(toggleStatus: boolean): void,
    getIsCoverable(): boolean,
    getIsFormReady(): boolean,
    setReason(reason: string): void,
    getModalStatus(): string,
    setModalStatus(status: string): void,
    getBaseInvoiceDetails(): any,
    getFormReason(): string,
    getTransactionDetails(): any,
    getDateOfLastMutualTransaction(): string,
    getSumOfAllMutualTransactions(): number,
    getTransactionStatus(): string,
    getTotalMutualTransactionsCount(): number,
    getPayorDbaName(): string,
    getPayeeDbaName(): string,
    handleToggleChange(modal: string): void,
    cancel(): void,
    fetchInvoiceDetails(fetch: FetchInterface<Invoice>, invoiceId: string): void,
    payloadHandler(data: any): void,
    fetchInvoicesAuditCSV(fetch: FetchInterfaceLegacy, invoiceId: string): void,
    fetchAuditHistoryInvoices(fetch: FetchInterface<AuditHistoryEntity>, invoiceId: string): void,
    fetchInvoicesItems(fetch: FetchInterface<InvoiceItemsEntity>, invoiceId: string): void,
    updateInvoice(fetch: FetchInterfaceLegacy): void,
    operateOnInvoice(fetch: FetchInterfaceLegacy): void
}

const InvoiceDetailsPageStore = (sharedStore: {
    getAuditDropdownCategory: () => string,
    getAuditDropdownSubCategory: () => string,
    setAuditDropdownCategory(category: string): void
}): InvoiceDetailsPageStoreInterface =>
    makeAutoObservable({
        form: {
            isCovered: false as boolean,
            isCoverable: false as boolean,
            isAmlRisk: false as boolean,
            isReversedReject: false as boolean,
            isMarkAsUnPaid: false as boolean,
            isCancelled: false as boolean,
            reason: '',
        },
        modalStatus: '',
        invoice: {
            causationIdType: '',
            invoiceId: '',
            type: '',
            invoiceType: '',
            invoiceAmount: 0,
            transferAmount: 0,
            transferStatus: '',
            invoiceDate: '',
            dueDate: '',
            invoiceState: '',
            isCovered: false as boolean,
            isAmlRisk: false as boolean,
            isReversedReject: false as boolean,
            isMarkAsUnPaid: false as boolean,
            isCoverable: false as boolean,
            isCancelled: false as boolean,
            payee: {
                dbaName: '',
                name: '',
                status: '',
                createdTimestamp: 0,
                totalTransactionVolume: 0,
                averageTransactionSize: 0,
                hopscotchBalance: 0,
                bankPartnerBalance: 0,
                returns: 0,
                hopscotchUser: false as boolean,
                score: '',
                logo: '',
                caseId: '',
                decision: '',
                profileLink: '',
                sinceDate: '',
                linkedAccountDetails: [],
                planType: ''
            },
            payor: {
                dbaName: '',
                name: '',
                createdTimestamp: 0,
                status: '',
                totalTransactionVolume: 0,
                averageTransactionSize: 0,
                hopscotchBalance: 0,
                bankPartnerBalance: 0,
                returns: 0,
                hopscotchUser: false as boolean,
                score: '',
                logo: '',
                caseId: '',
                decision: '',
                profileLink: '',
                sinceDate: '',
                linkedAccountDetails: [],
                planType: ''
            },
            mutualTransaction: {} as MutualTransaction,
            action: {} as Action,
        },
        auditHistory: [] as AuditHistoryEntity[],
        invoiceItems: [
            {
                id: '',
                description: '',
                qty: 0,
                rate: 0,
                amount: 0,
            }
        ],
        transactionId: '',
        totalDetails: {} as TotalDetails,
        getAuditInvoicesHistory(): AuditHistoryEntity[] {
            return this.auditHistory;
        },
        getInvoiceState() {
            return this.invoice.invoiceState;
        },
        getInvoiceItems(): InvoiceItemsEntity[] {
            return this.invoiceItems;
        },
        getInvoiceTotalDetails(): any {
            return {
                totalDetails: {
                    subTotal: this.totalDetails.subTotal,
                    tax: this.totalDetails.tax,
                    total: this.totalDetails.total,
                },
            };
        },
        getTransactionId() {
            return this.transactionId;
        },
        getIsCoveredToggle(): boolean {
            return this.form.isCovered;
        },
        getIsCancelledToggle(): boolean {
            return this.form.isCancelled;
        },
        setIsCancelledToggle(toggleStatus: boolean) {
            this.form.isCancelled = toggleStatus;
        },
        setIsCoveredToggle(toggleStatus: boolean) {
            this.form.isCovered = toggleStatus;
        },
        setAmlRiskToggle(toggleStatus: boolean) {
            this.form.isAmlRisk = toggleStatus;
        },
        setReversedRejectToggle(toggleStatus: boolean) {
            this.form.isReversedReject = toggleStatus;
        },
        setMarkAsUnPaidToggle(toggleStatus: boolean) {
            this.form.isMarkAsUnPaid = toggleStatus;
        },
        getIsCoverable() {
            return this.form.isCoverable;
        },
        getIsFormReady(): boolean {
            return sharedStore.getAuditDropdownSubCategory() !== ''
                && (this.form.isCancelled !== this.invoice.isCancelled
                    || this.form.isAmlRisk !== this.invoice.isAmlRisk ||
                    this.form.isReversedReject !== this.invoice.isReversedReject ||
                    this.form.isMarkAsUnPaid !== this.invoice.isMarkAsUnPaid);
        },
        setReason(reason: string) {
            this.form.reason = reason;
        },
        getModalStatus() {
            return this.modalStatus;
        },
        setModalStatus(status: string) {
            this.modalStatus = status;
        },
        getBaseInvoiceDetails(): any {
            return {
                invoiceDetails: selectInvoiceHeader(this.invoice),
                payee: invoiceDetailPayeePayorSelector(this.invoice.payee),
                payor: invoiceDetailPayeePayorSelector(this.invoice.payor),
                mutualTransactions: selectMutualTransactions(this.invoice.mutualTransaction),
            };
        },
        // @TODO: LALA - continue UTing here.
        getFormReason(): string {
            return this.form.reason;
        },
        getTransactionDetails(): Invoice {
            return this.invoice;
        },
        getDateOfLastMutualTransaction(): string {
            return Moment(this.invoice.mutualTransaction.lastTransactionDate).format('MMM DD, YYYY');
        },
        getSumOfAllMutualTransactions(): number {
            return this.invoice.mutualTransaction.totalAmount;
        },
        getTransactionStatus(): string {
            return this.invoice.invoiceState;
        },
        getTotalMutualTransactionsCount(): number {
            return this.invoice.mutualTransaction.totalAmount;
        },
        getPayorDbaName(): string {
            return this.invoice.payor.dbaName;
        },
        getPayeeDbaName(): string {
            return this.invoice.payee.dbaName;
        },
        handleToggleChange(modal: string): void {
            if (modal === 'amlRisk') {
                this.setAmlRiskToggle(!this.form.isAmlRisk);
                this.setModalStatus('amlRisk');
            }
            if (modal === 'cancel') {
                this.setIsCancelledToggle(!this.form.isCancelled);
                this.setModalStatus('cancel');
            }
            if (modal === REVERSE) {
                this.setReversedRejectToggle(!this.form.isReversedReject);
                this.setModalStatus(REVERSE);
            }
            if (modal === MARK_AS_UN_PAID) {
                this.setMarkAsUnPaidToggle(!this.form.isMarkAsUnPaid);
                this.setModalStatus(MARK_AS_UN_PAID);
            }
        },
        cancel() {
            this.setAmlRiskToggle(this.invoice.isAmlRisk);
            this.setReversedRejectToggle(this.invoice.isReversedReject);
            this.setMarkAsUnPaidToggle(this.invoice.isMarkAsUnPaid);
            this.setIsCancelledToggle(this.invoice.isCancelled);
            this.setModalStatus('');
            sharedStore.setAuditDropdownCategory('');
        },
        fetchInvoiceDetails(fetch: FetchInterface<Invoice>, invoiceId: string): void {
            fetch({ url: FACTORED_INVOICE_DETAILS_API(invoiceId) }, ({ data }) => this.payloadHandler(data));
        },
        payloadHandler(data: any) {
            this.invoice = data;
            this.form = { ...data };
            this.invoice.invoiceId = data.invoiceId;
            this.invoice.payee.linkedAccountDetails = data.payee.linkedAccountDetails;
            this.invoice.payor.linkedAccountDetails = data.payor.linkedAccountDetails;

            const payeePlan = (data?.payee?.subscriptionDetails?.plan || '') as string;
            const payeeBillingFrequency = (data?.payee?.subscriptionDetails?.billingFrequency || '') as string;
            this.invoice.payee.planType = getPlanName({ planType: payeePlan, billingFrequency: payeeBillingFrequency });

            const payorPlan = (data?.payor?.subscriptionDetails?.plan || '') as string;
            const payorBillingFrequency = (data?.payor?.subscriptionDetails?.billingFrequency || '') as string;
            this.invoice.payor.planType = getPlanName({ planType: payorPlan, billingFrequency: payorBillingFrequency });
        },
        fetchInvoicesAuditCSV(fetch: FetchInterfaceLegacy, invoiceId: string): void {
            fetch({ url: AUDIT_HISTORY_INVOICES_CSV_URL(invoiceId) }, (res: any) => {
                fileDownload(res.data, 'invoice_history.csv');
            });
        },
        fetchAuditHistoryInvoices(fetch: FetchInterface<AuditHistoryEntity>, invoiceId: string) {
            fetch({ url: AUDIT_HISTORY_INVOICES_URL(invoiceId) }, (res: any) => {
                this.auditHistory = res.data.map((theHistory: any) => ({
                    id: theHistory.id,
                    modalId: theHistory.modelId,
                    date: theHistory.date,
                    operator: theHistory.operator,
                    operationReason: theHistory.operationReason,
                    operationType: theHistory.operations.operationType,
                    catergory: theHistory.operations.Category,
                    subCategory: theHistory.operations.SubCategory,
                }));
            });
        },
        fetchInvoicesItems(fetch: FetchInterface<InvoiceItemsEntity>, invoiceId: string): void {
            fetch({ url: INVOICE_LINE_ITEMS_URL(invoiceId) }, (res: any) => {
                this.invoiceItems = res.data.lineItems.map((theInvoice: any) => ({
                    id: theInvoice.id,
                    description: theInvoice.description,
                    qty: theInvoice.quantity,
                    rate: theInvoice.rate,
                    amount: theInvoice.amount,
                }));
                this.totalDetails.total = res.data.totalDetails.total;
                this.totalDetails.subTotal = res.data.totalDetails.subTotal;
                this.totalDetails.tax = res.data.totalDetails.tax;
                this.transactionId = res.data.transactionId;
            });
        },
        updateInvoice(fetch: FetchInterfaceLegacy): void {
            this.operateOnInvoice(fetch);
        },
        operateOnInvoice(fetch: FetchInterfaceLegacy): void {
            const data = {
                invoiceId: this.invoice.invoiceId,
                operationType: '',
                shouldFlag: this.modalStatus === 'amlRisk' ? this.form.isAmlRisk : this.form.isCancelled,
                reason: this.form.reason,
                audit: {
                    category: sharedStore.getAuditDropdownCategory(),
                    subCategory: sharedStore.getAuditDropdownSubCategory(),
                }
            };

            if (this.modalStatus === 'amlRisk') {
                data.operationType = 'Aml';
            } else if (this.modalStatus === 'cancel') {
                data.operationType = 'Cancel';
            } else if (this.modalStatus === REVERSE) {
                data.operationType = 'ReverseFactoredInvoiceRejection';
                data.shouldFlag = this.form.isReversedReject;
            } else if (this.modalStatus === MARK_AS_UN_PAID) {
                data.operationType = 'FactoredInvoiceMarkAsUnPaid';
                data.shouldFlag = this.form.isMarkAsUnPaid;
            }

            fetch({
                url: OPERATE_FACTORED_INVOICE_URL,
                method: 'PUT',
                data,
            }, () => {
                this.fetchInvoiceDetails(fetch, this.invoice.invoiceId);
                this.fetchInvoicesItems(fetch, this.invoice.invoiceId);
                this.fetchAuditHistoryInvoices(fetch, this.invoice.invoiceId);
            }, true, { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        }
    });

export default InvoiceDetailsPageStore;