import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import { AccountPageTab } from './AccountDetailsPageStoreInterface';
import usePaymentTypeTab from './hooks/usePaymentTypeTab';
import AccountActionsSection, { PaymentTypeTab } from './sections/accountActionsSection/AccountActionsSection';
import AccountInfoSection from './sections/accountInfoSection/AccountInfoSection';
import HeaderSection from './sections/headerSection/HeaderSection';
import TransactionsSection from './sections/transactionsSection/TransactionsSection';

const AccountDetailsPage: React.FC = observer(() => {
    const { caseId = '', view = '', lbaId: selectedId = '', paymentType } = useParams();
    const fetch = useFetchLegacy();
    const { AccountDetailsPageStore } = useStore();
    const bizId = AccountDetailsPageStore.getBizId();
    const pageView = AccountDetailsPageStore.getPageView();
    const account = AccountDetailsPageStore.getAccount();

    const payment = usePaymentTypeTab(account, paymentType as keyof typeof PaymentTypeTab);
    const planType = AccountDetailsPageStore.getPlanType();

    useEffect(() => {
        if (caseId) {
            AccountDetailsPageStore.setCaseId(caseId);
            AccountDetailsPageStore.fetchAccount(fetch, caseId);
            AccountDetailsPageStore.fetchAccountAuditHistory(fetch, caseId);
        };
    }, [AccountDetailsPageStore, caseId]);

    useEffect(() => {
        if (bizId !== '' && view) {
            AccountDetailsPageStore.fetchTransactionsDetailsForAccount(fetch, bizId, view);
            AccountDetailsPageStore.fetchAccountTransactionsSummary(fetch, bizId);

        }
    }, [AccountDetailsPageStore, bizId, view]);

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <HeaderSection />
                        {/* @TODO: If we rely on a url for this, then we can make this stateless */}
                        {pageView === AccountPageTab.info &&
                            <>
                                <AccountInfoSection />
                                <TransactionsSection />
                            </>}
                        {pageView === AccountPageTab.actions && <AccountActionsSection
                            caseId={caseId}
                            selectedId={selectedId}
                            paymentType={payment as keyof typeof PaymentTypeTab}
                            planType={planType}
                        />}
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default AccountDetailsPage;
