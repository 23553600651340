import { PINK_SVG_COLOR, TRANSACTION_TYPE } from 'src/Constant';
import { Account, DirectBankAccountDto, Form, TransactionSummary, TransactionSummaryPayload } from 'src/pages/accountDetailsPage/AccountDetailsPageStoreInterface';
import { AccountSearchInterface, AccountSearchInterfaceResponse } from 'src/pages/accountSearchPage/AccountSearchPageStoreInterface';
import { SearchTransaction, SearchTransactionDto } from 'src/pages/transactionSearchPage/TransactionsSearchPageStoreInterface';
import getInPhoneNumberFormat from './getInPhoneNumberFormat';
import getPaymentTypeLabel from './getPaymentTypeLabel';
import { AccountDetail, AccountDetailPayload, CardDto, EventInterface, EventInterfaceResponse, invoiceDetailPayeePayorInterface, invoiceDetailPayeePayorPayloadInterface, InvoiceHeader, InvoiceHeaderPayload, LbaSearchPayloadInterface as PaymentSearchPayloadInterface, LinkedAccountDetails, LinkedBankAccountDto, mutualTransactionInterface, mutualTransactionPayloadInterface, PaymentSearchInterface, PaymentType, TransactionDetails, transactionDetailsPayloadInterface } from './interfaces';
import { SearchInvoice } from './interfaces/SearchInvoice';
import { SearchInvoiceDto } from './interfaces/SearchInvoiceDto';
import getPlanName from './PlanUtils';

/* eslint-disable import/prefer-default-export */

export const invoiceDetailPayeePayorSelector = (payeeOrPayor: invoiceDetailPayeePayorPayloadInterface): invoiceDetailPayeePayorInterface => ({
    dbaName: payeeOrPayor.dbaName,
    name: payeeOrPayor.name,
    profileLink: payeeOrPayor?.profileLink || '',
    createdTimestamp: payeeOrPayor?.sinceDate || 'N/A',
    score: payeeOrPayor.score,
    accountLogo: payeeOrPayor.logo,
    isVerified: payeeOrPayor.status === 'Pass',
    hopscotchBalance: payeeOrPayor.hopscotchBalance,
    bankPartnerBalance: payeeOrPayor.bankPartnerBalance,
    averageTransactionSize: payeeOrPayor.averageTransactionSize,
    totalTransactionVolume: payeeOrPayor.totalTransactionVolume,
    caseId: payeeOrPayor.caseId,
    linkedAccountBlock: payeeOrPayor.linkedAccountDetails.map((lba: any) => selectLinkedAccountDetails(lba)),
    planType: payeeOrPayor.planType
});

export const selectTransactionDetails = (transaction: transactionDetailsPayloadInterface): TransactionDetails => ({
    counterPartyLogo: transaction.counterPartyLogo,
    counterPartyDbaName: transaction.counterPartyDbaName,
    invoiceId: transaction.invoiceId,
    transactionDate: transaction?.dueDate || transaction?.paymentDate || '',
    amount: transaction.amount,
    name: transaction.counterPartyFullName,
    email: transaction.counterPartyEmail,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectLinkedAccountDetails = (lba: LinkedBankAccountDto): LinkedAccountDetails => ({
    id: lba.id,
    institutionName: lba?.institutionName ?? '',
    ownerName: lba.ownerName,
    ownerEmail: lba.ownerEmail,
    ownerPhoneNumber: lba.ownerPhoneNumber ? getInPhoneNumberFormat(lba.ownerPhoneNumber) : null,
    accountNumber: lba.accountNumber,
    addressLine1: lba.address?.data?.street || '',
    addressLine2: lba.address?.data?.city ? `${lba.address.data.city}, ${lba.address.data.region} ${lba.address.data.postalCode}` : '',
    address: lba.address ? lba.address : '',
    availableBalance: lba.transactionSummary?.availableBalance || 0,
    totalTransactionsCount: lba.transactionSummary?.numberOfTransactions || 0,
    totalTransactionsSum: lba.transactionSummary?.valueOfTransactions || 0,
    isFundingAccount: lba.isFundingAccount,
    isDepositAccount: lba.isDepositAccount,
    isBlocked: lba.isBlocked,
    isOps: lba.hasOpsUpdated,
    paymentType: lba?.paymentType ?? PaymentType.NotSet,
    ownerNameConfidence: lba?.matchPercentage?.nameMatchResult ? parseInt(lba.matchPercentage.nameMatchResult) : null,
    addressConfidence: lba?.matchPercentage?.addressMatchResult ? parseInt(lba.matchPercentage.addressMatchResult) : null,
    emailConfidence: lba?.matchPercentage?.emailMatchResult ? parseInt(lba.matchPercentage.emailMatchResult) : null,
    phoneNumberConfidence: lba?.matchPercentage?.phoneNoMatchResult ? parseInt(lba.matchPercentage.phoneNoMatchResult) : null,
});

export const selectInvoiceHeader = (invoice: InvoiceHeaderPayload): InvoiceHeader => ({
    causationIdType: invoice?.type || 'Invoice',
    transactionType: invoice.isAmlRisk,
    transactionStatus: invoice.invoiceState,
    transactionAmount: invoice.invoiceAmount,
    transferAmount: '',
    transferStatus: invoice.invoiceState,
    transactionDate: invoice.invoiceDate,
    transactionDueDate: invoice.dueDate,
    transactionCompletedDate: ''
});

export const selectMutualTransactions = (mutualTransaction: mutualTransactionPayloadInterface): mutualTransactionInterface => ({
    lastTransactionDate: mutualTransaction?.lastTransactionDate || 'N/A',
    totalAmount: mutualTransaction?.totalAmount || 0,
    avgTransactionSize: ((mutualTransaction?.totalAmount || 0) / (mutualTransaction?.totalTransactions || 0)) || 0,
    totalTransactions: mutualTransaction?.totalTransactions
});

export const selectEvents = (theEvent: EventInterfaceResponse): EventInterface => ({
    alertType: theEvent.alertType,
    alertSubType: theEvent.alertSubType,
    dateTime: theEvent.dateTime,
    dbaName: theEvent?.dbaName || 'N/A',
    userName: (theEvent?.userName && theEvent.userName.trim()) || 'N/A',
    userEmail: theEvent?.userEmail || 'N/A',
    alertId: theEvent.alertId,
    businessLogo: theEvent.businessLogo,
    traceId: theEvent.traceId
});

export const selectPaymentSearch = (dto: PaymentSearchPayloadInterface): PaymentSearchInterface => ({
    id: dto.id,
    businessName: dto?.fullName || 'N/A',
    userName: dto?.dbaName || 'N/A',
    accountLogo: dto.logo,
    email: dto.email,
    businessType: dto.type,
    dateReceived: dto.createdAt,
    caseID: dto.caseId,
    status: selectFrozenOrLockedStatus(dto),
    isBlocked: dto.isBlocked,
    hasOpsUpdated: dto.hasOpsUpdated,
    paymentStatus: dto.isLocked ? 'Locked' : 'Unlocked',
    hopscotchStatus: dto.status === 'Pass' ? 'Verified' : 'Unverified',
    pendingStatus: dto.pendingStatus,
    institutionName: dto.institutionName,
    accountNumber: dto.accountNumber,
    institutionLogo: dto.institutionLogo,
    paymentType: dto.paymentType
});

type selectFrozenOrLockedStatus = (theCase: { isFrozen: boolean; isLocked: boolean; }) => string;
const selectFrozenOrLockedStatus: selectFrozenOrLockedStatus = (theCase) => {
    if (theCase.isLocked) {
        return 'Locked';
    }

    if (theCase.isFrozen) {
        return 'Restricted';
    }

    return 'Unrestricted';
};

export type SelectAccountSearchInterface = (theAccount: AccountSearchInterfaceResponse) => AccountSearchInterface;
export const selectAccountSearch: SelectAccountSearchInterface = (dto) => ({
    caseId: dto.caseId,
    businessLogo: dto.businessLogo,
    name: dto.name,
    dbaName: dto?.dbaName || 'N/A',
    emailAddress: dto?.emailAddress || 'N/A',
    businessType: dto.businessType,
    caseStatus: dto.caseStatus,
    frozen: dto.isFrozen ? 'Restricted' : 'Unrestricted',
    isLocked: dto.isLocked,
    hasInvoiceLimitBeenUpdated: dto.hasInvoiceLimitBeenUpdated,
    createdTimestamp: dto.createdTimestamp,
    transactionVolume: dto.transactionVolume,
    invoiceLimit: dto.invoiceLimit,
    factoringLimit: dto.factoringLimit,
    accountStatus: dto.accountStatus,
    riskScore: dto.riskScore,
    paymentDetails: dto.paymentDetails,
    paymentStatus: dto.paymentStatus,
    bizId: dto.bizId,
    verificationStatus: dto.verificationStatus,
    isLockedByOps: dto.isLockedByOps,
    planType: getPlanName({ planType: dto?.subscriptionDetails?.plan || '', billingFrequency: dto?.subscriptionDetails?.billingFrequency || '' }),
});

type selectAccountDetail = (accountDetail: AccountDetailPayload) => AccountDetail;
export const selectAccountDetail: selectAccountDetail = (accountDetail) => ({
    ...accountDetail,
    linkedAccounts: accountDetail?.linkedBankAccountDetails?.map((lba: LinkedBankAccountDto) => selectLinkedAccountDetails(lba)) || [],
    externalDetails: accountDetail?.externalDetails ? accountDetail.externalDetails : { isPlatformUser: true, createdByBizId: '', createdByDbaName: '' }
});

type selectSearchTransaction = (dto: SearchTransactionDto) => SearchTransaction;
export const selectSearchTransaction: selectSearchTransaction = (dto) => ({
    id: dto.transactionId,
    transactionType: dto.transactionType,
    transactionAmount: dto.transactionAmount,
    date: dto.transactionDate,
    transactionStatus: dto.transactionStatus,
    transferStatus: dto.transferStatus,
    payorDbaName: dto.transactionType === TRANSACTION_TYPE.Funding ? dto.payor.institutionName : dto.payor.dbaName,
    payorName: dto.transactionType === TRANSACTION_TYPE.Funding ? `xxxx${dto.payor.accountNumber}` : dto.payor.name,
    payeeDbaName: dto.transactionType === TRANSACTION_TYPE.Withdrawing ? dto.payee.institutionName : dto.payee.dbaName,
    payeeName: dto.transactionType === TRANSACTION_TYPE.Withdrawing ? `xxxx${dto.payee.accountNumber}` : dto.payee.name,
    payorAccountLogo: dto.transactionType === TRANSACTION_TYPE.Funding ? PINK_SVG_COLOR : dto.payor.accountLogo,
    payeeAccountLogo: dto.transactionType === TRANSACTION_TYPE.Withdrawing ? PINK_SVG_COLOR : dto.payee.accountLogo,
    payeePaymentMethod: dto.payee.paymentMethod,
    payorPaymentMethod: dto.payor.paymentMethod,
    payeePaymentType: getPaymentTypeLabel(dto.payee.paymentType),
    payorPaymentType: getPaymentTypeLabel(dto.payor.paymentType),
    source: dto?.source ?? 'N/A'
});

type selectSearchInvoice = (theInvoice: SearchInvoiceDto) => SearchInvoice;
export const selectSearchInvoice: selectSearchInvoice = (theInvoice) => ({
    payeeAccountLogo: theInvoice.payeeDto.accountLogo,
    payorAccountLogo: theInvoice.payorDto.accountLogo,
    payeeBusinessName: theInvoice.payeeDto.dbaName,
    payorBusinessName: theInvoice.payorDto.dbaName,
    payeeUserName: theInvoice.payeeDto.name,
    payorUserName: theInvoice.payorDto.name,
    invoiceID: theInvoice.invoiceId,
    invoiceState: theInvoice.invoiceState,
    invoiceAmount: theInvoice.invoiceAmount,
    submittedDate: theInvoice.initiatedDate,
    dueDate: theInvoice.dueDate,
    invoiceType: theInvoice?.type || 'Invoice',
    payeePaymentMethod: theInvoice.payeeDto.paymentMethod,
    payorPaymentMethod: theInvoice.payorDto.paymentMethod,
    payeePaymentType: getPaymentTypeLabel(theInvoice.payeeDto.paymentType),
    payorPaymentType: getPaymentTypeLabel(theInvoice.payeeDto.paymentType),
});

type accountDetailSelector = ({ data }: any) => { account: Account, form: Form };

export const accountDetailSelector: accountDetailSelector = ({ data }) => ({
    account: {
        ...data,
        verificationStatus: data.verificationStatus,
        hopscotchBalance: data.hopscotchBalance,
        columnBalance: data.columnBalance,
        factoringLimit: data.factoringLimit,
        invoiceLimit: data.invoiceLimit,
        customLimits: {
            transaction: data.customLimits.transaction,
            payee: data.customLimits.payee,
            payor: data.customLimits.payor,
            dailyLimit: data.customLimits.dailyLimit,
        },
        riskScore: data.riskScore,
        hasInvoiceLimitBeenUpdated: data.hasInvoiceLimitBeenUpdated as boolean,
        isFrozen: data.isFrozen as boolean,
        isLocked: data.isLocked as boolean,
        primaryAccountOwner: (data?.beneficialOwners) ? data.beneficialOwners[0] : '',
        linkedAccounts: data?.linkedBankAccountDetails?.map((lba: LinkedBankAccountDto) => selectLinkedAccountDetails(lba)) ?? [] as LinkedAccountDetails[],
        cards: data?.cards ?? [],
        directBankAccounts: data?.directBankAccounts ?? [],
        deletedPayments: data?.deletedPaymentMethods ?? [],
    },
    form: {
        isRestricted: data.isFrozen as boolean,
        isLocked: data.isLocked as boolean,
        factoringLimit: data.factoringLimit,
        invoiceLimit: data.invoiceLimit,
        customLimits: {
            transaction: data.customLimits.transaction,
            payee: data.customLimits.payee,
            payor: data.customLimits.payor,
            dailyLimit: data.customLimits.dailyLimit,
        },
        riskScore: data.riskScore,
        paymentIdChange: '',
        cards: data?.cards ?? [] as CardDto[],
        reason: '',
        linkedAccountDetails: data?.linkedBankAccountDetails?.map((lba: LinkedBankAccountDto) => selectLinkedAccountDetails(lba)) ?? [] as LinkedAccountDetails[],
        directBankAccounts: data?.directBankAccounts ?? [] as DirectBankAccountDto[],
        flowData: {
            availableCreditLimit: data?.flowData?.availableCreditLimit,
            creditLimit: data?.flowData?.creditLimit,
            approvalStatus: data?.flowData?.approvalStatus,
        }
    }
});

type accountTransactionSummarySelector = (payload: TransactionSummaryPayload) => { transactionSummary: TransactionSummary };

export const accountTransactionSummarySelector: accountTransactionSummarySelector = ({ data }: any) => ({
    transactionSummary: {
        ...data,
        payablesBalance: data.payablesTotal,
        payablesPastDue: data.payablesTotal,
        payablesNextSeven: data.pastDuePayablesTotal,
        receivablesBalance: data.receivablesTotal,
        receivablesPastDue: data.pastDueReceivablesTotal,
        receivablesNextSeven: data.receivablesTotal,
        completedPayables: data.completedPayablesTotal,
        completedReceivables: data.completedReceivablesTotal,
        completedNetFlow: data.completedFlowTotal,
    }
});