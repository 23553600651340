import React from 'react';
import styles from './Row.module.scss';

type Props = {
    children: any;
}

const Row: React.FC<Props> = ({ children }): JSX.Element => (
    <div className={styles.row}>{children}</div>
);

export default Row;
