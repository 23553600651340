import React from 'react';
import Button from 'src/components/base/buttons/button/Button';

interface props {
    onClick: () => void;
    disabled?: boolean;
}

const ContinueButton: React.FC<props> = ({ onClick, disabled }) => <Button
    onClick={onClick}
    disabled={disabled}>
    Continue
</Button>;

export default ContinueButton;