const currencyColumnFilter = (dataFieldName: string): any => [
    {
        text: '$0',
        value: [dataFieldName, '=', 0],
    },
    {
        text: '$1 - $3,000',
        value: [
            [dataFieldName, '<', 3000],
            [dataFieldName, '>', 0],
        ],
    }, {
        text: '$3,000 - $5,000',
        value: [
            [dataFieldName, '>=', 3000],
            [dataFieldName, '<', 5000],
        ],
    }, {
        text: '$5,000 - $10,000',
        value: [
            [dataFieldName, '>=', 5000],
            [dataFieldName, '<', 10000],
        ],
    }, {
        text: '$10,000 - $20,000',
        value: [
            [dataFieldName, '>=', 10000],
            [dataFieldName, '<', 20000],
        ],
    }, {
        text: '$20,000+',
        value: [dataFieldName, '>=', 20000],
    }
];

export default currencyColumnFilter;