import React from 'react';
import ExitIcon from '../../../logos/ExitIcon';
import styles from './CloseButton.module.scss';

interface CloseButtonInterface {
    onClick: () => void
};

export const CloseButton: React.FC<CloseButtonInterface> = ({ onClick }) => (
    <button
        type="button"
        className={styles.closebtn}
        onClick={onClick}>
        <ExitIcon />
    </button>);

export default CloseButton;