import React from 'react';

const ChartIcon = (): JSX.Element => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.166626 1.81199C0.166626 0.903313 0.903252 0.166687 1.81193 0.166687C2.7206 0.166687 3.45722 0.903313 3.45722 1.81199V10.1881C3.45722 11.0967 2.7206 11.8334 1.81193 11.8334C0.903252 11.8334 0.166626 11.0967 0.166626 10.1881V1.81199ZM1.81193 1.06412C1.39889 1.06412 1.06406 1.39895 1.06406 1.81199V10.1881C1.06406 10.6011 1.39889 10.9359 1.81193 10.9359C2.22496 10.9359 2.55979 10.6011 2.55979 10.1881V1.81199C2.55979 1.39895 2.22496 1.06412 1.81193 1.06412Z"
            fill="#030D45"
        />
        <path
            d="M4.35466 4.80344C4.35466 3.89477 5.09129 3.15814 5.99996 3.15814C6.90863 3.15814 7.64526 3.89477 7.64526 4.80344V10.1881C7.64526 11.0967 6.90863 11.8334 5.99996 11.8334C5.09129 11.8334 4.35466 11.0967 4.35466 10.1881V4.80344ZM5.99996 4.05558C5.58693 4.05558 5.2521 4.39041 5.2521 4.80344V10.1881C5.2521 10.6011 5.58693 10.9359 5.99996 10.9359C6.41299 10.9359 6.74782 10.6011 6.74782 10.1881V4.80344C6.74782 4.39041 6.41299 4.05558 5.99996 4.05558Z"
            fill="#030D45"
        />
        <path
            d="M8.5427 1.81199C8.5427 0.903313 9.27932 0.166687 10.188 0.166687C11.0967 0.166687 11.8333 0.903313 11.8333 1.81199V10.1881C11.8333 11.0967 11.0967 11.8334 10.188 11.8334C9.27932 11.8334 8.5427 11.0967 8.5427 10.1881V1.81199ZM10.188 1.06412C9.77496 1.06412 9.44013 1.39895 9.44013 1.81199V10.1881C9.44013 10.6011 9.77496 10.9359 10.188 10.9359C10.601 10.9359 10.9359 10.6011 10.9359 10.1881V1.81199C10.9359 1.39895 10.601 1.06412 10.188 1.06412Z"
            fill="#030D45"
        />
    </svg>
);

export default ChartIcon;