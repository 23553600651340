import { observer } from 'mobx-react-lite';
import React from 'react';
import { NOT_AVAILABLE_PLACEHOLDER } from 'src/Constant';
import styles from 'src/pages/transactionDetailsPage/TransactionDetailsPage.module.scss';
import { Section } from '../../components';
import Row from '../../components/base/row/Row';
import BasePanel from '../../components/business/transactions/basePanel/BasePanel';
import DownloadIcon from '../../components/logos/DownloadIcon';
import Content from '../../layouts/content/Content';
import EntireLayout from '../../layouts/entireLayout/EntireLayout';
import Header from '../../layouts/header/Header';
import { useStore } from '../../store';
import ListItem from './listItem/ListItem';
import ActionsSection from './sections/ActionsSection';
import StatusSection from './sections/StatusSection';

const TransactionDetailContent: React.FC = observer(() => {
    const { TransactionDetailsPageStore } = useStore();

    /**
     * Migrate away from the transactionDetails and use the transaction itself.
     */
    const { transactionDetails, payee, payor, mutualTransactions } = TransactionDetailsPageStore.getBaseProps();
    const transaction = TransactionDetailsPageStore.transaction;
    const invoiceAmount = TransactionDetailsPageStore.getInvoiceAmount();
    const totalReceived = TransactionDetailsPageStore.getTotalReceived();

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <Header icon={<DownloadIcon />}
                            title="Payment report"
                            id={transaction.transactionId} />
                        <StatusSection
                            transactionDetails={transaction}
                            invoiceAmount={invoiceAmount}
                            totalReceived={totalReceived}
                        />
                        <BasePanel
                            transactionDetails={transactionDetails}
                            payee={payee}
                            payor={payor}
                            mutualTransactions={mutualTransactions} />
                        <div className={styles.idContainer}>
                            <ListItem label='Treasury Prime Account ID' response={transaction.payeeBankPartnerId} />
                            <ListItem label='Treasury Prime Account ID' response={transaction.payorBankPartnerId} />
                        </div>
                        <div className={styles.wrapper}>
                            <div className={styles.listContainer}>
                                <h4>Hopscotch status</h4>
                                <ListItem label='Transaction type' response={transactionDetails.transactionType || NOT_AVAILABLE_PLACEHOLDER} />
                                <ListItem label='Transfer status' response={transactionDetails.transferStatus || NOT_AVAILABLE_PLACEHOLDER} />
                                <ListItem label='Transaction status' response={transactionDetails.transactionStatus || NOT_AVAILABLE_PLACEHOLDER} />
                            </div>
                            <div className={styles.listContainer}>
                                <h4>Treasury Prime status</h4>
                                <ListItem label='Treasury Prime payment ID' response={transaction.bankPartnerId} />
                                <ListItem label='Treasury Prime payment type' response={transactionDetails.transactionType || NOT_AVAILABLE_PLACEHOLDER} />
                                <ListItem label='Treasury Prime payment status' response={transactionDetails.transactionStatus || NOT_AVAILABLE_PLACEHOLDER} />
                            </div>
                            <div className={styles.listContainer}>
                                <h4>Tabapay status</h4>
                                <ListItem label='Tabapay transaction ID' response={transaction.cardPartnerId} />
                                <ListItem label='Tabapay status' response={transaction.cardTransactionStatus} />
                                <ListItem label='Tabapay networkRC' response={NOT_AVAILABLE_PLACEHOLDER} />
                            </div>
                        </div>
                        <ActionsSection />
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default TransactionDetailContent;