import { observer } from 'mobx-react-lite';
import React from 'react';
import Button from 'src/components/base/buttons/button/Button';
import HalfPanel from 'src/layouts/twoColumnRow/halfPanel/HalfPanel';
import { useStore } from 'src/store';
import styles from './PlanDetails.module.scss';

interface LeftBlockProps {
    openModal: () => void
    onHandleHistoryModal: (value: boolean) => void
    planDetails?: any
}

const LeftBlock: React.FC<LeftBlockProps> = observer(({ openModal, onHandleHistoryModal }) => {
    const { PaidPlanControlsStore } = useStore();
    const updatedFeature = PaidPlanControlsStore.getUpdatedFeature();

    const handleSave = () => {
        openModal();
    };

    return (
        <HalfPanel>
            <div className={styles.flexContainer}>
                {/* commenting this code out for now as we plan to integrate with the BE in a later sprint */}
                {/* <div className={styles.flexItem}>
                    <span className={styles.itemField}>Free trial length</span> <span className={styles.itemField}> {planDetails?.trialLength}</span>
                </div>
                <div className={styles.flexItem}>
                    <span className={styles.itemField}>Plan cost</span> <span className={styles.itemField}> {planDetails?.planCost}</span>
                </div>
                <div className={styles.flexItem}>
                    <span className={styles.itemField}>Annual discount</span> <span className={styles.itemField}> {planDetails?.annualDiscount}</span>
                </div>
                <div className={styles.flexItem}>
                    <span className={styles.itemField}>Card fee charged</span> <span className={styles.itemField}> {planDetails?.cardFeeCharged}</span>
                </div> */}
                <div className={styles.buttonsGroup}>
                    <Button disabled={!updatedFeature} onClick={handleSave} classNames={styles.saveButton}>
                        Save
                    </Button>
                    <button
                        type="button"
                        className={styles['history-link']}
                        onClick={() => onHandleHistoryModal(true)}
                    >
                        History
                    </button>
                </div>
            </div>

        </HalfPanel>
    );
});

export default LeftBlock;