import { Popup, ScrollView } from 'devextreme-react';
import React from 'react';
import ButtonContainerPopups from 'src/components/base/buttonContainerPopups/ButtonContainerPopups';
import HRLine from 'src/components/base/hrLine/HRLine';
import PopupTitle from 'src/components/base/popups/popupTitle/PopupTitle';
import { Color, GENERIC_MODAL_TITLE, MARK_PAID_MODAL_TITLE } from 'src/Constant';
import Gap from 'src/layouts/gap/Gap';
import styles from './BaseConfirmationPopup.module.scss';

type BaseConfirmationPopupProps = {
    popupVisible: boolean;
    hidePopup: () => void;
    modalId?: string;
    updateCallback: any;
    reason?: string;
    children?: any;
    modalTitle?: string;
};

const BaseConfirmationPopup: React.FC<BaseConfirmationPopupProps> = ({ popupVisible, hidePopup, modalId, updateCallback, reason, children, modalTitle }) => {
    const onConfirm = () => {
        if (modalId) {
            updateCallback(fetch, modalId);
        }
        hidePopup();
    };

    const renderTitle = () => {
        if (modalTitle === MARK_PAID_MODAL_TITLE) {
            return <PopupTitle handleClick={hidePopup} status={Color.success} title={modalTitle} />;
        }
        return <PopupTitle handleClick={hidePopup} title={modalTitle || GENERIC_MODAL_TITLE} />;
    };

    return (
        <Popup
            visible={popupVisible}
            height='500px'
            width="500px"
            showCloseButton={true}
            onHiding={hidePopup}
            closeOnOutsideClick={true}
            titleRender={renderTitle}
        >
            <ScrollView width='100%' height='74%'>
                <br />
                {children}
                <div className={styles.reasoningContainer}>
                    <h4>
                        Reasoning
                    </h4>
                    <div className={styles.userInput}>
                        {reason}
                    </div>
                </div>
            </ScrollView>
            <Gap />
            <Gap />
            <HRLine />
            <ButtonContainerPopups onConfirm={onConfirm} hidePopup={hidePopup} />
        </Popup>
    );
};

export default BaseConfirmationPopup;