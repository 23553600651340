import { Popup, ScrollView } from 'devextreme-react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import ButtonContainerPopups from 'src/components/base/buttonContainerPopups/ButtonContainerPopups';
import HRLine from 'src/components/base/hrLine/HRLine';
import PopupTitle from 'src/components/base/popups/popupTitle/PopupTitle';
import { Color } from 'src/Constant';
import Gap from 'src/layouts/gap/Gap';
import { TransactionActions } from 'src/pages/transactionDetailsPage/TransactionDetailsPageStoreInterface';
import { useStore } from 'src/store';
import ReturnStandInOverview from '../returnStandInOverview/ReturnStandInOverview';
import styles from './TransactionActionPopup.module.scss';

type TransactionDetailsPopupProps = {
    popupVisible: boolean;
    hidePopup: () => void;
    modalId: string;
    updateCallback: any;
    accountDetails?: any;
};

const TransactionDetailsPopup: React.FC<TransactionDetailsPopupProps> = observer(({ popupVisible, hidePopup, modalId, updateCallback, accountDetails }) => {
    const fetch = useFetchLegacy();
    const { TransactionDetailsPageStore } = useStore();
    const modalStatus = TransactionDetailsPageStore.getModalStatus();
    const reason = TransactionDetailsPageStore.getFormReason();

    const onConfirm = () => {
        if (modalId) {
            updateCallback(fetch, modalId);
        }
        hidePopup();
    };

    const renderTitle = () => {

        const getTitle = () => {
            switch (modalStatus) {
                case TransactionActions.amlRisk:
                    return 'You are flagging a transaction.';
                case TransactionActions.isCovered:
                    return 'You are standing in to cover a returned transaction';
                case TransactionActions.isApproved:
                    return 'You are approving a card payment.';
                case TransactionActions.isDenied:
                    return 'You are denying a card payment.';
                default:
                    return '';
            }
        };

        const getColorStatus = () => {
            switch (modalStatus) {
                case TransactionActions.isApproved:
                    return Color.success;
                case TransactionActions.isDenied:
                    return Color.danger;
                default:
                    return Color.warning;
            }
        };

        return (<PopupTitle handleClick={hidePopup} title={getTitle()} status={getColorStatus()} />);
    };

    return (
        <Popup
            visible={popupVisible}
            height={modalStatus === TransactionActions.amlRisk ? '500px' : '600px'}
            width="500px"
            showCloseButton={true}
            onHiding={hidePopup}
            closeOnOutsideClick={true}
            titleRender={renderTitle}>


            {modalStatus === TransactionActions.isCovered &&
                <ReturnStandInOverview
                    dbaName={accountDetails.payor.dbaName}
                    score={accountDetails.payor.score}
                    createdTimestamp={accountDetails.payor.createdTimestamp}
                    accountLogo={accountDetails.payor.accountLogo}
                    isVerified={accountDetails.payor.isVerified}
                    formReason={reason}
                    amount={accountDetails.transactionDetails.transferAmount}
                    planType={accountDetails.planType}
                />
            }

            {/* @TODO: LALA Left off here */}
            {(modalStatus === TransactionActions.isApproved || TransactionActions.isDenied || TransactionActions.amlRisk) &&
                <ScrollView width='100%' height='74%'>
                    <div className={styles.reasoningContainer}>
                        <h4 className={styles.reasonHeader}>
                            Reasoning
                        </h4>
                        <div className={styles.userInput}>
                            {reason}
                        </div>
                    </div>
                </ScrollView>
            }

            <Gap />
            <Gap />
            <HRLine />
            <ButtonContainerPopups onConfirm={onConfirm} hidePopup={hidePopup} />
        </Popup>
    );
});

export default TransactionDetailsPopup;
