import React from 'react';
import GreyText from '../../../../components/base/GreyText';
import styles from './SoleProprietorCard.module.scss';

const SoleProprietorCard: React.FC = () => (
    <div className={styles.soleProprietorCard}>
        <h3>Sole Proprietor</h3>
        <GreyText>No Business Information</GreyText>
    </div>
);

export default SoleProprietorCard;