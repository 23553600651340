import { observer } from 'mobx-react-lite';
import React from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import BaseAuditHistoryPopup from 'src/components/base/popups/baseAuditHistoryPopup/BaseAuditHistoryPopup';
import AuditHistoryContent from 'src/pages/accountDetailsPage/sections/accountActionsSection/accountActionsAuditHistoryPopup/auditHistoryContent/AuditHistoryContent';
import { useStore } from 'src/store';

export interface AuditHistory {
    id: string,
    modalId: string,
    date: string,
    operator: string,
    operationReason: string,
    freezeAccount: boolean,
    invoicingLimit: number,
}

type AccountActionsAuditHistoryPopupProps = {
    popupVisible: boolean;
    hidePopup: any;
};

const AccountActionsAuditHistoryPopup: React.FC<AccountActionsAuditHistoryPopupProps> = observer(({ popupVisible, hidePopup }) => {
    const { AccountDetailsPageStore } = useStore();
    const caseId = AccountDetailsPageStore.getCaseId();
    const fetch = useFetchLegacy();

    return (
        <BaseAuditHistoryPopup
            popupVisible={popupVisible}
            hidePopup={hidePopup}
            title="Account Actions History"
            updateCallBack={() => AccountDetailsPageStore.fetchAccountAuditHistoryCSV(fetch, caseId)}
        >
            <AuditHistoryContent />
        </BaseAuditHistoryPopup>
    );
});

export default AccountActionsAuditHistoryPopup;