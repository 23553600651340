import { observer } from 'mobx-react-lite';
import React from 'react';
import { FactoredInvoicesAuditHistory } from 'src/pages/factoredInvoiceDetailsPage/FactoredInvoiceDetailsPageInterface';
import AuditItem from './FactoredInvoiceAuditItem';
import styles from './FactoredInvoiceAuditItem.module.scss';

interface props {
    histories: FactoredInvoicesAuditHistory[];
}

const FactoredInvoiceDetailsContent: React.FC<props> = observer(({ histories }) => (
    <div className={styles.historyWrapper}>
        {histories.map((theHistory: FactoredInvoicesAuditHistory) => (
            <AuditItem key={theHistory.id} auditInvoice={theHistory} />
        ))}
    </div>
));

export default FactoredInvoiceDetailsContent;