const accountStatusFilter = [
    {
        text: 'Unrestricted',
        value: ['isFrozen', '=', 'Unrestricted'],
    },
    {
        text: 'Restricted',
        value:
            [
                ['isFrozen', '=', 'Restricted'],
                ['isLocked', '=', false]
            ],
    },
    {
        text: 'Locked',
        value: ['isLocked', '=', true],
    },
];

export default accountStatusFilter;