import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _react = typeof React

const UnverifiedIcon = (): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M11.0082 2.39577C12.0439 1.15003 13.9561 1.15003 14.9918 2.39577C15.6303 3.16383 16.6536 3.49631 17.6216 3.25026C19.1917 2.85119 20.7387 3.97516 20.8444 5.59174C20.9095 6.58844 21.5419 7.45887 22.4697 7.82882C23.9745 8.42885 24.5654 10.2475 23.7007 11.6174C23.1676 12.462 23.1676 13.538 23.7007 14.3826C24.5654 15.7525 23.9745 17.5712 22.4697 18.1712C21.5419 18.5411 20.9095 19.4116 20.8444 20.4083C20.7387 22.0248 19.1917 23.1488 17.6216 22.7497C16.6536 22.5037 15.6303 22.8362 14.9918 23.6042C13.9561 24.85 12.0439 24.85 11.0082 23.6042C10.3697 22.8362 9.34642 22.5037 8.37838 22.7497C6.80827 23.1488 5.26126 22.0248 5.15561 20.4083C5.09047 19.4116 4.45806 18.5411 3.53027 18.1712C2.02546 17.5712 1.43456 15.7525 2.29929 14.3826C2.83243 13.538 2.83243 12.462 2.29929 11.6174C1.43456 10.2475 2.02546 8.42885 3.53027 7.82882C4.45806 7.45887 5.09047 6.58844 5.15561 5.59174C5.26126 3.97516 6.80827 2.85119 8.37838 3.25026C9.34642 3.49631 10.3697 3.16383 11.0082 2.39577Z" fill="#DCDCDC" />
        <g clipPath="url(#clip0_2171_9370)">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9099 10.8395C17.03 10.9588 17.03 11.1523 16.9099 11.2716L11.9868 16.1605C11.8666 16.2798 11.6718 16.2798 11.5517 16.1605L9.09012 13.7161C8.96996 13.5967 8.96996 13.4033 9.09012 13.2839C9.21028 13.1646 9.4051 13.1646 9.52526 13.2839L11.7692 15.5123L16.4747 10.8395C16.5949 10.7202 16.7897 10.7202 16.9099 10.8395Z" fill="white" stroke="#1D191F" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_2171_9370">
                <rect width="12" height="11" fill="white" transform="translate(7 8)" />
            </clipPath>
        </defs>
    </svg>
);

export default UnverifiedIcon;