import { useEffect } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import { FEATURE_TOGGLE_API } from 'src/Constant';
import { useStore } from '../..';

const useFetchFeatures = (): void => {
    const { sharedStore } = useStore();
    const fetch = useFetchLegacy();
    useEffect(() => {
        fetch({ url: FEATURE_TOGGLE_API }, (res: any) => {
            sharedStore.setFeatures(res.data);
        });
    }, [sharedStore]);
};

export default useFetchFeatures;