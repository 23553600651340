import Moment from 'moment';
import React from 'react';
import styles from './FactoredInvoiceAuditItem.module.scss';

export interface props {
    auditInvoice: {
        id: string,
        modalId: string,
        date: string,
        operator: string,
        operationReason: string,
        flagType: string,
    }
}

const FactoredInvoiceAuditItem: React.FC<props> = ({ auditInvoice }) => {

    const id = auditInvoice.id;
    const modalId = auditInvoice.modalId;
    const formattedDate = Moment(auditInvoice.date).format('MMMM DD, YYYY');
    const operator = auditInvoice.operator;
    const flagType = auditInvoice.flagType;
    const flagTypeLabel = auditInvoice.flagType ? 'marked this invoice' : '';

    return (<div className={styles.historyContainer} key={id} id={modalId}>
        <div>
            <h4 className={styles.info}>{formattedDate} - {operator}, {flagTypeLabel} {flagType}</h4>
            <p>{auditInvoice.operationReason}</p>
        </div>
    </div>);
};

export default FactoredInvoiceAuditItem;