/**
 * This is a Custom Console Logger. All other classes should use this class
 * and should not use console directly.
 * This class is the only place where eslint rule is disabled.
 * No other class should use eslint disabling.
 *
 * https://www.w3schools.com/Colors/colors_names.asp
 */

class Logger {
    private static jsonOn = true;
    private static traceOn = false;
    private static debugOn = true;
    private static infoOn = true;
    private static warnOn = true;
    private static errorOn = true;
    private static productionWothyMessage = true;
    private static productionWothyStats = true;

    public static printJSON(message: string, jsonString: string, logIt?: boolean): void {
        if (Logger.jsonOn) {
            if (logIt) {
                // eslint-disable-next-line no-console
                console.log(
                    `${Logger.timestamp()} %c${message}\n${JSON.stringify(JSON.parse(jsonString), null, 2)}`,
                    'color: green; font-weight: normal; font-size: 12px;'
                );
            }
        }
    }

    public static trace(message: string): void {
        if (Logger.traceOn) {
            // eslint-disable-next-line no-console
            console.log(`${Logger.timestamp()} %c${message}`, 'color: CadetBlue; font-weight: normal; font-size: 12px;');
        }
    }

    public static debug(message: string): void {
        if (Logger.debugOn) {
            // eslint-disable-next-line no-console
            console.log(`${Logger.timestamp()} %c${message}`, 'color: blue; font-weight: normal; font-size: 12px;');
        }
    }

    public static info(message: string): void {
        if (Logger.infoOn) {
            // eslint-disable-next-line no-console
            console.log(`${Logger.timestamp()} %c${message}`, 'font-weight: normal; font-size: 12px;');
        }
    }

    public static warn(message: string): void {
        if (Logger.warnOn) {
            // eslint-disable-next-line no-console
            console.log(`${Logger.timestamp()} %c${message}`, 'color: chocolate; font-weight: normal; font-size: 12px;');
        }
    }

    public static error(message: string): void {
        if (Logger.errorOn) {
            // eslint-disable-next-line no-console
            console.log(`${Logger.timestamp()} %c${message}`, 'color: red; font-weight: normal; font-size: 12px;');
        }
    }

    public static productionWorthyMessage(message: string): void {
        if (Logger.productionWothyMessage) {
            // eslint-disable-next-line no-console
            console.log(`%c${Logger.timestamp()} ${message}`, 'color: Indigo; font-weight: normal; font-size: 12px;');
        }
    }

    public static productionWorthyStats(message: string): void {
        if (Logger.productionWothyStats) {
            // eslint-disable-next-line no-console
            console.log(`%c${Logger.timestamp()} ${message}`, 'color: Lavender; font-weight: normal; font-size: 12px;');
        }
    }

    private static timestamp(): string {
        return new Date().toLocaleTimeString();
    }
}

export default Logger;
