import React from 'react';
import Button from 'src/components/base/buttons/button/Button';
import CancelButton from '../buttons/cancelButton/CancelButton';
import styles from './ButtonContainerPopups.module.scss';

type ButtonContainerPopupsProps = {
    hidePopup: () => void;
    onConfirm: () => void;
};

const ButtonContainerPopups: React.FC<ButtonContainerPopupsProps> = ({ hidePopup, onConfirm }) => (
    <div className={styles.btnContainer}>
        <CancelButton onClick={hidePopup}>Back</CancelButton>
        <Button onClick={onConfirm}>Confirm</Button>
    </div>
);

export default ButtonContainerPopups;