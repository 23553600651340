// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import { BlockButton } from 'src/components';
import { getInCurrencyFormat } from 'src/util';
import { PaymentType } from 'src/util/interfaces';
import styles from './PaymentMediumBlock.module.scss';

interface Props {
    institutionName: string;
    isBackup: boolean;
    isBlocked: boolean;
    paymentType: keyof typeof PaymentType
    isOps: boolean;
    accountNumber: string;
    ownerName: string;
    balance?: number;
}

const PaymentMediumBlock: React.FC<Props> = ({ institutionName, isBackup, isBlocked, isOps, accountNumber, ownerName, balance, paymentType }) => (
    <div className={styles.linkedBankAccount}>
        <div className={cn(styles.row, styles.firstRow)}>
            <div className={styles.institutionWrapper}>
                <p>{institutionName || 'N/A'}</p>
                <p className={styles.smallerText}>{accountNumber ? `xxxx${accountNumber}` : 'N/A'}</p>
            </div>
            <div className={styles.leftAside}>
                {isBackup && <button
                    type="button"
                    className={cn(styles.statusContainer, {
                        [styles.statusContainerGrey]: true,
                        [styles.active]: isBackup
                    })}>Backup
                </button>}
                {(institutionName) && <BlockButton
                    isBlocked={isBlocked}
                    isOps={isOps}
                    paymentType={paymentType} />
                }
            </div>
        </div>
        <div className={cn(styles.row, styles.secondRow)}>
            <p className={cn(styles.smallerText, styles.greyText)}>
                {`Name: ${ownerName != null ? ownerName : 'N/A'}`}
            </p>
            {balance && (<p className={cn(styles.smallerText, styles.greyText)}>
                {`Balance: ${balance ? getInCurrencyFormat(balance) : 'N/A'}`}
            </p>)}
        </div>
    </div>
);

export default PaymentMediumBlock;