import React from 'react';

type Props = {
    width: number;
    height: number;
}

const CardLogo: React.FC<Props> = ({ width, height }): JSX.Element => (

    <svg width={width} height={height} viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.6666 1.93333V18.0667C25.6666 18.6 25.4777 19.0556 25.0999 19.4333C24.7221 19.8111 24.2666 20 23.7333 20H2.26659C1.73325 20 1.2777 19.8111 0.899919 19.4333C0.522141 19.0556 0.333252 18.6 0.333252 18.0667V1.93333C0.333252 1.4 0.522141 0.944445 0.899919 0.566667C1.2777 0.188889 1.73325 0 2.26659 0H23.7333C24.2666 0 24.7221 0.188889 25.0999 0.566667C25.4777 0.944445 25.6666 1.4 25.6666 1.93333ZM1.83325 5.43333H24.1666V1.93333C24.1666 1.82222 24.1221 1.72222 24.0333 1.63333C23.9444 1.54444 23.8444 1.5 23.7333 1.5H2.26659C2.15547 1.5 2.05547 1.54444 1.96659 1.63333C1.8777 1.72222 1.83325 1.82222 1.83325 1.93333V5.43333ZM1.83325 8.93333V18.0667C1.83325 18.1778 1.8777 18.2778 1.96659 18.3667C2.05547 18.4556 2.15547 18.5 2.26659 18.5H23.7333C23.8444 18.5 23.9444 18.4556 24.0333 18.3667C24.1221 18.2778 24.1666 18.1778 24.1666 18.0667V8.93333H1.83325ZM1.83325 18.5V1.5V18.5Z" fill="#1D191F" />
    </svg>
);

export default CardLogo;
