import React from 'react';

type Props = {
    width: number;
    height: number;
    classNames?: string
}

const PaymentsIcon: React.FC<Props> = ({ width, height, classNames }): JSX.Element => (
    <svg fill="#030D45" width={width} height={height} className={classNames} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512">
        <path d="M480,172.534V160a32.025,32.025,0,0,0-21.333-30.133V117.333a32.035,32.035,0,0,0-32-32H85.333a32.035,32.035,0,0,0-32,32v12.534A32.025,32.025,0,0,0,32,160v12.534a32.025,32.025,0,0,0-21.333,30.133v192a32.035,32.035,0,0,0,32,32H469.333a32.035,32.035,0,0,0,32-32v-192A32.025,32.025,0,0,0,480,172.534ZM85.333,106.667H426.667a10.679,10.679,0,0,1,10.667,10.667V128H74.667V117.333A10.679,10.679,0,0,1,85.333,106.667ZM64,149.333H448A10.679,10.679,0,0,1,458.667,160v10.667H53.333V160A10.679,10.679,0,0,1,64,149.333ZM480,394.667a10.681,10.681,0,0,1-10.667,10.667H42.667A10.681,10.681,0,0,1,32,394.667v-192A10.679,10.679,0,0,1,42.667,192H469.333A10.679,10.679,0,0,1,480,202.667Z" />
        <path d="M263.536,289.927c-1.182-.453-2.281-.865-3.229-1.286-1.1-.484-2.427-.995-3.891-1.552-7.021-2.661-11.083-4.75-11.083-7.51,0-.786,1.271-2.9,4.333-4.453,2.281-1.146,10.557-4.3,21.349,3.635a10.666,10.666,0,0,0,12.635-17.187,48.824,48.824,0,0,0-16.984-8.137v-8.1a10.667,10.667,0,0,0-21.333,0v8.591a36.519,36.519,0,0,0-5.3,2.167c-9.891,5.005-16.036,14-16.036,23.487,0,18.021,16.781,24.4,24.844,27.458,1.068.4,2.042.766,2.854,1.125,1.245.547,2.682,1.1,4.224,1.693,8.234,3.146,10.745,4.87,10.745,7.37,0,.786-1.271,2.9-4.339,4.448-2.281,1.161-10.557,4.3-21.344-3.63a10.666,10.666,0,0,0-12.635,17.188,48.566,48.566,0,0,0,16.984,8.025V352a10.667,10.667,0,0,0,21.333,0v-9.172a36.293,36.293,0,0,0,5.292-2.119C281.854,335.7,288,326.7,288,317.224,288,299.271,271.474,292.958,263.536,289.927Z" />
        <path d="M256,202.667a96,96,0,1,0,96,96A96.11,96.11,0,0,0,256,202.667Zm0,170.667a74.667,74.667,0,1,1,74.667-74.667A74.751,74.751,0,0,1,256,373.333Z" />
        <path d="M149.333,362.667H105.318a42.828,42.828,0,0,0-30.651-30.651v-66.7a42.833,42.833,0,0,0,30.651-30.651h44.016a10.667,10.667,0,0,0,0-21.333H96A10.667,10.667,0,0,0,85.333,224,21.356,21.356,0,0,1,64,245.333,10.667,10.667,0,0,0,53.333,256v85.333A10.667,10.667,0,0,0,64,352a21.354,21.354,0,0,1,21.333,21.333A10.667,10.667,0,0,0,96,384h53.333a10.667,10.667,0,1,0,0-21.333Z" /><path d="M448,245.333A21.356,21.356,0,0,1,426.667,224,10.667,10.667,0,0,0,416,213.333H362.667a10.667,10.667,0,1,0,0,21.333h44.016a42.833,42.833,0,0,0,30.651,30.651v66.7a42.828,42.828,0,0,0-30.651,30.651H362.667a10.667,10.667,0,1,0,0,21.333H416a10.667,10.667,0,0,0,10.667-10.667A21.354,21.354,0,0,1,448,352a10.667,10.667,0,0,0,10.667-10.667V256A10.667,10.667,0,0,0,448,245.333Z" />
    </svg>
);

export default PaymentsIcon;