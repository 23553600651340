// eslint-disable-next-line
import React from 'react';
import styles from './ReversalOverview.module.scss';

type ReversalOverviewProps = {
    currentStatus: string;
    updatedStatus: string;
};

const ReversalOverview: React.FC<ReversalOverviewProps> = ({ currentStatus, updatedStatus }) => (
    <div className={styles.statusContent}>
        <div className={styles.currentStatusContainer}>
            <h4 className={styles.firstTitle}>Current status</h4>
            <h3>{currentStatus}</h3>
        </div>
        <div className={styles.updatedStatusContainer}>
            <h4 className={styles.secondTitle}>Updated status</h4>
            <h3>{updatedStatus}</h3>
        </div>
    </div>
);

export default ReversalOverview;