import React, { memo } from 'react';

import { File } from '@zendeskgarden/react-forms';
import { Col, Row } from '@zendeskgarden/react-grid';

import { ReactComponent as DeleteIcon } from 'src/static-assets/logos/trash-table-icon.svg';
import { ReactComponent as UploadDoc } from 'src/static-assets/logos/upload-doc.svg';

import styles from './FileUploadForm.module.scss';

interface IFormValue {
    file: File;
    onRemove: () => void
};

const FileItem: React.FC<IFormValue> = memo((props: IFormValue) => {
    const { file, onRemove } = props;

    return (
        <li className={styles['file-list-item']}>
            <File>
                <UploadDoc className={styles['mr-2']} />
                {file?.name}
                <Row>
                    <Col textAlign="center">
                        <DeleteIcon
                            aria-label="delete"
                            onClick={onRemove}
                            tabIndex={-1}
                            className={styles['cursor-pointer']}
                        />
                    </Col>
                </Row>
            </File>
        </li>
    );
});

export default FileItem;