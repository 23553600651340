// eslint-disable-next-line id-length
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'src/store';
import styles from './BusinessCard.module.scss';


const BusinessCard: React.FC = observer(() => {
    const { CaseVerificationDetailPageStore } = useStore();
    const { dbaName, link, website } = CaseVerificationDetailPageStore.form.business;

    return (
        <div className={cn([styles.card])}>
            <div className={styles.cardContentWrapper}>
                <div className={styles.companyNameLinkContainer}>
                    <h3 className={styles.name}>{dbaName}</h3>
                    <div className={styles.linkContainer}>
                        <a href={link} className={styles.link} target="_blank" rel="noreferrer">Middesk Profile</a>
                    </div>
                </div>
                <div className={styles.vendorWebsite}>
                    <div className={styles.website}>
                        <p>{website}</p>
                    </div>
                    <div className={styles.vendor}>
                        <p>{CaseVerificationDetailPageStore.renderVendorStatus()}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
);

export default BusinessCard;
