import { dropDownInterface } from './interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const mapDropDownOptions = (object: any): dropDownInterface[] => {
    const list: dropDownInterface[] = [];

    return Object.keys(object).map(key => ({ value: key, description: object[key] }));

    return list;
};

export default mapDropDownOptions;