import { DataGrid } from 'devextreme-react';
import { HeaderFilter, Paging, SearchPanel, Selection } from 'devextreme-react/data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RowData } from 'src/util/interfaces';

type DataGridWrapperProps = {
    dataSource: any;
    children: React.ReactNode;
    onCellPrepared: any;
    keyExp: string
    navigator: ((rowData: any) => void) | string;
    wordWrapEnabled?: any;
    headerFilter?: boolean;
    id?: string;
    classNames?: string;
    pageSize?: number;
    textValue?: string | number | undefined;
    handleTextChange?: (value: string) => void;
};

const DataGridWrapper: React.FC<DataGridWrapperProps> = ({ children, dataSource, onCellPrepared, navigator, keyExp, wordWrapEnabled, headerFilter, id, classNames, pageSize = 10, textValue, handleTextChange }) => {

    const navigate = useNavigate();
    const onSelectedChange = navigator instanceof Function
        ? navigator
        : (rowData: RowData) => {
            navigate(`${navigator}/${rowData.selectedRowKeys[0]}`);
        };

    return (
        <DataGrid
            id={id}
            className={classNames}
            dataSource={dataSource}
            keyExpr={keyExp}
            onCellPrepared={onCellPrepared}
            width="100%"
            showColumnLines={false}
            showRowLines={false}
            showBorders={false}
            allowColumnResizing={true}
            onSelectionChanged={onSelectedChange}
            wordWrapEnabled={wordWrapEnabled}
        >
            <HeaderFilter visible={headerFilter} />
            <Selection mode="single" />
            <Paging defaultPageSize={pageSize} />
            <SearchPanel
                visible={true}
                width={440}
                placeholder="Search by keyword"
                defaultText={textValue}
                onTextChange={handleTextChange}
            />
            {children}
        </DataGrid>
    );
};

export default DataGridWrapper;