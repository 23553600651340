// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import AccountLink from 'src/components/business/accounts/accountLink/AccountLink';
import PaymentMediumBlock from 'src/components/business/PaymentMediumBlock/PaymentMediumBlock';
import { payee as payeeInterface, payor as payorInterface } from 'src/components/business/transactions/basePanel/BasePanel';
import { getCurrencyOrNotApplicable, getDateFormatNoTime, getInCurrencyFormat } from 'src/util';
import { PaymentType } from '../../../util/interfaces';
import styles from '../TransactionDetailsPage.module.scss';

type Props = {
    payee: payeeInterface,
    payor: payorInterface,
    mutualTransactions: {
        lastTransactionDate: string,
        averageTransactionSize: number,
        totalTransactions: number,
        totalAmount: number,
    }
}

const PayeePayorSection: React.FC<Props> = ({ payee, payor, mutualTransactions }) => {
    const payeeLBAData = payee.linkedAccountBlock;
    const payorLBAData = payor.linkedAccountBlock;

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.payeePayorContainer}>
                <div className={styles.payeePayorWrapper}>
                    <div className={styles.payeeContainer}>

                        <h4 className={styles.payeeTitle}>Payee</h4>
                        <div className={styles.contentContainer}>
                            <AccountLink
                                dbaName={payee.dbaName}
                                isVerified={payee.isVerified}
                                score={payee.score}
                                createdTimestamp={payee.createdTimestamp}
                                caseId={payee.caseId}
                                accountLogo={payee.accountLogo}
                                key="payee"
                                planType={payee.planType}
                            />
                            <div className={styles.informationBlock}>
                                <div className={styles.row}>
                                    <p className={cn(styles.greyText, styles.leftSideSpacer)}>Hopscotch balance:</p>
                                    <p className={styles.greyText}>{getCurrencyOrNotApplicable(payee?.hopscotchBalance)}</p>
                                </div>
                                <div className={styles.row}>
                                    <p className={cn(styles.greyText, styles.leftSideSpacer)}>Bank balance:</p>
                                    <p className={styles.greyText}>{getCurrencyOrNotApplicable(payee?.bankPartnerBalance)}</p>
                                </div>
                                <div className={styles.row}>
                                    <p className={cn(styles.greyText, styles.leftSideSpacer)}>Avg txn size:</p>
                                    <p className={styles.greyText}>{getCurrencyOrNotApplicable(payee?.averageTransactionSize)}</p>
                                </div>
                                <div className={styles.row}>
                                    <p className={cn(styles.greyText, styles.leftSideSpacer)}>Txn volume:</p>
                                    <p className={styles.greyText}>{payee?.totalTransactionVolume}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={styles.payorContainer}>
                        <h4 className={styles.payorTitle}>Payor</h4>
                        <div className={styles.contentContainer}>
                            <div className={styles.contentData}>
                                <AccountLink
                                    dbaName={payor.dbaName}
                                    isVerified={payor.isVerified}
                                    score={payor.score}
                                    createdTimestamp={payor.createdTimestamp}
                                    caseId={payor.caseId}
                                    accountLogo={payor.accountLogo}
                                    key="payor"
                                    planType={payor.planType}
                                />
                                <div className={styles.informationBlock}>
                                    <div className={styles.row}>
                                        <p className={cn(styles.greyText, styles.leftSideSpacer)}>Hopscotch balance:</p>
                                        <p className={styles.greyText}>{getCurrencyOrNotApplicable(payor.hopscotchBalance)}</p>
                                    </div>
                                    <div className={styles.row}>
                                        <p className={cn(styles.greyText, styles.leftSideSpacer)}>Bank balance:</p>
                                        <p className={styles.greyText}>{getCurrencyOrNotApplicable(payor.bankPartnerBalance)}</p>
                                    </div>
                                    <div className={styles.row}>
                                        <p className={cn(styles.greyText, styles.leftSideSpacer)}>Avg txn size:</p>
                                        <p className={styles.greyText}>{getCurrencyOrNotApplicable(payor.averageTransactionSize)}</p>
                                    </div>
                                    <div className={styles.row}>
                                        <p className={cn(styles.greyText, styles.leftSideSpacer)}>Txn volume:</p>
                                        <p className={styles.greyText}>{payor.totalTransactionVolume}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.mutualContainer}>
                    <p className={styles.greyText}>Mutual Transaction(s):</p>
                    <p className={styles.greyText}>{mutualTransactions.lastTransactionDate === 'N/A' ? 'N/A' : getDateFormatNoTime(mutualTransactions.lastTransactionDate)}</p>
                    <p className={styles.greyText}>{getInCurrencyFormat(mutualTransactions.totalAmount)}</p>
                    <p className={styles.greyText}>(+{mutualTransactions.totalTransactions})</p>
                </div>

                <div className={styles.lbaContainer}>
                    <div className={styles.payeeLBA}>
                        <PaymentMediumBlock
                            institutionName={payeeLBAData?.institutionName}
                            isBackup={payeeLBAData?.isFunding}
                            isBlocked={payeeLBAData?.isBlocked}
                            isOps={payeeLBAData?.isOps || false}
                            accountNumber={payeeLBAData?.accountNumber}
                            ownerName={payeeLBAData?.ownerName}
                            paymentType={payorLBAData?.paymentType ?? PaymentType.NotSet}
                        />
                    </div>
                    <div className={styles.payorLBA}>
                        <PaymentMediumBlock
                            institutionName={payorLBAData?.institutionName}
                            isBackup={payorLBAData?.isFunding}
                            isBlocked={payorLBAData?.isBlocked}
                            isOps={payorLBAData?.isOps || false}
                            accountNumber={payorLBAData?.accountNumber}
                            ownerName={payorLBAData?.ownerName}
                            paymentType={payorLBAData?.paymentType ?? PaymentType.NotSet}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayeePayorSection;