import React from 'react';
import { ACCENT_PINK_COLOR } from 'src/Constant';

type DisplayAccountLogoProps = {
    accountLogo?: string;
}

const DisplayAccountLogo: React.FC<DisplayAccountLogoProps> = ({ accountLogo }): JSX.Element => (
    accountLogo
        ? <img src={`data:image/png;base64, ${accountLogo}`} width="42px" height="42px" alt="Logo" />
        : <svg width="42" height="42" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="29" height="30" rx="5" fill={ACCENT_PINK_COLOR} />
        </svg>
);

export default DisplayAccountLogo;