import React from 'react';

type Props = {
    width: number;
    height: number;
    classNames?: string
}

const SearchIcon: React.FC<Props> = ({ width, height, classNames }): JSX.Element => (
    <svg width={width} height={height} className={classNames} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.70081 1.06406C3.13952 1.06406 1.06406 3.13787 1.06406 5.69494C1.06406 8.25202 3.13952 10.3258 5.70081 10.3258C6.97863 10.3258 8.13492 9.81019 8.974 8.97495C9.81687 8.13595 10.3376 6.97656 10.3376 5.69494C10.3376 3.13787 8.26211 1.06406 5.70081 1.06406ZM0.166626 5.69494C0.166626 2.64125 2.64486 0.166626 5.70081 0.166626C8.75677 0.166626 11.235 2.64125 11.235 5.69494C11.235 7.06307 10.737 8.31562 9.91315 9.28063L11.7017 11.0671C11.877 11.2422 11.8772 11.5263 11.702 11.7017C11.5269 11.877 11.2428 11.8772 11.0675 11.702L9.27725 9.9139C8.3129 10.7304 7.0641 11.2233 5.70081 11.2233C2.64486 11.2233 0.166626 8.74864 0.166626 5.69494Z"
            fill="#030D45"
        />
    </svg>
);

export default SearchIcon;