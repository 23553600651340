import { DataGrid } from 'devextreme-react';
import { Column, SearchPanel } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Content from 'src/layouts/content/Content';
import EntireLayout from '../../../layouts/entireLayout/EntireLayout';
import { useStore } from '../../../store';
import Row from '../../base/row/Row';
import Section from '../../base/section/Section';
import SectionLabel from '../../base/sectionLabel/SectionLabel';
import UUIDInput from '../UUIDInput';
import styles from './TraceInfoPage.module.scss';

const TraceInfoPage: React.FC = observer(() => {
    const { TraceInfoPageStore } = useStore();

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Trace Information</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <div className={styles.container}>
                            <UUIDInput />
                            <button type="button" className={styles.tracebtn}>
                                trace
                            </button>
                        </div>
                        <DataGrid
                            id="gridContainer"
                            dataSource={TraceInfoPageStore.logData}
                            keyExpr="ID"
                            width="auto"
                            showRowLines={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            rowAlternationEnabled={true}
                        >
                            <SearchPanel visible={true} width={300} placeholder="search" />
                            <Column dataField="ID" caption="id" dataType="string" />
                            <Column dataField="inserted_ts" caption="inserted_ts" dataType="string" format="2022-01-12 17:54:31" />
                            <Column dataField="logging_ts" caption="logging_ts" dataType="string" format="2022-01-12 17:54:31" />
                            <Column dataField="trace_id" caption="trace_id" dataType="string" format="fb80934a-117b-440f-a7ca-3398d248e637" />
                            <Column dataField="hostname" caption="hostname" dataType="string" format="ip-172-17-84-87.ec2.internal" />
                            <Column dataField="login_id" caption="login_id" dataType="string" />
                            <Column dataField="process_name" caption="process_name" dataType="string" />
                            <Column dataField="process_id" caption="process_id" dataType="string" />
                            <Column dataField="thread_id" caption="thread_id" dataType="string" />
                            <Column dataField="category_level" caption="category_level" dataType="string" />
                            <Column dataField="category" caption="category" dataType="string" />
                            <Column dataField="classname" caption="classname" dataType="string" />
                            <Column dataField="line_number" caption="line_number" dataType="number" alignment="left" />
                            <Column dataField="message" caption="message" dataType="string" />
                            <Column dataField="exception_stacktrace" caption="exception_stacktrace" dataType="string" />
                        </DataGrid>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});


export default TraceInfoPage;