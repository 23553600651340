import { createContext, useContext } from 'react';
import EngEventsSearchPageStore, { EngEventsSearchPageStoreInterface } from 'src/components/engineering/pages/engEventsSearchPage/EngEventsSearchPageStore';
import SupportPageStore, { SupportPageStoreInterface } from 'src/components/engineering/pages/support/SupportPageStore';
import TraceInfoPageStore, { TraceInfoPageInterface } from 'src/components/engineering/traceInfoPage/TraceInfoPageStore';
import AccountDetailsPageStore, { AccountDetailsPageStoreInterface } from 'src/pages/accountDetailsPage/AccountDetailsPageStore';
import AccountSearchPageStore, { AccountSearchPageStoreInterface } from 'src/pages/accountSearchPage/AccountSearchPageStore';
import BusinessPageStore, { BusinessPageStoreInterface } from 'src/pages/businessPage/BusinessPageStore';
import CardControlsPageStore, { CardControlsPageStoreInterface } from 'src/pages/cardControlsPage/CardControlsPageStore';
import CardVerificationSearchPageStore, { CardVerificationSearchPageStoreInterface } from 'src/pages/cardVerificationSearchPage/CardVerificationSearchPageStore';
import CaseVerificationDetailPageStore, { CaseVerificationDetailPageStoreInterface } from 'src/pages/caseVerificationDetailPage/CaseVerificationDetailPageStore';
import CaseVerificationSearchPageStore, { CaseVerificationSearchPageStoreInterface } from 'src/pages/caseVerificationSearchPage/CaseVerificationSearchPageStore';
import CaseVerificationSearchPageStoreV2 from 'src/pages/caseVerificationSearchPageV2/CaseVerificationSearchPageStoreV2';
import PaidPlanControlsStore, { PaidPlanControlsInterface } from 'src/pages/paidPlanControls/PaidPlanControlsStore';
import EventsSearchPageStore, { EventsSearchPageStoreInterface } from '../pages/eventsSearchPage/EventsSearchPageStore';
import FactoredInvoiceDetailsPageStore, { FactoredInvoiceDetailsPageStoreInterface } from '../pages/factoredInvoiceDetailsPage/FactoredInvoiceDetailsPageStore';
import FactoredInvoicesSearchPageStore, { FactoredInvoicesSearchPageStoreInterface } from '../pages/factoredInvoicesSearchPage/FactoredInvoicesSearchPageStore';
import FactoredRiskModelPageStore, { FactoredRiskModelPageStoreInterface } from '../pages/factoredRiskModelPage/FactoredRiskModelPageStore';
import InvoiceDetailsPageStore, { InvoiceDetailsPageStoreInterface } from '../pages/invoiceDetailsPage/InvoiceDetailsPageStore';
import InvoicesSearchPageStore, { InvoicesSearchPageStoreInterface } from '../pages/invoicesSearchPage/InvoicesSearchPageStore';
import LBAVerificationSearchPageStore, { LBAVerificationSearchPageStoreInterface } from '../pages/lbaVerificationSearchPage/LBAVerificationSearchPageStore';
import ProgramControlsPageStore, { ProgramControlsPageInterface } from '../pages/programControlsPage/ProgramControlsPageStore';
import TransactionDetailsPageStore, { TransactionDetailsPageStoreInterface } from '../pages/transactionDetailsPage/TransactionDetailsPageStore';
import TransactionsSearchPageStore, { TransactionsSearchPageStoreInterface } from '../pages/transactionSearchPage/TransactionsSearchPageStore';
import SharedStore, { SharedStoreProp } from './SharedStore';


const sharedStore = SharedStore();
interface StoreProp {
    sharedStore: SharedStoreProp;
    TransactionDetailsPageStore: TransactionDetailsPageStoreInterface;
    InvoiceDetailsPageStore: InvoiceDetailsPageStoreInterface;
    AccountSearchPageStore: AccountSearchPageStoreInterface;
    EventsSearchPageStore: EventsSearchPageStoreInterface;
    FactoredInvoiceDetailsPageStore: FactoredInvoiceDetailsPageStoreInterface;
    AccountDetailsPageStore: AccountDetailsPageStoreInterface;
    FactoredInvoicesSearchPageStore: FactoredInvoicesSearchPageStoreInterface;
    FactoredRiskModelPageStore: FactoredRiskModelPageStoreInterface;
    TransactionsSearchPageStore: TransactionsSearchPageStoreInterface;
    InvoicesSearchPageStore: InvoicesSearchPageStoreInterface;
    CaseVerificationSearchPageStore: CaseVerificationSearchPageStoreInterface;
    CaseVerificationDetailPageStore: CaseVerificationDetailPageStoreInterface;
    LBAVerificationPageStore: LBAVerificationSearchPageStoreInterface;
    ProgramControlsPageStore: ProgramControlsPageInterface;
    PaidPlanControlsStore: PaidPlanControlsInterface;
    BusinessPageStore: BusinessPageStoreInterface;
    TraceInfoPageStore: TraceInfoPageInterface;
    EngEventsSearchPageStore: EngEventsSearchPageStoreInterface;
    CaseVerificationSearchPageStoreV2: any;
    CardVerificationSearchPageStore: CardVerificationSearchPageStoreInterface;
    CardControlsPageStore: CardControlsPageStoreInterface,
    SupportPageStore: SupportPageStoreInterface,
};

const store = {
    sharedStore,
    TransactionDetailsPageStore: TransactionDetailsPageStore(sharedStore),
    InvoiceDetailsPageStore: InvoiceDetailsPageStore(sharedStore),
    AccountSearchPageStore: AccountSearchPageStore(),
    AccountDetailsPageStore: AccountDetailsPageStore(sharedStore),
    EventsSearchPageStore: EventsSearchPageStore(),
    FactoredInvoiceDetailsPageStore: FactoredInvoiceDetailsPageStore(sharedStore),
    FactoredInvoicesSearchPageStore: FactoredInvoicesSearchPageStore(),
    FactoredRiskModelPageStore: FactoredRiskModelPageStore(),
    TransactionsSearchPageStore: TransactionsSearchPageStore(),
    InvoicesSearchPageStore: InvoicesSearchPageStore(),
    InvoiceDetailPageStore: InvoiceDetailsPageStore(sharedStore),
    CaseVerificationSearchPageStore: CaseVerificationSearchPageStore(),
    CaseVerificationSearchPageStoreV2: CaseVerificationSearchPageStoreV2(),
    CaseVerificationDetailPageStore: CaseVerificationDetailPageStore(sharedStore),
    LBAVerificationPageStore: LBAVerificationSearchPageStore(),
    ProgramControlsPageStore: ProgramControlsPageStore(),
    PaidPlanControlsStore: PaidPlanControlsStore(),
    BusinessPageStore: BusinessPageStore(),
    TraceInfoPageStore: TraceInfoPageStore(),
    EngEventsSearchPageStore: EngEventsSearchPageStore(),
    CardVerificationSearchPageStore: CardVerificationSearchPageStore(),
    CardControlsPageStore: CardControlsPageStore(),
    SupportPageStore: SupportPageStore(),
};

export const StoreContext = createContext(store);

export const useStore = (): StoreProp => useContext<typeof store>(StoreContext);

export default store;
