import React from 'react';
import Biz from 'src/components/business/Biz';
import Eng from 'src/components/engineering/Eng';
import styles from 'src/static-assets/styles/pages/_home.module.scss';
import { useStore } from '../../store';
import useFetchExchangeCodeForTokens from './useFetchExchangeCodeForTokens';

const Home: React.FC = () => {
    const { sharedStore } = useStore();
    useFetchExchangeCodeForTokens();
    const isLoading = sharedStore.isLoading;

    return (
        <div className={styles.componentsContainer}>
            {!isLoading && <Biz />}
            {!isLoading && <Eng />}
        </div>
    );
};

export default Home;
