import React from 'react';
import styles from './TextValue.module.scss';

type Props = {
    children: any;
}

const TextValue: React.FC<Props> = ({ children }): JSX.Element => (
    <div className={styles.text}>{children}</div>
);

export default TextValue;