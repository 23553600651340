import React from 'react';
import styles from 'src/pages/transactionDetailsPage/TransactionDetailsPage.module.scss';

type ListItemProps = {
    label: string;
    response: string | number;
}

const ListItem: React.FC<ListItemProps> = ({ label, response }) => (
    <p>{label}: <span className={styles.containerItem}>{response}</span></p>
);

export default ListItem;