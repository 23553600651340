import { makeAutoObservable } from 'mobx';
import { CARD_SEARCH_COUNT, CARD_SEARCH_STATUS, CARD_SEARCH_UPDATED_BY, CASES_COUNT_URL, CASE_LBA_SEARCH_COUNT, FACTORED_INVOICES_COUNT_URL, FLAGGED_TRANSACTIONS_COUNT_URL, FLAGGED_TRANSACTIONS_LASTWEEK_COUNT_URL, RETURNED_TRANSACTIONS_COUNT_URL, RETURNED_TRANSACTIONS_LASTWEEK_COUNT_URL, TOTAL_DEPOSIT_API, TRANSACTION_SEARCH_COUNT_URL, TRANSACTION_VOLUME_API } from 'src/Constant';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { Logger } from 'src/util';
import { SearchPaymentType } from '../../util/interfaces';

const KEY_OF_30_DAY_TRANSACTIONAL_AMOUNT = '30DayTransactionalAmount';

export type BusinessPageStoreInterface = {
    verifiedCount: number,
    reviewCount: number;
    monthlyTransactionAmount: number;
    awaitingTransactions: number;
    blockedHopCardCount: number;
    totalReturned: number;
    totalDeposits: number;
    totalFlagged: number;
    totalFactored: number;
    totalFlaggedPast7Days: number;
    totalReturnedPast7Days: number;
    totalFactoredAwaiting: number;
    totalRejected: number;
    totalPastDue: number;
    totalBlockedBankAccounts: number;
    totalUnblockedBankAccounts: number;
    getTotalUnblockedBankAccounts(): number;
    getTotalAccounts(): number;
    getTotalReviewedAccounts(): number;
    getTotalFlaggedTransactions(): number;
    getTransactionVolumeOver30Days(): number;
    getTotalReturned(): number;
    getTotalFactored(): number;
    getTotalFlaggedPast7Days(): number;
    getTotalReturnedPast7Days(): number;
    getTotalFactoredAwaiting(): number;
    getTotalDeposits(): number;
    getTotalBlockedBankAccounts(): number;
    getAwaitingTransactions(): number;
    getBlockedHopCardCount(): number;
    fetchLBACounts(fetch: FetchInterfaceLegacy): void;
    fetchCasesCount(fetch: FetchInterfaceLegacy): void;
    fetchFlaggedTransactionsCount(fetch: FetchInterfaceLegacy): void;
    fetchAwaitingTransactionsCount(fetch: FetchInterfaceLegacy): void;
    fetchFlaggedTransactionsLastWeekCount(fetch: FetchInterfaceLegacy): void;
    fetchReturnedTransactionsCount(fetch: FetchInterfaceLegacy): void;
    fetchReturnedTransactionsLastWeekCount(fetch: FetchInterfaceLegacy): void;
    fetchFactoredInvoicesCount(fetch: FetchInterfaceLegacy): void;
    fetchTransactionVolumeOver30Days(fetch: FetchInterfaceLegacy): void;
    fetchTotalDeposits(fetch: FetchInterfaceLegacy): void;
    fetchBlockedHopCount(fetch: FetchInterfaceLegacy): void;
};

const BusinessPageStore = (): BusinessPageStoreInterface =>
    makeAutoObservable({
        verifiedCount: 0,
        reviewCount: 0,
        monthlyTransactionAmount: 0,
        awaitingTransactions: 0,
        blockedHopCardCount: 0,
        totalReturned: 0,
        totalDeposits: 0,
        totalFlagged: 0,
        totalFactored: 0,
        totalFlaggedPast7Days: 0,
        totalReturnedPast7Days: 0,
        totalFactoredAwaiting: 0,
        totalRejected: 0,
        totalPastDue: 0,
        totalBlockedBankAccounts: 0,
        totalUnblockedBankAccounts: 0,
        getTotalUnblockedBankAccounts() {
            return this.totalUnblockedBankAccounts;
        },
        getTotalAccounts() {
            return this.verifiedCount;
        },
        getTotalReviewedAccounts() {
            return this.reviewCount;
        },
        getTotalFlaggedTransactions() {
            return this.totalFlagged;
        },
        getTransactionVolumeOver30Days() {
            return this.monthlyTransactionAmount;
        },
        getTotalReturned() {
            return this.totalReturned;
        },
        getTotalFactored() {
            return this.totalFactored;
        },
        getTotalFlaggedPast7Days() {
            return this.totalFlaggedPast7Days;
        },
        getTotalReturnedPast7Days() {
            return this.totalReturnedPast7Days;
        },
        getTotalFactoredAwaiting() {
            return this.totalRejected + this.totalPastDue;
        },
        getTotalDeposits() {
            return this.totalDeposits;
        },
        getTotalBlockedBankAccounts() {
            return this.totalBlockedBankAccounts;
        },
        getAwaitingTransactions() {
            return this.awaitingTransactions;
        },
        getBlockHopCardCount() {
            return this.awaitingTransactions;
        },
        getBlockedHopCardCount() {
            return this.blockedHopCardCount;
        },
        fetchLBACounts(fetch: FetchInterfaceLegacy) {
            fetch({ url: CASE_LBA_SEARCH_COUNT('Blocked', 'All') }, (res) => {
                this.totalBlockedBankAccounts = res.data;
            });
            fetch({ url: CASE_LBA_SEARCH_COUNT('UnBlocked', 'All') }, (res) => {
                this.totalUnblockedBankAccounts = res.data;
            });
        },
        fetchCasesCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: `${CASES_COUNT_URL}?status=Pass` }, (res) => {
                this.verifiedCount = res.data;
            });
            fetch({ url: `${CASES_COUNT_URL}?status=Review` }, (res) => {
                this.reviewCount = res.data;
            });
        },
        fetchFlaggedTransactionsCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: FLAGGED_TRANSACTIONS_COUNT_URL }, (res) => {
                this.totalFlagged = res.data;
            });
        },
        fetchAwaitingTransactionsCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: TRANSACTION_SEARCH_COUNT_URL(SearchPaymentType.cardWaitingApproval) },
                ({ data }) => {
                    this.awaitingTransactions = data;
                });
        },
        fetchBlockedHopCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: `${CARD_SEARCH_COUNT(CARD_SEARCH_STATUS.Blocked, CARD_SEARCH_UPDATED_BY.Hop)}` },
                ({ data }) => {
                    this.blockedHopCardCount = data;
                });
        },
        fetchFlaggedTransactionsLastWeekCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: FLAGGED_TRANSACTIONS_LASTWEEK_COUNT_URL }, (res) => {
                this.totalFlaggedPast7Days = res.data;
            });
        },
        fetchReturnedTransactionsCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: RETURNED_TRANSACTIONS_COUNT_URL }, (res) => {
                this.totalReturned = res.data;
            });
        },
        fetchReturnedTransactionsLastWeekCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: RETURNED_TRANSACTIONS_LASTWEEK_COUNT_URL }, (res) => {
                this.totalReturnedPast7Days = res.data;
            });
        },
        fetchFactoredInvoicesCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: FACTORED_INVOICES_COUNT_URL }, (res) => {
                this.totalFactored = res.data;
            });
            fetch({ url: `${FACTORED_INVOICES_COUNT_URL}?type=${SearchPaymentType.rejected}` }, (res) => {
                this.totalRejected = res.data;
            });
            fetch({ url: `${FACTORED_INVOICES_COUNT_URL}?type=${SearchPaymentType.pastDue}` }, (res) => {
                this.totalPastDue = res.data;
            });
        },
        fetchTransactionVolumeOver30Days(fetch: FetchInterfaceLegacy) {
            fetch({ url: TRANSACTION_VOLUME_API }, (res: any) => {
                this.monthlyTransactionAmount = res.data[KEY_OF_30_DAY_TRANSACTIONAL_AMOUNT];
                Logger.info(JSON.stringify(this.monthlyTransactionAmount));
            });
        },
        fetchTotalDeposits(fetch: FetchInterfaceLegacy) {
            fetch({ url: TOTAL_DEPOSIT_API }, (res: any) => {
                this.totalDeposits = res.data.totalDeposits;
            });
        },
    });

export default BusinessPageStore;
