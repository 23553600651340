import React from 'react';
import styles from '../ProgramControlsPage.module.scss';
import BalanceCard from './BalanceCard';

interface BalancesProps {
    header: string;
    currentBalance: number;
    availableBalance: number;
}

const ProgramReserveBalances: React.FC<BalancesProps> = ({ header, currentBalance, availableBalance }) => (
    <div className={styles.balanceContainer}>
        <div>
            <h3>{header}</h3>
            <BalanceCard isAnimated={true} label="Current" balance={currentBalance} />
            <BalanceCard label="Available" balance={availableBalance} />
        </div>
    </div>
);

export default ProgramReserveBalances;