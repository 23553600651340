import { observer } from 'mobx-react-lite';
import React from 'react';
import { Params, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import BaseAuditHistoryPopup from 'src/components/base/popups/baseAuditHistoryPopup/BaseAuditHistoryPopup';
import { useStore } from 'src/store';
import AuditHistoryTransactionsContent from './auditHistoryTransactionsContent/AuditHistoryTransactionsContent';

type Props = {
    popupVisible: boolean;
    hidePopup: any;
};

const TransactionDetailsAuditPopup: React.FC<Props> = observer(({ popupVisible, hidePopup }) => {
    const { TransactionDetailsPageStore } = useStore();
    const { transactionId }: Readonly<Params<string>> = useParams();
    const fetch = useFetchLegacy();

    const histories = TransactionDetailsPageStore.getAuditTransHistory();

    return (
        <BaseAuditHistoryPopup
            popupVisible={popupVisible}
            hidePopup={hidePopup}
            title="Payments History"
            updateCallBack={() => TransactionDetailsPageStore.fetchTransactionsAuditCSV(fetch, transactionId ?? '')}
        >
            <AuditHistoryTransactionsContent histories={histories} />
        </BaseAuditHistoryPopup>
    );
});

export default TransactionDetailsAuditPopup;