
export const TransactionActions = {
  amlRisk: 'amlRisk',
  isCovered: 'isCovered',
  isApproved: 'isApproved',
  isDenied: 'isDenied'
} as const;
export interface Form {
  isCovered: boolean;
  isCoverable: boolean;
  isAmlRisk: boolean;
  isApproved: boolean;
  isDenied: boolean;
  reason: string;
}
export interface Transaction {
  totalReceived: number;
  invoiceFeeAmount: number;
  invoiceAmount: number;
  isFeeOnPayorForInvoice: boolean;
  causationIdType: string;
  transactionId: string;
  transactionType: string;
  transactionAmount: number;
  transferAmount: number;
  transferStatus: string;
  transactionDate: string;
  invoiceDueDate: string;
  transactionStatus: string;
  isCovered: boolean;
  isAmlRisk: boolean;
  isCoverable: boolean;
  payee: Payee;
  payer: Payer;
  mutualTransaction: MutualTransaction;
  action: Action;
  isWaitingForApproval: boolean;
  isDenied: boolean;
  isApproved: boolean;
  isInitiated: boolean;
  meta?: Meta;
  invoiceTotalAmount: number;
  paymentAmount: number;
  invoiceSubTotal: number;
  amount: number;
  cardPartnerId: string;
  cardTransactionStatus: string;
  bankPartnerId: string;
  payorBankPartnerId: string;
  payeeBankPartnerId: string;
}

export interface Meta {
  actualAmount: number;
  feePercentage: number;
  feeAmount: number;
  amountWithFee: number;
}

export interface Payee {
  createdTimestamp: string;
  dbaName: string;
  name: string;
  totalTransactionVolume: number;
  averageTransactionSize: number;
  hopscotchBalance: string;
  bankPartnerBalance: string;
  returns: number;
  hopscotchUser: boolean;
  score: string;
  logo: string;
  caseId: string;
  decision: string;
  profileLink: string;
  sinceDate: string;
  status: string;
  linkedAccountDetails: object;
  isFeeOnPayor: boolean;
  planType: string;
}
export interface Payer {
  createdTimestamp: string;
  dbaName: string;
  name: string;
  totalTransactionVolume: number;
  averageTransactionSize: number;
  hopscotchBalance: string;
  bankPartnerBalance: string;
  returns: number;
  hopscotchUser: boolean;
  score: string;
  logo: string;
  caseId: string;
  decision: string;
  profileLink: string;
  sinceDate: string;
  status: string;
  linkedAccountDetails: object;
  planType: string;
}
export interface MutualTransaction {
  lastTransactionDate: string;
  totalTransactions: number;
  totalAmount: number;
}
export interface Action {
  reason: string;
  lastAuditDate: string;
  lastAuditedBy: string;
  flagged: boolean;
  transactionCovered: boolean;
}
export interface TransactionDetailAuditHistoryEntity {
  id: string;
  modalId: string;
  date: string;
  operator: string;
  flagTransaction: boolean;
  flagType: string;
  operationReason: string;
}