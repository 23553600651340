import React from 'react';

type FastTrackIconProps = {
    width?: number;
    height?: number;
}

const FastTrackIcon: React.FC<FastTrackIconProps> = ({ width, height }) => (
    <svg width={width || '20'} height={height || '20'} viewBox="0 0 86 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M28.8596 31.2373L13.118 50.5863C11.7242 52.3081 9.59255 53.2919 7.37888 53.2919C3.03354 53.2919 0 49.6845 0 45.913C0 44.3553 0.491925 42.7155 1.63975 41.3217L16.7255 23.5304C16.9714 23.2845 16.7255 22.8745 16.3975 22.9565C16.3155 22.9565 16.1516 22.9565 15.9876 22.9565C12.7901 23.2845 9.75652 21.8087 7.70683 19.3491L1.72174 11.9702C-2.21366 7.21491 1.14783 0 7.37888 0H7.46087C9.67454 0 11.7242 0.98385 13.118 2.70559L28.8596 21.9727C31.0733 24.6783 31.0733 28.5317 28.8596 31.2373Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M55.9976 31.2373L40.256 50.5863C38.8622 52.3081 36.7305 53.2919 34.5168 53.2919C30.1715 53.2919 27.1379 49.6845 27.1379 45.913C27.1379 44.3553 27.6299 42.7155 28.7777 41.3217L44.0274 23.5304C44.2733 23.2845 44.0274 22.8745 43.6994 22.9565C43.5355 22.9565 43.3715 22.9565 43.2075 22.9565C40.01 23.2845 36.9764 21.8087 34.9268 19.3491L28.9417 11.9702C24.9243 7.21491 28.3678 0 34.5168 0H34.5988C36.8125 0 38.8622 0.98385 40.256 2.70559L55.9976 21.9727C58.2112 24.6783 58.2112 28.5317 55.9976 31.2373Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M83.9553 31.2373L68.2137 50.5863C66.8199 52.3081 64.6882 53.2919 62.4746 53.2919C58.1292 53.2919 55.0957 49.6845 55.0957 45.913C55.0957 44.3553 55.5876 42.7155 56.7355 41.3217L71.9032 23.5304C72.1491 23.2845 71.9032 22.8745 71.5752 22.9565C71.4112 22.9565 71.2473 23.0385 71.0833 23.0385C67.8858 23.3665 64.8522 21.8907 62.8025 19.4311L56.8174 12.0522C52.882 7.29689 56.3255 0.0819855 62.4746 0.0819855H62.5566C64.7702 0.0819855 66.8199 1.06584 68.2137 2.78758L83.9553 22.0547C86.087 24.6783 86.087 28.5317 83.9553 31.2373Z" fill="black" />
    </svg>
);

export default FastTrackIcon;