// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import getInNumberFormat from 'src/util/getInNumberFormat';
import { SearchStatus } from 'src/util/interfaces';
import styles from './LinkButton.module.scss';

type LinkButtonProps = {
    store?: {
        setSelected: (status: string) => void;
    };
    status?: string;
    to: string;
    count: number;
    label: string;
    isActive: boolean;
    showDelimiter?: boolean;
};

const LinkButton: React.FC<LinkButtonProps> = ({ isActive, store, to, status, count, label, showDelimiter: isLast }): JSX.Element => {
    const content = () => <>{label} ({getInNumberFormat(count)})</>;
    return <>
        {!isActive && (<NavLink
            to={to}
            className={cn(
                styles.componentLink,
                {
                    [styles.isActive]: isActive,
                    [styles.pendingNumber]: status
                })}
            onClick={() => {
                store?.setSelected(status ?? SearchStatus.All);
            }}> {content()}
        </NavLink>)}
        {isActive && (<span className={cn(styles.isActive, styles.label)}> {content()}</span>)}
        {isLast && '|'}
    </>;
};

export default LinkButton;