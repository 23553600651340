// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import styles from './TwoColumnRow.module.scss';

type Props = {
    children?: React.ReactNode,
    classNames?: string
}

const TwoColumnRow: React.FC<Props> = ({ children, classNames }) => (
    <div data-testid="TwoColumnRow" className={cn(styles.twoColumns, classNames)}>{children}</div>
);

export default TwoColumnRow;
