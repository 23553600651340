import { observer } from 'mobx-react-lite';
import React from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import AuditDropDown from 'src/components/base/auditDropDown/AuditDropDown';
import Button from 'src/components/base/buttons/button/Button';
import CancelButton from 'src/components/base/buttons/cancelButton/CancelButton';
import RadioLabelDynamic from 'src/components/base/radioLabels/radioLabelDynamic/RadioLabelDynamic';
import Textarea from 'src/components/base/Textarea';
import Gap from 'src/layouts/gap/Gap';
import HalfPanel from 'src/layouts/twoColumnRow/halfPanel/HalfPanel';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import { useStore } from 'src/store';
import styles from './OverallActionSection.module.scss';

const OverallActionSection: React.FC = observer(() => {
    const { CaseVerificationDetailPageStore } = useStore();
    const score = CaseVerificationDetailPageStore.getScore();
    const decision = CaseVerificationDetailPageStore.getDecision();
    const reasoning = CaseVerificationDetailPageStore.getReasoning();
    const id = CaseVerificationDetailPageStore.getCaseId();
    const isFormReady = CaseVerificationDetailPageStore.getIsFormReady();

    const fetch = useFetchLegacy();

    return (<>
        <h4 className={styles.sectionText}>Overall Action</h4>
        <hr className={styles.hr} />

        <div className={styles.overallActionsContainer}>
            <form>
                <div className={styles.riskProfileScoreContainer}>
                    <p className={styles.riskProfileScoreText}>Risk Profile Score</p>
                    <RadioLabelDynamic
                        label="Low"
                        setChecked={() => CaseVerificationDetailPageStore.setScore('Low')}
                        isChecked={score === 'Low'}
                        isPositive={true}
                        isSmallLabel={true}
                    />
                    <RadioLabelDynamic
                        label="High"
                        setChecked={() => CaseVerificationDetailPageStore.setScore('High')}
                        isChecked={score === 'High'}
                        isSmallLabel={true}
                    />
                </div>
                <div className={styles.riskProfilePassContainer}>
                    <p className={styles.riskProfilePassText}>Risk Profile Pass</p>
                    <RadioLabelDynamic
                        label="Pass"
                        setChecked={() => CaseVerificationDetailPageStore.setDecisionAndStatus('Pass')}
                        isChecked={decision === 'Pass'}
                        isPositive={true}
                        isSmallLabel={true}
                    />
                    <RadioLabelDynamic
                        label='Fail'
                        setChecked={() => CaseVerificationDetailPageStore.setDecisionAndStatus('Fail')}
                        isChecked={decision === 'Fail'}
                        isSmallLabel={true}
                    />
                </div>
                <TwoColumnRow>
                    <HalfPanel>
                        <Gap />
                        <AuditDropDown />
                    </HalfPanel>
                    <HalfPanel>
                        <div className={styles.reasoningContainer}>
                            <p className={styles.reasoningText}>Reasoning</p>
                            <Textarea
                                key={id}
                                defaultInput={reasoning}
                                placeholder="Detailed reason for verification decision"
                                maxLength={2000}
                                onChange={(e: any) => CaseVerificationDetailPageStore.setReasoning(e)} />
                        </div>
                    </HalfPanel>
                </TwoColumnRow>
                <div className={styles.lastButtonContainer}>
                    <CancelButton onClick={() => CaseVerificationDetailPageStore.cancel()} />
                    <Button
                        disabled={!isFormReady}
                        onClick={() => CaseVerificationDetailPageStore.updateCase(fetch)}>
                        Save
                    </Button>
                </div>
            </form>

        </div></>);
});

export default OverallActionSection;