import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import BaseConfirmationPopup from 'src/components/base/popups/baseConfirmationPopup/BaseConfirmationPopup';
import { MARK_AS_UN_PAID, REVERSE } from 'src/Constant';
import ReversalOverview from 'src/pages/factoredInvoiceDetailsPage/factoredPopup/factoredContent/overview/reversalOverview/ReversalOverview';
import MarkedAsUnPaidOverview from 'src/pages/invoiceDetailsPage/sections/invoiceSection/MarkedAsUnPaidOverview/MarkedAsUnPaidOverview';
import { useStore } from 'src/store';
import styles from './InvoiceReverceReject.module.scss';

type InvoiceReverceRejectPopupProps = {
    modalStatus: string;
    popupVisible: boolean;
    hidePopup: any;
    updateCallback: any;
};

const InvoiceReverceRejectPopup: React.FC<InvoiceReverceRejectPopupProps> = observer(({ modalStatus, popupVisible, hidePopup, updateCallback }) => {
    const { InvoiceDetailsPageStore } = useStore();

    const modalDetails = useMemo(() => {
        if (modalStatus === REVERSE) {
            return {
                'title': 'You are reversing a rejected invoice.',
                'explanation': 'Clicking confirm below will update the status of the invoice from “rejected” to “pending"',
                'currentStatus': 'Rejected',
                'updatedStatus': 'Pending'
            };
        }

        if (modalStatus === MARK_AS_UN_PAID) {
            return {
                'title': 'You are making a paid invoice as unpaid',
                'explanation': 'Clicking confirm below will update the status of the invoice from “MarkAsPaid” to “Pending"',
                'currentStatus': 'MarkAsPaid',
                'updatedStatus': 'Pending'
            };
        }

        return null;
    }, [modalStatus]);

    const overview = useMemo(() => {
        if (modalStatus === REVERSE) {
            return (
                <ReversalOverview
                    currentStatus={modalDetails?.currentStatus as string}
                    updatedStatus={modalDetails?.updatedStatus as string}
                />
            );
        }

        if (modalStatus === MARK_AS_UN_PAID) {
            return (
                <MarkedAsUnPaidOverview
                    currentStatus={modalDetails?.currentStatus as string}
                    updatedStatus={modalDetails?.updatedStatus as string}
                />
            );
        }

        return null;
    }, [modalDetails, modalStatus]);

    return (
        <BaseConfirmationPopup
            modalId={modalStatus}
            modalTitle={modalDetails?.title as string}
            popupVisible={popupVisible}
            hidePopup={hidePopup}
            updateCallback={updateCallback}
            reason={InvoiceDetailsPageStore.getFormReason()}
        >
            <h4 className={styles.explanation}>
                {modalDetails?.explanation}
            </h4>
            {overview}
            < br />
        </BaseConfirmationPopup >
    );
});

export default InvoiceReverceRejectPopup;