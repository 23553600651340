import moment from 'moment';

const PERIOD_FOR_COMING_UP = 6;
const PERIOD_FOR_NEXT_WEEK = 7;

export enum DateFormats {
    fullDate = 'MMMM DD, YYYY',
}

export const today = (): Date => new Date(todayInNumber());
export const todayInNumber = (): number => new Date().setHours(0, -1 * new Date().getTimezoneOffset(), 0, 0);

export const isOverdue = (date: Date): boolean => {
    const dueDate = new Date(date);
    const todaysDate = today();

    return dueDate.valueOf() - todaysDate.valueOf() < 0;
};

export const isComingUp = (date: Date): boolean => {
    const todaysDate = today();

    return date > todaysDate && date.valueOf() - todaysDate.valueOf() < PERIOD_FOR_COMING_UP;
};

export const isComingUpInOneWeek = (date: Date): boolean => {
    const todaysDate = today();
    const dueDate = new Date(date);
    const sevenDaysLater = new Date(todayInNumber() + PERIOD_FOR_NEXT_WEEK * 24 * 60 * 60 * 1000);

    return dueDate > todaysDate && dueDate <= sevenDaysLater;
};

export const localDateFormatter = (key: string, value: string): string => {
    if (isIsoDate(value)) {
        return new Date(value).toLocaleDateString('en-CA');
    }

    return value;
};

export const formatDateWithoutTimeFormat = (stringDate: string): Date => {
    const date = new Date(stringDate);
    const dateWithoutTimeZone = new Date(date?.toISOString().slice(0, -1));

    return dateWithoutTimeZone;
};

export const isIsoDate = (dateStr: string): boolean => {
    const regexExp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
    // /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/gi;

    return regexExp.test(dateStr);
};
/**
 * @deprecated - please use daysFromToday, straight.
 * @param dateString 
 * @returns 
 */
export const daysFromToday = (dateString: string): number => {
    const todaysDate = today();
    const comparedDate = new Date(dateString);
    const differenceBetweenDates = comparedDate.getTime() - todaysDate.getTime();

    // Negative number means it has already past, positive means date is in the future
    return Math.ceil(differenceBetweenDates / (1000 * 3600 * 24));
};

/**
 * @function formatDate
 * @description format the date based on Date format type
 * 
 * @param date - Date | string
 * @param format - string
 * 
 * @returns - string
 */
export const formatDate = (date: Date | string, format = DateFormats.fullDate): string => moment(date).format(format);

export default {
    daysFromToday,
    isIsoDate,
    formatDateWithoutTimeFormat,
    localDateFormatter,
    isComingUpInOneWeek,
    isComingUp,
    isOverdue,
    today,
    todayInNumber
};