/* eslint-disable array-callback-return */
import fileDownload from 'js-file-download';
import { makeAutoObservable } from 'mobx';
import { FetchInterface } from 'src/api/useFetch';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import {
    ACCOUNT_AUDIT_HISTORY_API,
    ACCOUNT_AUDIT_HISTORY_CSV_API,
    ACCOUNT_DETAILS_TRANSACTION_SUMMARY,
    ACCOUNT_DETAILS_URL_API,
    ACCOUNT_TRANSACTIONS_CSV,
    PAYABLES, SUBMIT_USER_FINANCIALS, TOAST_FAILURE_TEXT, TOAST_SUCCESS_TEXT,
    TRANSACTIONS_FOR_ACCOUNT, TRIGGER_RE_UNDERWRITING
} from 'src/Constant';
import { getInCurrencyFormat, sinceTimestamp } from 'src/util';
import { AccountDetailsLinkedBankAccount, Address, AuditDropDownInterface, CardDto, ExternalDetails, PaymentType, RiskScore, SubscriptionDetails } from 'src/util/interfaces';
import getPlanName from 'src/util/PlanUtils';
import { accountDetailSelector, accountTransactionSummarySelector } from 'src/util/selectors';
import { Account, AccountPageTab, AuditHistory, DeletedPayments, DirectBankAccountDto, Form, MonetaryInvoice, TransactionSummary, TransactionSummaryPayload } from './AccountDetailsPageStoreInterface';

export const INPUTS = {
    invoiceLimit: 'invoiceLimit',
    factoringLimit: 'factoringLimit',
    transactionLimit: 'transactionLimit',
    payeeLimit: 'payeeLimit',
    payorLimit: 'payorLimit',
    dailyLimit: 'dailyLimit',
    riskScore: 'riskScore',
    isRestricted: 'restricted',
    isLocked: 'isLocked',
} as const;

export interface UserFinancialsValues {
    type: string;
    files: File[];
}
export interface AccountDetailsPageStoreInterface {
    transactionType: string;
    transactions: MonetaryInvoice[];
    caseId: string;
    view: string;
    isExternalUser: boolean;
    planType: string;
    transactionSummary: TransactionSummary,
    account: Account;
    audits: AuditHistory[],
    form: Form;
    isLoading: boolean;
    isShowUserDocsUploadModal: boolean;
    getShowUserDocsUploadModal(): boolean;
    setShowUserDocsUploadModal(value: boolean): void;
    getLoading(): boolean;
    getCaseId(): string;
    getUserId(): string;
    getLogo(): string;
    getBusinessName(): string;
    getPrimaryAccountOwnersName(): string;
    getStreetName(): string;
    getStreetNumber(): string;
    getCity(): string;
    getChangedLbaDetails(): any;
    getState(): string;
    getPostalCode(): number;
    getBeneficialOwners(): string[];
    getHopscotchBalance(): string;
    getBankBalance(): string;
    getInvoiceLimit(): number;
    getFactorLimit(): number;
    getFlowCreditLimit(): number;
    getFlowAvailableBalance(): number;
    getFlowApprovalStatus(): string;
    getTransactionLimit(): number;
    getPayeeLimit(): number;
    getPayorLimit(): number;
    getDailyLimit(): number;
    getVerificationStatus(): string;
    getIsRestricted(): boolean;
    getIsLocked(): boolean;
    getAccountReasoning(): string;
    getIsFormReady(): boolean;
    getPageView(): string;
    setPageView(view: string): void;
    setReason(reason: string): void;
    setInvoiceLimit(limit: number): void;
    setFactorLimit(limit: number): void;
    setTransactionLimit(limit: number): void;
    setPayeeLimit(limit: number): void;
    setPayorLimit(limit: number): void;
    setDailyLimit(limit: number): void;
    setRiskScore(score: keyof typeof RiskScore): void;
    setLastUpdatedDate(updatedDate: string): void;
    setUpdatedBy(lastUpdatedBy: string): void;
    setIsRestricted(isRestricted: boolean): void;
    setIsLocked(isLocked: boolean): void;
    setCaseId(caseId: string): void;
    getAccountStatusIfNotVerified(): string | boolean;
    getIsAccountVerified(): boolean;
    getRestrictionStatus(): string;
    getAuditHistory(): AuditHistory[];
    getAreRadioButtonsDisabled(id: string): boolean;
    getAccountActionsStatus(): any;
    getHasAFieldChanged(fieldNameOrId: string): boolean;
    getFormLinkedAccounts(): AccountDetailsLinkedBankAccount[];
    getFormCards(): CardDto[];
    getFormDirectBankAccounts(): DirectBankAccountDto[];
    getAccountLinkedAccounts(): AccountDetailsLinkedBankAccount[];
    setFormLinkedAccountStatus(id: string, blocked: boolean): void;
    setCardStatus(id: string, blocked: boolean): void;
    setDirectBankAccountStatus(id: string, blocked: boolean): void;
    getLbaDetails(): any;
    getRiskScore(): keyof typeof RiskScore;
    getOldRiskScore(): keyof typeof RiskScore;
    getBusinessType(): string;
    getWebsite(): string;
    getIndustry(): string;
    getEmail(): string;
    getChangedPaymentMethodAndType(): Nullable<{ isBlocked: boolean, type: keyof typeof PaymentType }>;
    getAverageTransactionAmount(): string;
    getAverageTransactionVolume(): string;
    getAccountCreated(): string;
    setCancel(): void;
    fetchAccount(fetch: FetchInterface<Account>, caseId: string): void;
    updateAccount(fetch: FetchInterface<Account>): void;
    onSubmitUserFinancials(fetch: FetchInterface<Account>, values: UserFinancialsValues): void;
    onTriggerReUnderwriting(fetch: FetchInterface<Account>): void;
    fetchTransactionsDetailsForAccount(fetch: FetchInterfaceLegacy, userId: string, view: string): void;
    fetchAccountAuditHistoryCSV(fetch: FetchInterfaceLegacy, caseId: string): void;
    fetchAccountTransactionsCSV(fetch: FetchInterfaceLegacy, bizId: string): void;
    fetchAccountAuditHistory(fetch: FetchInterface<AuditHistory>, caseId: string): void;
    fetchAccountTransactionsSummary(fetch: FetchInterface<TransactionSummary>, bizId: string): void;
    getSelectedTransactionSummaryType(): string;
    setSelectedTransactionType(transactionType: any): void;
    getTransactionsUrl(bizId: string, view: string): string;
    getTransactions(): MonetaryInvoice[];
    setTransactions(transactions: any): any;
    getBizId(): string;
    getIsLbaDisabled(id: string): boolean;
    haveLinkedBankAccountsChanged(): boolean;
    getIsLbaModified(): boolean;
    getHasFormChanged(): boolean;
    getPaymentChangedId(): string;
    getHasInvoiceLimitBeenUpdated(): boolean;
    getChangedPaymentMethodDto(): any;
    getTotalTransactions(): number;
    getTransactionSummaryDetails(): TransactionSummary;
    getAccount(): Account;
    payloadHandler(res: any): void;
    getExternalDetails(): ExternalDetails;
    getSubscriptionDetails(): Nullable<SubscriptionDetails>
    getChangedPaymentMethod(): any;
    getChangedCardDetails(): CardDto | undefined;
    getChangedDbaDetails(): DirectBankAccountDto | undefined;
    getDeletedPayments(): DeletedPayments;
    getPlanType(): string;
    getTpAccountId(): string;
}

export const DEFAULT_SUBSCRIPTION_DETAILS = { plan: '', recurlyUrl: '', billingFrequency: '' };

const AccountDetailsPageStore = (sharedStore: AuditDropDownInterface): AccountDetailsPageStoreInterface => makeAutoObservable({
    transactionType: PAYABLES,
    caseId: '',
    view: AccountPageTab.info,
    isExternalUser: false,
    planType: '',
    transactionSummary: {} as TransactionSummary,
    account: {
        verificationStatus: 'N/A',
        dbaName: 'N/A',
        bizId: '',
        userId: 'N/A',
        name: 'N/A',
        legalBusinessName: 'N/A',
        email: 'N/A',
        caseStatus: 'N/A',
        hopscotchBalance: 'N/A',
        columnBalance: 'N/A',
        invoiceLimit: 0,
        factoringLimit: 0,
        customLimits: {
            transaction: 0,
            payee: 0,
            payor: 0,
            dailyLimit: 0
        },
        hasInvoiceLimitBeenUpdated: false as boolean,
        transactionVolume: 'N/A',
        vendorId: '',
        createdTimestamp: 0,
        address: {} as Address,
        transactions: [] as MonetaryInvoice[],
        businessLogo: '',
        beneficialOwners: [''],
        lastUpdatedBy: '',
        lastUpdatedDate: '',
        isFrozen: false as boolean,
        isLocked: false as boolean,
        reason: '',
        averageTransactionAmount: 'N/A',
        riskScore: RiskScore.NotSet as keyof typeof RiskScore,
        businessType: 'N/A',
        website: 'N/A',
        industry: 'N/A',
        primaryAccountOwner: 'N/A',
        externalDetails: {} as ExternalDetails,
        subscriptionDetails: DEFAULT_SUBSCRIPTION_DETAILS as Nullable<SubscriptionDetails>,
        linkedAccounts: [] as AccountDetailsLinkedBankAccount[],
        cards: [] as CardDto[],
        directBankAccounts: [] as DirectBankAccountDto[],
        deletedPayments: {} as DeletedPayments,
        flowData: {
            availableCreditLimit: 0,
            creditLimit: 0,
            approvalStatus: 'N/A'
        },
        tpAccountId: ''
    },
    transactions: [],
    audits: [] as AuditHistory[],
    form: {
        riskScore: RiskScore.NotSet
    } as Form,
    isShowUserDocsUploadModal: false as boolean,
    isLoading: false as boolean,
    getShowUserDocsUploadModal(): boolean {
        return this.isShowUserDocsUploadModal;
    },
    setShowUserDocsUploadModal(value: boolean): void {
        this.isShowUserDocsUploadModal = value;
    },
    getLoading(): boolean {
        return this.isLoading;
    },
    getSelectedTransactionSummaryType(): string {
        return this.transactionType;
    },
    setSelectedTransactionType(transactionType: string) {
        this.transactionType = transactionType;
    },
    getPlanType() {
        return this.planType;
    },
    getTransactions() {
        return this.transactions;
    },
    getTotalTransactions(): number {
        return this.transactions.length;
    },
    setTransactions(transactions: any) {
        this.transactions = transactions;
    },
    getHasInvoiceLimitBeenUpdated() {
        return this.account.hasInvoiceLimitBeenUpdated;
    },
    getBizId(): string {
        return this.account.bizId;
    },
    getCaseId(): string {
        return this.caseId;
    },
    getUserId(): string {
        return this.account.userId;
    },
    getRiskScore() {
        return this.form.riskScore;
    },
    getOldRiskScore() {
        return this.account.riskScore;
    },
    getBusinessType(): string {
        return this.account.businessType;
    },
    getWebsite(): string {
        return this.account.website;
    },
    getIndustry(): string {
        return this.account.industry;
    },
    getEmail(): string {
        return this.account.email;
    },
    getAccountCreated(): string {
        return sinceTimestamp(this.account.createdTimestamp) || 'N/A';
    },
    getInvoiceLimit(): number {
        return this.form.invoiceLimit;
    },
    getAverageTransactionAmount() {
        if (this.account.averageTransactionAmount !== null) {
            return getInCurrencyFormat(this.account.averageTransactionAmount);
        }
        return 'N/A';
    },
    getAverageTransactionVolume() {
        if (this.account.transactionVolume !== 'N/A') {
            return this.account.transactionVolume;
        }
        return this.account.transactionVolume;
    },
    getVerificationStatus(): string {
        return this.account.verificationStatus;
    },
    getIsRestricted(): boolean {
        return this.form.isRestricted;
    },
    getIsLocked(): boolean {
        return this.form.isLocked;
    },
    getAccountReasoning() {
        return this.form.reason;
    },
    getFormLinkedAccounts(): AccountDetailsLinkedBankAccount[] {
        return this.form.linkedAccountDetails;
    },
    getFormCards(): CardDto[] {
        return this.form.cards;
    },
    getFormDirectBankAccounts(): DirectBankAccountDto[] {
        return this.form.directBankAccounts;
    },
    getAccountLinkedAccounts(): AccountDetailsLinkedBankAccount[] {
        return this.form.linkedAccountDetails;
    },
    setCancel(): void {
        this.form.factoringLimit = this.account.factoringLimit;
        this.form.invoiceLimit = this.account.invoiceLimit;
        this.form.customLimits.transaction = this.account.customLimits.transaction;
        this.form.customLimits.payee = this.account.customLimits.payee;
        this.form.customLimits.payor = this.account.customLimits.payor;
        this.form.customLimits.dailyLimit = this.account.customLimits.dailyLimit;
        this.form.isLocked = this.account.isLocked;
        this.form.isRestricted = this.account.isFrozen;
        this.form.paymentIdChange = '';

        this.form.linkedAccountDetails = this.account.linkedAccounts;

        sharedStore.setAuditDropdownCategory('');
    },
    setFormLinkedAccountStatus(id: string, blocked: boolean): void {
        const index = this.form.linkedAccountDetails.findIndex((account: AccountDetailsLinkedBankAccount) => account.id === id);
        this.form.linkedAccountDetails[index].isBlocked = blocked;
        this.form.paymentIdChange = (id === this.form.paymentIdChange) ? '' : id;
    },
    setCardStatus(id, blocked) {
        const index = this.form.cards.findIndex((card: CardDto) => card.zuroAccountId === id);
        (this.form.cards[index] as CardDto).isBlocked = blocked;
        this.form.paymentIdChange = (id === this.form.paymentIdChange) ? '' : id;
    },
    setDirectBankAccountStatus(id, blocked) {
        const index = this.form.directBankAccounts.findIndex((dba: DirectBankAccountDto) => dba.zuroAccountId === id);
        (this.form.directBankAccounts[index] as DirectBankAccountDto).isBlocked = blocked;
        this.form.paymentIdChange = (id === this.form.paymentIdChange) ? '' : id;
    },
    getIsLbaDisabled(id: string): boolean {
        return this.form.paymentIdChange !== '' && this.form.paymentIdChange !== id;
    },
    getIsFormReady(): boolean {
        return this.getHasFormChanged();
    },
    getHasFormChanged(): boolean {
        return (
            sharedStore.getAuditDropdownSubCategory() !== ''
            && (this.form.isRestricted !== this.account.isFrozen
                || this.form.isLocked !== this.account.isLocked
                || this.form.riskScore !== this.account.riskScore
                || this.form.invoiceLimit !== this.account.invoiceLimit
                || this.form.factoringLimit !== this.account.factoringLimit
                || this.form.customLimits.transaction !== this.account.customLimits.transaction
                || this.form.customLimits.payee !== this.account.customLimits.payee
                || this.form.customLimits.payor !== this.account.customLimits.payor
                || this.form.customLimits.dailyLimit !== this.account.customLimits.dailyLimit
                || this.form.paymentIdChange.length > 0)
        );
    },
    haveLinkedBankAccountsChanged() {
        return this.form.paymentIdChange !== ''
            && this.form.paymentIdChange !== INPUTS.factoringLimit
            && this.form.paymentIdChange !== INPUTS.invoiceLimit
            && this.form.paymentIdChange !== INPUTS.transactionLimit
            && this.form.paymentIdChange !== INPUTS.payeeLimit
            && this.form.paymentIdChange !== INPUTS.payorLimit
            && this.form.paymentIdChange !== INPUTS.dailyLimit
            && this.form.paymentIdChange !== INPUTS.isLocked
            && this.form.paymentIdChange !== INPUTS.isRestricted
            && this.form.paymentIdChange !== INPUTS.riskScore;
    },

    getIsLbaModified(): boolean {
        return this.form.paymentIdChange !== '';
    },
    getPaymentChangedId(): string {
        return this.form.paymentIdChange;
    },
    getAreRadioButtonsDisabled(id: string): boolean {
        return (this.getPaymentChangedId() !== '' && this.getPaymentChangedId() !== id);
    },
    getChangedPaymentMethod() {
        const changedLba = this.getChangedLbaDetails();
        if (changedLba) {
            return changedLba;
        }
        return this.getChangedCardDetails();
    },
    getChangedPaymentMethodDto() {
        const id = this.getPaymentChangedId();
        if (this.haveLinkedBankAccountsChanged()) {
            const paymentMethod = this.getChangedPaymentMethodAndType();
            return {
                id,
                blocked: paymentMethod?.isBlocked,
                type: paymentMethod?.type
            };
        }

        return null;
    },
    getChangedPaymentMethodAndType(): any {
        const changedLba = this.getChangedLbaDetails();
        if (changedLba?.id) {
            return {
                ...changedLba,
                type: PaymentType.LinkedBankAccount
            };
        }

        const changedCard = this.getChangedCardDetails();
        if (changedCard?.zuroAccountId) {
            return {
                ...changedCard,
                type: PaymentType.CreditCard
            };
        }

        const changedDba = this.getChangedDbaDetails();
        if (changedDba?.zuroAccountId) {
            return {
                ...changedDba,
                type: PaymentType.DirectBankAccount
            };
        }
        return undefined;
    },
    getChangedLbaDetails(): any {
        const selectedLba = this.form?.linkedAccountDetails?.filter((account: { id: string; }) => account.id === this.getPaymentChangedId());
        return selectedLba ? selectedLba[0] : [];
    },
    getChangedCardDetails() {
        const selectedCard = this.form?.cards?.filter((card: { zuroAccountId: string; }) => card.zuroAccountId === this.getPaymentChangedId());
        return selectedCard ? selectedCard[0] : undefined;
    },
    getChangedDbaDetails() {
        const selectedAccount = this.form?.directBankAccounts?.filter((dba: { zuroAccountId: string; }) => dba.zuroAccountId === this.getPaymentChangedId());
        return selectedAccount ? selectedAccount[0] : undefined;
    },
    getLbaDetails(): any {
        return this.account.linkedAccounts;
    },
    getPageView() {
        return this.view ?? AccountPageTab.info;
    },
    setPageView(view) {
        this.view = view;
    },
    setInvoiceLimit(limit) {
        if (!this.getHasAFieldChanged(INPUTS.invoiceLimit)) {
            this.form.invoiceLimit = limit;
            this.setPaymentIdChange(this.form.invoiceLimit !== this.account.invoiceLimit, INPUTS.invoiceLimit);
        }
    },
    setFactorLimit(limit) {
        if (!this.getHasAFieldChanged(INPUTS.factoringLimit)) {
            this.form.factoringLimit = limit;
            this.setPaymentIdChange(this.form.factoringLimit !== this.account.factoringLimit, INPUTS.factoringLimit);
        }
    },
    setTransactionLimit(limit) {
        if (!this.getHasAFieldChanged(INPUTS.transactionLimit)) {
            this.form.customLimits.transaction = limit;
            this.setPaymentIdChange(this.form.customLimits.transaction !== this.account.customLimits.transaction, INPUTS.transactionLimit);
        }
    },
    setPayeeLimit(limit) {
        if (!this.getHasAFieldChanged(INPUTS.payeeLimit)) {
            this.form.customLimits.payee = limit;
            this.setPaymentIdChange(this.form.customLimits.payee !== this.account.customLimits.payee, INPUTS.payeeLimit);
        }
    },
    setPayorLimit(limit) {
        if (!this.getHasAFieldChanged(INPUTS.payorLimit)) {
            this.form.customLimits.payor = limit;
            this.setPaymentIdChange(this.form.customLimits.payor !== this.account.customLimits.payor, INPUTS.payorLimit);
        }
    },
    setDailyLimit(limit) {
        if (!this.getHasAFieldChanged(INPUTS.dailyLimit)) {
            this.form.customLimits.dailyLimit = limit;
            this.setPaymentIdChange(this.form.customLimits.dailyLimit !== this.account.customLimits.dailyLimit, INPUTS.dailyLimit);
        }
    },
    setRiskScore(score) {
        if (!this.getHasAFieldChanged(INPUTS.riskScore)) {
            this.form.riskScore = score;
            this.setPaymentIdChange(this.form.riskScore !== this.account.riskScore, INPUTS.riskScore);
        }
    },
    setLastUpdatedDate(updatedDate: string) {
        this.account.lastUpdatedDate = updatedDate;
    },
    setUpdatedBy(lastUpdatedBy: string): void {
        this.account.lastUpdatedBy = lastUpdatedBy;
    },
    setIsRestricted(isRestricted: boolean): void {
        if (!this.getHasAFieldChanged(INPUTS.isRestricted)) {
            this.form.isRestricted = isRestricted;
            this.setPaymentIdChange(this.form.isRestricted !== this.account.isFrozen, INPUTS.isRestricted);
        }
    },
    /**
     * 
     * @param hasChanged: boolean determining if an input has changed from it's original value.
     * @param fieldOrId: field name of an input that changed or an id of a payment method.
     */
    setPaymentIdChange(hasChanged: boolean, fieldOrId: string): void {
        this.form.paymentIdChange = hasChanged ? fieldOrId : '';
    },
    getHasAFieldChanged(fieldNameOrId: string) {
        if (this.form.paymentIdChange === '') {
            return false;
        }
        return this.form.paymentIdChange !== fieldNameOrId;
    },
    setIsLocked(isLocked: boolean): void {
        if (!this.getHasAFieldChanged(INPUTS.isLocked)) {
            this.form.isLocked = isLocked;
            this.setPaymentIdChange(this.form.isLocked !== this.account.isLocked, INPUTS.isLocked);
        }
    },
    setCaseId(caseId: string) {
        this.caseId = caseId;
    },
    getLogo(): string {
        return this.account.businessLogo;
    },
    getBusinessName(): string {
        return this.account.dbaName;
    },
    getPrimaryAccountOwnersName(): string {
        return this.account.name;
    },
    getBeneficialOwners(): any {
        return this.account.beneficialOwners?.map((owner: string) => `${owner} `);
    },
    getHopscotchBalance(): string {
        return this.account.hopscotchBalance;
    },
    getBankBalance(): string {
        return this.account.columnBalance;
    },
    getFactorLimit(): number {
        return this.form.factoringLimit || 0;
    },
    getFlowCreditLimit(): number {
        return this.form.flowData?.creditLimit || 0;
    },
    getFlowAvailableBalance(): number {
        return this.form.flowData?.availableCreditLimit || 0;
    },
    getFlowApprovalStatus(): string {
        return this.form.flowData?.approvalStatus || 'N/A';
    },
    getTransactionLimit(): number {
        return this.form.customLimits.transaction || 0;
    },
    getPayeeLimit(): number {
        return this.form.customLimits.payee || 0;
    },
    getPayorLimit(): number {
        return this.form.customLimits.payor || 0;
    },
    getDailyLimit(): number {
        return this.form.customLimits.dailyLimit || 0;
    },
    getStreetName(): string {
        return this.account?.address?.address_line1 || '';
    },
    getStreetNumber(): string {
        return this.account?.address?.address_line2 || '';
    },
    getCity(): string {
        return this.account?.address?.city || '';
    },
    getState(): string {
        return this.account?.address?.state || '';
    },
    getPostalCode(): number {
        return this.account?.address?.postal_code || 0;
    },
    setReason(reason: string) {
        this.form.reason = reason;
    },
    getAccountStatusIfNotVerified(): string | boolean {
        return this.account?.caseStatus === 'Verified' ?? this.account?.caseStatus;
    },
    getIsAccountVerified(): boolean {
        return this.account?.caseStatus === 'Pass';
    },
    getRestrictionStatus(): string {
        return this.account?.isFrozen ? 'Restricted' : 'Unrestricted';
    },
    getAuditHistory(): AuditHistory[] {
        return this.audits;
    },
    getAccount() {
        return this.account;
    },
    getTransactionSummaryDetails() {
        return this.transactionSummary;
    },
    getExternalDetails() {
        return this?.account?.externalDetails || { createdByBizId: '', createdByDbaName: '', isPlatformUser: true };
    },
    getSubscriptionDetails() {
        return this?.account?.subscriptionDetails;
    },
    getAccountActionsStatus() {
        return {
            hasIsRestrictedChanged: this.account.isFrozen !== this.form.isRestricted,
            hasIsLockedChanged: this.account.isLocked !== this.form.isLocked,
            hasRiskScoreChanged: this.account.riskScore !== this.form.riskScore,
            hasInvoiceLimitChanged: this.account.invoiceLimit !== this.form.invoiceLimit,
            hasFactorLimitChanged: this.account.factoringLimit !== this.form.factoringLimit,
            hasTransactionLimitChanged: this.account.customLimits.transaction !== this.form.customLimits.transaction,
            hasPayeeLimitChanged: this.account.customLimits.payee !== this.form.customLimits.payee,
            hasPayorLimitChanged: this.account.customLimits.payor !== this.form.customLimits.payor,
            hasDailyLimitChanged: this.account.customLimits.dailyLimit !== this.form.customLimits.dailyLimit
        };
    },
    getDeletedPayments() {
        return this.account.deletedPayments;
    },
    fetchAccount(fetch: FetchInterface<any>, caseId: string) {
        fetch({ url: ACCOUNT_DETAILS_URL_API(caseId) }, (res: any) => this.payloadHandler(res));
    },
    payloadHandler(res: any) {
        const { account, form } = accountDetailSelector(res);
        this.account = account as Account;
        this.form = form as Form;

        const plan = (res?.data?.subscriptionDetails?.plan || '') as string;
        const billingFrequency = (res?.data?.subscriptionDetails?.billingFrequency || '') as string;
        this.planType = getPlanName({ planType: plan, billingFrequency });
    },
    updateAccount(fetch: FetchInterface<any>) {
        fetch({
            url: ACCOUNT_DETAILS_URL_API(this.caseId),
            method: 'PUT',
            data: {
                isFrozen: this.form.isRestricted,
                isLocked: this.form.isLocked,
                riskScore: this.form.riskScore as keyof typeof RiskScore,
                invoiceLimit: this.form.invoiceLimit,
                factoringLimit: this.form.factoringLimit,
                customLimits: {
                    transaction: this.form.customLimits.transaction,
                    payee: this.form.customLimits.payee,
                    payor: this.form.customLimits.payor,
                    dailyLimit: this.form.customLimits.dailyLimit,
                },
                accountReason: this.form.reason,
                paymentUpdate: this.getChangedPaymentMethodDto(),
                audit: {
                    category: sharedStore.getAuditDropdownCategory(),
                    subCategory: sharedStore.getAuditDropdownSubCategory(),
                }
            }
        },
            async (res: any) => {
                this.payloadHandler(res);
                await this.fetchAccount(fetch, this.caseId);
                await this.fetchAccountAuditHistory(fetch, this.caseId);
            },
            true,
            { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT }
        );
    },
    getTransactionsUrl(bizId: string, view: string) {
        return `${TRANSACTIONS_FOR_ACCOUNT(bizId, view)}`;
    },
    fetchTransactionsDetailsForAccount(fetch: FetchInterface<MonetaryInvoice[]>, bizId: string, view: string) {
        fetch({ url: this.getTransactionsUrl(bizId, view) }, ({ data }) => {
            this.transactions = data;
        },
            true
        );
    },
    fetchAccountAuditHistoryCSV(fetch: FetchInterface<string | ArrayBuffer | ArrayBufferView | Blob>, caseId: string) {
        fetch({ url: ACCOUNT_AUDIT_HISTORY_CSV_API(caseId) }, ({ data }) => {
            fileDownload(data, 'audit_history.csv');
        });
    },
    fetchAccountTransactionsCSV(fetch: FetchInterface<string | ArrayBuffer | ArrayBufferView | Blob>, bizId: string) {
        fetch({ url: ACCOUNT_TRANSACTIONS_CSV(bizId) }, (res: any) => {
            fileDownload(res.data, 'account_trans.csv');
        });
    },
    fetchAccountAuditHistory(fetch: FetchInterface<AuditHistory>, caseId: string) {
        fetch({ url: ACCOUNT_AUDIT_HISTORY_API(caseId) }, ({ data }) => {
            this.audits = data;
        });
    },
    payloadHandlerTransactionSummary(res: TransactionSummaryPayload) {
        const { transactionSummary } = accountTransactionSummarySelector(res);
        this.transactionSummary = transactionSummary;
    },
    fetchAccountTransactionsSummary(fetch: FetchInterface<TransactionSummary>, bizId: string) {
        fetch({ url: ACCOUNT_DETAILS_TRANSACTION_SUMMARY(bizId) }, (res: any) => this.payloadHandlerTransactionSummary(res));
    },
    getTpAccountId(): string {
        return this.account?.tpAccountId;
    },
    async onSubmitUserFinancials(fetch: FetchInterface<any>, values: UserFinancialsValues) {
        const formData = new FormData();
        formData.append('bizId', this.account.bizId);
        formData.append('userId', this.account.userId);
        formData.append('type', values.type);

        const fileLength = values.files.length;
        for (let i = 0; i < fileLength; i++) {
            formData.append('files', values.files[i]);
        }

        this.isLoading = true;
        await fetch({
            url: SUBMIT_USER_FINANCIALS,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }, () => {
            this.isShowUserDocsUploadModal = false;
        }, true, { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        this.isLoading = false;
    },
    async onTriggerReUnderwriting(fetch: FetchInterface<any>) {
        this.isLoading = true;
        await fetch({
            url: TRIGGER_RE_UNDERWRITING,
            method: 'POST',
            data: {
                'bizId': this.account.bizId,
                'userId': this.account.userId
            },
        }, () => { }, true, { successMessage: TOAST_SUCCESS_TEXT, failMessage: TOAST_FAILURE_TEXT });
        this.isLoading = false;
    },
});

export default AccountDetailsPageStore;