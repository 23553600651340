import React from 'react';

const HomeIcon = (): JSX.Element => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.41797 1.21404C6.16531 1.04006 5.8346 1.04006 5.58195 1.21404L1.41692 4.0821C1.22262 4.2159 1.10431 4.43151 1.09557 4.66303C1.06533 5.46333 1.03328 6.8648 1.1213 7.85955C1.18769 8.60997 1.36442 9.60193 1.50796 10.3317C1.57462 10.6705 1.86959 10.9166 2.22018 10.9166H4.15915C4.24176 10.9166 4.30872 10.8482 4.30872 10.7638V8.319C4.30872 7.72828 4.77749 7.2494 5.35573 7.2494H6.82193C7.40018 7.2494 7.86894 7.72828 7.86894 8.319V10.7638C7.86894 10.8482 7.9359 10.9166 8.01851 10.9166H9.77974C10.1303 10.9166 10.4253 10.6705 10.492 10.3317C10.6355 9.60193 10.8122 8.60997 10.8786 7.85955C10.9666 6.8648 10.9346 5.46333 10.9043 4.66303C10.8956 4.43151 10.7773 4.2159 10.583 4.0821L6.41797 1.21404ZM5.08034 0.453813C5.63618 0.07106 6.36374 0.0710595 6.91958 0.453813L11.0846 3.32188C11.51 3.61478 11.781 4.09635 11.8011 4.62767C11.8313 5.42708 11.8662 6.882 11.7724 7.94208C11.7013 8.74561 11.516 9.77921 11.3718 10.5123C11.2185 11.2915 10.5455 11.8334 9.77974 11.8334H8.01851C7.44026 11.8334 6.9715 11.3545 6.9715 10.7638V8.319C6.9715 8.23461 6.90454 8.1662 6.82193 8.1662H5.35573C5.27313 8.1662 5.20616 8.23461 5.20616 8.319V10.7638C5.20616 11.3545 4.7374 11.8334 4.15915 11.8334H2.22018C1.4544 11.8334 0.781396 11.2915 0.628117 10.5123C0.483913 9.77921 0.298601 8.74561 0.227506 7.94208C0.133712 6.882 0.168598 5.42708 0.1988 4.62767C0.218873 4.09635 0.489953 3.61478 0.915309 3.32188L5.08034 0.453813Z"
            fill="#030D45"
        />
    </svg>
);

export default HomeIcon;