import React from 'react';
import PaymentMediumBlock from 'src/components/business/PaymentMediumBlock/PaymentMediumBlock';
import styles from 'src/components/business/transactions/linkedBankAccountView/fundingWithdrawingReportView/FundingWithdrawingReportView.module.scss';
import PayeePayorCardView from 'src/components/business/transactions/payeePayorCardView/PayeePayorCardView';
import { useStore } from 'src/store';
import { PaymentType } from 'src/util/interfaces';
import LbaCardView from '../../lbaCardView/LbaCardView';

type WithdrawingReportViewProps = {
    payor: any;
}

const WithdrawingReportView: React.FC<WithdrawingReportViewProps> = ({ payor }) => {
    const { TransactionDetailsPageStore } = useStore();
    const payeeLbaDetails = TransactionDetailsPageStore.getPayeeFirstLBADetails();
    const payorLbaDetails = TransactionDetailsPageStore.getPayorFirstLBADetails();

    return (
        <div>
            <div className={styles.mainWrapper}>
                <div className={styles.payeeLbaContainer}>
                    <div className={styles.payeeLbaWrapper}>
                        <LbaCardView
                            title="Payee"
                            institutionName={payeeLbaDetails?.institutionName}
                            accountNumber={payeeLbaDetails?.accountNumber}
                            availableBalance={payeeLbaDetails?.availableBalance}
                            valueOfTransactions={payeeLbaDetails?.totalTransactionsCount}
                            numberOfTransactions={payeeLbaDetails?.totalTransactionsSum}
                        />
                        <PayeePayorCardView
                            title="Payor"
                            dbaName={payor?.dbaName}
                            isVerified={payor?.isVerified}
                            score={payor?.score}
                            createdTimestamp={payor?.createdTimestamp}
                            caseId={payor?.caseId}
                            accountLogo={payor?.accountLogo}
                            hopscotchBalance={payor?.hopscotchBalance}
                            bankPartnerBalance={payor?.bankPartnerBalance}
                            averageTransactionSize={payor?.averageTransactionSize}
                            totalTransactionVolume={payor?.totalTransactionVolume}
                            planType={payor?.planType}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.lbaContainer}>
                <div className={styles.payeePayorWrapper}>
                    <div className={styles.payeeLBA}>
                        <PaymentMediumBlock
                            institutionName={payeeLbaDetails?.institutionName}
                            isBlocked={payeeLbaDetails?.isBlocked}
                            isOps={payeeLbaDetails?.isOps}
                            accountNumber={payeeLbaDetails?.accountNumber}
                            ownerName={payeeLbaDetails?.ownerName}
                            balance={payeeLbaDetails?.availableBalance}
                            isBackup={payorLbaDetails?.isFunding}
                            paymentType={payeeLbaDetails?.paymentType ?? PaymentType.NotSet}
                        />
                    </div>
                    <div className={styles.payorLBA}>
                        <PaymentMediumBlock
                            institutionName={payorLbaDetails?.institutionName}
                            isBackup={payorLbaDetails?.isFunding}
                            isBlocked={payorLbaDetails?.isBlocked}
                            isOps={payorLbaDetails?.isOps}
                            accountNumber={payorLbaDetails?.accountNumber}
                            ownerName={payorLbaDetails?.ownerName}
                            balance={payorLbaDetails?.availableBalance}
                            paymentType={payorLbaDetails?.paymentType ?? PaymentType.NotSet}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithdrawingReportView;