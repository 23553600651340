import { useEffect } from 'react';
import { useStore } from 'src/store';

const useFetchExchangeCodeForTokens = (): void => {
    const { sharedStore } = useStore();
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const codeAsStr = queryParams.get('code');

        if (codeAsStr) {
            sharedStore.fetchExchangeCodeForTokens(codeAsStr);
        }
    }, [sharedStore]);
};

export default useFetchExchangeCodeForTokens;