import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import HRLine from 'src/components/base/hrLine/HRLine';
import LBALargeBlock from 'src/components/business/lbaLargeBlock/LBALargeBlock';
import { useStore } from 'src/store';
import styles from './LBASection.module.scss';

const LBASection: React.FC = observer(() => {
    const { CaseVerificationDetailPageStore } = useStore();
    let lbaDetails = CaseVerificationDetailPageStore.getFirstLBADetails();

    useEffect(() => {
        // @TODO: LALA - this needs to be refactored. This is improper flow
        lbaDetails = CaseVerificationDetailPageStore.getFirstLBADetails();
    }, [CaseVerificationDetailPageStore]);

    return (
        <>
            <div className={styles.moduleTextContainer}>
                <h4>LBA information</h4>
            </div>
            <HRLine />
            <div className={styles.lbaContainer}>
                <div className={styles.lbaWrapper}>
                    {lbaDetails &&
                        <LBALargeBlock
                            lba={lbaDetails}
                            selectedId=''
                            isDisabled={false} />
                    }
                </div>
            </div>

        </>
    );
});

export default LBASection;
