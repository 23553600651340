import React from 'react';
import styles from './RenderPayorPaymentCell.module.scss';

type props = {
    data?: {
        payorPaymentType: string;
        payorPaymentMethod: string
    }
};

const RenderPayorPaymentCell: React.FC<props> = ({ data }) => (<><span className={styles.businessName}>( {data?.payorPaymentType || 'N/A'} )</span> {data?.payorPaymentMethod}</>);

export default RenderPayorPaymentCell; 