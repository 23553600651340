// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './HopOpsToolTip.module.scss';

type HopOpsToolTipProps = {
    tagLabel: string;
}

const HopOpsToolTip: React.FC<HopOpsToolTipProps> = ({ tagLabel }) => {
    const data = (tagLabel === 'H') ? 'hopscotchToolTip' : 'opsToolTip';

    return (
        <>
            <span
                data-testid="letterSpan"
                data-tip={true}
                data-for={data}
                className={cn(
                    styles.tagContainer,
                    {
                        [styles.hopLabel]: tagLabel === 'H',
                        [styles.opsLabel]: tagLabel === 'O',
                    }
                )}>{tagLabel}</span>

            <ReactTooltip id="hopscotchToolTip" place="right" effect="solid">
                Hopscotch
            </ReactTooltip>
            <ReactTooltip id="opsToolTip" place="right" effect="solid">
                Ops
            </ReactTooltip>
        </>
    );
};

export default HopOpsToolTip;