// eslint-disable-next-line
import cn from 'classnames';
import { Popup, ScrollView } from 'devextreme-react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import AuditDropDown from 'src/components/base/auditDropDown/AuditDropDown';
import Button from 'src/components/base/buttons/button/Button';
import CancelButton from 'src/components/base/buttons/cancelButton/CancelButton';
import DollarInput from 'src/components/base/dollarInput/DollarInput';
import HRLine from 'src/components/base/hrLine/HRLine';
import PopupTitle from 'src/components/base/popups/popupTitle/PopupTitle';
import RadioButton from 'src/components/base/radioButton/RadioButton';
import Textarea from 'src/components/base/Textarea';
import Toggles from 'src/components/base/Toggles';
import ToolTip from 'src/components/base/toolTip/ToolTip';
import AccountLink from 'src/components/business/accounts/accountLink/AccountLink';
import { HIGH_RISK_SCORE, LOW_RISK_SCORE } from 'src/Constant';
import Gap from 'src/layouts/gap/Gap';
import HalfPanel from 'src/layouts/twoColumnRow/halfPanel/HalfPanel';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import AccountActionsAuditHistoryPopup from 'src/pages/accountDetailsPage/sections/accountActionsSection/accountActionsAuditHistoryPopup/AccountActionsAuditHistoryPopup';
import ReasoningSection from 'src/pages/factoredInvoiceDetailsPage/factoredPopup/factoredContent/reasoningSection/ReasoningSection';
import { useStore } from 'src/store';
import { INPUTS } from '../../AccountDetailsPageStore';
import { AccountPageTab } from '../../AccountDetailsPageStoreInterface';
import styles from './AccountActionsSection.module.scss';
import InfoBlock from './infoBlock/InfoBlock';
import ModalLinkedAccountDataBlock from './modalLinkedAccountDataBlock/ModalLinkedAccountDataBlock';
import PaymentMethodLinks from './paymentMethodLinks/PaymentMethodLinks';
import PaymentMethodList from './paymentMethodList/PaymentMethodList';

export const PaymentTypeTab = {
    Card: 'Card',
    LBA: 'LBA',
    DBA: 'DBA',
    Deleted: 'Deleted'
} as const;

type props = {
    caseId: string;
    selectedId: string;
    paymentType: keyof typeof PaymentTypeTab;
    planType: string;
}

const AccountActionsSection: React.FC<props> = observer(({ caseId, selectedId, paymentType, planType }) => {
    const { AccountDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();
    const { hasIsRestrictedChanged, hasIsLockedChanged, hasRiskScoreChanged, hasInvoiceLimitChanged, hasFactorLimitChanged, hasTransactionLimitChanged, hasPayeeLimitChanged, hasPayorLimitChanged, hasDailyLimitChanged } = AccountDetailsPageStore.getAccountActionsStatus();
    const hasLinkedAccountsChanged = AccountDetailsPageStore.haveLinkedBankAccountsChanged();
    const paymentDetails = AccountDetailsPageStore.getChangedPaymentMethod();
    const [popupVisible, setPopupVisible] = useState(false);
    const [continuePopupVisible, setContinuePopupVisible] = useState(false);
    const userReasoningText = AccountDetailsPageStore.getAccountReasoning();
    const isRestricted = AccountDetailsPageStore.getIsRestricted();
    const isLocked = AccountDetailsPageStore.getIsLocked();
    const isAccountVerified = AccountDetailsPageStore.getIsAccountVerified();
    const isFormReady = AccountDetailsPageStore.getIsFormReady();
    const logo = AccountDetailsPageStore.getLogo();
    const name = AccountDetailsPageStore.getBusinessName();
    const riskScore = AccountDetailsPageStore.getRiskScore();
    const externalDetails = AccountDetailsPageStore.getExternalDetails();
    const accountCreatedDate = AccountDetailsPageStore.getAccountCreated();
    const invoiceLimit = AccountDetailsPageStore.getInvoiceLimit();
    const factorLimit = AccountDetailsPageStore.getFactorLimit();

    const transactionLimit = AccountDetailsPageStore.getTransactionLimit();
    const payeeLimit = AccountDetailsPageStore.getPayeeLimit();
    const payorLimit = AccountDetailsPageStore.getPayorLimit();
    const dailyLimit = AccountDetailsPageStore.getDailyLimit();

    const linkedAccounts = AccountDetailsPageStore.getFormLinkedAccounts();
    const cards = AccountDetailsPageStore.getFormCards();
    const hasInvoiceLimitBeenUpdated = AccountDetailsPageStore.getHasInvoiceLimitBeenUpdated();
    const directBankAccounts = AccountDetailsPageStore.getFormDirectBankAccounts();
    const deletedPayments = AccountDetailsPageStore.getDeletedPayments();

    const renderTitle = () => (
        <PopupTitle handleClick={() => { setContinuePopupVisible(false); }} title="You are making changes below" />
    );

    return (
        <>
            <div className={styles.parentContainer}>
                <div className={styles.accountActionsContainer}>
                    <div className={styles.titleContainer}>
                        <h4 className={styles.title}>Actions</h4>
                        <AccountActionsAuditHistoryPopup popupVisible={popupVisible} hidePopup={() => setPopupVisible(false)} />
                        <button type="button" className={styles.historyLink} onClick={() => setPopupVisible(true)}>
                            History
                        </button>
                    </div>
                    <HRLine />
                    <div className={cn(styles.row, styles.firstRow)}>
                        <div className={styles.labelContainer}>
                            <p className={styles.label}>Restrict Account</p>
                        </div>

                        <div className={styles.toggleContainer}>
                            <Toggles
                                checked={isRestricted}
                                disabled={AccountDetailsPageStore.getHasAFieldChanged(INPUTS.isRestricted)}
                                onChange={() => {
                                    AccountDetailsPageStore.setIsRestricted(!isRestricted);
                                }} />
                        </div>
                    </div>
                    <div className={styles.greyTextContainer}>
                        <p className={styles.greyText}>
                            User may continue to invoice and funds may still flow into the account, but the user cannot make payments or transfer funds
                            out of their Hopscotch balance.
                        </p>
                    </div>
                    <div className={cn(styles.row)}>
                        <div className={styles.labelContainer}>
                            <p className={styles.label}>Lock Account</p>
                        </div>

                        <div className={styles.toggleContainer}>
                            <Toggles
                                checked={isLocked}
                                disabled={AccountDetailsPageStore.getHasAFieldChanged(INPUTS.isLocked)}
                                onChange={() => {
                                    AccountDetailsPageStore.setIsLocked(!isLocked);
                                }} />
                        </div>
                    </div>
                    <div className={styles.greyTextContainer}>
                        <p className={styles.greyText}>
                            User is kicked out of current session and locked from logging into account again.
                        </p>
                    </div>
                    {externalDetails.isPlatformUser && <>
                        <Gap />
                        <TwoColumnRow>
                            <div className={styles.labelContainer}>
                                <p className={styles.label}>Risk Status</p>
                            </div>
                            <div className={styles.statusContainer}>
                                <input className={styles.radio} type='radio' id="low" checked={riskScore === LOW_RISK_SCORE} onChange={() => AccountDetailsPageStore.setRiskScore(LOW_RISK_SCORE)} />
                                <RadioButton
                                    disabled={false}
                                    onClick={() => { }}
                                    isPositive={true}
                                    isActive={false}
                                    label="Low"
                                />
                                <input className={cn(styles.radio, styles.rightContainer)} type='radio' id="high" checked={riskScore === HIGH_RISK_SCORE} onChange={() => AccountDetailsPageStore.setRiskScore(HIGH_RISK_SCORE)} />
                                <RadioButton
                                    disabled={false}
                                    onClick={() => { }}
                                    isPositive={false}
                                    isActive={false}
                                    label="High"
                                />
                            </div>
                        </TwoColumnRow>
                        <Gap />

                        <TwoColumnRow>
                            <div className={styles.labelContainer}>
                                <p className={styles.label}>Invoicing Limit
                                    {
                                        hasInvoiceLimitBeenUpdated &&
                                        <ToolTip toolTipText='Invoice limit has been updated' />
                                    }
                                </p>
                            </div>
                            <DollarInput
                                placeholder={invoiceLimit}
                                classNames={styles.inputDynamic}
                                value={invoiceLimit}
                                onChangeHandler={(text: string) => {
                                    AccountDetailsPageStore.setInvoiceLimit(parseFloat(text || '0'));
                                }} />
                        </TwoColumnRow>
                        <Gap />
                        <TwoColumnRow>
                            <div className={styles.labelContainer}>
                                <p className={styles.label}>Factoring Limit</p>
                            </div>
                            <DollarInput
                                placeholder={factorLimit}
                                classNames={styles.inputDynamic}
                                value={factorLimit}
                                onChangeHandler={(text: string) => {
                                    AccountDetailsPageStore.setFactorLimit(parseFloat(text || '0'));
                                }} />
                        </TwoColumnRow>
                        <Gap />
                        <TwoColumnRow>
                            <div className={styles.labelContainer}>
                                <p className={styles.label}>Transaction Limit (Fac)</p>
                            </div>
                            <DollarInput
                                placeholder={transactionLimit}
                                classNames={styles.inputDynamic}
                                value={transactionLimit}
                                onChangeHandler={(text: string) => {
                                    AccountDetailsPageStore.setTransactionLimit(parseFloat(text || '0'));
                                }} />
                        </TwoColumnRow>
                        <Gap />
                        <TwoColumnRow>
                            <div className={styles.labelContainer}>
                                <p className={styles.label}>Payee Limit (Fac)</p>
                            </div>
                            <DollarInput
                                placeholder={payeeLimit}
                                classNames={styles.inputDynamic}
                                value={payeeLimit}
                                onChangeHandler={(text: string) => {
                                    AccountDetailsPageStore.setPayeeLimit(parseFloat(text || '0'));
                                }} />
                        </TwoColumnRow>
                        <Gap />
                        <TwoColumnRow>
                            <div className={styles.labelContainer}>
                                <p className={styles.label}>Payor Limit (Fac) </p>
                            </div>
                            <DollarInput
                                placeholder={payorLimit}
                                classNames={styles.inputDynamic}
                                value={payorLimit}
                                onChangeHandler={(text: string) => {
                                    AccountDetailsPageStore.setPayorLimit(parseFloat(text || '0'));
                                }} />
                        </TwoColumnRow>
                        <Gap />
                        <TwoColumnRow>
                            <div className={styles.labelContainer}>
                                <p className={styles.label}>Daily Limit (Fac) </p>
                            </div>
                            <DollarInput
                                placeholder={dailyLimit}
                                classNames={styles.inputDynamic}
                                value={dailyLimit}
                                onChangeHandler={(text: string) => {
                                    AccountDetailsPageStore.setDailyLimit(parseFloat(text || '0'));
                                }} />
                        </TwoColumnRow>
                    </>}
                </div>
                <div className={styles.ownershipContainer}>

                    <PaymentMethodLinks
                        caseId={caseId}
                        paymentType={paymentType}
                        cards={cards}
                        linkedAccounts={linkedAccounts}
                        directBankAccounts={directBankAccounts}
                        deletedPayments={deletedPayments}
                        classNames={styles.titleContainer}
                    />
                    <HRLine />

                    <PaymentMethodList
                        selectedId={selectedId}
                        paymentType={paymentType}
                        linkedAccounts={linkedAccounts}
                        directBankAccounts={directBankAccounts}
                        cards={cards}
                        deletedPayments={deletedPayments}
                        isDisabled={(id: string) => AccountDetailsPageStore.getAreRadioButtonsDisabled(id)}
                        setCardPaymentStatus={(id: string, blocked: boolean) => AccountDetailsPageStore.setCardStatus(id, blocked)}
                        classNames={styles.linkedAccountWrapper}
                    />
                </div>
            </div >
            <Gap />
            <TwoColumnRow>
                <HalfPanel isLeft={true}>
                    <AuditDropDown />
                </HalfPanel>
                <HalfPanel>
                    <p className={styles.reasoningLabel}>Description</p>
                    <Textarea
                        placeholder='Please provide a detailed reason for what you are changing'
                        maxLength={2000}
                        isFullSize={true}
                        onChange={(e: any) => {
                            AccountDetailsPageStore.setReason(e);
                        }} />
                </HalfPanel>
            </TwoColumnRow>
            <div className={styles.buttonContainer}>
                <CancelButton
                    onClick={() => {
                        AccountDetailsPageStore.setCancel();
                    }}>
                    Cancel
                </CancelButton>
                <Button disabled={!isFormReady} onClick={() => {
                    setContinuePopupVisible(true);
                }}>Continue</Button>
            </div>
            <Gap />
            <Popup
                visible={continuePopupVisible}
                height="530px"
                width="500px"
                showCloseButton={true}
                onHiding={() => { setContinuePopupVisible(false); }}
                closeOnOutsideClick={true}
                titleRender={renderTitle}
            >
                <ScrollView width='100%' height='82.5%'>

                    <p className={styles.accountText}>Hopscotch Account</p>
                    <div className={styles.accountContainer}>
                        <div className={styles.accountLinkContainer}>
                            <AccountLink dbaName={name} score={riskScore} isVerified={isAccountVerified} createdTimestamp={accountCreatedDate} accountLogo={logo} isExternalUser={!externalDetails.isPlatformUser} planType={planType} />
                        </div>
                    </div>
                    <div id="isRestricted">
                        {hasIsRestrictedChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm will stop the account from making any payments, they will still be able to receive funds."
                                titleText="Updated Status"
                                type="frozen"
                                restrictedStatus={isRestricted}
                            />
                        }
                    </div>
                    <div id="isLocked">
                        {hasIsLockedChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm will lock the following account."
                                titleText="Updated Status"
                                type="locked"
                                lockedStatus={isLocked}
                            />
                        }
                    </div>
                    <div id="riskScore">
                        {hasRiskScoreChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm below update the risk status on this account."
                                titleText="Updated Risk Status"
                                type="riskScore"
                            />
                        }
                    </div>
                    <div id="invoiceLimit">
                        {hasInvoiceLimitChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm below will update the invoice limit on this account."
                                titleText="Invoice Limit"
                                type="invoice"
                                amount={AccountDetailsPageStore.form.invoiceLimit}
                            />
                        }
                    </div>
                    <div id="factorLimit">
                        {hasFactorLimitChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm below will update the factoring limit on this account."
                                titleText="Factor Limit"
                                type="factor"
                                amount={AccountDetailsPageStore.form.factoringLimit}
                            />
                        }
                    </div>
                    <div id="transactionLimit">
                        {hasTransactionLimitChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm below will update the transaction limit on this account."
                                titleText="Transaction Limit"
                                type="transaction"
                                amount={transactionLimit}
                            />
                        }
                    </div>
                    <div id="payeeLimit">
                        {hasPayeeLimitChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm below will update the payee limit on this account."
                                titleText="Payee Limit"
                                type="payee"
                                amount={payeeLimit}
                            />
                        }
                    </div>
                    <div id="payorLimit">
                        {hasPayorLimitChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm below will update the payor limit on this account."
                                titleText="Payor Limit"
                                type="payor"
                                amount={payorLimit}
                            />
                        }
                    </div>
                    <div id="dailyLimit">
                        {hasDailyLimitChanged &&
                            <InfoBlock
                                riskScore={riskScore}
                                paragraphText="Clicking confirm below will update the daily limit on this account."
                                titleText="Daily Limit"
                                type="daily"
                                amount={dailyLimit}
                            />
                        }
                    </div>
                    <div id="linkedAccounts">
                        {hasLinkedAccountsChanged &&
                            <ModalLinkedAccountDataBlock
                                paragraphText="Clicking confirm will update the linked account flag on the account"
                                institutionName={paymentDetails?.institutionName}
                                accountNumber={paymentDetails?.accountNumber ?? paymentDetails?.lastFourDigits}
                                ownerName={paymentDetails?.ownerName}
                                ownerEmail={paymentDetails?.ownerEmail}
                                locked={paymentDetails?.isBlocked} />
                        }
                    </div>

                    <ReasoningSection userReasoning={userReasoningText} />
                </ScrollView>
                <Gap />
                <HRLine />
                <div className={styles.btnContainer}>
                    <CancelButton onClick={() => { setContinuePopupVisible(false); }}>
                        Back
                    </CancelButton>
                    <Button
                        onClick={() => {
                            AccountDetailsPageStore.updateAccount(fetch);
                            AccountDetailsPageStore.setPageView(AccountPageTab.info);
                            setContinuePopupVisible(false);
                        }}>
                        Confirm
                    </Button>
                </div>
            </Popup>
        </>
    );
});

export default AccountActionsSection;