import { makeAutoObservable } from 'mobx';
import { LogoData } from './TraceInfoPageInterface';

export interface TraceInfoPageInterface {
    logData: LogoData[]
}

const TraceInfoPageStore = (): TraceInfoPageInterface =>
    makeAutoObservable({
        logData: [
            {
                ID: '1134315',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-84-87.ec2.internal',
                login_id: 'root',
                process_name: 'InvoicingService',
                process_id: '1',
                thread_id: 'Thread-409',
                category_level: '1',
                category: 'INFO',
                classname: 'com.zurohq.services.invoice.InvoicePublisher',
                line_number: 189,
                message: 'Publishing InvoiceEvent for InvoiceId: cbf1a97b InvoiceType: Payable',
                exception_stacktrace: 'NULL',
            },
            {
                ID: '1134318',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-84-6.ec2.internal',
                login_id: 'root',
                process_name: 'SessionService',
                process_id: '1',
                thread_id: 'Thread-156169',
                category_level: '1',
                category: 'INFO',
                classname: 'org.piangles.backbone.services.session.SessionManagementServiceImpl',
                line_number: 230,
                message: 'Validating Session for UserId:569b7a6 SessionId:4a05a9ee-c42d-49d1-9431-9b96e4345b8d',
                exception_stacktrace: 'NULL',
            },
            {
                ID: '1134319',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-84-87.ec2.internal',
                login_id: 'root',
                process_name: 'NotificationService',
                process_id: '1',
                thread_id: 'Thread-118',
                category_level: '1',
                category: 'INFO',
                classname: 'com.zurohq.services.notify.NotificationServiceImpl',
                line_number: 97,
                message: 'Reveived sendNotificationToAllUsersInBusiness for BizId: 5301d4a of Type: ReceivableInvoice',
                exception_stacktrace: 'NULL',
            },

            {
                ID: '1134335',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-84-6.ec2.internal',
                login_id: 'root',
                process_name: 'MessagingService',
                process_id: '1',
                thread_id: 'Thread-10889',
                category_level: '1',
                category: 'INFO',
                classname: 'org.piangles.backbone.services.msg.MessagingServiceImpl',
                line_number: 236,
                message: 'Retreving topics for EntityType: User with Id: 0278135',
                exception_stacktrace: 'NULL',
            },

            {
                ID: '1134342',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-87-28.ec2.internal',
                login_id: 'root',
                process_name: 'CommunicatorService',
                process_id: '1',
                thread_id: 'Thread-255',
                category_level: '1',
                category: 'INFO',
                classname: 'com.zurohq.services.comm.SendGridCommunicator',
                line_number: 106,
                message: 'Creating Email From: donotreply@tryhopscotch.io To: hopuser511@mailinator.com',
                exception_stacktrace: 'NULL',
            },
            {
                ID: '1134344',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-87-28.ec2.internal',
                login_id: 'root',
                process_name: 'AccountingService',
                process_id: '1',
                thread_id: 'Thread-575',
                category_level: '1',
                category: 'INFO',
                classname: 'com.zurohq.services.acs.AccountsReceivableAggregator',
                line_number: 146,
                message: 'Aggregating newAccountsRecord with BizId: 5301d4a',
                exception_stacktrace: 'NULL',
            },

            {
                ID: '1134345',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-84-87.ec2.internal',
                login_id: 'root',
                process_name: 'MessagingService',
                process_id: '1',
                thread_id: 'Thread-575',
                category_level: '1',
                category: 'INFO',
                classname: 'org.piangles.backbone.services.msg.MessagingServiceImpl',
                line_number: 236,
                message: 'Retreving topics for EntityType: Business with Id: 5301d4a',
                exception_stacktrace: 'NULL',
            },

            {
                ID: '1134347',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-84-87.ec2.internal',
                login_id: 'root',
                process_name: 'AccountingService',
                process_id: '1',
                thread_id: 'Thread-576',
                category_level: '1',
                category: 'INFO',
                classname: 'com.zurohq.services.acs.AccountsPayableAggregator',
                line_number: 146,
                message: 'Aggregating newAccountsRecord with BizId: 10acd48',
                exception_stacktrace: 'NULL',
            },

            {
                ID: '1134349',
                inserted_ts: '2022-01-12 17:54:31',
                logging_ts: '2022-01-12 17:54:31',
                trace_id: 'fb80934a-117b-440f-a7ca-3398d248e637',
                hostname: 'ip-172-17-84-87.ec2.internal',
                login_id: 'root',
                process_name: 'MessagingService',
                process_id: '1',
                thread_id: 'Thread-10891',
                category_level: '1',
                category: 'INFO',
                classname: 'org.piangles.backbone.services.msg.MessagingServiceImpl',
                line_number: 236,
                message: 'Retreving topics for EntityType: Business with Id: 10acd48',
                exception_stacktrace: 'NULL',
            },
        ],

    });

export default TraceInfoPageStore;
