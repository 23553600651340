
// eslint-disable-next-line
// eslint-disable-next-line id-length
import cn from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import { getInCurrencyFormat } from 'src/util';
import styles from './DollarInput.module.scss';


type Props = {
    placeholder: number;
    classNames?: string;
    value?: number;
    onChangeHandler: (value: any) => void;
    disabled?: boolean;
};

const DollarInput: React.FC<Props> = ({ placeholder, classNames, onChangeHandler, disabled, value }) => (<CurrencyInput
    prefix="$"
    className={cn(styles.input, classNames)}
    placeholder={getInCurrencyFormat(placeholder)}
    decimalsLimit={2}
    disabled={disabled}
    value={value}
    onValueChange={onChangeHandler}
/>);

export default DollarInput;