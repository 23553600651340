import 'devextreme/dist/css/dx.light.css';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GenerateReportPopup from './components/base/popups/generateReportPopup/GenerateReportPopup';
import ToastContainerWrapper from './components/base/toastContainerWrapper/ToastContainerWrapper';
import EngEventsSearchPage from './components/engineering/pages/engEventsSearchPage/EngEventsSearchPage';
import SupportPage from './components/engineering/pages/support/SupportPage';
import TraceInfoPage from './components/engineering/traceInfoPage/TraceInfoPage';
import Loader from './components/loader/Loader';
import { ROLES } from './Constant';
import AccountDetails from './pages/accountDetailsPage/AccountDetailsPage';
import AccountSearch from './pages/accountSearchPage/AccountSearchPage';
import BusinessPage from './pages/businessPage/BusinessPage';
import CardControlsPage from './pages/cardControlsPage/CardControlsPage';
import CardVerificationSearchPage from './pages/cardVerificationSearchPage/CardVerificationSearchPage';
import CaseBizVerificationDetailPage from './pages/caseVerificationDetailPage/CaseBizVerificationDetailPage';
import AMLVerificationPage from './pages/caseVerificationDetailPage/CaseVerificationDetailPage';
import CaseVerificationSearchPage from './pages/caseVerificationSearchPage/CaseVerificationSearchPage';
import CaseVerificationSearchPageV2 from './pages/caseVerificationSearchPageV2/CaseVerificationSearchPageV2';
import EngineeringMain from './pages/EngineeringMain';
import EventsSearchPage from './pages/eventsSearchPage/EventsSearchPage';
import FactoredInvoiceDetailsPage from './pages/factoredInvoiceDetailsPage/FactoredInvoiceDetailsPage';
import FactoredInvoicesSearchPage from './pages/factoredInvoicesSearchPage/FactoredInvoicesSearchPage';
import FactoredRiskModelPage from './pages/factoredRiskModelPage/FactoredRiskModelPage';
import Home from './pages/home/Home';
import InvoiceDetailsPage from './pages/invoiceDetailsPage/InvoiceDetailsPage';
import InvoicesSearchPage from './pages/invoicesSearchPage/InvoicesSearchPage';
import LBAVerificationSearchPage from './pages/lbaVerificationSearchPage/LBAVerificationSearchPage';
import Login from './pages/loginPage/Login';
import PaidPlanControls from './pages/paidPlanControls/PaidPlanControls';
import ProgramControlsPage from './pages/programControlsPage/ProgramControlsPage';
import TransactionDetailsPage from './pages/transactionDetailsPage/TransactionDetailsPage';
import TransactionDetailsPageByPaymentId from './pages/transactionDetailsPage/TransactionDetailsPageByPaymentId';
import TransactionsSearchPage from './pages/transactionSearchPage/TransactionsSearchPage';
import './static-assets/styles/main.scss';
import { useStore } from './store';
import useHasAuthorization from './store/hooks/useHasAuthorization';

const RouteRegistry: React.FC = observer(() => {
    const { sharedStore } = useStore();
    const isCreditEnabled = useHasAuthorization(ROLES.creditRisk);
    const isBusinessEnabled = useHasAuthorization(ROLES.business);
    const isFrontlineEnabled = useHasAuthorization(ROLES.frontline);
    const isTechnologyEnabled = useHasAuthorization(ROLES.technology);
    const isLoading = sharedStore.isLoading;

    return (
        <div className="content">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/eng" element={isTechnologyEnabled ? <EngineeringMain /> : <Login />} />
                    <Route path="/eng/support" element={isTechnologyEnabled ? <SupportPage /> : <Login />} />
                    <Route path="/biz" element={(isBusinessEnabled || isFrontlineEnabled) ? <BusinessPage /> : 'You do not have access to this page'} />
                    <Route path="/biz/account-search/status/:status" element={<AccountSearch />} />
                    <Route path="/biz/events-search" element={(isBusinessEnabled || isFrontlineEnabled) ? <EventsSearchPage /> : <Login />} />
                    <Route path="/biz/case-verifications/status/:status" element={<CaseVerificationSearchPage />} />
                    <Route path="/biz/case-verifications-new/status/:status" element={<CaseVerificationSearchPageV2 />} />
                    <Route path="/biz/lba-verifications/status/:status/updatedBy/:updatedBy" element={<LBAVerificationSearchPage />} />
                    <Route path="/biz/card-verifications/status/:status/updatedBy/:updatedBy" element={<CardVerificationSearchPage />} />
                    <Route path="/biz/invoices/search" element={<InvoicesSearchPage />} />
                    <Route path="/biz/search-transactions/:type" element={<TransactionsSearchPage />} />
                    <Route path="/biz/account-details/:caseId/view/:view" element={<AccountDetails />}>
                        <Route path=":paymentType" element={<AccountDetails />}>
                            <Route path=":lbaId" element={<AccountDetails />} />
                        </Route>
                    </Route>
                    <Route path="/biz/payment-details/:paymentId" element={<TransactionDetailsPageByPaymentId />} />
                    <Route path="/biz/transaction-details/:transactionId" element={<TransactionDetailsPage />} />
                    <Route path="/biz/invoice-details/:invoiceId" element={<InvoiceDetailsPage />} />
                    <Route path="/biz/aml-verification/:caseId" element={<AMLVerificationPage />} />
                    <Route path="/biz/aml-biz-verification/:bizId" element={<CaseBizVerificationDetailPage />} />
                    <Route path="/biz/factored-invoices" element={<FactoredInvoicesSearchPage />} />
                    <Route path="/biz/factored-invoices/:invoiceId" element={<FactoredInvoiceDetailsPage />} />
                    <Route path="/biz/card-controls" element={<CardControlsPage />} />
                    <Route path="/biz/program-controls" element={isCreditEnabled ? <ProgramControlsPage /> : <Login />} />
                    <Route path="/biz/paid-plan-controls" element={isCreditEnabled ? <PaidPlanControls /> : <Login />} />
                    <Route path="/biz/factored-risk-model" element={isCreditEnabled ? <FactoredRiskModelPage /> : <Login />} />
                    <Route path="/eng/trace-info" element={<TraceInfoPage />} />
                    <Route path="/eng/events-search" element={isTechnologyEnabled ? <EngEventsSearchPage /> : <Login />} />
                </Routes>
            </BrowserRouter>
            <GenerateReportPopup popupVisible={sharedStore.generateReportPopupVisible} hidePopup={() => sharedStore.setGenerateReportPopupVisble(false)} />
            <ToastContainerWrapper />
            <Loader isLoading={isLoading} />
        </div>
    );
});

export default RouteRegistry;
