import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Button from 'src/components/base/buttons/button/Button';
import Label from 'src/components/base/label/Label';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import InfoIcon from 'src/components/logos/InfoIcon';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import Gap from 'src/layouts/gap/Gap';
import LabelWrapper from 'src/layouts/labelWrapper/LabelWrapper';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import { useStore } from 'src/store';
import DollarInput from '../../components/base/dollarInput/DollarInput';
import Balances from './balances/Balances';
import styles from './ProgramControlsPage.module.scss';

const ProgramControlsPage: React.FC = () => {
    const fetch = useFetchLegacy();
    const { ProgramControlsPageStore } = useStore();
    const invoiceLimit = parseFloat(ProgramControlsPageStore.getInvoiceLimit());

    useEffect(() => {
        ProgramControlsPageStore.fetchProgramControlsBalances(fetch);
    }, [ProgramControlsPageStore]);

    const programAvailableBalance = ProgramControlsPageStore.getProgramAvailableBalance();
    const programCurrentBalance = ProgramControlsPageStore.getProgramCurrentBalance();
    const standInAvailableBalance = ProgramControlsPageStore.getStandInAvailableBalance();
    const standInCurrentBalance = ProgramControlsPageStore.getStandInCurrentBalance();
    const factoringAvailableBalance = ProgramControlsPageStore.getFactoringAvailableBalance();
    const factoringCurrentBalance = ProgramControlsPageStore.getFactoringCurrentBalance();
    const feeAndChargebackAvailableBalance = ProgramControlsPageStore.getFeeAndChargebackAvailableBalance();
    const feeAndChargebackCurrentBalance = ProgramControlsPageStore.getFeeAndChargebackCurrentBalance();

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Program controls</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <h4 className={styles.limitsTitle}>Limits</h4>
                        <hr className={styles.hrLine} />
                        <TwoColumnRow>
                            <LabelWrapper>
                                <Label>Invoice Limit
                                    <div data-tip={true} data-for="registerTip" className={styles.tooltipContainer}>
                                        <InfoIcon />
                                    </div>
                                </Label>
                            </LabelWrapper>
                            <ReactTooltip id="registerTip" place="right" effect="solid" className={styles.infoContainer}>
                                All new accounts opened will automatically receive this limit
                                <br />
                                <br />
                                All existing accounts that haven’t had an account level change will receive this new limit
                                <br />
                                <br />
                                All existing accounts that HAVE had an account level change will NOT receive this limit
                            </ReactTooltip>

                            <DollarInput placeholder={invoiceLimit} onChangeHandler={(inputLimit) => ProgramControlsPageStore.setInvoiceLimit(inputLimit)} />
                        </TwoColumnRow>
                        <Button onClick={() => ProgramControlsPageStore.setSystemInvoiceLimit(fetch)}>Save</Button>
                        <Gap />
                        <Row>
                            <Balances header='Program Balances' currentBalance={programCurrentBalance} availableBalance={programAvailableBalance} />
                            <Balances header='Stand-in Balances' currentBalance={standInCurrentBalance} availableBalance={standInAvailableBalance} />
                            <Balances header='Factoring Balances' currentBalance={factoringCurrentBalance} availableBalance={factoringAvailableBalance} />
                            <Balances header='Card chargeback & fees' currentBalance={feeAndChargebackCurrentBalance} availableBalance={feeAndChargebackAvailableBalance} />
                        </Row>
                    </Section>
                </Row>
            </Content>
        </EntireLayout >
    );
};

export default ProgramControlsPage;