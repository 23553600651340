import className from 'classnames';
import React from 'react';
import styles from './Score.module.scss';

type scoreProps = {
    score: string;
    isAccountDetailsScore?: boolean;
}

const Score: React.FC<scoreProps> = ({ score, isAccountDetailsScore }): JSX.Element =>
    <div className={className(
        {
            [styles.highScoreContainer]: score === 'High',
            [styles.notSetScoreContainer]: score === 'NotSet',
            [styles.lowScoreContainer]: score === 'Low',
            [styles.isAccountDetailsScore]: isAccountDetailsScore
        })}>
        {score}
    </div>;


export default Score;