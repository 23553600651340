import DataGrid, { Column, HeaderFilter, Paging, SearchPanel, Selection } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import {
    Row, Section
} from 'src/components';
import PageTitle from 'src/components/pageTitle/PageTitle';
import { ACCOUNT_DETAILS_DYNAMIC_APP_URL } from 'src/Constant';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import { renderAccountLogo, renderInstitutionLogo, renderStatusCell } from 'src/util';
import RenderBusinessCell from 'src/util/datagrid/cellRenders/renderBusinessCell/RenderBusinessCell';
import TwoLevelHeaders from 'src/util/datagrid/cellRenders/twoLevelHeaders/TwoLevelHeaders';
import greyOnCellPrepared from 'src/util/datagrid/onCellPrepared/greyOnCellPrepared';
import renderGreyTextCell from 'src/util/datagrid/renderTextCell';
import PaymentLinks from '../../components/paymentLinks/PaymentLinks';
import RenderPaymentStatusCell from '../../util/datagrid/cellRenders/renderPaymentStatusCell/RenderPaymentStatusCell';
import { SearchParams } from '../../util/interfaces';
import { PaymentTypeTab } from '../accountDetailsPage/sections/accountActionsSection/AccountActionsSection';
import styles from './LBAVerificationSearchPage.module.scss';

const LBAVerificationSearchPage: React.FC = observer(() => {
    const { status: paramStatus, updatedBy: paramUpdatedBy } = useParams<SearchParams>();
    const fetch = useFetchLegacy();
    const navigate = useNavigate();
    const { LBAVerificationPageStore: store, sharedStore } = useStore();

    useEffect(() => {
        if (paramStatus && paramUpdatedBy) {
            store.fetchLBAs(fetch, paramStatus, paramUpdatedBy);
        }
    }, [store, paramStatus, paramUpdatedBy]);

    useEffect(() => {
        store.fetchLBAsCount(fetch);
    }, [store]);

    const lbas = store.getLBAs();


    const onSelectedChange = (rowData: any) => {
        const { caseID, id } = rowData.selectedRowKeys[0];
        navigate(ACCOUNT_DETAILS_DYNAMIC_APP_URL(caseID, undefined, id, PaymentTypeTab.LBA));
    };

    const stores = {
        getLinkUrl: store.getLinkUrl,
        setSelected: store.setSelected,
    } as {
        getLinkUrl: (status: string, updatedBy: string) => string,
        setSelected: (status?: string) => void
    };

    const renderInstitutionCell = (cellData: { institutionName: string; accountNumber: string; }) => <><p className={styles.businessName}>{cellData.institutionName}</p>xxxx{cellData.accountNumber}</>;
    const textValue = sharedStore.textValue;

    const handleTextChange = (event: string) => {
        sharedStore.setTextValue(event);
    };

    return (
        <EntireLayout>
            <Content>
                <PageTitle title="LBA verifications" />
                <Row>
                    <Section>
                        <PaymentLinks
                            store={stores}
                            status={paramStatus}
                            updatedBy={paramUpdatedBy}
                            counts={store.getCounts()}
                        />

                        <DataGrid
                            dataSource={lbas}
                            keyExpr={['caseID', 'id']}
                            onCellPrepared={greyOnCellPrepared}
                            width="100%"
                            showColumnLines={false}
                            showRowLines={false}
                            showBorders={false}
                            allowColumnResizing={true}
                            onSelectionChanged={onSelectedChange}
                            wordWrapEnabled={true}
                        >
                            <HeaderFilter visible={true} />
                            <Selection mode="single" />
                            <Paging defaultPageSize={10} />
                            <SearchPanel
                                visible={true}
                                width={440}
                                placeholder="Search by keyword"
                                defaultText={textValue}
                                onTextChange={handleTextChange}
                            />
                            <Column
                                allowSorting={false}
                                cellRender={renderAccountLogo}
                                caption=""
                                dataField="accountLogo"
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField=""
                                allowSorting={false}
                                headerCellRender={() => TwoLevelHeaders('Business name (User name)', 'Email')}
                                allowSearch={true}
                                allowFiltering={true}
                                width="auto"
                                cellRender={renderGreyTextCell}
                                calculateCellValue={RenderBusinessCell}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                allowSorting={false}
                                cellRender={renderInstitutionLogo}
                                caption=""
                                dataField="institutionLogo"
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField=""
                                allowSorting={false}
                                headerCellRender={() => TwoLevelHeaders('Institution name', 'Acc. #')}
                                allowSearch={true}
                                allowFiltering={true}
                                width="auto"
                                cellRender={renderGreyTextCell}
                                calculateCellValue={renderInstitutionCell}
                                allowHeaderFiltering={false}
                            />
                            <Column width="100%" />
                            <Column
                                headerCellRender={() => TwoLevelHeaders('Payment', 'Method')}
                                cellRender={RenderPaymentStatusCell}
                                width="auto"
                            />
                            <Column
                                dataField="hopscotchStatus"
                                headerCellRender={() => TwoLevelHeaders('Hopscotch', 'Verification')}
                                cellRender={renderStatusCell}
                                width="auto"
                            />
                            <Column
                                dataField="status"
                                headerCellRender={() => TwoLevelHeaders('Account', 'status')}
                                cellRender={renderStatusCell}
                                width="auto"
                            />
                            <Column
                                caption="Date Received"
                                allowSearch={true}
                                allowFiltering={true}
                                allowSorting={true}
                                alignment="left"
                                dataField="dateReceived"
                                width={133}
                                headerCellRender={() => TwoLevelHeaders('Date received', 'Time received')}
                                dataType="date"
                                format="MMM dd, yyyy hh:mm a"
                            />
                            <Column width="auto" dataField="businessName" visible={false} />
                            <Column width="auto" dataField="userName" visible={false} />
                            <Column width="auto" dataField="email" visible={false} />
                            <Column width="auto" dataField="institutionName" visible={false} />
                            <Column width="auto" dataField="accountNumber" visible={false} />
                        </DataGrid>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});
export default LBAVerificationSearchPage;