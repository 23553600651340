import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from 'src/pages/transactionDetailsPage/transactionDetailsAuditPopup/auditHistoryTransactionsContent/AuditHistoryTransactionsContent.module.scss';
import { TransactionDetailAuditHistoryEntity } from 'src/pages/transactionDetailsPage/TransactionDetailsPageStoreInterface';
import AuditHistoryTransactionsItem from './AuditHistoryTransactionsItem';

interface props {
    histories: TransactionDetailAuditHistoryEntity[];
}

const AuditHistoryTransactionsContent: React.FC<props> = observer(({ histories }) =>

(
    <div className={styles.historyWrapper}>
        {histories.map((theHistory: TransactionDetailAuditHistoryEntity) => (
            <AuditHistoryTransactionsItem key={theHistory.id} auditTransaction={theHistory} />
        ))}
    </div>
));

export default AuditHistoryTransactionsContent;