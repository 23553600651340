// eslint-disable-next-line
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import CurrencyValue from 'src/components/base/currencyValue/CurrencyValue';
import { HIGH_RISK_SCORE, LOW_RISK_SCORE } from 'src/Constant';
import styles from './InfoBlock.module.scss';

type Props = {
    riskScore: string;
    paragraphText: string;
    titleText: string;
    type: string;
    amount?: number;
    lockedStatus?: boolean;
    restrictedStatus?: boolean;
}

const InfoBlock: React.FC<Props> = observer(({ riskScore, paragraphText, titleText, type, amount, lockedStatus, restrictedStatus }) => (
    <>
        <p className={styles.headerText}>{paragraphText}</p>
        <div className={styles.infoContent}>
            <div className={styles.infoContainer}>
                <h4 className={styles.secondTitle}>{titleText}</h4>
                {type === 'frozen' && <p className={styles.frozen}>{restrictedStatus ? 'Restricted' : 'Unrestricted'}</p>}
                {type === 'locked' && <p className={styles.locked}>{lockedStatus ? 'Locked' : 'Unlocked'}</p>}
                {type === 'riskScore' &&
                    <div className={styles.statusWrapper}>
                        <div className={styles.statusContainer}>
                            <div className={cn(styles.statusContainer, { [styles.statusContainerLow]: riskScore === LOW_RISK_SCORE, [styles.statusContainerHigh]: riskScore === HIGH_RISK_SCORE })}>{riskScore}</div>
                        </div>
                    </div>

                }
                {type === 'factor' && <CurrencyValue>{amount || 0}</CurrencyValue>}
                {type === 'invoice' && <CurrencyValue>{amount || 0}</CurrencyValue>}
                {type === 'transaction' && <CurrencyValue>{amount || 0}</CurrencyValue>}
                {type === 'payee' && <CurrencyValue>{amount || 0}</CurrencyValue>}
                {type === 'payor' && <CurrencyValue>{amount || 0}</CurrencyValue>}
                {type === 'daily' && <CurrencyValue>{amount || 0}</CurrencyValue>}
            </div>
        </div>
    </>
));

export default InfoBlock;
