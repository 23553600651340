import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import useFetchLegacy from 'src/api/useFetchLegacy';
import AuditDropDown from 'src/components/base/auditDropDown/AuditDropDown';
import CancelButton from 'src/components/base/buttons/cancelButton/CancelButton';
import ContinueButton from 'src/components/base/buttons/continueButton/ContinueButton';
import HRLine from 'src/components/base/hrLine/HRLine';
import BaseConfirmationPopup from 'src/components/base/popups/baseConfirmationPopup/BaseConfirmationPopup';
import Textarea from 'src/components/base/Textarea';
import ToggleContainer from 'src/components/base/toggleContainer/ToggleContainer';
import { MARK_AS_PAID, MARK_AS_UN_PAID, REJECTED_STATUS, REVERSE } from 'src/Constant';
import Gap from 'src/layouts/gap/Gap';
import HalfPanel from 'src/layouts/twoColumnRow/halfPanel/HalfPanel';
import TwoColumnRow from 'src/layouts/twoColumnRow/TwoColumnRow';
import CancelActionPopup from 'src/pages/invoiceDetailsPage/cancelActionPopup/CancelActionPopup';
import InvoiceDetailsAuditPopup from 'src/pages/invoiceDetailsPage/invoiceDetailsAuditPopup/InvoiceDetailsAuditPopup';
import InvoiceReverceReject from 'src/pages/invoiceDetailsPage/sections/invoiceSection/InvoiceReverceReject/InvoiceReverceReject';
import { useStore } from 'src/store';
import styles from '../InvoiceDetailsPage.module.scss';

const ActionsSection: React.FC = observer(() => {
    const { InvoiceDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();
    const [popupVisible, setPopupVisible] = useState(false);
    const [actionPopupVisible, setActionPopupVisible] = useState(false);
    const [cancelPopupVisible, setCancelPopupVisible] = useState(false);
    const [isShowInvoiceActionModal, setShowInvoiceActionModal] = useState(false);
    const isFormReady = InvoiceDetailsPageStore.getIsFormReady();
    const payeeDetails = InvoiceDetailsPageStore.getBaseInvoiceDetails();
    const invoiceState = InvoiceDetailsPageStore.getInvoiceState();
    const modalStatus = InvoiceDetailsPageStore.getModalStatus();
    const isInvoiceRejected = invoiceState === REJECTED_STATUS;
    const isInvoiceMarkAsPaid = invoiceState === MARK_AS_PAID;

    const onReverseRejection = useCallback(() => {
        InvoiceDetailsPageStore.handleToggleChange(REVERSE);
    }, [InvoiceDetailsPageStore.handleToggleChange]);

    const onMarkAsUnPaid = useCallback(() => {
        InvoiceDetailsPageStore.handleToggleChange(MARK_AS_UN_PAID);
    }, [InvoiceDetailsPageStore.handleToggleChange]);

    return <>
        <div className={styles.container}>
            <h3>Actions</h3>
            <InvoiceDetailsAuditPopup popupVisible={popupVisible} hidePopup={() => setPopupVisible(false)} />
            <button type="button" className={styles.historyLink} onClick={() => setPopupVisible(true)}>
                History
            </button>
        </div>

        <Gap />
        <HRLine />
        <Gap />
        <div className={styles.actionRow}>
            <ToggleContainer
                key="amlRisk"
                label="AML Flag"
                checked={InvoiceDetailsPageStore.form.isAmlRisk}
                onHandleChange={() => { InvoiceDetailsPageStore.handleToggleChange('amlRisk'); }}
                description="Updating this toggle will flag this transaction as a potential AML risk."
            />
            {
                (invoiceState === 'Pending' && !InvoiceDetailsPageStore.form.isAmlRisk) &&
                <ToggleContainer
                    key="cancel"
                    label="Cancel"
                    checked={InvoiceDetailsPageStore.form.isCancelled}
                    onHandleChange={() => { InvoiceDetailsPageStore.handleToggleChange('cancel'); }}
                    description="Updating this toggle will cancel the invoice and remove it from the payee & payor’s queue."
                />
            }

            {isInvoiceRejected && (
                <ToggleContainer
                    key={REVERSE}
                    label="Reverse rejection"
                    checked={InvoiceDetailsPageStore.form.isReversedReject}
                    onHandleChange={onReverseRejection}
                    description="Reverses a payor’s rejection of a invoice. The invoice will revert back to pending status, enabling it to be paid by the Payor again."
                />
            )}

            {isInvoiceMarkAsPaid && (
                <ToggleContainer
                    key={MARK_AS_UN_PAID}
                    label="Mark As UnPaid"
                    checked={InvoiceDetailsPageStore.form.isMarkAsUnPaid}
                    onHandleChange={onMarkAsUnPaid}
                    description="This will mark a Paid Invoice as MarkedAsUnPaid in our system. The invoice will revert back to pending status, enabling it to be paid by the Payor again."
                />
            )}
        </div>
        <div className={styles.astertikContainer}>
            * Only 1 toggle can be selected at a time
        </div>
        <Gap />
        <TwoColumnRow>
            <HalfPanel isLeft={true}>
                <AuditDropDown />
            </HalfPanel>
            <HalfPanel>
                <p className={styles.reasoningLabel}>Description</p>
                <Textarea
                    maxLength={2000}
                    isFullSize={true}
                    placeholder='Detailed reason for updating the status of the transaction'
                    onChange={(value: string) => {
                        InvoiceDetailsPageStore.setReason(value);
                    }}
                />
            </HalfPanel>
        </TwoColumnRow>
        {/* //@TODO: Def can abstract this whole thing out I think into a ButtonContainer component. */}
        <div className={styles.buttonContainer}>
            <CancelButton onClick={() => InvoiceDetailsPageStore.cancel()} />
            <ContinueButton
                onClick={() => {
                    if (modalStatus === 'amlRisk') {
                        setActionPopupVisible(true);
                    } else if (modalStatus === 'cancel') {
                        setCancelPopupVisible(true);
                    } else if (modalStatus === REVERSE) {
                        setShowInvoiceActionModal(true);
                    } else if (modalStatus === MARK_AS_UN_PAID) {
                        setShowInvoiceActionModal(true);
                    }
                }}
                disabled={!isFormReady}
            />
        </div>
        <BaseConfirmationPopup
            popupVisible={actionPopupVisible}
            hidePopup={() => { setActionPopupVisible(false); }}
            modalId="action"
            updateCallback={() => {
                InvoiceDetailsPageStore.updateInvoice(fetch);
            }}
            reason={InvoiceDetailsPageStore.getFormReason()}
        />
        <CancelActionPopup
            popupVisible={cancelPopupVisible}
            hidePopup={() => { setCancelPopupVisible(false); }}
            modalId="cancelAction"
            reason={InvoiceDetailsPageStore.getFormReason()}
            updateCallback={() => {
                InvoiceDetailsPageStore.updateInvoice(fetch);
            }}
            dbaName={payeeDetails.payee.dbaName}
            riskScore={payeeDetails.payee.score}
            isAccountVerified={payeeDetails.payee.isVerified}
            createdTimeStamp={payeeDetails.payee.createdTimestamp}
            accountLogo={payeeDetails.payee.accountLogo}
            planType={payeeDetails.payee.planType}
        />
        <InvoiceReverceReject
            key={modalStatus}
            modalStatus={modalStatus}
            popupVisible={isShowInvoiceActionModal}
            updateCallback={() => {
                InvoiceDetailsPageStore.updateInvoice(fetch);
            }}
            hidePopup={() => {
                setShowInvoiceActionModal(false);
            }}
        />
    </>;
});

export default ActionsSection;