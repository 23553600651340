import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import CurrencyValue from 'src/components/base/currencyValue/CurrencyValue';
import HRLine from 'src/components/base/hrLine/HRLine';
import Label from 'src/components/base/label/Label';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import BasePanel from 'src/components/business/transactions/basePanel/BasePanel';
import FastTrackIcon from 'src/components/logos/FastTrackIcon';
import FileIcon from 'src/components/logos/FileIcon';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import Gap from 'src/layouts/gap/Gap';
import Header from 'src/layouts/header/Header';
import { useStore } from 'src/store';
import { dateUtils } from 'src/util';
import ActionsSection from './actionsSection/ActionsSection';
import styles from './FactoredInvoiceDetailsPage.module.scss';

const FactoredInvoiceDetailsPage: React.FC = observer(() => {
    const { invoiceId } = useParams();
    const fetch = useFetchLegacy();
    const { FactoredInvoiceDetailsPageStore } = useStore();

    useEffect(() => {
        if (invoiceId) {
            FactoredInvoiceDetailsPageStore.fetchFactoredInvoiceDetails(fetch, invoiceId);
            FactoredInvoiceDetailsPageStore.fetchAuditHistoryInvoices(fetch, invoiceId);
        }
    }, [FactoredInvoiceDetailsPageStore, invoiceId]);

    const { dueDate } = FactoredInvoiceDetailsPageStore.factoredInvoiceDetails;
    const { invoiceAmount, amountFactored, fee, outstandingBalance } = FactoredInvoiceDetailsPageStore.getInvoiceMonetaryDetails();
    const daysDifference = dateUtils.daysFromToday(dueDate);
    const daysDifferenceAbsoluteValue = daysDifference ? Math.abs(daysDifference) : 0;
    const daysDifferenceTitle = daysDifference > 0 ? 'Days Until Due Date' : 'Days Past Due';
    const { transactionDetails, payee, payor, mutualTransactions } = FactoredInvoiceDetailsPageStore.getBaseTransactionDetails();

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <Header icon={<FileIcon />} title="Factored invoice report" id={invoiceId} />
                        <BasePanel transactionDetails={transactionDetails} payee={payee} payor={payor} mutualTransactions={mutualTransactions} />
                        <Gap />
                        <Label>Factor Status</Label>
                        <div className={styles.hrlineContainer}>
                            <HRLine />
                        </div>
                        <div className={styles.subHeaderContainer}>
                            <div className={styles.dataContainer}>
                                <span>Invoice amount</span>
                                <span><CurrencyValue>{invoiceAmount}</CurrencyValue></span>
                            </div>
                            <div className={styles.iconContainer}>
                                <FastTrackIcon height={15} width={15} />
                            </div>
                            <div className={styles.dataContainer}>
                                <span>Fee</span>
                                <span><CurrencyValue>{fee}</CurrencyValue></span>
                            </div>
                            <div className={styles.iconContainer}>
                                <FastTrackIcon height={15} width={15} />
                            </div>
                            <div className={styles.dataContainer}>
                                <span>Factored amount</span>
                                <span><CurrencyValue>{amountFactored}</CurrencyValue></span>
                            </div>
                            <div className={styles.iconContainer}>
                                <FastTrackIcon height={15} width={15} />
                            </div>
                            <div className={styles.dataContainer}>
                                <span>Outstanding balance</span>
                                <span><CurrencyValue>{outstandingBalance}</CurrencyValue></span>
                            </div>
                            <div className={styles.dateStatusContainer}>
                                <span>{daysDifferenceTitle}</span>
                                <span>{daysDifferenceAbsoluteValue}</span>
                            </div>
                        </div>
                        <Gap />
                        {invoiceId && <ActionsSection invoiceId={invoiceId} />}
                    </Section>
                </Row >
            </Content >
        </EntireLayout >
    );
});

export default FactoredInvoiceDetailsPage;
