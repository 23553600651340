/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { riskScoreType } from '../../../../util/interfaces';
import styles from './AccountLink.module.scss';
import AccountLinkContent from './content/AccountLinkContent';

type Props = {
    dbaName: string;
    isVerified: boolean;
    score: riskScoreType;
    createdTimestamp: string;
    caseId?: string;
    accountLogo?: string;
    isExternalUser?: boolean;
    planType: string;
};

const AccountLink: React.FC<Props> = ({ dbaName, isVerified, score, createdTimestamp, caseId, accountLogo, isExternalUser = false, planType }) => {
    const renderAccountLinkContent = () => (<AccountLinkContent
        dbaName={dbaName}
        isVerified={isVerified}
        score={score}
        date={createdTimestamp}
        logo={accountLogo}
        isExternalUser={isExternalUser}
        planType={planType}
    />);
    return (caseId) ? (<Link
        to={`/biz/account-details/${caseId}/view/payables`}
        className={styles.linkContainer}
        data-testid="link">
        {renderAccountLinkContent()}
    </Link>)
        : renderAccountLinkContent();
};

export default AccountLink;