// eslint-disable-next-line
import cn from 'classnames';
import React from 'react';
import { DirectBankAccountDto } from 'src/pages/accountDetailsPage/AccountDetailsPageStoreInterface';
import RadioLabelDynamic from '../../base/radioLabels/radioLabelDynamic/RadioLabelDynamic';
import styles from '../lbaLargeBlock/LBALargeBlock.module.scss';

type Props = {
    directBankAccount: DirectBankAccountDto,
    selectedId: string;
}

const DBALargeBlock: React.FC<Props> = ({
    directBankAccount,
    selectedId
}) => {
    const {
        accountHolderName,
        accountNumber,
        institutionName,
        isFundingAccount: isBackupAccount,
        routingNumber,
        zuroAccountId: id,
    } = directBankAccount;
    return (
        <div className={cn(styles.linkedAccountBlock, { [styles.highlightBorder]: id === selectedId })}>
            <div className={cn(styles.row, styles.headerRow)}>
                <div className={styles.headerWrapper}>
                    <div className={styles.leftContainer}>
                        <p className={cn(styles.institutionName)}>{institutionName}</p>
                        <p className={cn(styles.label, styles.smallLabel)}>Account:{accountNumber}</p>
                        <p className={cn(styles.label, styles.smallLabel)}>Routing:{routingNumber}</p>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.buttonWrapper}>
                            <RadioLabelDynamic
                                setChecked={() => { }}
                                isPositive={true}
                                isChecked={false}
                                label="Unblock"
                                classNames={styles.RadioLabelDynamic}
                                disabled={true}
                            />
                            <RadioLabelDynamic
                                setChecked={() => { }}
                                isPositive={false}
                                isChecked={false}
                                label="Block"
                                classNames={styles.RadioLabelDynamic}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.contentBlock}>
                <div className={styles.ownerDetails}>
                    <div className={styles.availableBalanceRow}>
                        <p className={styles.availableBalanceLabel}>{accountHolderName}</p>
                    </div>
                    {/* <div className={styles.availableBalanceRow}>
                        <p className={styles.availableBalanceLabel}>Created At: {createdAt}</p>
                    </div>
                    <div className={styles.availableBalanceRow}>
                        <p className={styles.availableBalanceLabel}>Has Ops Updated: {hasOpsUpdated}</p>
                    </div>
                    <div className={styles.availableBalanceRow}>
                        <p className={styles.availableBalanceLabel}>Is Deleted: {isDeleted}</p>
                    </div> */}
                </div>
                <div className={styles.accountDetails}>
                    <div className={styles.backupContainer}>
                        {isBackupAccount && <p className={cn(styles.statusContainer, styles.statusContainerDarkGrey)}>Backup</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DBALargeBlock;