import { useStore } from '..';

/**
 * If the length of roles is greater than 0, then we know the user has this role in their list of roles.
 * @param roleLimit 'Technology' | 'Business' | etc
 * @returns boolean if the role that has been passed in is within the user's roles
 */
const useHasAuthorization = (roleLimit: string): boolean => {
    const { sharedStore } = useStore();
    const roles = sharedStore.getRoles();
    return roles.filter(role => role === roleLimit).length > 0;
};

export default useHasAuthorization; 