import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Params, useParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import BasePanel from 'src/components/business/transactions/basePanel/BasePanel';
import DownloadIcon from 'src/components/logos/DownloadIcon';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import Gap from 'src/layouts/gap/Gap';
import Header from 'src/layouts/header/Header';
import InvoiceStatusSection from 'src/pages/invoiceDetailsPage/sections/StatusSection';
import { useStore } from 'src/store';
import ActionsSection from './sections/ActionsSection';
import InvoicesSection from './sections/invoiceSection/InvoiceSection';

export interface InvoiceItem {
    id: string,
    description: string,
    qty: number;
    rate: number,
    amount: number,
}

const InvoiceDetailsPage: React.FC = observer(() => {
    const { invoiceId }: Readonly<Params<string>> = useParams();
    const { InvoiceDetailsPageStore } = useStore();
    const fetch = useFetchLegacy();

    useEffect(() => {
        InvoiceDetailsPageStore.fetchInvoiceDetails(fetch, invoiceId ?? '');
        InvoiceDetailsPageStore.fetchInvoicesItems(fetch, invoiceId ?? '');
        InvoiceDetailsPageStore.fetchAuditHistoryInvoices(fetch, invoiceId ?? '');
    }, [InvoiceDetailsPageStore, invoiceId]);

    const { invoiceDetails, payee, payor, mutualTransactions } = InvoiceDetailsPageStore.getBaseInvoiceDetails();

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <Header icon={<DownloadIcon />} title="Invoice report" id={invoiceId} />
                        <InvoiceStatusSection transactionDetails={invoiceDetails} />
                        <BasePanel transactionDetails={invoiceDetails} payee={payee} payor={payor} mutualTransactions={mutualTransactions} />
                        <Gap />
                        <InvoicesSection />
                        <Gap />
                        <Gap />
                        <ActionsSection />
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default InvoiceDetailsPage;

