import React from 'react';
import CurrencyValue from 'src/components/base/currencyValue/CurrencyValue';
import styles from '../ProgramControlsPage.module.scss';

interface Props {
    label: React.ReactNode,
    balance: number;
    isAnimated?: boolean,
    programBalance?: boolean,
}

const BalanceCard: React.FC<Props> = ({ label, balance, isAnimated, programBalance }) => (
    <div className={styles.content}>
        <h3>{label}</h3>
        {programBalance ? (<h2>N/A</h2>) : (<CurrencyValue
            isAnimated={isAnimated}
            classNames={styles.currencyValue}>
            {balance}
        </CurrencyValue>)}
    </div>
);

export default BalanceCard;