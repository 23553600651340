// eslint-disable-next-line
// eslint-disable-next-line
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './ModalLinkedAccountDataBlock.module.scss';

type Props = {
    paragraphText: string,
    institutionName: string,
    accountNumber: string,
    ownerName: string,
    ownerEmail: string,
    locked: boolean
}

const ModalLinkedAccountDataBlock: React.FC<Props> = observer(({ paragraphText, institutionName, accountNumber, ownerName, ownerEmail, locked }) => (
    <>
        <p className={styles.headerText}>{paragraphText}</p>
        <div className={styles.infoContent}>
            <div className={styles.splitPane}>
                <div className={styles.linkedAccountContainer}>
                    <p className={cn(styles.accountText, styles.leftAlignText)}>Linked bank account</p>
                    <div className={styles.row}>
                        <p className={cn(styles.secondaryText, styles.labelText)}>Institution name:</p>
                        <p className={styles.secondaryText}>{institutionName}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={cn(styles.secondaryText, styles.labelText)}>Account number:</p>
                        <p className={styles.secondaryText}>xxxx{accountNumber}</p>
                    </div>
                    {ownerName && <div className={styles.row}>
                        <p className={cn(styles.secondaryText, styles.labelText)}>Owner name:</p>
                        <p className={styles.secondaryText}>{ownerName}</p>
                    </div>}
                    {ownerEmail && <div className={styles.row}>
                        <p className={cn(styles.secondaryText, styles.labelText)}>Owner email:</p>
                        <p className={styles.secondaryText}>{ownerEmail}</p>
                    </div>}
                </div>
                <div className={styles.accountStatusContainer}>
                    <p className={cn(styles.accountText, styles.rightAlignText)}>Account status</p>
                    <div className={styles.statusContainerWrapper}>
                        {(!locked
                            ? <button type="button" className={cn(styles.statusContainer, styles.statusContainerLow)}>OK</button>
                            : <button type="button" className={cn(styles.statusContainer, styles.statusContainerHigh)}>NOT OK</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>
));

export default ModalLinkedAccountDataBlock;
