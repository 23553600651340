// eslint-disable-next-line id-length
import { Column, HeaderFilter } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFetchLegacy from 'src/api/useFetchLegacy';
import LinkButton from 'src/components/base/buttons/linkButton/LinkButton';
import LinkButtonWrapper from 'src/components/base/buttons/linkButton/linkButtonWrapper/LinkButtonWrapper';
import DataGridWrapper from 'src/components/base/dataGridWrapper/DataGridWrapper';
import Row from 'src/components/base/row/Row';
import Section from 'src/components/base/section/Section';
import SectionLabel from 'src/components/base/sectionLabel/SectionLabel';
import StatusCell from 'src/components/base/statusCell/StatusCell';
import {
    BLACK_LIGHT_TEXT_COLOR,
    FACTORED_INVOICE_SEARCH_APP_URL,
    FACTORED_INVOICE_WRITEOFF_DAY_LIMIT,
    GREY_TEXT_COLOR, VERTICAL_ALIGN
} from 'src/Constant';
import Content from 'src/layouts/content/Content';
import EntireLayout from 'src/layouts/entireLayout/EntireLayout';
import { useStore } from 'src/store';
import { renderAccountLogo, renderAmountCell, renderPayeeBusinessUserName, renderPayorBusinessUserName, renderTwoLevelHeader } from 'src/util';
import currencyColumnFilter from 'src/util/datagrid/filters/currencyColumnFilter';
import rowType from 'src/util/datagrid/rowType';
import daysFromToday from 'src/util/date/daysFromToday';
import RenderPayorPaymentCell from '../../util/datagrid/cellRenders/renderPayorPaymentCell/RenderPayorPaymentCell';


const FactoredInvoicesSearchPage: React.FC = observer(() => {
    const [searchParams] = useSearchParams();
    const { FactoredInvoicesSearchPageStore, sharedStore } = useStore();
    const paramStatus = searchParams.get('type');
    FactoredInvoicesSearchPageStore.setType(paramStatus);
    const fetch = useFetchLegacy();
    const factoredInvoices = FactoredInvoicesSearchPageStore.getFactoredInvoices();
    const { rejectedCount, pastDueCount, allCount } = FactoredInvoicesSearchPageStore.getCounts();

    useEffect(() => {
        FactoredInvoicesSearchPageStore.fetchCasesCount(fetch);
        FactoredInvoicesSearchPageStore.fetchInvoices(fetch);
    }, [FactoredInvoicesSearchPageStore, paramStatus]);

    const renderStatus = (cell: { value: string, data: { invoiceDueDate: string, isWrittenOff: boolean } }): JSX.Element => {
        const daysBetweenDueDateAndCurrentDate = (daysFromToday(cell.data.invoiceDueDate));
        if (daysBetweenDueDateAndCurrentDate < 0 && cell.value === 'Pending') {
            if (cell.data.isWrittenOff) {
                return <StatusCell status='Written Off' />;
            }
            if (daysBetweenDueDateAndCurrentDate < FACTORED_INVOICE_WRITEOFF_DAY_LIMIT) {
                return <StatusCell status='Pending Writeoff' />;
            }
            return <StatusCell status='Past Due' />;
        }
        return <StatusCell status={cell.value} />;
    };

    const onCellPrepared = (e: any) => {
        if (rowType(e)) {
            e.cellElement.style.color = GREY_TEXT_COLOR;
            e.cellElement.style.verticalAlign = VERTICAL_ALIGN;
        }
        if (rowType(e) && e.column.dataField === 'payeeDbaName'
            || e.rowType === 'data' && e.column.dataField === 'payorDbaName') {
            e.cellElement.style.color = BLACK_LIGHT_TEXT_COLOR;
        }
    };

    const textValue = sharedStore.textValue;

    const handleTextChange = (event: string) => {
        sharedStore.setTextValue(event);
    };

    return (
        <EntireLayout>
            <Content>
                <Row>
                    <Section>
                        <SectionLabel>Factored invoices</SectionLabel>
                    </Section>
                </Row>
                <Row>
                    <Section>
                        <LinkButtonWrapper>
                            <LinkButton
                                isActive={paramStatus === 'all'}
                                to="?type=all"
                                count={allCount}
                                label="All"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramStatus === 'rejected'}
                                to="?type=rejected"
                                count={rejectedCount}
                                label="Rejected"
                                showDelimiter={true}
                            />
                            <LinkButton
                                isActive={paramStatus === 'pastDue'}
                                to="?type=pastDue"
                                count={pastDueCount}
                                label="Past Due"
                            />
                        </LinkButtonWrapper>

                        <DataGridWrapper
                            dataSource={factoredInvoices}
                            onCellPrepared={onCellPrepared}
                            keyExp='invoiceId'
                            navigator={FACTORED_INVOICE_SEARCH_APP_URL()}
                            wordWrapEnabled={true}
                            headerFilter={true}
                            textValue={textValue}
                            handleTextChange={handleTextChange}
                        >
                            <Column
                                allowSorting={false}
                                dataField="payeeLogo"
                                caption="Payee"
                                cellRender={renderAccountLogo}
                                width="auto"
                                allowHeaderFiltering={false}
                                allowFiltering={true}
                            />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                width="auto"
                                cellRender={renderPayeeBusinessUserName}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                allowSorting={false}
                                dataField="payorLogo"
                                caption="Payor"
                                cellRender={renderAccountLogo}
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                cellRender={renderPayorBusinessUserName}
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column width="100%" />
                            <Column
                                dataField="invoiceId"
                                caption="ID"
                                dataType="string"
                                width="auto"
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField="type"
                                caption="Type"
                                dataType="string"
                                width="auto"
                            />
                            <Column
                                dataField="payorPaymentType"
                                caption="Payment Method"
                                cellRender={RenderPayorPaymentCell}
                                dataType="string"
                                width={200}
                            />
                            <Column
                                allowSorting={true}
                                allowFiltering={true}
                                dataField="invoiceState"
                                dataType="string"
                                caption="Status"
                                width="auto"
                                cellRender={renderStatus}
                            />
                            <Column
                                dataField="factorAmount"
                                caption="Amount"
                                dataType="number"
                                alignment="left"
                                cellRender={renderAmountCell}
                                width="auto"
                                format="currency"
                            >
                                <HeaderFilter dataSource={currencyColumnFilter('factorAmount')} />
                            </Column>
                            <Column
                                dataField="dateFactored"
                                headerCellRender={() => renderTwoLevelHeader('Last status', 'date')}
                                dataType="date"
                                format="MMM dd, yyyy"
                                width={115}
                            />
                            <Column
                                dataField="invoiceDueDate"
                                caption="Due date"
                                dataType="date"
                                format="MMM dd, yyyy"
                                width={115}
                            />
                            <Column dataField="payeeDbaName" visible={false} />
                            <Column dataField="payeeFirstName" visible={false} />
                            <Column dataField="payeeLastName" visible={false} />
                            <Column dataField="payorDbaName" visible={false} />
                            <Column dataField="payorFirstName" visible={false} />
                            <Column dataField="payorLastName" visible={false} />
                        </DataGridWrapper>
                    </Section>
                </Row>
            </Content>
        </EntireLayout>
    );
});

export default FactoredInvoicesSearchPage;
