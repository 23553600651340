/* eslint-disable deprecation/deprecation */
import { makeAutoObservable } from 'mobx';
import { FetchInterfaceLegacy } from 'src/api/useFetchLegacy';
import { INVOICES_SEARCH_COUNT_URL, INVOICES_SEARCH_URL } from 'src/Constant';
import { SearchInvoiceDto } from 'src/util/interfaces/SearchInvoiceDto';
import { selectSearchInvoice } from 'src/util/selectors';
import { InvoiceInterface } from './InvoicesSearchPageStoreInterface';

export interface InvoicesSearchPageStoreInterface {
    type: string;
    invoices: InvoiceInterface[];
    submittedCount: number;
    rejectedCount: number;
    paidCount: number;
    allCount: number;
    getInvoices(): any;
    fetchInvoices(fetch: any): void;
    fetchInvoicesCount(fetch: any): void;
    getCounts(): { submittedCount: number; rejectedCount: number; paidCount: number; allCount: number };
    getInvoiceUrl(): any;
    setType(type: string): void;
    setSelected(status?: string): void;
}

const InvoicesSearchPageStore = (): InvoicesSearchPageStoreInterface =>
    makeAutoObservable({
        type: 'submitted',
        submittedCount: 0,
        rejectedCount: 0,
        paidCount: 0,
        allCount: 0,
        invoices: [],
        getInvoices() {
            return this.invoices;
        },
        setType(type: string) {
            this.type = type;
        },
        getCounts(): { submittedCount: number; rejectedCount: number; paidCount: number; allCount: number; } {
            return {
                submittedCount: this.submittedCount,
                rejectedCount: this.rejectedCount,
                paidCount: this.paidCount,
                allCount: this.rejectedCount + this.paidCount + this.submittedCount,
            };
        },
        getInvoiceUrl() {
            if (this.type === 'submitted') {
                return `${INVOICES_SEARCH_URL}?type=submitted`;
            }
            if (this.type === 'rejected') {
                return `${INVOICES_SEARCH_URL}?type=rejected`;
            }
            if (this.type === 'paid') {
                return `${INVOICES_SEARCH_URL}?type=paid`;
            }
            return `${INVOICES_SEARCH_URL}`;
        },
        fetchInvoices(fetch: FetchInterfaceLegacy) {
            fetch({ url: this.getInvoiceUrl() }, (res) => {
                this.invoices = res.data?.map((theInvoice: SearchInvoiceDto) => selectSearchInvoice(theInvoice));
            },
                true
            );
        },
        fetchInvoicesCount(fetch: FetchInterfaceLegacy) {
            fetch({ url: `${INVOICES_SEARCH_COUNT_URL}?type=submitted` }, (res) => {
                this.submittedCount = res.data;
            });
            fetch({ url: `${INVOICES_SEARCH_COUNT_URL}?type=rejected` }, (res) => {
                this.rejectedCount = res.data;
            });
            fetch({ url: `${INVOICES_SEARCH_COUNT_URL}?type=paid` }, (res) => {
                this.paidCount = res.data;
            });
            fetch({ url: `${INVOICES_SEARCH_COUNT_URL}` }, (res) => {
                this.allCount = res.data;
            });
        },
        setSelected(status: string) {
            window.console.log(status);
        }
    });

export default InvoicesSearchPageStore;
