import fileDownload from 'js-file-download';
import { makeAutoObservable } from 'mobx';
import { FetchInterface } from 'src/api/useFetch';
import { BASIC_PLAN, FREEMIUM_PLAN, PAID_PLAN_FEATURES, PLAN_AUDIT_HISTORY_API, PLAN_AUDIT_HISTORY_CSV_API, PREMIUM_PLAN, UPDATE_PLAN_FEATURE } from 'src/Constant';
import { AuditHistory, PaidProgramControlsInterface } from './paidPlanControlsInterface/PaidPlanControlsInterface';

export type PaidPlanControlsInterface = {
    freemiumPlan: PaidProgramControlsInterface[],
    premiumPlan: PaidProgramControlsInterface[],
    freePlan: PaidProgramControlsInterface[],
    freeTrialNoPayPlan: PaidProgramControlsInterface[],
    isFreemiumPlan: boolean,
    isPremiumPlan: boolean,
    isFreePlan: boolean,
    isFreeTrialNoPayPlan: boolean,
    audits: AuditHistory[],
    getFreemiumPlan(): PaidProgramControlsInterface[],
    getFreeTrialNoPayPlan(): PaidProgramControlsInterface[],
    getPremiumPlan(): PaidProgramControlsInterface[],
    getFreePlan(): PaidProgramControlsInterface[],
    getIsFreeTrialNoPayPlan(): boolean,
    getIsFreemiumPlan(): boolean,
    getIsPremiumPlan(): boolean,
    getIsFreePlan(): boolean,
    getUpdatedFeature(): string;
    getAuditHistory(): AuditHistory[];
    setIsFreeTrialNoPayPlan(value: boolean): void,
    setIsFreePlan(value: boolean): void,
    setIsFreemiumPlan(value: boolean): void,
    setIsPremiumPlan(value: boolean): void,
    setUpdatedFeature(value: string): void
    updatePlanType(value: any, featureId: string, planType: string): void,
    fetchFreemiumPlan(fetch: FetchInterface<PaidProgramControlsInterface>): void,
    fetchPremiumPlan(fetch: FetchInterface<PaidProgramControlsInterface>): void,
    fetchFreePlan(fetch: FetchInterface<PaidProgramControlsInterface>): void,
    fetchPlanAudits(fetch: FetchInterface<AuditHistory>): void,
    fetchPlanAuditsCSV(fetch: FetchInterface<string | ArrayBuffer | ArrayBufferView | Blob>): void;
    fetchFreeTrialNoPayPlan(fetch: FetchInterface<PaidProgramControlsInterface>): void
    updatePlan(fetch: FetchInterface<PaidProgramControlsInterface>): void
};

export enum PlanTypeEnums {
    freePlan = 'freePlan',
    freemiumPlan = 'freemiumPlan',
    premiumPlan = 'premiumPlan',
    freeTrialNoPayPlan = 'freeTrialNoPayPlan'
}


const DEFAULT_UPDATE_PAYLOAD = { groupId: '', featureId: '', description: '', isEnabled: false };

const PaidPlanControlsStore = (): PaidPlanControlsInterface =>
    makeAutoObservable({
        freemiumPlan: [] as PaidProgramControlsInterface[],
        premiumPlan: [] as PaidProgramControlsInterface[],
        freePlan: [] as PaidProgramControlsInterface[],
        freeTrialNoPayPlan: [] as PaidProgramControlsInterface[],
        isFreemiumPlan: false,
        isPremiumPlan: false,
        isFreePlan: false,
        isFreeTrialNoPayPlan: false,
        updatedFeature: '',
        updatePayload: DEFAULT_UPDATE_PAYLOAD,
        audits: [] as AuditHistory[],
        getFreemiumPlan() {
            return this.freemiumPlan;
        },
        getUpdatedFeature(): string {
            return this.updatedFeature;
        },
        setUpdatedFeature(value): void {
            this.updatedFeature = value;
        },
        getFreeTrialNoPayPlan() {
            return this.freeTrialNoPayPlan;
        },
        getPremiumPlan() {
            return this.premiumPlan;
        },
        getFreePlan() {
            return this.freePlan;
        },
        getIsFreeTrialNoPayPlan() {
            return this.isFreeTrialNoPayPlan;
        },
        getIsFreemiumPlan() {
            return this.isFreemiumPlan;
        },
        getIsPremiumPlan() {
            return this.isPremiumPlan;
        },
        getIsFreePlan() {
            return this.isFreePlan;
        },
        getAuditHistory(): AuditHistory[] {
            return this.audits;
        },
        setIsFreeTrialNoPayPlan(value: any) {
            this.isFreeTrialNoPayPlan = value;
        },
        setIsFreePlan(value: any) {
            this.isFreePlan = value;
        },
        setIsFreemiumPlan(value: any) {
            this.isFreemiumPlan = value;
        },
        setIsPremiumPlan(value: any) {
            this.isPremiumPlan = value;
        },
        setPlanType(planType: PlanTypeEnums, values: any) {
            this[planType] = values;
        },
        updatePlanType(value: any, featureId: string, planType: string): void {
            const plans = [...this[planType as PlanTypeEnums]];
            const newList = plans.map(feature => {
                if (feature.featureId === featureId) {

                    // This updateFeature would be used to disable the checkboxes and also the save button and also save which feature was updated
                    if (!this.updatedFeature) {
                        this.updatedFeature = featureId;
                    } else if (this.updatedFeature === featureId) {
                        // The case in which the updated feature checkbox is being reverted before saving at that time we need to enable other checkboxes
                        this.updatedFeature = '';
                    }

                    // This will update the payload which can later be passed via the api
                    this.updatePayload = { ...this.updatePayload, featureId, description: feature?.featureDescription, isEnabled: value };

                    return { ...feature, enabled: value };
                }
                return { ...feature };
            });
            this.setPlanType(planType as PlanTypeEnums, newList);

        },
        fetchFreemiumPlan(fetch: FetchInterface<PaidProgramControlsInterface>): void {
            fetch({ url: PAID_PLAN_FEATURES(PREMIUM_PLAN) }, (res) => {
                this.freemiumPlan = res.data;
            },
                true
            );
            this.setIsPremiumPlan(false);
            this.setIsFreePlan(false);
            this.setIsFreeTrialNoPayPlan(false);
            this.setUpdatedFeature('');
            // @TODO :-  As new Plans come might need to update the groupId below
            this.updatePayload = { featureId: '', description: '', isEnabled: false, groupId: PREMIUM_PLAN };
        },
        fetchPremiumPlan(fetch: FetchInterface<PaidProgramControlsInterface>): void {
            fetch({ url: PAID_PLAN_FEATURES(PREMIUM_PLAN) }, (res) => {
                this.premiumPlan = res.data;
            },
                true
            );
            this.setIsFreemiumPlan(false);
            this.setIsFreePlan(false);
            this.setIsFreeTrialNoPayPlan(false);
            this.setUpdatedFeature('');
            // @TODO :-  As new Plans come might need to update the groupId below
            this.updatePayload = { featureId: '', description: '', isEnabled: false, groupId: PREMIUM_PLAN };
        },
        fetchFreePlan(fetch: FetchInterface<PaidProgramControlsInterface>): void {
            fetch({ url: PAID_PLAN_FEATURES(BASIC_PLAN) }, (res) => {
                this.freePlan = res.data;
            },
                true
            );
            this.setIsFreemiumPlan(false);
            this.setIsPremiumPlan(false);
            this.setIsFreeTrialNoPayPlan(false);
            this.setUpdatedFeature('');
            this.updatePayload = { ...this.updatePayload, groupId: BASIC_PLAN };
        },
        fetchPlanAudits(fetch: FetchInterface<AuditHistory>): void {
            fetch({
                url: PLAN_AUDIT_HISTORY_API,
                method: 'GET'
            }, (res) => {
                this.audits = res.data;
            }, true);
        },
        fetchPlanAuditsCSV(fetch: FetchInterface<string | ArrayBuffer | ArrayBufferView | Blob>) {
            fetch({ url: PLAN_AUDIT_HISTORY_CSV_API }, (res) => {
                fileDownload(res.data, 'plan_audit_history.csv');
            });
        },
        fetchFreeTrialNoPayPlan(fetch: FetchInterface<PaidProgramControlsInterface>): void {
            // adding freemium plan just to have data show, will need to update once other plans come in
            fetch({ url: PAID_PLAN_FEATURES(FREEMIUM_PLAN) }, (res) => {
                this.freeTrialNoPayPlan = res.data;
            },
                true
            );
            this.setIsFreemiumPlan(false);
            this.setIsPremiumPlan(false);
            this.setIsFreePlan(false);
            this.setUpdatedFeature('');
            // @TODO :-  As new Plans come might need to update the groupId below
            this.updatePayload = { featureId: '', description: '', isEnabled: false, groupId: FREEMIUM_PLAN };
        },
        updatePlan(fetch) {
            // This function would be used to update the feature toggle
            fetch({
                url: UPDATE_PLAN_FEATURE,
                method: 'PUT',
                data: {
                    ...this.updatePayload
                }
            }, () => {
                this.setUpdatedFeature('');
                this.updatePayload = { ...DEFAULT_UPDATE_PAYLOAD, groupId: this.updatePayload.groupId };
                this.fetchPlanAudits(fetch as unknown as FetchInterface<AuditHistory>);
            },
                true,
                { successMessage: 'Plan updated succesfully', failMessage: 'Failed to update the plan' }
            );
        },
    });

export default PaidPlanControlsStore;