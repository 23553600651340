import { PaymentType, PaymentTypeLabel } from './interfaces';

const getPaymentTypeLabel = (paymentType: keyof typeof PaymentType): keyof typeof PaymentTypeLabel => {
    switch (paymentType) {
        case PaymentType.CreditCard:
            return 'CC';
        case PaymentType.DebitCard:
            return 'DC';
        case PaymentType.LinkedBankAccount:
            return 'L';
        case PaymentType.DirectBankAccount:
            return 'D';
        case PaymentType.NotSet:
            return 'N/A';
        default:
            return '';
    }
};

export default getPaymentTypeLabel;