
import React from 'react';

type WarningIconProps = {
    width: number;
    height: number;
}

const WarningIcon: React.FC<WarningIconProps> = ({ width, height }): JSX.Element => (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.99996 4.70115C6.22401 4.70115 6.40564 4.87707 6.40564 5.09408V7.18971C6.40564 7.40672 6.22401 7.58264 5.99996 7.58264C5.77591 7.58264 5.59428 7.40672 5.59428 7.18971V5.09408C5.59428 4.87707 5.77591 4.70115 5.99996 4.70115Z"
            fill="#030D45"
        />
        <path
            d="M6.54087 8.76144C6.54087 9.05078 6.29869 9.28534 5.99996 9.28534C5.70122 9.28534 5.45905 9.05078 5.45905 8.76144C5.45905 8.47209 5.70122 8.23753 5.99996 8.23753C6.29869 8.23753 6.54087 8.47209 6.54087 8.76144Z"
            fill="#030D45"
        />
        <path
            d="M4.79716 1.36789C5.37819 0.544036 6.62173 0.544036 7.20276 1.36789C7.92325 2.3895 8.94305 3.87556 9.62533 5.0408C10.3415 6.264 11.157 7.93829 11.6996 9.0923C12.1266 10.0006 11.4823 11.0301 10.456 11.0891C9.19153 11.1618 7.37485 11.25 5.99996 11.25C4.62507 11.25 2.80838 11.1618 1.54394 11.0891C0.517624 11.0301 -0.126704 10.0006 0.300362 9.0923C0.842941 7.93829 1.65837 6.264 2.37459 5.0408C3.05687 3.87556 4.07666 2.3895 4.79716 1.36789ZM6.53279 1.81116C6.27391 1.4441 5.72601 1.4441 5.46713 1.81116C4.74756 2.83145 3.74454 4.29414 3.08035 5.42848C2.38328 6.61898 1.58048 8.26581 1.03876 9.418C0.848777 9.82208 1.13204 10.2782 1.59199 10.3046C2.85505 10.3772 4.65103 10.4641 5.99996 10.4641C7.34888 10.4641 9.14487 10.3772 10.4079 10.3046C10.8679 10.2782 11.1511 9.82207 10.9612 9.418C10.4194 8.26581 9.61664 6.61898 8.91957 5.42848C8.25538 4.29414 7.25236 2.83145 6.53279 1.81116Z"
            fill="#030D45"
        />
    </svg>
);

export default WarningIcon;