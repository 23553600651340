import React from 'react';
import styles from 'src/components/base/businessUserNameCell/BusinessUserNameCell.module.scss';

type BusinessUserNameCellProps = {
    dbaName: string;
    firstName: string;
    lastName: string;
}

const BusinessUserNameCell: React.FC<BusinessUserNameCellProps> = ({ dbaName, firstName, lastName }): JSX.Element =>
    <p>
        <span className={styles.dbaName}>{dbaName}</span>
        <br />
        {firstName} {lastName}
    </p>;

export default BusinessUserNameCell;