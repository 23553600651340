import React from 'react';
import {
    Row,
    Section,
    SectionLabel
} from '../components';
import CardForm from '../components/engineering/cardForm/CardForm';
import Content from '../layouts/content/Content';
import EntireLayout from '../layouts/entireLayout/EntireLayout';
import Gap from '../layouts/gap/Gap';
import '../static-assets/styles/base/_popup-devextreme.scss';

const EngineeringMain: React.FC = () => (<EntireLayout>
    <Content>
        <Row>
            <Section>
                <SectionLabel>Iframe Rendering</SectionLabel>
                <Gap />
                <CardForm />
            </Section>
        </Row>
    </Content>
</EntireLayout>
);

export default EngineeringMain;
