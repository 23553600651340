import React from 'react';
import {
    BlockButton
} from 'src/components';
import { PaymentDetail } from 'src/util/interfaces';

interface props {
    data: PaymentDetail
};

const RenderPaymentStatusCell: React.FC<props> = ({ data }) => <BlockButton isBlocked={data.isBlocked} isOps={data.hasOpsUpdated} paymentType={data.paymentType} />;

export default RenderPaymentStatusCell;